<template>
  <button
    ref="buttonRef"
    :type="type"
    :is-loading="isLoading"
    :disabled="isLoading"
    class="ce-buttons-button btn"
    :class="{ 'is-icon': icon }"
    :data-bs-toggle="dropdown ? 'dropdown' : null"
  >
    <template v-if="isLoading">
      <slot name="loading">
        <span class="loading-indicator" />
      </slot>
    </template>
    <span><slot /></span>
  </button>
</template>
<script>
import { ref, onMounted } from 'vue'
import Dropdown from 'bootstrap/js/dist/dropdown.js'

export default {
  props: {
    type: {
      type: String,
      default: 'button'
    },
    isLoading: {
      type: Boolean,
      default: false
    },
    icon: {
      type: Boolean,
      required: false,
      default: false
    },
    dropdown: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  setup (props) {
    const buttonRef = ref(null)
    const buttonDropdown = ref(null)

    /* Lifecycles */
    onMounted(() => {
      if (props.dropdown) {
        buttonDropdown.value = new Dropdown(buttonRef.value)
      }
    })

    return {
      buttonRef,
      buttonDropdown
    }
  }
}
</script>

<style lang="scss" scoped>
.btn {
  &.is-icon {
    padding: 0 5px;
  }

  &.is-icon > span {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
</style>
