<template>
  <div>
    <slot>
      <ce-button
        v-if="group?.isExclusive"
        disabled
        class="interest-button text-white position-relative mt-md-0 mt-2 btn-gold"
      >
        Exclusive
      </ce-button>
      <ce-button
        v-else
        class="interest-button text-white position-relative mt-md-0 mt-2 px-4"
        :class="{
          'btn-red': alreadyJoined,
          'btn-green': !alreadyJoined,
        }"
        :is-loading="requestOnload"
        :disabled="requestOnload"
        @click.stop="askFirst()"
      >
        <transition name="fade">
          <ce-icon
            v-if="requestIcon"
            :icon="requestIcon"
            class="interest-request-status"
          />
        </transition>
        <template v-if="requestStatus">
          {{ requestStatus }}
        </template>
        <template v-else>
          {{ alreadyJoined ? alreadyJoinedText : joinToGroupText }}
        </template>
      </ce-button>
    </slot>
  </div>
</template>

<script>
import { ref, inject } from 'vue'
import debounce from 'lodash/debounce.js'
// import { Inertia } from '@inertiajs/inertia';
import CeButton from '@/components/Buttons/CeButton.vue'
// import { useJoinGroup, useGroup } from '@/Use/groups.js';
import emitter from '@/emitter.js'

export default {
  name: 'GroupJoinButton',
  components: { CeButton },
  props: {
    group: {
      type: Object,
      required: true
    },
    alreadyJoinedText: {
      type: String,
      required: false,
      default: 'Leave'
    },
    joinToGroupText: {
      type: String,
      required: false,
      default: 'Join Group'
    }
  },
  setup (props) {
    const alreadyJoined = ref(props.group.is_member)
    const requestStatus = ref(null)
    const requestIcon = ref(false)
    const requestOnload = ref(false)
    const dialog = inject('dialog')
    const { isUserLoggedIn } = ref(props.group.is_member)
    const clearRequestState = debounce(() => {
      requestIcon.value = null
      requestStatus.value = null
    }, 1500)

    // const { joinGroup, leaveGroup } = useJoinGroup();
    const postToWall = ref(false)

    const join = () => {
      // store.dispatch('listener/listenToNewGroup', props.group.id);
      // return joinGroup(props.group.id, postToWall.value);
    }
    const leave = () => {
      // store.dispatch('listener/unlistenToGroup', props.group.id);
      // return leaveGroup(props.group.id);
    }
    const groupAction = async () => {
      try {
        if (isUserLoggedIn.value) {
          requestIcon.value = null
          requestStatus.value = null
          requestOnload.value = true
          const data = !alreadyJoined.value ? await join() : await leave()
          requestOnload.value = false
          alreadyJoined.value = data.is_user_member
          requestIcon.value = 'check'
          emitter.emit(`groupChanged-${props.group.id}`, data)
        } else {
          // await Inertia.visit(
          //   `/login?redirect=groups/${props.group.slug}?auto-join=1&postToWall=${postToWall.value}`
          // );
        }
      } finally {
        clearRequestState()
      }
    }

    const askFirst = () => {
      if (alreadyJoined.value) {
        groupAction()
        return
      }

      const dialogButtons = [
        {
          label: 'Yes',
          class: 'btn-gradient-gold',
          handler: (closeDialog) => {
            postToWall.value = true
            groupAction()
            closeDialog()
          }
        },
        {
          label: 'No',
          class: 'btn-light-gray',
          handler: (closeDialog) => {
            postToWall.value = false
            groupAction()
            closeDialog()
          }
        }
      ]

      dialog.show('Do you want to post this on your wall?', {
        title: 'You are about to join this group',
        buttons: dialogButtons
      })
    }

    return {
      alreadyJoined,
      requestStatus,
      requestOnload,
      groupAction,
      askFirst
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~bootstrap/scss/bootstrap-utilities';
.interest-request-status {
  position: absolute;
  top: 50%;
  transform: translate(-130%, -40%);
  font-size: 0.8rem;
}
</style>
