<template>
  <div
    :id="modalId"
    ref="modalRef"
    class="modal fade"
    tabindex="-1"
    aria-hidden="true"
  >
    <div
      class="modal-dialog modal-dialog-centered"
      :class="dialogClassNames"
    >
      <div class="modal-content">
        <slot v-if="toShow || persistent" />
      </div>
    </div>
  </div>
</template>

<script>
import { ref, computed, onMounted, onBeforeUnmount } from 'vue'
import Modal from 'bootstrap/js/dist/modal.js'

export default {
  props: {
    size: {
      type: String,
      default: ''
    },
    staticBackdrop: {
      type: Boolean,
      default: false
    },
    preventClose: {
      type: Boolean,
      default: false
    },
    persistent: {
      type: Boolean,
      required: false,
      default: false
    },
    modalId: {
      type: String,
      required: false,
      default: 'ce-modal'
    }
  },
  emits: ['ready', 'close'],
  setup (props, { emit }) {
    const modalRef = ref(null)
    const modalInstance = ref(null)
    const toShow = ref(false)

    const dialogClassNames = computed(() => {
      const classNames = []
      switch (props.size) {
        case 'sm':
          classNames.push('modal-sm')
          break

        case 'lg':
          classNames.push('modal-lg')
          break

        case 'xl':
          classNames.push('modal-xl')
          break

        default:
      }

      return classNames
    })

    const preventCloseHandler = (e) => {
      if (props.preventClose) {
        // check if the ids are same
        if (modalRef.value.id === e.target.id) {
          e.preventDefault()
        }
      }
    }

    /* Methods */
    const open = () => {
      if (modalInstance.value) {
        modalInstance.value.show()
      }
    }

    const close = () => {
      if (modalInstance.value) {
        modalRef.value.removeEventListener(
          'hide.bs.modal',
          preventCloseHandler
        )
        modalInstance.value.hide()
        if (toShow.value) {
          toShow.value = false
          emit('close')
        }
      }
    }

    /* Lifecycles */
    onMounted(() => {
      modalRef.value.addEventListener('show.bs.modal', () => {
        toShow.value = true
      })
      if (props.preventClose) {
        modalRef.value.addEventListener('hide.bs.modal', preventCloseHandler)
      }
      if (!props.preventClose) {
        modalRef.value.addEventListener('hidden.bs.modal', () => {
          toShow.value = false
          emit('close')
        })
      }
      modalInstance.value = new Modal(modalRef.value, {
        backdrop: props.staticBackdrop ? 'static' : true,
        keyboard: !props.preventClose
      })
      emit('ready')
    })

    onBeforeUnmount(() => {
      close()
    })

    return {
      modalRef,
      toShow,
      dialogClassNames,
      open,
      close
    }
  }
}
</script>
