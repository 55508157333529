<template>
  <aside
    class="main-sidebar sidebar-light-secondary elevation-4 position-fixed"
  >
    <router-link to="/" class="brand-link">
      <img
        src="../../assets/logo_hand.png"
        alt="AdminLTE Logo"
        class="brand-image"
      />
      <span class="brand-text font-weight-light">
        <img
          src="../../assets/perfect_friend.png"
          alt="Perfect Friend"
          class="perfect-friend-logo"
        />
      </span>
    </router-link>
    <div class="sidebar">
      <nav class="mt-2">
        <ul
          class="nav nav-pills nav-sidebar flex-column"
          data-widget="treeview"
          role="menu"
          data-accordion="true"
        >
          <li class="nav-item">
            <router-link
              :to="{ name: 'home' }"
              active-class="active-sidebar"
              class="nav-link"
            >
              <i class="nav-icon fas fa-house" />
              <p>Home</p>
            </router-link>
          </li>
          <li class="nav-item">
            <router-link
              :to="{ name: 'inbox' }"
              active-class="active-sidebar"
              class="nav-link"
            >
              <i class="nav-icon fas fa-message" />
              <p>Inbox</p>
            </router-link>
          </li>
          <li class="nav-item">
            <a href="#" active-class="active-sidebar" class="nav-link">
              <i class="nav-icon fas fa-folder-tree" />
              <p>
                Directory
                <i class="fas fa-angle-right right" />
                <span class="badge badge-info right">5</span>
              </p>
            </a>
            <ul class="nav nav-treeview">
              <li class="nav-item">
                <router-link
                  :to="{ name: 'ManageUser' }"
                  active-class="active-sidebar"
                  class="nav-link"
                >
                  <i class="nav-icon far fa-circle text-info" />
                  <p>Users</p>
                </router-link>
              </li>
              <li class="nav-item">
                <router-link
                  :to="{ name: 'ManageGroup' }"
                  active-class="active-sidebar"
                  class="nav-link"
                >
                  <i class="nav-icon far fa-circle text-info" />
                  <p>Groups</p>
                </router-link>
              </li>
              <li class="nav-item">
                <router-link
                  :to="{ name: 'ManageEvent' }"
                  active-class="active-sidebar"
                  class="nav-link"
                >
                  <i class="nav-icon far fa-circle text-info" />
                  <p>Events</p>
                </router-link>
              </li>
              <li class="nav-item">
                <router-link
                  :to="{ name: 'ManageHuddle' }"
                  active-class="active-sidebar"
                  class="nav-link"
                >
                  <i class="nav-icon far fa-circle text-info" />
                  <p>Huddles</p>
                </router-link>
              </li>
              <li class="nav-item">
                <a href="#" active-class="active-sidebar" class="nav-link">
                  <i class="nav-icon far fa-circle text-info" />
                  <p>Deals</p>
                </a>
              </li>
            </ul>
          </li>

          <li class="nav-item">
            <router-link
              :to="{ name: 'alert' }"
              active-class="active-sidebar"
              class="nav-link"
            >
              <i class="nav-icon fas fa-shield" />
              <p>Alert</p>
            </router-link>
          </li>
          <li class="nav-item">
            <router-link
              :to="{ name: 'reporting' }"
              active-class="active-sidebar"
              class="nav-link"
            >
              <i class="nav-icon fas fa-chart-simple" />
              <p>Reporting</p>
            </router-link>
          </li>
          <li class="nav-item">
            <a href="#" active-class="active-sidebar" class="nav-link">
              <i class="nav-icon far fa-id-card" />
              <p>
                Subscriptions
                <i class="fas fa-angle-right right" />
              </p>
            </a>
            <ul class="nav nav-treeview">
                <li class="nav-item">
                    <router-link
                        :to="{ name: 'Subscribers' }"
                        active-class="active-sidebar"
                        class="nav-link"
                        >
                        <i class="nav-icon fas fa-users" />
                        <p>Subscribers</p>
                    </router-link>
                </li>
                <li class="nav-item">
                    <router-link
                        :to="{ name: 'UserTier' }"
                        active-class="active-sidebar"
                        class="nav-link"
                        >
                        <i class="nav-icon fas fa-list" />
                        <p>Tiers</p>
                    </router-link>
                </li>
                <li class="nav-item">
                    <router-link
                        :to="{ name: 'FeatureBlocker' }"
                        active-class="active-sidebar"
                        class="nav-link"
                        >
                        <i class="nav-icon fas fa-align-justify"></i>
                        <p>Feature Blocker</p>
                    </router-link>
                </li>
                <li class="nav-item">
                    <router-link
                        :to="{ name: 'Abilities' }"
                        active-class="active-sidebar"
                        class="nav-link"
                        >
                        <i class="nav-icon fas fa-screwdriver-wrench"></i>
                        <p>Abilities</p>
                    </router-link>
                </li>
            </ul>
          </li>
        </ul>
      </nav>
    </div>
  </aside>
</template>

<script>
export default {
  // mounted(){    
  //   $('[data-widget="treeview"]').Treeview('init');
  // } 
};
</script>

<style>
.brand-image {
  margin-top: 7px !important;
}
.perfect-friend-logo {
  margin-left: -13px !important;
}
.nav-icon {
  color: #343a40;
}
.fas {
  color: #343a40;
}
.sidebar {
  padding-left: 0px !important;
}
.active-sidebar {
  background: #e2eaf1 !important;
  border-radius: 0px 25px 25px 0px !important;
  color: #b18d47 !important;
}

.active-sidebar > .nav-icon {
  color: #b18d47;
}

.nav-treeview{
    margin-left: 10px;
}
.nav-sidebar .menu-is-opening>.nav-link i.right, .nav-sidebar .menu-is-opening>.nav-link svg.right, .nav-sidebar .menu-open>.nav-link i.right, .nav-sidebar .menu-open>.nav-link svg.right {
    -webkit-transform: rotate(-90deg) !important;
    transform: rotate(90deg) !important;
}
</style>
