<template>
  <group-media-form
    class="form-1"
    :group-id="groupForm.id"
    :data="{
      bannerPhotoId: Number(groupForm?.image_id),
      previewVideoId: groupForm?.video_id,
    }"
    @success="groupMediaCreated"
  >
    <template #submit="{ submit, isSubmitting }">
      <div class="row">
        <div class="col-6 col-sm-6 mb-3">
          <ce-button
            class="btn-lg btn btn-gray text-white text-bold w-100"
            @click="$emit('cancelEdit')"
          >
            Cancel
          </ce-button>
        </div>
        <div class="col-6 col-sm-6 mb-3">
          <ce-button
            class="btn-lg btn-gradient-gold text-bold w-100"
            :is-loading="isSubmitting"
            :disabled="isSubmitting"
            @click="submit"
          >
            {{ isPublished ? "Save" : "Continue" }}
          </ce-button>
        </div>
      </div>
    </template>
  </group-media-form>
</template>

<script>
import { defineAsyncComponent, computed, ref } from "vue";
import { useStore } from "vuex";
import { useRoute, useRouter } from "vue-router";

export default {
  components: {
    GroupMediaForm: defineAsyncComponent(() =>
      import("@/Modules/Group/GroupMediaForm.vue")
    ),
  },
  props: {},
  emits: ["createGroup", "isLoading", "cancelEdit"],
  async setup(props, { emit }) {
    const store = useStore();

    const route = useRoute();
    const router = useRouter();

    const slug = ref(router.currentRoute._value.params.slug);

    emit("isLoading", true);
    const getGroupInfoResource = await store.dispatch("group/groupEdit", {
      slug: slug.value,
      api: "media",
    });
    emit("isLoading", false);

    store.dispatch("group/setGroupInfo", getGroupInfoResource.data);
    const groupForm = computed(() => getGroupInfoResource.data.form);

    const groupMediaCreated = (response) => {
      emit("createGroup", response.data, { steps: "media" });
    };

    const isPublished = computed(() => getGroupInfoResource.data.isPublished);

    return {
      isPublished,
      groupMediaCreated,
      slug,
      groupForm,
    };
  },
};
</script>

<style lang="scss" scoped>
@import "../../scss/customizations";

:deep() {
  @import "../../scss/form/form-1";
}

.container {
  max-width: 680px;
}

/* purgecss start ignore */
:deep(.ce-inputs-textarea textarea) {
  $number-of-rows: 5;
  $line-height: 1.5rem;
  $border-width-total: 2px;
  $padding-total: 1.5rem;

  min-height: textarea-height(
    $number-of-rows,
    $line-height,
    $border-width-total,
    $padding-total
  );
}

/* purgecss end ignore */
</style>
