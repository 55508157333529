<template>
  <div>
    <Preloader :is-loading="isLoading" />
    <div class="card card-success">
      <div class="card-body">
        <header-stories-component
          :title="`Edit Profile Info`"
          :search-for="`users`"
          :show-search="false"
          :show-total="false"
        />
      </div>
    </div>
    <div class="card card-primary">
      <div class="card-body pa-5">
        <div class="card card-primary profile">
          <div class="card-body">
            <div class="col-xl-12 col-lg-12 col-md-12">
              <div class="row">
                <div class="col-xl-4 col-lg-4 col-md-4 col-sm-12">
                  <div class="profile-photo mb-3">
                    <ce-aspect-ratio
                      :aspect-ratio="1 / 1"
                      class="bg-light-gray rounded-2 overflow-hidden"
                    >
                      <photo-field
                        :src="profile?.primary_photo?.modified"
                        thumbnail-size="md"
                        :blur-hash="profile?.primary_photo?.blurhash"
                        :placeholder="`//via.placeholder.com/150`"
                        croppable
                        :cropper-options="{
                          dragMode: 'move',
                          viewMode: 3,
                          cropBoxMovable: false,
                          cropBoxResizable: false,
                          autoCropArea: 1,
                          aspectRatio: 1,
                        }"
                        :alt="altText"
                        @finished="savePrimaryPhoto"
                        @removed="removeProfilePhoto"
                      />
                    </ce-aspect-ratio>
                  </div>
                  <div class="text-white mt-4">
                    <section
                      class="d-flex align-items-center justify-content-between mb-2"
                    >
                      <h2 class="fs-6 text-gray mb-0">Smoker</h2>
                      <span v-if="!isEditedUserStatus" class="fw-semibold">{{
                        profile?.profile?.are_you_smoker.value
                      }}</span>
                      <select
                        v-if="isEditedUserStatus"
                        v-model="areYouSmoker"
                        class="form-select form-select-sm"
                      >
                        <option
                          v-for="(row, index) in smoking"
                          :key="index"
                          :value="row.id"
                        >
                          {{ row.value }}
                        </option>
                      </select>
                    </section>
                    <section
                      class="d-flex align-items-center justify-content-between mb-2"
                    >
                      <h2 class="fs-6 text-gray mb-0">Drinking</h2>
                      <span v-if="!isEditedUserStatus" class="fw-semibold">{{
                        profile?.profile?.are_you_drinker.value
                      }}</span>
                      <select
                        v-if="isEditedUserStatus"
                        v-model="areYouDrinker"
                        class="form-select form-select-sm"
                      >
                        <option
                          v-for="(row, index) in drinking"
                          :key="index"
                          :value="row.id"
                        >
                          {{ row.value }}
                        </option>
                      </select>
                    </section>
                    <section
                      class="d-flex align-items-center justify-content-between mb-2"
                    >
                      <h2 class="fs-6 text-gray mb-0">Relationship</h2>
                      <span v-if="!isEditedUserStatus" class="fw-semibold">{{
                        profile?.profile?.relationship_status.value
                      }}</span>
                      <select
                        v-if="isEditedUserStatus"
                        v-model="userRelationshipStatus"
                        class="form-select form-select-sm"
                      >
                        <option
                          v-for="(row, index) in relationshipStatus"
                          :key="index"
                          :value="row.id"
                        >
                          {{ row.value }}
                        </option>
                      </select>
                    </section>
                    <section
                      class="d-flex align-items-center justify-content-between mb-2"
                    >
                      <h2 class="fs-6 text-gray mb-0">Children</h2>
                      <span v-if="!isEditedUserStatus" class="fw-semibold">{{
                        profile?.profile?.any_children.value
                      }}</span>
                      <select
                        v-if="isEditedUserStatus"
                        v-model="anyChildren"
                        class="form-select form-select-sm"
                      >
                        <option
                          v-for="(row, index) in children"
                          :key="index"
                          :value="row.id"
                        >
                          {{ row.value }}
                        </option>
                      </select>
                    </section>
                    <section
                      class="d-flex align-items-center justify-content-between"
                    >
                      <h2 class="fs-6 text-gray mb-0">Education</h2>
                      <span v-if="!isEditedUserStatus" class="fw-semibold">{{
                        profile?.profile?.education_level.value
                      }}</span>
                      <select
                        v-if="isEditedUserStatus"
                        v-model="educationLevel"
                        class="form-select form-select-sm"
                      >
                        <option
                          v-for="(row, index) in educationalLevel"
                          :key="index"
                          :value="row.id"
                        >
                          {{ row.value }}
                        </option>
                      </select>
                    </section>
                    <div
                      class="d-flex align-items-center justify-content-center mt-3"
                    >
                      <div v-if="!isEditedUserStatus">
                        <button
                          type="button"
                          is-loading="false"
                          class="ce-buttons-button btn btn-sm btn-transparent p-0 edit-btn"
                          @click="edit('isEditedUserStatus')"
                        >
                          <span> Edit </span>
                        </button>
                      </div>
                      <div v-else>
                        <button
                          type="button"
                          is-loading="false"
                          class="ce-buttons-button btn btn-sm btn-transparent p-0 edit-btn"
                          @click="saveProfile('isEditedUserStatus')"
                        >
                          <span> Save </span>
                        </button>
                        <span class="separator"> | </span>
                        <button
                          type="button"
                          is-loading="false"
                          class="ce-buttons-button btn btn-sm btn-transparent p-0 edit-btn"
                          @click="edit('isEditedUserStatus')"
                        >
                          <span> Cancel </span>
                        </button>
                      </div>
                    </div>
                  </div>
                  <section class="mt-5">
                    <div class="d-flex align-items-center mb-2">
                      <h2 class="fs-6 ff-merriweather fw-semibold mb-0">
                        What I'm looking for in Perfect Friends
                      </h2>
                    </div>
                    <section
                      class="d-flex align-items-center justify-content-between mb-2"
                    >
                      <h2 class="fs-6 text-gray mb-0">Income Level</h2>
                      <span
                        v-if="!isEditedPreparedStatus"
                        class="d-flex fw-semibold"
                      >
                        <!-- <div class="multiselect-tag is-user" v-for="row in profile.preference.income_level" :key="row.id"> {{ row.value }}</div> -->
                        <div
                          v-for="row in profile?.preference?.income_level"
                          :key="row.id"
                          class=""
                        >
                          {{ row.value }}
                        </div>
                      </span>
                      <select
                        v-else
                        v-model="incomeLevelPreference"
                        class="form-select"
                      >
                        <option
                          v-for="row in incomeLevel"
                          :key="row.id"
                          :value="row.id"
                        >
                          {{ row.value }}
                        </option>
                      </select>
                      <!-- <multi-select-component
                                    v-else
                                    :options="incomeLevel"
                                    :type="`income_preference`"
                                    @select="selectedPreference"
                                    @deselect="deSelectedPreference"
                                /> -->
                    </section>
                    <section
                      class="d-flex align-items-center justify-content-between mb-2"
                    >
                      <h2 class="fs-6 text-gray mb-0">Ethnicity</h2>
                      <span
                        v-if="!isEditedPreparedStatus"
                        class="d-flex fw-semibold"
                      >
                        <!-- <div class="multiselect-tag is-user" v-for="row in profile.preference.ethnicity" :key="row.id"> {{ row.value }}</div> -->
                        <div
                          v-for="row in profile?.preference?.ethnicity"
                          :key="row.id"
                          class=""
                        >
                          {{ row.value }}
                        </div>
                      </span>
                      <select
                        v-else
                        v-model="ethnicityPreference"
                        class="form-select"
                      >
                        <option
                          v-for="row in ethnicity"
                          :key="row.id"
                          :value="row.id"
                        >
                          {{ row.value }}
                        </option>
                      </select>
                      <!-- <multi-select-component
                                    v-else
                                    :options="ethnicity"
                                    :type="`ethnicity_preference`"
                                    @select="selectedPreference"
                                    @deselect="deSelectedPreference"
                                /> -->
                    </section>
                    <section
                      class="d-flex align-items-center justify-content-between mb-2"
                    >
                      <h2 class="fs-6 text-gray mb-0">Gender</h2>
                      <span
                        v-if="!isEditedPreparedStatus"
                        class="d-flex fw-semibold"
                      >
                        <!-- <div class="multiselect-tag is-user" v-for="row in profile.preference.gender" :key="row.id"> {{ row.value }}</div> -->
                        <div
                          v-for="row in profile?.preference?.gender"
                          :key="row.id"
                          class=""
                        >
                          {{ row.value }}
                        </div>
                      </span>
                      <select
                        v-else
                        v-model="genderPreference"
                        class="form-select"
                      >
                        <option
                          v-for="row in gender"
                          :key="row.id"
                          :value="row.id"
                        >
                          {{ row.value }}
                        </option>
                      </select>
                      <!-- <multi-select-component
                                    v-else
                                    :options="gender"
                                    :type="`gender_preference`"
                                    @select="selectedPreference"
                                    @deselect="deSelectedPreference"
                                /> -->
                    </section>
                    <section
                      class="d-flex align-items-center justify-content-between mb-2"
                    >
                      <h2 class="fs-6 text-gray mb-0">Age Range</h2>
                      <span
                        v-if="!isEditedPreparedStatus"
                        class="d-flex fw-semibold"
                        >{{ profile?.preference?.age_from }}-{{
                          profile?.preference?.age_to
                        }}</span
                      >
                      <ce-range
                        v-else
                        v-model="localValueAge"
                        class="form-select-sm"
                        style="width: 62%"
                        :min="minAge"
                        :max="maxAge"
                      >
                        <template #label="{ startValue, endValue }">
                          <small>{{ startValue }} - {{ endValue }}</small>
                        </template>
                      </ce-range>
                    </section>
                    <section
                      class="d-flex align-items-center justify-content-between mb-2"
                    >
                      <h2 class="fs-6 text-gray mb-0">Zodiac Sign</h2>
                      <span
                        v-if="!isEditedPreparedStatus"
                        class="d-flex fw-semibold"
                      >
                        <!-- <div class="multiselect-tag is-user" v-for="row in profile.preference.zodiac_sign" :key="row.id"> {{ row.value }}</div> -->
                        <div
                          v-for="row in profile?.preference?.zodiac_sign"
                          :key="row.id"
                          class=""
                        >
                          {{ row.value }}
                        </div>
                      </span>
                      <select
                        v-else
                        v-model="zodiacSignPreference"
                        class="form-select"
                      >
                        <option
                          v-for="row in zodiacSign"
                          :key="row.id"
                          :value="row.id"
                        >
                          {{ row.value }}
                        </option>
                      </select>
                      <!-- <multi-select-component
                                    v-else
                                    :options="zodiacSign"
                                    :type="`zodiac_sign_preference`"
                                    @select="selectedPreference"
                                    @deselect="deSelectedPreference"
                                /> -->
                    </section>
                    <section
                      class="d-flex align-items-center justify-content-between mb-2"
                    >
                      <h2 class="fs-6 text-gray mb-0">Smoker</h2>
                      <span
                        v-if="!isEditedPreparedStatus"
                        class="d-flex fw-semibold"
                      >
                        <!-- <div class="multiselect-tag is-user" v-for="row in profile.preference.are_you_smoker" :key="row.id"> {{ row.value }}</div> -->
                        <div
                          v-for="row in profile?.preference?.are_you_smoker"
                          :key="row.id"
                          class=""
                        >
                          {{ row.value }}
                        </div>
                      </span>
                      <select
                        v-else
                        v-model="smokingPreference"
                        class="form-select"
                      >
                        <option
                          v-for="row in smoking"
                          :key="row.id"
                          :value="row.id"
                        >
                          {{ row.value }}
                        </option>
                      </select>
                      <!-- <multi-select-component
                                    v-else
                                    :options="smoking"
                                    :type="`smoking_preference`"
                                    @select="selectedPreference"
                                    @deselect="deSelectedPreference"
                                /> -->
                    </section>
                    <section
                      class="d-flex align-items-center justify-content-between mb-2"
                    >
                      <h2 class="fs-6 text-gray mb-0">Drinker</h2>
                      <span
                        v-if="!isEditedPreparedStatus"
                        class="d-flex fw-semibold"
                      >
                        <!-- <div class="multiselect-tag is-user" v-for="row in profile.preference.are_you_drinker" :key="row.id"> {{ row.value }}</div> -->
                        <div
                          v-for="row in profile?.preference?.are_you_drinker"
                          :key="row.id"
                          class=""
                        >
                          {{ row.value }}
                        </div>
                      </span>
                      <select
                        v-else
                        v-model="drinkingPreference"
                        class="form-select"
                      >
                        <option
                          v-for="row in drinking"
                          :key="row.id"
                          :value="row.id"
                        >
                          {{ row.value }}
                        </option>
                      </select>
                      <!-- <multi-select-component
                                    v-else
                                    :options="drinking"
                                    :type="`drinking_preference`"
                                    @select="selectedPreference"
                                    @deselect="deSelectedPreference"
                                /> -->
                    </section>
                    <section
                      class="d-flex align-items-center justify-content-between mb-2"
                    >
                      <h2 class="fs-6 text-gray mb-0">Relationship</h2>
                      <span
                        v-if="!isEditedPreparedStatus"
                        class="d-flex fw-semibold"
                      >
                        <!-- <div class="multiselect-tag is-user" v-for="row in profile.preference.relationship_status" :key="row.id"> {{ row.value }}</div> -->
                        <div
                          v-for="row in profile?.preference
                            ?.relationship_status"
                          :key="row.id"
                          class=""
                          :value="row.id"
                        >
                          {{ row.value }}
                        </div>
                      </span>
                      <select
                        v-else
                        v-model="relationshipStatusPreference"
                        class="form-select"
                      >
                        <option
                          v-for="row in relationshipStatus"
                          :key="row.id"
                          :value="row.id"
                        >
                          {{ row.value }}
                        </option>
                      </select>
                      <!-- <multi-select-component
                                    v-else
                                    :options="relationshipStatus"
                                    :type="`relationship_status_preference`"
                                    @select="selectedPreference"
                                    @deselect="deSelectedPreference"
                                /> -->
                    </section>
                    <section
                      class="d-flex align-items-center justify-content-between mb-2"
                    >
                      <h2 class="fs-6 text-gray mb-0">Children</h2>
                      <span v-if="!isEditedPreparedStatus" class="fw-semibold">
                        <!-- <div class="multiselect-tag is-user"> {{ profile.preference.any_children }}</div> -->
                        <div class="">
                          {{ profile?.preference?.any_children }}
                        </div>
                      </span>
                      <select
                        v-else
                        v-model="childrenPreference"
                        class="form-select"
                      >
                        <option
                          v-for="row in children"
                          :key="row.id"
                          :value="row.id"
                        >
                          {{ row.value }}
                        </option>
                      </select>
                      <!-- <multi-select-component
                                    v-else
                                    :options="children"
                                    :type="`children_preference`"
                                    @select="selectedPreference"
                                    @deselect="deSelectedPreference"
                                /> -->
                    </section>
                    <section
                      class="d-flex align-items-center justify-content-between mb-2"
                    >
                      <h2 class="fs-6 text-gray mb-0">Education</h2>
                      <span v-if="!isEditedPreparedStatus" class="fw-semibold">
                        <!-- <div class="multiselect-tag is-user" v-for="row in profile.preference.education_level" :key="row.id"> {{ row.value }}</div> -->
                        <div
                          v-for="row in profile?.preference?.education_level"
                          :key="row.id"
                          class=""
                        >
                          {{ row.value }}
                        </div>
                      </span>
                      <select
                        v-else
                        v-model="educationalLevelPreference"
                        class="form-select"
                      >
                        <option
                          v-for="row in educationalLevel"
                          :key="row.id"
                          :value="row.id"
                        >
                          {{ row.value }}
                        </option>
                      </select>
                      <!-- <multi-select-component
                                    v-else
                                    :options="educationalLevel"
                                    :type="`educational_level_preference`"
                                    @select="selectedPreference"
                                    @deselect="deSelectedPreference"
                                /> -->
                    </section>
                    <section
                      class="d-flex align-items-center justify-content-between mb-2"
                    >
                      <h2 class="fs-6 text-gray mb-0">Body Type</h2>
                      <span v-if="!isEditedPreparedStatus" class="fw-semibold">
                        <!-- <div class="multiselect-tag is-user" v-for="row in profile.preference.body_type" :key="row.id"> {{ row.value }}</div> -->
                        <div
                          v-for="row in profile?.preference?.body_type"
                          :key="row.id"
                          class=""
                        >
                          {{ row.value }}
                        </div>
                      </span>
                      <select
                        v-else
                        v-model="bodyTypePreference"
                        class="form-select"
                      >
                        <option
                          v-for="row in bodyType"
                          :key="row.id"
                          :value="row.id"
                        >
                          {{ row.value }}
                        </option>
                      </select>
                      <!-- <multi-select-component
                                    v-else
                                    :options="bodyType"
                                    :type="`body_type_preference`"
                                    @select="selectedPreference"
                                    @deselect="deSelectedPreference"
                                /> -->
                    </section>
                    <section
                      class="d-flex align-items-center justify-content-between mb-2"
                    >
                      <h2 class="fs-6 text-gray mb-0">Height</h2>
                      <span v-if="!isEditedPreparedStatus" class="fw-semibold"
                        >4'3-5'9</span
                      >
                      <ce-range
                        v-else
                        v-model="localValueModel"
                        class="form-select-sm"
                        style="width: 62%"
                        :min="minHeight"
                        :max="maxHeight"
                      >
                        <template #label="{ startValue, endValue }">
                          <small
                            >{{ heightInString(startValue) }} -
                            {{ heightInString(endValue) }}</small
                          >
                        </template>
                      </ce-range>
                    </section>
                    <div
                      class="d-flex align-items-center justify-content-center mt-3"
                    >
                      <div v-if="!isEditedPreparedStatus">
                        <button
                          type="button"
                          is-loading="false"
                          class="ce-buttons-button btn btn-sm btn-transparent p-0 edit-btn"
                          @click="edit('isEditedPreparedStatus')"
                        >
                          <span> Edit </span>
                        </button>
                      </div>
                      <div v-else>
                        <button
                          type="button"
                          is-loading="false"
                          class="ce-buttons-button btn btn-sm btn-transparent p-0 edit-btn"
                          @click="updatePreference"
                        >
                          <span> Save </span>
                        </button>
                        <span class="separator"> | </span>
                        <button
                          type="button"
                          is-loading="false"
                          class="ce-buttons-button btn btn-sm btn-transparent p-0 edit-btn"
                          @click="cancelPreference"
                        >
                          <span> Cancel </span>
                        </button>
                      </div>
                    </div>
                  </section>
                </div>
                <div class="col-xl-7 col-lg-7 col-md-7 col-sm-12 ml-4">
                  <section class="text-white">
                    <div class="d-flex">
                      <section class="flex-fill">
                        <div
                          v-if="!isEditedBasicInfo"
                          class="d-flex flex-wrap align-items-top"
                        >
                          <h1 class="fs-2 ff-merriweather fw-bold mb-0">
                            {{ lastName }} {{ firstName }}
                          </h1>
                          <div class="d-flex align-items-center ms-3">
                            <button
                              type="button"
                              is-loading="false"
                              class="btn btn-sm edit-btn"
                              @click="edit(`isEditedBasicInfo`)"
                            >
                              <span> Edit </span>
                            </button>
                          </div>
                        </div>
                        <div v-else class="row">
                          <div class="col-lg-12">
                            <div class="row">
                              <div class="col-lg-4">
                                <span> First Name</span>
                                <input
                                  v-model="firstName"
                                  class="form-control form-control-sm pf-text"
                                  type="text"
                                />
                              </div>
                              <div class="col-lg-4">
                                <span> Last Name</span>
                                <input
                                  v-model="lastName"
                                  class="form-control form-control-sm pf-text"
                                  type="text"
                                />
                              </div>
                              <div class="col-lg-4 mt-4">
                                <button
                                  type="button"
                                  is-loading="false"
                                  class="ce-buttons-button btn btn-sm btn-transparent p-0 edit-btn"
                                  @click="saveProfile('isEditedBasicInfo')"
                                >
                                  <span> Save </span>
                                </button>
                                <span class="separator"> | </span>
                                <button
                                  type="button"
                                  is-loading="false"
                                  class="ce-buttons-button btn btn-sm btn-transparent p-0 edit-btn"
                                  @click="cancelEditBasicInfo"
                                >
                                  <span> Cancel </span>
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          class="ls-3 fw-semibold small text-gray"
                          style="text-transform: uppercase"
                        >
                          {{ profile.subscription_type }} ACCOUNT
                        </div>
                        <div
                          v-if="!isEditedBasicInfo"
                          class="py-3 fw-semibold small"
                        >
                          <span>
                            {{
                              moment().diff(
                                moment(profile?.birth_date),
                                "years"
                              )
                            }}
                            - </span
                          >{{ profile?.zodiac_sign?.value }} <br />
                          {{ profile?.gender?.value }} <br />
                          {{ profile?.profile?.ethnicity.value }} <br />
                          {{ completeAddress }}
                        </div>
                        <div v-else class="row mt-3">
                          <div class="col-lg-6 mb-4">
                            <div class="col-lg-12">
                              <span>Birthday</span>
                              <datepicker
                                ref="inputRef"
                                v-model="picked"
                                type="date"
                                :format="dd - MM - YYYY"
                                class="form-control form-control-sm pf-text"
                                style="
                                  background: #262626 !important;
                                  color: #cacaca;
                                "
                              />
                            </div>
                            <div class="col-lg-12 mt-2">
                              <span>Gender</span>
                              <select
                                v-model="userGender"
                                class="form-select w-100 form-select-sm"
                              >
                                <option
                                  v-for="(row, index) in gender"
                                  :key="index"
                                  :value="row.id"
                                >
                                  {{ row.value }}
                                </option>
                              </select>
                            </div>
                            <div class="col-lg-12 mt-2">
                              <span>Ethnicity</span>
                              <select
                                v-model="userEthnicity"
                                class="form-select w-100 form-select-sm"
                              >
                                <option
                                  v-for="(row, index) in ethnicity"
                                  :key="index"
                                  :value="row.id"
                                >
                                  {{ row.value }}
                                </option>
                              </select>
                            </div>
                          </div>
                          <div class="col-lg-6 mb-4">
                            <div class="col-lg-12">
                              <span>Location</span>
                              <vue-google-autocomplete
                                id="map2"
                                ref="refAddress"
                                v-model="completeAddress"
                                classname="form-control form-control-sm pf-text"
                                placeholder="Please type your address"
                                types="(cities)"
                                country="us"
                                @placechanged="getAddressData"
                              />
                            </div>
                            <div class="col-lg-12 mt-2">
                              <span>Phone Number</span>
                              <input
                                v-model="profile.mobile_number"
                                class="form-control form-control-sm pf-text"
                                type="text"
                              />
                            </div>
                            <div class="col-lg-12 mt-2">
                              <span>Email Address</span>
                              <input
                                v-model="profile.email"
                                class="form-control form-control-sm pf-text"
                                type="text"
                              />
                            </div>
                          </div>
                        </div>
                      </section>
                      <div />
                    </div>
                    <div class="mt-2">
                      <div class="d-flex align-items-center">
                        <h2 class="fs-6 ff-merriweather fw-semibold">
                          Why I'm the Perfect Friend
                        </h2>
                        <div
                          v-if="!whyImThePerfectFriend"
                          class="d-flex align-items-center ms-3"
                        >
                          <button
                            type="button"
                            is-loading="false"
                            class="ce-buttons-button btn btn-sm mb-2 btn-transparent p-0 edit-btn"
                            @click="edit(`whyImThePerfectFriend`)"
                          >
                            <span> Edit </span>
                          </button>
                        </div>
                      </div>
                      <div
                        v-if="!whyImThePerfectFriend"
                        class="description-display"
                      >
                        {{ aboutMe }}
                      </div>
                      <div v-else class="row">
                        <div class="col-lg-12">
                          <div class="row">
                            <div>
                              <textarea
                                v-model="aboutMe"
                                class="form-control"
                                row="5"
                              />
                              <button
                                type="button"
                                is-loading="false"
                                class="ce-buttons-button btn btn-sm btn-transparent p-0 edit-btn"
                                @click="saveProfile('whyImThePerfectFriend')"
                              >
                                <span> Save </span>
                              </button>
                              <span class="separator"> | </span>
                              <button
                                type="button"
                                is-loading="false"
                                class="ce-buttons-button btn btn-sm btn-transparent p-0 edit-btn"
                                @click="cancelWhyImThePerfectFriend"
                              >
                                <span> Cancel </span>
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <hr class="bg-dark-gray my-5" />
                    <section class="pt-5">
                      <template v-if="editInProgress">
                        <div class="d-flex align-items-center ms-3">
                          <div class="spinner-border text-gray" role="status">
                            <span class="visually-hidden">Loading...</span>
                          </div>
                        </div>
                      </template>
                      <template v-else>
                        <div
                          v-if="!isEditPhoto"
                          class="d-flex align-items-center ms-3"
                        >
                          <button
                            type="button"
                            is-loading="false"
                            class="btn btn-sm edit-btn photo-edit"
                            @click="edit(`isPhotoEdit`)"
                          >
                            <span> Edit </span>
                          </button>
                        </div>
                        <div
                          v-else
                          class="d-flex align-items-center photo-edit-cancel"
                        >
                          <template v-if="imageToBeDeleted.length > 0">
                            <button
                              type="button"
                              is-loading="false"
                              class="ce-buttons-button btn btn-sm btn-transparent p-0 edit-btn"
                              @click="deleteImages"
                            >
                              <span> Delete </span>
                            </button>
                            <span class="separator"> | </span>
                          </template>
                          <button
                            type="button"
                            is-loading="false"
                            class="ce-buttons-button btn btn-sm btn-transparent p-0 edit-btn"
                            @click="edit(`isPhotoEdit`)"
                          >
                            <span> Cancel </span>
                          </button>
                        </div>
                      </template>

                      <div class="pt-2">
                        <photo-gallery
                          class="photo-gallery"
                          :photos="profile.photos"
                          image-key="modified"
                          image-key-full="full"
                          :is-edit-photo="isEditPhoto"
                          @galleryCheckUpdate="checkImage"
                        />
                      </div>
                    </section>
                    <section class="mt-5">
                      <div class="d-flex align-items-center">
                        <h2 class="fs-6 ff-merriweather fw-semibold">
                          What I’m looking for in a Perfect Friend
                        </h2>
                        <div
                          v-if="!whyImLookingPerfectFriend"
                          class="d-flex align-items-center ms-3"
                        >
                          <button
                            type="button"
                            is-loading="false"
                            class="ce-buttons-button btn btn-sm mb-2 btn-transparent p-0 edit-btn"
                            @click="edit(`whyImLookingPerfectFriend`)"
                          >
                            <span> Edit </span>
                          </button>
                        </div>
                      </div>
                      <div
                        v-if="!whyImLookingPerfectFriend"
                        class="description-display"
                      >
                        {{ lookingPerfectFriend }}
                      </div>
                      <div v-else class="row">
                        <div class="col-lg-12">
                          <div class="row">
                            <div>
                              <textarea
                                v-model="lookingPerfectFriend"
                                class="form-control"
                                row="5"
                              />
                              <button
                                type="button"
                                is-loading="false"
                                class="ce-buttons-button btn btn-sm btn-transparent p-0 edit-btn"
                                @click="
                                  saveProfile('whyImLookingPerfectFriend')
                                "
                              >
                                <span> Save </span>
                              </button>
                              <span class="separator"> | </span>
                              <button
                                type="button"
                                is-loading="false"
                                class="ce-buttons-button btn btn-sm btn-transparent p-0 edit-btn"
                                @click="cancelWhyImLookingPerfectFriend"
                              >
                                <span> Cancel </span>
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </section>
                    <section class="mt-5">
                      <div class="d-flex align-items-center">
                        <h2 class="fs-6 ff-merriweather fw-semibold">
                          The events and activities I’d like to do with my
                          Perfect Friends
                        </h2>
                        <div
                          v-if="!eventsAndActivities"
                          class="d-flex align-items-center ms-3"
                        >
                          <button
                            type="button"
                            is-loading="false"
                            class="ce-buttons-button btn btn-sm mb-2 btn-transparent p-0 edit-btn"
                            @click="edit(`eventsAndActivities`)"
                          >
                            <span> Edit </span>
                          </button>
                        </div>
                      </div>
                      <div
                        v-if="!eventsAndActivities"
                        class="description-display"
                      >
                        {{ eventActivities }}
                      </div>
                      <div v-else class="row">
                        <div class="col-lg-12">
                          <div class="row">
                            <div>
                              <textarea
                                v-model="eventActivities"
                                class="form-control"
                                row="5"
                              />
                              <button
                                type="button"
                                is-loading="false"
                                class="ce-buttons-button btn btn-sm btn-transparent p-0 edit-btn"
                                @click="saveProfile('eventsAndActivities')"
                              >
                                <span> Save </span>
                              </button>
                              <span class="separator"> | </span>
                              <button
                                type="button"
                                is-loading="false"
                                class="ce-buttons-button btn btn-sm btn-transparent p-0 edit-btn"
                                @click="cancelEventActivities"
                              >
                                <span> Cancel </span>
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </section>
                    <hr class="bg-dark-gray my-5" />
                    <section>
                      <div class="d-flex align-items-center mb-3">
                        <h2 class="fs-6 ff-merriweather fw-semibold mb-0">
                          Interests
                        </h2>
                        <div
                          v-if="!isInterest"
                          class="d-flex align-items-center ms-3"
                        >
                          <button
                            type="button"
                            is-loading="false"
                            class="ce-buttons-button btn btn-sm btn-transparent p-0 edit-btn"
                            @click="edit(`isInterest`)"
                          >
                            <span> Edit </span>
                          </button>
                        </div>
                        <div v-else class="d-flex align-items-center ms-3">
                          <button
                            type="button"
                            is-loading="false"
                            class="ce-buttons-button btn btn-sm btn-transparent p-0 edit-btn"
                            @click="updateInterest"
                          >
                            <span> Save </span>
                          </button>
                          <span class="separator"> | </span>
                          <button
                            type="button"
                            is-loading="false"
                            class="ce-buttons-button btn btn-sm btn-transparent p-0 edit-btn"
                            @click="cancelInterest"
                          >
                            <span> Cancel </span>
                          </button>
                        </div>
                      </div>
                      <span v-if="isInterest" class="mb-2"
                        >Add or Remove Interests</span
                      >
                      <div v-if="!isInterest" class="d-flex flex-wrap mb-n3">
                        <interest-chip-component
                          v-for="row in profileInterest"
                          :key="row.id"
                          :name="row.interest"
                          :interest-id="row.id"
                        />
                      </div>
                      <div
                        v-else
                        class="interest-search-chip my-3"
                        data-v-7c9430cd=""
                      >
                        <form class="mb-3" @submit.prevent="postInterest">
                          <div class="input-group">
                            <div class="input-group-prepend">
                              <span class="input-group-text"
                                ><i class="fa fa-magnifying-glass"
                              /></span>
                            </div>
                            <input
                              v-model="interestText"
                              type="text"
                              class="form-control pf-text"
                            />
                            <button
                              type="submit"
                              is-loading="false"
                              class="ce-buttons-button btn btn-gradient-gold"
                            >
                              <span> Add </span>
                            </button>
                          </div>
                        </form>
                        <div class="d-flex flex-wrap my-3">
                          <interest-chip-component
                            v-for="row in profileInterest"
                            :key="row.id"
                            :name="row.interest"
                            :interest-id="row.id"
                            :has-icon="true"
                            :icon="`xmark`"
                            :is-interested="getIncludes(row.id)"
                            :show-selected="false"
                            @remove="removeInterest(row)"
                          />
                        </div>
                        <hr class="" />
                        <div class="d-flex flex-wrap mb-n3">
                          <interest-chip-component
                            v-for="row in interest"
                            :key="row.id"
                            :name="row.interest"
                            :interest-id="row.id"
                            :has-icon="true"
                            :is-interested="getIncludes(row.id)"
                            :show-selected="true"
                            :icon="`heart`"
                            @add="addNewInterest(row)"
                            @remove="removeInterest(row)"
                          />
                        </div>
                      </div>
                    </section>
                  </section>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <alert-modal :message="message"></alert-modal>
  </div>
</template>
<script>
import { nextTick, onMounted, ref, reactive } from "vue";
import $ from "jquery";
import Datepicker from "vue3-datepicker";
import Preloader from "@/Layouts/Common/Preloader.vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import moment from "moment";
import CeRange from "@/components/Inputs/CeRange.vue";
import useHeight from "@/Use/use-height.js";
import useAge from "@/Use/use-age.js";
import HeaderStoriesComponent from "@/components/HeaderStoriesComponent.vue";
import ProfileImageComponent from "@/components/ProfileImageComponent.vue";
import InterestChipComponent from "@/components/InterestChipComponent.vue";
import VueGoogleAutocomplete from "vue-google-autocomplete";
import AlertModal from "@/components/AlertModal.vue";
import PhotoField from "@/components/Inputs/PhotoField.vue";
import PhotoGallery from "@/components/Galleries/PhotoGallery.vue";

// import MultiSelectComponent from '../../components/MultiSelectComponent.vue';

export default {
  name: "App",
  components: {
    HeaderStoriesComponent,
    ProfileImageComponent,
    Datepicker,
    CeRange,
    Preloader,
    InterestChipComponent,
    VueGoogleAutocomplete,
    AlertModal,
    PhotoField,
    PhotoGallery,
    // MultiSelectComponent
  },
  setup() {
    const store = useStore();
    const router = useRouter();

    const editInProgress = ref(false);

    const isEditedUserStatus = ref(false);
    const isEditedPreparedStatus = ref(false);
    const isEditedBasicInfo = ref(false);
    const whyImThePerfectFriend = ref(false);
    const whyImLookingPerfectFriend = ref(false);
    const eventsAndActivities = ref(false);
    const isInterest = ref(false);
    const isEditPhoto = ref(false);
    const picked = ref(new Date(store.state.user.profile.birth_date));
    // const profile = ref(JSON.parse(localStorage.getItem('profile')));
    const profile = ref([]);
    const selected = ref(null);
    const gender = ref(store.state.user.enums.gender);
    const ethnicity = ref(store.state.user.enums.ethnicity);
    const smoking = ref(store.state.user.enums.smoking);
    const drinking = ref(store.state.user.enums.drinking);
    const relationshipStatus = ref(store.state.user.enums.relationshipStatus);
    const educationalLevel = ref(store.state.user.enums.educationalLevel);
    const children = ref(store.state.user.enums.children);
    const bodyType = ref(store.state.user.enums.bodyType);
    const incomeLevel = ref(store.state.user.enums.incomeLevel);
    const zodiacSign = ref(store.state.user.enums.zodiacSign);
    const interest = ref(store.state.user.enums.interest);
    const profileInterest = ref([]);
    const eventActivities = ref(null);
    const lookingPerfectFriend = ref(null);
    const aboutMe = ref(null);
    const completeAddress = ref(null);
    const userGender = ref(null);
    const userEthnicity = ref(null);
    const firstName = ref(null);
    const lastName = ref(null);
    const mobileNumber = ref(null);
    const email = ref(null);
    const areYouSmoker = ref(null);
    const areYouDrinker = ref(null);
    const userRelationshipStatus = ref(null);
    const anyChildren = ref(null);
    const educationLevel = ref(null);

    const streetAddress = ref(null);
    const city = ref(null);
    const state = ref(null);
    const zipCode = ref(null);
    const country = ref(null);
    const latitude = ref(null);
    const longitude = ref(null);

    const isGoogleMapLoaded = ref(null);
    const localValue = ref(null);
    const hasModifiedLocation = ref(false);
    const isLoading = ref(false);
    const interestText = ref(null);

    const value = ref(null);

    const imageToBeDeleted = ref([]);
    const primaryPhoto = ref(null);

    const {
      toString: heightInString,
      min: minHeight,
      max: maxHeight,
    } = useHeight();
    const localValueModel = ref([minHeight, maxHeight]);

    const { min: minAge, max: maxAge } = useAge();
    const localValueAge = ref([minAge, maxAge]);

    // preference model
    const incomeLevelPreference = ref(
      profile.value.preference?.income_level[0]?.id
    );
    const ethnicityPreference = ref(profile.value.preference?.ethnicity[0]?.id);
    const genderPreference = ref(profile.value.preference?.gender[0]?.id);
    const zodiacSignPreference = ref(
      profile.value.preference?.zodiac_sign[0]?.id
    );
    const smokingPreference = ref(
      profile.value.preference?.are_you_smoker[0]?.id
    );
    const drinkingPreference = ref(
      profile.value.preference?.are_you_drinker[0]?.id
    );
    const relationshipStatusPreference = ref(
      profile.value.preference?.relationship_status[0]?.id
    );
    const childrenPreference = ref(profile.value.preference?.any_children);
    const educationalLevelPreference = ref(
      profile.value.preference?.education_level[0]?.id
    );
    const bodyTypePreference = ref(profile.value.preference?.body_type[0]?.id);

    const message = ref("");

    const edit = (label) => {
      if (label === "isEditedUserStatus") {
        isEditedUserStatus.value = !isEditedUserStatus.value;
      }
      if (label === "isEditedPreparedStatus") {
        isEditedPreparedStatus.value = !isEditedPreparedStatus.value;
      }
      if (label === "isEditedBasicInfo") {
        isEditedBasicInfo.value = !isEditedBasicInfo.value;
      }
      if (label === "whyImThePerfectFriend") {
        whyImThePerfectFriend.value = !whyImThePerfectFriend.value;
      }
      if (label === "whyImLookingPerfectFriend") {
        whyImLookingPerfectFriend.value = !whyImLookingPerfectFriend.value;
      }
      if (label === "eventsAndActivities") {
        eventsAndActivities.value = !eventsAndActivities.value;
      }
      if (label === "isInterest") {
        isInterest.value = !isInterest.value;
      }
      if (label === "isPhotoEdit") {
        isEditPhoto.value = !isEditPhoto.value;
        if (!isEditPhoto.value) {
          imageToBeDeleted.value = [];
        }
      }
    };

    // INTEREST
    const postInterest = async () => {
      const response = await store.dispatch("postInterest", {
        interest: interestText.value,
      });
      if (response.status === 200) {
        interest.value.push(response.data.data);
        await store.dispatch("getEnums");
        $(document).Toasts("create", {
          class: "bg-success",
          title: "Interest",
          body: "New interest successfully added!",
        });
      }
    };

    const addNewInterest = (key) => {
      profileInterest.value.push(key);
    };

    const removeInterest = (key) => {
      profileInterest.value.splice(profileInterest.value.indexOf(key), 1);
    };

    const getIncludes = (id) => {
      return profileInterest.value.find((value) => value.id === id);
    };

    const updateInterest = async () => {
      const interestForm = profileInterest.value.map(({ id }) => id);
      const form = {
        id: profile.value.id,
        interests: interestForm,
      };

      const response = await store.dispatch("updateProfile", form);
      if (response.status === 200) {
        localStorage.removeItem("profile");
        const resp = await store.dispatch("loadUserProfile", {
          value: profile.value.user_name,
        });
        localStorage.setItem("profile", JSON.stringify(resp));
        profile.value = resp;
        // $(document).Toasts('create', {
        //     class: 'bg-success',
        //     title: 'Interest',
        //     body: 'New interest successfully added!',
        // });
        isInterest.value = !isInterest.value;
      }
    };

    const cancelInterest = () => {
      profileInterest.value = interest.value.filter((value) => {
        return profile.value.interests.includes(value.interest);
      });
      edit("isInterest");
    };

    const savePrimaryPhoto = async (response) => {
      try {
        isBeingSaved.primaryPhoto = true;
        primaryPhoto.value = response.id;

        await nextTick();
        await saveProfile("isPrimaryPhoto");
      } finally {
        isBeingSaved.primaryPhoto = false;
      }
    };

    const saveProfile = async (section) => {
      const form = {
        id: profile.value.id,
        image: primaryPhoto.value,
        identify_events_activities: eventActivities.value,
        what_type_of_friend_are_you_looking_for: lookingPerfectFriend.value,
        about_me: aboutMe.value,
        street_address:
          streetAddress.value ?? profile.value.profile?.street_address,
        city: city.value ?? profile.value.profile?.city,
        state: state.value ?? profile.value.profile?.state,
        country: country.value ?? profile.value.profile?.country,
        latitude: latitude.value ?? profile.value.profile?.latitude,
        longitude: longitude.value ?? profile.value.profile?.longitude,
        gender: userGender.value,
        ethnicity: userEthnicity.value,
        first_name: firstName.value,
        last_name: lastName.value,
        mobile_number: mobileNumber.value,
        email: email.value,
        birth_date: picked.value,
        are_you_smoker: areYouSmoker.value,
        are_you_drinker: areYouDrinker.value,
        relationship_status: userRelationshipStatus.value,
        any_children: anyChildren.value,
        education_level: educationLevel.value,
        submit_block: section,
      };

      await store
        .dispatch("updateProfile", form)
        .then((response) => {
          if (response.status === 200) {
            edit(section);
            store
              .dispatch("loadUserProfile", { value: profile.value.user_name })
              .then((resp) => {
                localStorage.removeItem("profile");
                profile.value = resp;
                localStorage.setItem("profile", JSON.stringify(resp));
              });
          }
          if (response?.status === 203) {
            message.value = response.data.error;
            $("#alert-modal").modal("show");
          }
        })
        .catch((e) => {
          if (e.response?.status === 422) {
            const error = e.response?.data.error;
            Object.keys(error).forEach((value, index) => {
              message.value = e.response.data.error[value][0];
            });
            $("#alert-modal").modal("show");
          }
        });
    };

    const cancelEventActivities = () => {
      eventActivities.value = profile.value.profile.identify_events_activities;
      edit("eventsAndActivities");
    };

    const cancelWhyImLookingPerfectFriend = () => {
      lookingPerfectFriend.value =
        profile.value.profile.what_type_of_friend_are_you_looking_for;
      edit("whyImLookingPerfectFriend");
    };

    const cancelWhyImThePerfectFriend = () => {
      aboutMe.value = profile?.value.profile?.about_me;
      edit("whyImThePerfectFriend");
    };

    const cancelEditBasicInfo = () => {
      userGender.value = profile.value.profile.gender.id;
      userEthnicity.value = profile.value.profile.ethnicity.id;
      firstName.value = profile.value.first_name;
      lastName.value = profile.value.last_name;
      mobileNumber.value = profile.value.mobile_number;
      email.value = profile.value.email;
      picked.value = new Date(store.state.user.profile.birth_date);
      edit("isEditedBasicInfo");
    };

    const getAddressData = (addressData, placeResultData) => {
      streetAddress.value = placeResultData.formatted_address;
      city.value = placeResultData.address_components[0].long_name;
      state.value = placeResultData.address_components[2].long_name;
      country.value = addressData.country;
      latitude.value = addressData.latitude;
      longitude.value = addressData.longitude;
    };

    const checkImage = (event) => {
      if (imageToBeDeleted.value.indexOf(event.target.value)) {
        imageToBeDeleted.value.push(event.target.value);
      } else {
        imageToBeDeleted.value.splice(
          imageToBeDeleted.value.indexOf(event.target.value),
          1
        );
      }
    };

    const deleteImages = async () => {
      if (imageToBeDeleted.value.length > 0) {
        editInProgress.value = true;
        const form = {
          id: profile.value.id,
          image: imageToBeDeleted.value,
        };

        const response = await store.dispatch("deleteUserPhotos", form);
        if (response.success) {
          const resp = await store.dispatch("loadUserProfile", {
            value: profile.value.user_name,
          });
          profile.value = resp;

          editInProgress.value = false;
          isEditPhoto.value = false;
        }
      } else {
        // temporary notification
        console.log("Please select photo to delete!");
      }
    };

    // Preference
    const updatePreference = async () => {
      const heightFrom = localValueModel.value[0];
      const heightTo = localValueModel.value[1];
      const ageFrom = localValueAge.value[0];
      const ageTo = localValueAge.value[1];

      // Add .join(',') when saving array of data

      const form = {
        id: profile.value.id,
        interest_id: profileInterest.value.map(({ id }) => id).join(","),
        income_level:
          incomeLevelPreference.value ??
          profile.value.preference.income_level.id,
        ethnicity:
          ethnicityPreference.value ?? profile.value.preference.ethnicity.id,
        street_address:
          streetAddress.value ?? profile.value.profile.street_address,
        city: city.value ?? profile.value.profile.city,
        state: state.value ?? profile.value.profile.state,
        country: country.value ?? profile.value.profile.country,
        latitude: latitude.value ?? profile.value.profile.latitude,
        longitude: longitude.value ?? profile.value.profile.longitude,
        gender: genderPreference.value ?? profile.value.preference.gender.id,
        age_from: ageFrom ?? profile.value.preference.age_from,
        age_to: ageTo ?? profile.value.preference.age_to,
        zodiac_sign:
          zodiacSignPreference.value ?? profile.value.preference.zodiac_sign.id,
        are_you_smoker:
          smokingPreference.value ?? profile.value.preference.are_you_smoker.id,
        are_you_drinker:
          drinkingPreference.value ??
          profile.value.preference.are_you_drinker.id,
        any_children:
          childrenPreference.value ?? profile.value.preference.any_children.id,
        education_level:
          educationalLevelPreference.value ??
          profile.value.preference.educational_level.id,
        relationship_status:
          relationshipStatusPreference.value ??
          profile.value.preference.relationship_status.id,
        body_type:
          bodyTypePreference.value ?? profile.value.preference.body_type.id,
        height_from: heightFrom ?? profile.value.preference.height_from,
        height_to: heightTo ?? profile.value.preference.height_to,
      };

      const response = await store.dispatch("updatePreference", form);
      if (response.status === 200) {
        edit("isEditedPreparedStatus");
        localStorage.removeItem("profile");
        const resp = await store.dispatch("loadUserProfile", {
          value: profile.value.user_name,
        });
        localStorage.setItem("profile", JSON.stringify(resp));
        profile.value = resp;
        // $(document).Toasts('create', {
        //     class: 'bg-success',
        //     title: 'Interest',
        //     body: 'New interest successfully added!',
        // });
      }
    };

    const cancelPreference = () => {
      // profile.value = JSON.parse(localStorage.getItem('profile'));
      edit("isEditedPreparedStatus");
    };

    const selectedPreference = (object, type) => {
      if (type === "income_preference") {
        incomeLevelPreference.value.push(object.id);
      }
      if (type === "ethnicity_preference") {
        ethnicityPreference.value.push(object.id);
      }
      if (type === "gender_preference") {
        genderPreference.value.push(object.id);
      }
      if (type === "zodiac_sign_preference") {
        zodiacSignPreference.value.push(object.id);
      }
      if (type === "smoking_preference") {
        smokingPreference.value.push(object.id);
      }
      if (type === "drinking_preference") {
        drinkingPreference.value.push(object.id);
      }
      if (type === "relationship_status_preference") {
        relationshipStatusPreference.value.push(object.id);
      }
      if (type === "children_preference") {
        childrenPreference.value.push(object.id);
      }
      if (type === "educational_level_preference") {
        educationalLevelPreference.value.push(object.id);
      }
      if (type === "body_type_preference") {
        bodyTypePreference.value.push(object.id);
      }
    };

    const deSelectedPreference = (object, type) => {
      if (type === "income_preference") {
        incomeLevelPreference.value.splice(object.id, 1);
      }
      if (type === "income_preference") {
        ethnicityPreference.value.splice(object.id, 1);
      }
      if (type === "income_preference") {
        genderPreference.value.splice(object.id, 1);
      }
      if (type === "income_preference") {
        zodiacSignPreference.value.splice(object.id, 1);
      }
      if (type === "income_preference") {
        smokingPreference.value.splice(object.id, 1);
      }
      if (type === "income_preference") {
        drinkingPreference.value.splice(object.id, 1);
      }
      if (type === "income_preference") {
        relationshipStatusPreference.value.splice(object.id, 1);
      }
      if (type === "income_preference") {
        childrenPreference.value.splice(object.id, 1);
      }
      if (type === "income_preference") {
        educationalLevelPreference.value.splice(object.id, 1);
      }
      if (type === "income_preference") {
        bodyTypePreference.value.splice(object.id, 1);
      }
    };

    onMounted(async () => {
      isLoading.value = true;
      const userName = router.currentRoute._value.params.user_name;
      const response = await store.dispatch("loadUserProfile", {
        value: userName,
      });
      if (response) {
        profile.value = response;

        primaryPhoto.value = response.primary_photo?.id;

        profileInterest.value = interest.value.filter((value) => {
          return response.interests.includes(value.interest);
        });
        eventActivities.value = response.profile.identify_events_activities;
        lookingPerfectFriend.value =
          response.profile.what_type_of_friend_are_you_looking_for;
        aboutMe.value = response.profile.about_me;
        completeAddress.value =
          response.profile.city +
          " " +
          profile.value.profile.state +
          " " +
          profile.value.profile.zip_code;
        userGender.value = response.profile.gender.id;
        userEthnicity.value = response.profile.ethnicity.id;
        firstName.value = response.first_name;
        lastName.value = response.last_name;
        mobileNumber.value = response.mobile_number;
        email.value = response.email;
        areYouSmoker.value = response.profile.are_you_smoker.id;
        areYouDrinker.value = response.profile.are_you_drinker.id;
        userRelationshipStatus.value = response.profile.relationship_status.id;
        anyChildren.value = response.profile.any_children.id;
        educationLevel.value = response.profile.education_level.id;
        isLoading.value = false;
      }
    });

    const isBeingSaved = reactive({
      primaryPhoto: false,
      bio: false,
    });

    const removeProfilePhoto = async () => {
      isBeingSaved.primaryPhoto = true;
      await store.dispatch("deleteUserProfilePhoto", {id: profile.value.primary_photo.id});
      await nextTick();
      await saveProfile("isPrimaryPhoto");
      isBeingSaved.primaryPhoto = false;
    }

    return {
      isBeingSaved,
      savePrimaryPhoto,
      editInProgress,
      isEditedUserStatus,
      isEditedPreparedStatus,
      isEditedBasicInfo,
      whyImThePerfectFriend,
      whyImLookingPerfectFriend,
      eventsAndActivities,
      isInterest,
      edit,
      picked,
      profile,
      moment,
      gender,
      ethnicity,
      isGoogleMapLoaded,
      localValue,
      hasModifiedLocation,
      smoking,
      drinking,
      relationshipStatus,
      educationalLevel,
      children,
      bodyType,
      incomeLevel,
      zodiacSign,
      heightInString,
      minHeight,
      maxHeight,
      localValueModel,
      localValueAge,
      minAge,
      maxAge,
      isLoading,
      selected,
      value,
      interest,
      profileInterest,
      removeInterest,
      getIncludes,
      addNewInterest,
      interestText,
      postInterest,
      cancelInterest,
      updateInterest,
      saveProfile,
      cancelEventActivities,
      eventActivities,
      lookingPerfectFriend,
      cancelWhyImLookingPerfectFriend,
      cancelWhyImThePerfectFriend,
      aboutMe,
      completeAddress,
      getAddressData,
      streetAddress,
      city,
      state,
      zipCode,
      country,
      latitude,
      longitude,
      cancelEditBasicInfo,
      userGender,
      userEthnicity,
      firstName,
      lastName,
      mobileNumber,
      email,
      areYouSmoker,
      areYouDrinker,
      userRelationshipStatus,
      anyChildren,
      educationLevel,
      isEditPhoto,
      imageToBeDeleted,
      checkImage,
      deleteImages,
      updatePreference,
      incomeLevelPreference,
      selectedPreference,
      deSelectedPreference,
      ethnicityPreference,
      genderPreference,
      zodiacSignPreference,
      smokingPreference,
      drinkingPreference,
      relationshipStatusPreference,
      childrenPreference,
      educationalLevelPreference,
      bodyTypePreference,
      cancelPreference,
      message,
      removeProfilePhoto
    };
  },
};
</script>
<style scoped>
.color-gray {
  color: #727e8b;
}
.form-control,
.form-select:focus {
  box-shadow: none;
}
.btn:focus,
.info-box {
  box-shadow: none;
}
.info-box {
  margin-bottom: 0;
  min-height: 0;
}
.profile {
  background-color: #262626;
  color: #d8d8d8;
}
.edit-btn {
  color: #b4914d;
  text-decoration: none;
  font-weight: 600;
}
.edit-btn:hover {
  color: #cacaca;
  text-decoration: none;
  font-weight: 600;
}
.fw-semibold {
  font-weight: 600 !important;
}
.form-select {
  width: 60%;
}
.pf-text,
.pf-text:focus,
textarea,
textarea:focus {
  background: #262626;
  color: #cacaca;
}
.separator {
  color: #757575;
  padding-left: 5px;
  padding-right: 5px;
}

.input-group-text {
  background-color: #505050 !important;
  border-color: #aaa;
  color: #fff;
}
.btn-gradient-gold {
  background: linear-gradient(to right, #d4c7a4, #b4914d);
  color: #000;
  border: none;
  padding: calc(0.375rem + 1px) calc(2.25rem + 1px);
}
.fa-heart {
  color: #fff;
}

.is-selected {
  background-color: #b4914d;
}

.multiselect-tags-search {
  width: 60% !important;
  background: #262626 !important;
}
.multiselect {
  margin: 0 !important;
}
.bg-select {
  width: 60%;
  background: #262626;
  color: black !important;
}
.multiselect-option {
  color: black !important;
}
.pac-container:after {
  /* Disclaimer: not needed to show 'powered by Google' if also a Google Map is shown */

  background-image: none !important;
  height: 0px;
}
.photo-edit {
  position: absolute;
  margin-top: -10px;
  right: 0;
}
.photo-edit-cancel {
  position: absolute;
  margin-top: -15px;
  right: 6px;
}
.image-checkbox {
  position: absolute;
  right: 10px;
  z-index: 9999;
  top: 2px;
  width: 20px;
  height: 20px;
}
.multiselect-tag.is-user {
  padding: 5px 8px;
  border-radius: 22px;
  background: #35495e;
  margin: 3px 3px 8px;
}

.multiselect-tag.is-user img {
  width: 18px;
  border-radius: 50%;
  height: 18px;
  margin-right: 8px;
  border: 2px solid #ffffffbf;
}

.multiselect-tag.is-user i:before {
  color: #ffffff;
  border-radius: 50%;
}
.is-user {
  white-space: normal !important;
}
.bg-select {
  width: 60%;
  background: #262626;
  color: black !important;
}
.spinner-border {
  width: 1rem !important;
  height: 1rem !important;
  position: absolute;
  margin-top: -10px;
  right: 0;
}
.absolute-center {
  position: absolute;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  width: 100px;
}
</style>
