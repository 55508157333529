<template>
  <div>
    <div class="card card-success mb-4">
      <div class="card-body">
        <h1 class="m-0 ff-merriweather fs-1 text-nero fw-bold">
          Edit {{ dynamicWordFor("Event") }}
        </h1>
      </div>
    </div>
    <div class="card border-bottom-1 mh-100">
      <Suspense>
        <template #default>
          <div>
            <div>
              <button
                class="btn text-decoration-none text-nero fw-semibold d-flex align-items-center"
                style="background-color: transparent"
                @click="goBack"
              >
                <span class="icon-arrow-left-alt me-1" /> Go Back
              </button>
            </div>

            <router-view
              :back-url="backUrl"
              @createEvent="createEvent"
              @eventIsPublished="eventIsPublished"
              @isLoading="isProcessLoading"
              @cancelEdit="isCancelEdit"
              :gatheringType="gatheringType"
            />
          </div>
        </template>

        <template #fallback>
          <div class="text-center mh-100 d-inline-block m-5">
            <span class="animation-loading-spinner m-5" />
          </div>
        </template>
      </Suspense>
    </div>
  </div>
</template>

<script>
import { onMounted, ref } from "vue";
import { useStore } from "vuex";
import { useRoute, useRouter } from "vue-router";
import { transformEventResource, liveChatEnabledTypes } from "@/Use/events.js";
import Preloader from "../../Layouts/Common/Preloader.vue";
import { GATHERING_TYPES, useDynamicWord } from "@/Use/events.js";

export default {
  name: "EditEvent",
  props: {
    gatheringType: {
      type: Number,
      default: GATHERING_TYPES.EVENT,
    },
  },
  emits: ["isLoading", "onGoHome"],
  setup(props, { emit }) {
    const store = useStore();

    const route = useRoute();
    const router = useRouter();

    const { dynamicWordFor } = useDynamicWord({
      gatheringType: props.gatheringType,
    });

    const backUrl = ref("");
    const isPublished = ref(false);

    const isCancelEdit = () => {
      emit("onGoHome", true);
    };

    const isProcessLoading = (status) => {
      emit("isLoading", status);
    };

    const eventIsPublished = (response) => {
      isPublished.value = response;
    };

    const createEvent = async (response, page) => {
      if (page.isPublished === true) {
        router.push({
          name: `${dynamicWordFor("event")}-edit-review-and-publish`,
          params: { id: response.data.id },
        });
      } else {
        if (page.steps === "name-location") {
          router.push({
            name: `${dynamicWordFor("event")}-edit-category-interest`,
            params: { id: response.data.id },
          });
        }
        if (page.steps === "category-interest") {
          router.push({
            name: `${dynamicWordFor("event")}-edit-description`,
            params: { id: response.data.id },
          });
        }
        if (page.steps === "description") {
          router.push({
            name: `${dynamicWordFor("event")}-edit-media`,
            params: { id: response.data.id },
          });
        }
        if (page.steps === "media") {
          // router.push(`/event/add/${response.data.data.slug}/permission`);
          router.push({
            name: `${dynamicWordFor("event")}-edit-admin`,
            params: { id: response.data.id },
          });
        }
        if (page.steps === "permission") {
          router.push({
            name: `${dynamicWordFor("event")}-edit-admin`,
            params: { id: response.data.id },
          });
        }
        if (page.steps === "admin") {
          router.push({
            name: `${dynamicWordFor("event")}-edit-schedule`,
            params: { id: response.data.id },
          });
        }
        if (page.steps === "schedule") {
          router.push({
            name: `${dynamicWordFor("event")}-edit-review-and-publish`,
            params: { id: response.data.id },
          });
        }
        if (page.steps === "review-and-publish") {
          router.push({
            name: `${dynamicWordFor("event")}-edit-invite-past-events`,
            params: { id: response.data.id },
          });
        }
      }
    };

    const liveChatEnabledOptions = [
      {
        label: "No",
        value: liveChatEnabledTypes.NO,
      },
      {
        label: "Yes",
        value: liveChatEnabledTypes.YES,
      },
    ];

    const goBack = () => {
      if (localStorage.getItem("prevURL")) {
        router.push(localStorage.getItem("prevURL"));
      } else {
        router.go(-1);
      }
    };

    return {
      isProcessLoading,
      goBack,
      createEvent,
      eventIsPublished,
      transformEventResource,
      liveChatEnabledOptions,
      backUrl,
      isPublished,
      isCancelEdit,
      dynamicWordFor,
      GATHERING_TYPES,
      // slug
    };
  },
};
</script>
<style lang="scss" scoped>
@import "../../scss/customizations";

.back-button {
  color: $color-nero;
  text-decoration: none;
  line-height: 1.2;
}

.form-column-wrap {
  max-width: 680px;
}
</style>
