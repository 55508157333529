<template>
  <group-admin-form
    class="form-1"
    :group-id="groupForm.id"
    :group-owner="groupForm.owner"
    :data="{
      admins: groupForm.admins?.map((admin) => ({
        id: admin.id,
        firstName: admin.first_name,
        lastName: admin.last_name,
        primaryPhotoUrl: admin.primary_photo?.sizes?.thumbnail,
        username: admin.user_name,
        role: admin.role.id,
      })),
    }"
    @success="groupAdminsCreated"
  >
    <template #title>
      <h1 class="fs-1">Identify admins for your group</h1>
    </template>
    <template #submit="{ submit, isSubmitting }">
      <div class="row">
        <div class="col-6 col-sm-6 mb-3">
          <ce-button
            class="btn-lg btn btn-gray text-white text-bold w-100"
            @click="$emit('cancelEdit')"
          >
            Cancel
          </ce-button>
        </div>
        <div class="col-6 col-sm-6 mb-3">
          <ce-button
            class="btn-lg btn-gradient-gold text-bold w-100"
            :is-loading="isSubmitting"
            :disabled="isSubmitting"
            @click="submit"
          >
            {{ isPublished ? "Continue" : "Finish and Preview" }}
          </ce-button>
        </div>
      </div>
    </template>
  </group-admin-form>
</template>

<script>
import { defineAsyncComponent, computed, ref, watch } from "vue";
import { useStore } from "vuex";
import { useRoute, useRouter } from "vue-router";

export default {
  components: {
    GroupAdminForm: defineAsyncComponent(() =>
      import("@/Modules/Group/GroupAdminForm.vue")
    ),
  },
  props: {},
  emits: ["createGroup", "isLoading", "cancelEdit"],
  async setup(props, { emit }) {
    const store = useStore();

    const route = useRoute();
    const router = useRouter();

    const slug = ref(router.currentRoute._value.params.slug);

    emit("isLoading", true);
    const getGroupInfoResource = await store.dispatch("group/groupEdit", {
      slug: slug.value,
      api: "admin",
    });
    emit("isLoading", false);

    store.dispatch("group/setGroupInfo", getGroupInfoResource.data);
    const groupForm = computed(() => getGroupInfoResource.data.form);

    const groupAdminsCreated = (response) => {
      emit("createGroup", response.data, { steps: "admin" });
    };

    const isPublished = computed(() => getGroupInfoResource.data.isPublished);

    return {
      isPublished,
      groupAdminsCreated,
      slug,
      groupForm,
    };
  },
};
</script>

<style lang="scss" scoped>
:deep() {
  @import "../../scss/form/form-1";
}

.container {
  max-width: 680px;
}
</style>
