<template>
  <div class="group--buttons">
    <!-- invite member -->
    <!-- <ce-button
      class="btn btn-gradient-gold mt-md-0 mt-2 me-md-2"
      @click="showMembers"
    >
      Invite Members
    </ce-button> -->

    <!-- edit group -->
    <a
      :href="`/group/${group?.slug}/edit`"
      class="btn btn-gradient-gold mt-md-0 mt-2 me-md-2"
    >
      Edit Group
    </a>

    <ce-button
      class="btn btn-gradient-gold mt-md-0 mt-2 me-md-2"
      data-toggle="dropdown"
    >
      Send Invites
    </ce-button>
    <div class="dropdown-menu dropdown-menu-right">
      <a
        href="#"
        class="dropdown-item"
      >
        Send a message to all
      </a>
      <div class="dropdown-divider" />
      <a
        href="#"
        class="dropdown-item"
      >
        Message invites Only
      </a>
      <div class="dropdown-divider" />
      <a
        href="#"
        class="dropdown-item"
      >
        Message Wait Listed Only
      </a>
      <div class="dropdown-divider" />
      <a
        href="#"
        class="dropdown-item"
      >
        Message Checked in Only
      </a>
    </div>

    <!-- delete -->
    <!-- <ce-button
      class="btn-danger mt-md-0 mt-2 me-md-2"
      @click="deleteGroup"
    >
      Delete
    </ce-button> -->

    <!-- publish / unpublish -->
    <!-- <group-publish-button
      v-if="group?.can_edit"
      class="mt-md-0 mt-2 me-md-2 d-md-inline-block"
      :group="group"
    >
    </group-publish-button> -->

    <!-- join / leave -->
    <!-- <group-join-button
      v-if="group?.is_published"
      class="me-md-2 d-md-inline-block"
      :group="group"
    /> -->
    <!-- live chat button -->
    <!-- <group-chat-live-button
      v-if="
        group?.is_published
      "
      :id="group?.id"
      :table-lookup="2"
      class="mt-2 me-md-2 d-md-inline-block"
    >
      <template #label> Live Chat </template>
    </group-chat-live-button> -->
    <!-- Send message to all members -->
    <!-- <group-chat-live-button
      v-if="group?.is_published"
      :id="group?.id"
      :table-lookup="6"
      class="mt-2 d-md-inline-block"
    >
      <template #label> Message All Members </template>
    </group-chat-live-button> -->
  </div>

  <!-- <teleport to="body">
    <ce-modal ref="inviteDialog">
      <member-lists :group="group">
        <template #title>
          <div class="d-flex">
            <div class="mx-auto">
              <strong>Invite Users</strong>
            </div>
            <ce-button
              icon
              type="button"
              data-bs-dismiss="modal"
              class="position-relative end-0 px-2"
              aria-label="Close"
              @click="inviteDialog.close()"
            >
              <ce-icon icon="close" />
            </ce-button>
          </div>
        </template>
      </member-lists>
    </ce-modal>
  </teleport> -->
</template>
<script>
// import { Inertia } from '@inertiajs/inertia';
import { ref, inject, computed } from 'vue'
import { useStore } from 'vuex'
import GroupJoinButton from '@/Pages/Group/GroupJoinButton.vue'
import CeButton from '@/components/Buttons/CeButton.vue'
import MemberLists from '@/Pages/Group/MemberListsInvite.vue'
import CeModal from '@/components/Modal/CeModal.vue'
// import { useGroup } from '@/Use/groups.js';
import CeIcon from '@/components/UI/CeIcon.vue'
import GroupPublishButton from '@/Pages/Group/GroupPublishButton.vue'
import GroupChatLiveButton from '@/components/Buttons/GroupChatLive.vue'

export default {
  name: 'GroupViewButtons',
  components: {
    CeIcon,
    GroupJoinButton,
    MemberLists,
    CeButton,
    CeModal,
    GroupPublishButton,
    GroupChatLiveButton
  },
  props: {
    group: {
      required: true,
      type: Object
    }
  },
  setup (props) {
    // const { deleteUserGroup } = useGroup();
    const inviteDialog = ref()
    const dialog = inject('dialog')
    const store = useStore()
    const lookUp = ref(2)
    const groupResource = computed(() => props.group)
    const showMembers = () => {
      inviteDialog.value.open()
    }

    const deleteGroup = () => {
      dialog.show('Are you sure you want to delete this group?', {
        title: props.group.name,
        buttons: [
          {
            label: 'No',
            class: 'btn-light-gray'
          },
          {
            label: 'Yes',
            class: 'btn-red',
            handler: async (closeFunc) => {
              try {
                // remove listening to this group
                store.dispatch('listener/unlistenToGroup', props.group.id)
                // await deleteUserGroup(props.group);
                // Inertia.visit('/my-groups', { method: 'get' });
              } catch (e) {
                dialog.show(`Unable to delete "${props.group.name}".`, {
                  title: 'Groups',
                  buttons: [
                    {
                      label: 'Close',
                      class: 'btn-light-gray'
                    }
                  ]
                })
              } finally {
                closeFunc()
              }
            }
          }
        ]
      })
    }

    return {
      inviteDialog,
      showMembers,
      deleteGroup,
      lookUp,
      groupResource
    }
  }
}
</script>
<style lang="scss" scoped>
@import '~bootstrap/scss/bootstrap-utilities';

/* purgecss start ignore */
:deep(.btn) {
  min-width: 100%;
}
@include media-breakpoint-up(md) {
  .group--buttons {
    .btn {
      min-width: 130px !important;
    }
  }
}

/* purgecss end ignore */
</style>
