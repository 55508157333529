import { ref } from 'vue'

export const useModal = () => {
  const modalRef = ref(null)

  const open = () => {
    modalRef.value.modal('show')
  }

  const close = () => {
    if (modalRef.value) {
      modalRef.value.modal('hide')
    }
  }

  return {
    modalRef,
    open,
    close
  }
}

export default null
