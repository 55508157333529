<template>
  <div ref="baseLayoutRef" class="base-layout-root h-100" :class="themeClass">
    <router-view />
    <dialog-modal
      ref="dialogModalRef"
      @ready="dialogQueue ? dialogQueue() : null"
    />
  </div>
</template>

<script>
import "../node_modules/admin-lte/plugins/select2-bootstrap4-theme/select2-bootstrap4.min.css";
import "../node_modules/admin-lte/plugins/select2/css/select2.min.css";

import { defineAsyncComponent, computed, ref, provide } from "vue";
import { usePage } from "@inertiajs/inertia-vue3";
export default {
  components: {
    DialogModal: defineAsyncComponent(() =>
      import("@/components/Modal/DialogModal.vue")
    ),
  },
  props: {},
  name: "App",
  setup() {
    const auth = computed(() => usePage().props.value.auth);
    const layoutComponent = computed(() =>
      auth.value ? "auth-layout" : "guest-layout"
    );
    const defaultThemeClass = "bg-white";
    const themeClass = ref(defaultThemeClass);
    const baseLayoutRef = ref(null);
    const dialogModalRef = ref(null);
    const dialogQueue = ref(null);

    /* Methods */
    const setLightTheme = () => {
      themeClass.value = "bg-white";
    };

    const setDarkTheme = () => {
      themeClass.value = "bg-nero";
    };

    const setDefaultTheme = () => {
      themeClass.value = defaultThemeClass;
    };

    const showDialog = (content, { title, buttons, size, onClose }) => {
      if (dialogModalRef.value === null) {
        dialogQueue.value = () => {
          showDialog(content, { title, buttons, size });
        };
        return false;
      }

      dialogModalRef.value.prompt(content, {
        title,
        buttons,
        size,
        onClose,
      });
      return true;
    };

    /* Provide */
    provide("setLightTheme", setLightTheme);
    provide("setDarkTheme", setDarkTheme);
    provide("setDefaultTheme", setDefaultTheme);
    provide("baseLayoutRef", baseLayoutRef);
    provide("dialog", {
      show: showDialog,
    });

    return {
      layoutComponent,
      themeClass,
      baseLayoutRef,
      dialogModalRef,
      dialogQueue,
    };
  },
};
</script>

<style>
@import url("https://fonts.googleapis.com/css?family=Source+Sans+Pro:300,400,400i,700&display=fallback");
</style>

<style lang="scss" scoped>
.base-layout-root {
  transition: background-color 0.25s linear;
}
</style>

<style lang="scss">
@import "./scss/index.scss";
</style>
