<template>
  <a href="#" class="color-gray" @click="selectMessageToSend">
    Message {{ messageTo }}
  </a>

  <modal-component
    :modal-id="`selectMessageType`"
    :title="selectMessageTitle"
    :header-style="`bg-linear`"
    :size="`modal-md`"
    :profile="false"
  >
    <template #modalBody>
      <div class="modal-body">
        <div class="row text-left">
          <div class="col-md-12 col-lg-12 col-sm-12">
            <div class="row">
              <div class="form-group">
                <div
                  class="card card-success"
                  :class="birthdayMessage ? 'selected-message-type' : ''"
                  @click="selectMessageType('selected_birthday')"
                >
                  <div class="p-3">
                    <div class="custom-control custom-radio">
                      <input
                        id="selected_birthday"
                        class="custom-control-input"
                        type="radio"
                        name="selectMessage"
                        disabled
                      />
                      <label
                        for="selected_birthday"
                        class="custom-control-label"
                        :class="{ 'text-secondary': birthdayMessage }"
                        >{{
                          messageTo === "User"
                            ? "People who are celebrating birthday today"
                            : messageTo === "Group"
                            ? "Send to all groups"
                            : messageTo === "Event"
                            ? "Send to all events"
                            : ""
                        }}</label
                      >
                    </div>
                    <small v-if="messageTo === 'User'" class="small-icon">{{
                      moment().format("ll")
                    }}</small>
                  </div>
                </div>
                <div
                  class="card card-success"
                  :class="selectedUser ? 'selected-message-type' : ''"
                  @click="selectMessageType('selected_user')"
                >
                  <div class="p-3">
                    <div class="custom-control custom-radio">
                      <input
                        id="selected_user"
                        class="custom-control-input text-warning"
                        type="radio"
                        name="selectMessage"
                        disabled
                      />
                      <label
                        for="selected_user"
                        class="custom-control-label"
                        :class="{ 'text-secondary': selectedUser }"
                        >{{
                          messageTo === "User"
                            ? "Selected Users"
                            : messageTo === "Group"
                            ? "Select groups"
                            : messageTo === "Event"
                            ? "Select events"
                            : ""
                        }}</label
                      >
                    </div>
                  </div>
                </div>
              </div>
              <div class="modal-footer border-0 float-right">
                <span
                  type="button"
                  class="btn-outline text-secondary"
                  @click="cancelMessageType"
                >
                  CANCEL
                </span>
                <span
                  type="button"
                  class="send"
                  @click="selectedUser || birthdayMessage ? nextMessage() : ''"
                >
                  NEXT
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
  </modal-component>

  <modal-component
    :modal-id="`message`"
    :title="messageTitle"
    :header-style="`bg-linear`"
    :size="`modal-md`"
    :class="`text-left`"
    :profile="false"
  >
    <template #modalBody>
      <message-modal
        :message-to="type"
        :selected-type="messageTo"
        @cancelMessage="cancelMessage"
      />
    </template>
  </modal-component>
</template>
<script>
import { ref } from "vue";
import $ from "jquery";
import moment from "moment";
import ModalComponent from "@/components/ModalComponent.vue";
import MessageModal from "../../components/Modal/MessageModal.vue";

export default {
  methods: {},
  components: {
    ModalComponent,
    MessageModal,
  },
  props: {
    messageTo: {
      type: String,
      required: true,
      default: "User",
    },
    selectMessageTitle: {
      type: String,
      required: true,
      default: "Message User",
    },
    messageTitle: {
      type: String,
      required: true,
      default: "Message User",
    },
  },
  setup(props) {
    const birthdayMessage = ref(false);
    const selectedUser = ref(false);
    const type = ref("");

    const nextMessage = () => {
      type.value =
        birthdayMessage.value && props.messageTo === "User"
          ? "People who have birthdays today"
          : birthdayMessage.value && props.messageTo === "Group"
          ? "All Groups"
          : birthdayMessage.value && props.messageTo === "Event"
          ? "All Event"
          : "Selected";
      $("#selectMessageType").modal("hide");
      $("#message").modal("show");
    };

    const selectMessageToSend = () => {
      $("#selectMessageType").modal("show");
    };

    const selectMessageType = (type) => {
      if (type === "selected_birthday") {
        birthdayMessage.value = true;
        selectedUser.value = false;
      }

      if (type === "selected_user") {
        birthdayMessage.value = false;
        selectedUser.value = true;
      }
    };

    const cancelMessageType = () => {
      birthdayMessage.value = false;
      selectedUser.value = false;
      $("#selectMessageType").modal("hide");
    };

    // SEND MESSAGE
    const cancelMessage = () => {
      birthdayMessage.value = false;
      selectedUser.value = false;
      $("#message").modal("hide");
    };

    return {
      birthdayMessage,
      selectedUser,
      nextMessage,
      selectMessageToSend,
      selectMessageType,
      cancelMessage,
      cancelMessageType,
      type,
    };
  },
  created: function () {
    this.moment = moment;
  },
};
</script>

<style scoped>
.downloadAndMessageAlign {
  text-align: center;
  position: inherit;
  margin-top: 15px;
}
.dropdown-menu-lg {
  min-width: auto;
}
.table td,
.table th {
  border-top: 0 !important;
}
.table td {
  vertical-align: middle;
}
.custom-control-label {
  font-weight: 500 !important;
}
.send {
  color: rgb(4, 98, 193);
}
.selected-message-type {
  background-color: #333;
  color: #cacaca !important;
}
.text-left {
  text-align: left;
}
.color-gray {
  color: #999;
}
.small-icon {
  color: #6c757d;
  position: relative;
  left: 25px;
  float: left;
  margin-top: -5px;
}
</style>
