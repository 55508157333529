<template>
  <div
    class="w-100 h-100"
    :class="{ 'has-image': !!imgSrc }"
  >
    <ce-visual-media-picker
      ref="videoPickerRef"
      class="w-100 h-100 position-relative photo-picker"
      :modal-container="baseLayoutRef"
      :file-types="FILE_TYPES"
      @picked="uploadVideo($event)"
      @invalid-file-type="promptInvalidFileType()"
    >
      <template #default="{ browseMedia: pickerBrowseVideo }">
        <button
          type="button"
          class="btn btn-transparent p-0 img-upload-btn position-relative w-100 h-100"
          :disabled="isUploadingVideo"
          @click="pickerBrowseVideo()"
        >
          <ce-aspect-ratio :aspect-ratio="aspectRatio">
            <video
              :src="localSrc"
              class="w-100 h-100 rounded-2 overflow-hidden bg-black pe-none"
              @error="handleVideoLoadError()"
            />
          </ce-aspect-ratio>
          <template v-if="isUploadingVideo">
            <span class="loading-indicator" />
          </template>
          <template v-else>
            <span class="icon-film" />
            <span class="icon-outline-plus" />
          </template>
        </button>
        <template v-if="!isUploadingVideo && !nonRemoveable">
          <button
            type="button"
            class="btn p-0 remove-btn"
            @click="removePhoto()"
          >
            <span class="icon-trash" />
          </button>
        </template>
        <span
          v-if="badgeNumber > 0"
          class="badge-number p-0"
        >
          {{ badgeNumber }}
        </span>
      </template>
      <template #browse-options="{ chooseFromFile, takeFromCamera }">
        <div class="row p-3">
          <p class="text-muted text-center font-monospace mb-3">
            Videos that do not meet this strict guideline will be subject to
            suspension, deactivation and/or removal.
          </p>
          <div
            class="mb-3 mb-md-0"
            :class="[isMobileDevice ? 'col-md-6' : 'col-md-12']"
          >
            <ce-button
              type="button"
              class="btn-nero w-100"
              @click="chooseFromFile()"
            >
              Add Video
            </ce-button>
          </div>
          <div
            v-if="isMobileDevice"
            class="col-md-6"
          >
            <ce-button
              type="button"
              class="btn-nero w-100"
              @click="takeFromCamera()"
            >
              Take From Device
            </ce-button>
          </div>
        </div>
      </template>
    </ce-visual-media-picker>
  </div>
</template>

<script>
/**
 * There's a technical debt on the implementation of this class, which is basically an extension of the PhotoField
 * a lot of variable names and function names should be renamed
 */
import { inject, ref, watch } from 'vue'
import MediaRepository from '@/Repositories/media.js'
import { FILE_TYPES } from '@/Use/use-video.js'
import { isMobileDevice } from '@/Use/use-user-agent.js'

export default {
  props: {
    modelValue: {
      type: Number,
      default: null
    },
    src: {
      type: String,
      default: null
    },
    placeholder: {
      type: String,
      default: '//via.placeholder.com/150'
    },
    editable: {
      type: Boolean,
      default: true
    },
    isUploading: {
      type: Boolean,
      default: false
    },
    croppable: {
      type: Boolean,
      default: false
    },
    nonRemoveable: {
      type: Boolean,
      default: false
    },
    aspectRatio: {
      type: Number,
      required: false,
      default: 16 / 9
    },
    cropperOptions: {
      type: Object,
      required: false,
      default: () => ({})
    },
    cropperViewMode: {
      type: Number,
      required: false,
      default: 3
    },
    alt: {
      type: String,
      default: null
    },
    upload: {
      type: File,
      default: undefined
    },
    badgeNumber: {
      type: Number,
      default: 0
    }
  },
  emits: [
    'update:modelValue',
    'update:isUploading',
    'update:src',
    'finished',
    'removed'
  ],
  setup (props, { emit }) {
    const baseLayoutRef = inject('baseLayoutRef')
    const dialog = inject('dialog')
    const localValue = ref(null)
    const imgSrc = ref(null)
    const clientSrc = ref('')
    const localSrc = ref('')
    const uploadedPhotoId = ref(null)
    const isUploadingVideo = ref(false)
    const photoEditorModalRef = ref(null)
    const videoPickerRef = ref(null)

    /* Methods */
    const removePhoto = () => {
      localValue.value = null
      imgSrc.value = null
      localSrc.value = null
      uploadedPhotoId.value = null
      emit('removed')
    }

    const loadVideoSrc = async () => {
      if (localValue.value === null) {
        return false
      }

      const {
        data: { data: media }
      } = await MediaRepository.get(localValue.value)
      localSrc.value = media.modified || media.original

      return true
    }

    const browsePhoto = () => {
      videoPickerRef.value.browsePhoto()
    }

    const displayEditedPhoto = (photoSrc) => {
      localValue.value = uploadedPhotoId.value
      imgSrc.value = photoSrc
      videoPickerRef.value.browseMode = null
      // videoPickerRef.value.browsePhoto();
      emit('finished', { id: localValue.value, src: photoSrc })
    }

    const uploadVideo = async (photo) => {
      // console.log(photo); // Upload photo
      try {
        clientSrc.value = URL.createObjectURL(photo)
        isUploadingVideo.value = true
        const {
          data: { data: media }
        } = await MediaRepository.upload(photo)
        isUploadingVideo.value = false
        uploadedPhotoId.value = media.id
        if (props.croppable) {
          photoEditorModalRef.value.open({
            id: media.id,
            src: media.original
          })
        } else {
          displayEditedPhoto(media.original)
        }
      } catch (e) {
        dialog.show(
          e.response?.data.message || 'Sorry, problem with the upload',
          {
            title: 'Uploads'
          }
        )
        isUploadingVideo.value = false
      }
    }

    const promptInvalidFileType = () => {
      dialog.show('Please select a valid video file type.', {
        title: 'Invalid File Type'
      })
    }

    const handleVideoLoadError = () => {
      if (localSrc.value !== clientSrc.value) {
        localSrc.value = clientSrc.value
      }
    }

    /* Watchers */
    watch(localValue, (newLocalValue) => {
      emit('update:modelValue', newLocalValue)
    })

    watch(
      () => props.modelValue,
      (newModelValue) => {
        localValue.value = newModelValue
        loadVideoSrc()
      },
      {
        immediate: true
      }
    )

    watch(imgSrc, (newImgSrc) => {
      emit('update:src', newImgSrc)
    })

    watch(
      () => props.src,
      (newSrc) => {
        imgSrc.value = newSrc
      },
      {
        immediate: true
      }
    )

    watch(isUploadingVideo, (newIsUploadingVideo) => {
      emit('update:isUploading', newIsUploadingVideo)
    })

    watch(
      () => props.isUploading,
      (newIsUploading) => {
        isUploadingVideo.value = newIsUploading
      },
      {
        immediate: true
      }
    )

    /* Lifecycles */
    if (props.upload) {
      uploadVideo(props.upload)
    }

    return {
      baseLayoutRef,
      localValue,
      loadVideoSrc,
      localSrc,
      imgSrc,
      removePhoto,
      browsePhoto,
      uploadVideo,
      isUploadingVideo,
      photoEditorModalRef,
      displayEditedPhoto,
      videoPickerRef,
      isMobileDevice,
      promptInvalidFileType,
      FILE_TYPES,
      handleVideoLoadError
    }
  }
}
</script>

<style lang="scss" scoped>

@import '../../scss/customizations';

.remove-btn {
  display: none;
}

.photo-picker {
  &:hover .remove-btn {
    background: #E7E7E7;
    border-radius: 50%;
    box-shadow: 0 4px 10px rgba(#212121, .25);
    color: #212121;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 32px;
    height: 32px;
    position: absolute;
    top: 6px;
    right: 10px;
    font-size: 1rem;
    z-index: 2;
  }
}

.img-upload-btn {
  position: relative;

  > .icon-film,
  > .icon-outline-plus {
    @include absolute-center;

    color: #FFF;
    font-size: 2rem;
    z-index: 1;
  }

  > .icon-outline-plus {
    display: none;
  }

  &:hover {
    &::before {
      content: ' ';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: #000;
      opacity: 0.5;
    }

    > .icon-film {
      display: none;
    }

    > .icon-outline-plus {
      display: block;
    }
  }

  .loading-indicator {
    @include loading-spinner;
    @include absolute-center;
  }
}

.badge-number {
  position: absolute;
  right: 0;
  background-color: rgb(180, 145, 77);
  height: 30px;
  width: 30px;
  border-radius: 15px;
  margin: 2px;
  color: white;
  font-size: 1em;
  line-height: 1.8em;
  text-align: center;
}
</style>
