<template>
  <div>
    <div class="my-5">
      <span class="fw-bold">People in this group:</span>
      (Use the filter to find people)
    </div>

    <ce-infinite-result
      v-if="members"
      :items="members"
      :has-more-pages="hasMorePages"
    >
      <template #default="{ item: member }">
        <perfect-friend-card
          class="grid-item"
          :perfect-friend="{
            id: member.id,
            username: member.user_name,
            name: `${member.first_name} ${member.last_name}`,
            photoSrc: member.primary_photo.sizes?.sm,
            interests: member.interests,
            aboutMe: member.about_me,
            isFavorite: member.is_favorite,
            roleName: member.role?.label,
          }"
          :buttons-container-class="
            moment(member.birth_date).format('MM-DD') === currentMonthDay &&
              group.name.includes('Birthday Group') &&
              group.isExclusive
              ? 'd-flex flex-column align-items-end justify-content-end'
              : undefined
          "
        >
          <template
            v-if="
              moment(member.birth_date).format('MM-DD') === currentMonthDay &&
                group.name.includes('Birthday Group') &&
                group.isExclusive
            "
            #contactButton
          >
            <profile-button-send-message
              class="btn-sm btn-gradient-gold order-2"
              :user-id="member.id"
              send-happy-birthday
            >
              <template #label>
                Wish Me a Happy Birthday
              </template>
            </profile-button-send-message>
          </template>
        </perfect-friend-card>
      </template>
    </ce-infinite-result>
  </div>
</template>

<script>
import { computed } from 'vue'
import ProfileButtonSendMessage from '@/Modules/Profile/ProfileButtonSendMessage.vue'
import CeInfiniteResult from '@/components/Utilities/CeInfiniteResult.vue'
import moment from 'moment'
import PerfectFriendCard from '../../Modules/PerfectFriend/PerfectFriendCard.vue'
// import useUrlQueryParams from '@/Use/use-url-query-params.js';

export default {
  name: 'GroupMembers',
  components: {
    ProfileButtonSendMessage,
    CeInfiniteResult,
    PerfectFriendCard
  },
  props: {
    group: {
      type: Object,
      required: true
    },
    members: {
      type: Array,
      required: true
    },
    hasMorePages: {
      type: Boolean,
      required: true
    }
  },
  setup (props) {
    const currentMonthDay = computed(() => moment().format('MM-DD'))
    // const { queryParamsObject } = useUrlQueryParams();
    const groupMembers = computed(() => props.members)

    return {
      currentMonthDay,
      groupMembers
    }
  },
  created: function () {
    this.moment = moment
  }
}
</script>

<style lang="scss" scoped>

.ce-utilities-infinite-result :deep(.items-grid) {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 1rem;
  @media (min-width: 576px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media (min-width: 768px) {
    grid-template-columns: repeat(3, 1fr);
  }
  @media (min-width: 992px) {
    grid-template-columns: repeat(2, 1fr);
  }
}

/* purgecss start ignore */
:deep(.pf-card .content-grid) {
  grid-template-columns: auto;
  @media (min-width: 1200px) {
    grid-template-columns: 160px minmax(200px, auto);
  }
}

/* purgecss end ignore */
</style>
