export const FILE_TYPES = [
  'video/mp4',
  'video/quicktime',
  'video/3gpp',
  'video/x-msvideo',
  'video/mpeg',
  'video/x-flv',
  'video/x-f4v',
  'video/x-matroska',
  'video/webm'
]

export const isAVideo = (file) => FILE_TYPES.includes(file.type)

export default null
