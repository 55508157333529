<template>
  <Preloader :is-loading="isLoading" />
  <div class="card card-success">
    <div class="card-body">
      <header-stories-component
        :title="`Users Groups`"
        :search-for="`user-groups`"
        :total="total"
      />
    </div>
  </div>
  <div class="card card-success">
    <div class="card-body color-gray">
      <div class="row">
        <div class="col-md-12 col-lg-8 col-xl-2">
          <div class="row">
            <user-info-component
              :name="`${account.first_name} ${account.last_name}`"
              :src="`${account.primary_photo?.sizes?.thumbnail}`"
              :account-type="`${accountType}`"
            />
          </div>
        </div>
        <div class="col-md-12 col-lg-8 col-xl-7">
          <div class="row">
            <statistics-card-component
              :card="statics"
              @change-status="changeStatus($event)"
            />
          </div>
        </div>
        <div class="col-md-12 col-lg-4 col-xl-3 border-start">
          <div class="row downloadAndMessageAlign">
            <div class="col-sm-6">
              <csv-button-component
                :csv-header="GroupCSVHeader"
                :user-id="userId"
                :type="`User-Groups`"
              />
            </div>
            <div class="col-sm-6">Message Groups</div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="card card-success">
    <div class="card-body">
      <user-group-table
        :group-type="dataTableUser"
        :list="groupList"
        @done="isDone($event)"
      />
    </div>
  </div>
</template>

<script>
import { onMounted, ref } from "vue";
import HeaderStoriesComponent from "@/components/HeaderStoriesComponent.vue";
import StatisticsCardComponent from "@/components/StatisticsCardComponent.vue";
import CsvButtonComponent from "@/components/CsvButtonComponent.vue";
import { userEnums } from "@/composable/enums.js";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import UserInfoComponent from "../../components/UserInfoComponent.vue";
import UserGroupTable from "../../components/Tables/UserGroupTable.vue";
import Preloader from "../../Layouts/Common/Preloader.vue";
import $ from "jquery";

export default {
  name: "App",
  components: {
    HeaderStoriesComponent,
    StatisticsCardComponent,
    CsvButtonComponent,
    UserInfoComponent,
    UserGroupTable,
    Preloader,
  },
  setup() {
    const account = ref({});
    const status = ref("active");
    const router = useRouter();
    const store = useStore();
    const { takeAction } = userEnums();
    const userName = ref(router.currentRoute.value.params.user_name);
    const userId = ref(null);
    const accountType = ref(null);
    const statics = ref([]);
    const total = ref(0);
    const dataTableUser = ref("");
    const isLoading = ref(false);
    const groupList = ref([]);
    const unpublished = ref(0);
    const active = ref(0);
    const deactivated = ref(0);
    const flagged = ref(0);
    const suspended = ref(0);
    const deleted = ref(0);
    const { GroupCSVHeader } = userEnums();

    statics.value = [
      { label: "Active", value: active.value },
      { label: "Deactivated", value: deactivated.value },
      { label: "Unpublished", value: unpublished.value },
      { label: "Flagged", value: flagged.value },
      { label: "Suspended", value: suspended.value },
      { label: "Deleted", value: deleted.value },
    ];

    const isDone = (event) => {
      isLoading.value = event;
    };

    const changeStatus = (event) => {
      dataTableUser.value = event;
      loadUser(event);
    };

    const loadUser = async (event) => {
      isLoading.value = true;
      $("#datatable").DataTable().destroy();
      const form = {
        id: userId.value,
        type: event ?? "all",
      };

      const response = await store.dispatch("group/loadUserGroupList", form);
      const data = response.data;
      groupList.value = data;

      setTimeout(() => {
        $("#datatable").DataTable({
          scrollX: true,
          columnDefs: [{ width: "40%", targets: 0 }],
          fixedColumns: true,
          lengthChange: true,
          ServerSide: true,
          bInfo: false,
          lengthChange: false,
          searching: false,
        });
      }, 250);

      isLoading.value = false;
    };
    onMounted(async () => {
      isLoading.value = true;
      const getUser = await store.dispatch("loadUserProfile", {
        value: userName.value,
      });
      accountType.value =
        getUser?.subscription_type.charAt(0).toUpperCase() +
        getUser?.subscription_type.slice(1) +
        " Account";
      userId.value = getUser?.id;
      account.value = getUser;

      const form = {
        id: userId.value,
        type: event ?? "all",
      };

      const statistics = await store.dispatch(
        "group/loadGroupUserStatistic",
        form
      );
      total.value = statistics.All;
      statics.value = [
        { label: "Active", value: statistics.active },
        { label: "Deactivated", value: statistics.deactivated },
        { label: "Unpublished", value: statistics.unpublished },
        { label: "Flagged", value: statistics.flagged },
        { label: "Suspended", value: statistics.suspended },
        { label: "Deleted", value: statistics.deleted },
      ];
      loadUser();
    });

    return {
      status,
      statics,
      takeAction,
      changeStatus,
      account,
      userId,
      accountType,
      isLoading,
      isDone,
      total,
      groupList,
      unpublished,
      active,
      deactivated,
      flagged,
      suspended,
      deleted,
      GroupCSVHeader,
    };
  },
};
</script>
<style scoped>
.color-gray {
  color: #727e8b;
}
.form-control:focus {
  box-shadow: none;
}
.btn:focus,
.info-box {
  box-shadow: none;
}
.color-gold {
  color: #b4914d;
  text-decoration: none;
  font-weight: 600;
}
.top-70 {
  top: 0% !important;
}
.start-58 {
  left: 58% !important;
}
.card {
  border: 0;
}
.widget-user-header {
  padding: 0;
  height: auto !important;
}
.widget-user .widget-user-header {
  padding: 0px;
}
.downloadAndMessageAlign {
  text-align: center;
  position: inherit;
  margin-top: 15px;
}
.info-box {
  margin-bottom: 0;
  min-height: 0;
}
</style>
