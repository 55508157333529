import moment from 'moment'
import map from 'lodash/map'
import forEach from 'lodash/forEach'
import throttle from 'lodash/throttle'
import pickBy from 'lodash/pickBy'
import cloneDeep from 'lodash/cloneDeep'
import { useStore } from 'vuex'
import { computed, reactive, ref, watch, inject } from 'vue'
import { useForm, usePage } from '@inertiajs/inertia-vue3'
import axios from 'axios'

export const GATHERING_TYPES = Object.freeze({
    EVENT: 1,
    HUDDLE: 2
})

export const RSVP_TYPES = Object.freeze({
    OPEN: 1,
    LIMITED_CAPACITY: 2,
    VIP_ONLY: 3
})

export const RSVP_STATUSES = Object.freeze({
    RSVP: 0,
    ATTENDING: 1,
    WAIT_LISTED: 2,
    ATTENDEES: 3
})

export const UNPUBLISH_STATUSES = Object.freeze({
    POSTPONED: 'postponed',
    RESCHEDULED: 'rescheduled',
    CANCELLED: 'cancelled'
})

export const EVENT_SETTINGS = Object.freeze({
    VENUE: 1,
    ONLINE_EVENT: 2,
    TO_BE_ANNOUNCED: 3
})

export const EVENTS_ALBUM_SETTINGS = Object.freeze({
    PUBLIC: 1,
    EVENT_ADMINS: 2,
    ME_ONLY: 3
})

export const EVENTS_TAKE_ACTION = Object.freeze({
    EDIT: "edit",
    FLAG: "flag",
    SUSPEND: "suspend",
    PUBLISH: "publish",
    DEACTIVATE: "deactivate",
    DELETE: "delete"
})
export const HUDDLES_TAKE_ACTION = Object.freeze({
    FLAG: 'flag',
    SUSPEND: 'suspend',
    DEACTIVATE: "deactivate",
    DELETE: "delete"
})

export const venueTypes = Object.freeze({
    VENUE: 'Venue',
    ONLINE_EVENT: 'Online Event',
    TO_BE_ANNOUNCED: 'To be announced'
})

export const liveChatEnabledTypes = Object.freeze({
    NO: 0,
    YES: 1
})

export const liveChatTypes = Object.freeze({
    OPEN_TO_ALL_PARTICIPANTS: 0,
    RSVP: 1,
    CHECK_INS: 2
})

export const formatInterestedCount = (value) => {
    if (value > 10 ** 3) {
        const number = Number((value / 10 ** 3).toFixed(1))
        return `+${number}k`
    }
    if (value > 10 ** 6) {
        const number = Number((value / 10 ** 6).toFixed(1))
        return `+${number}m`
    }
    return `+${Number(value.toFixed(1))}`
}

export const transformEventResource = (eventItem) => {
    const addressParts = []
    if (eventItem.venue_location) {
        addressParts.push(eventItem.venue_location)
    }
    if (eventItem.city) {
        addressParts.push(eventItem.city)
    }
    if (eventItem.street_address) {
        addressParts.push(eventItem.street_address)
    }
    if (eventItem.state) {
        addressParts.push(eventItem.state)
    }
    if (eventItem.country) {
        addressParts.push(eventItem.country)
    }
    const completeAddress = addressParts.join(', ')
    const imageUrl = eventItem.image_url
    const imageModifiedUrl = eventItem.image_modified_url
    const featuredImageUrl = eventItem.featured_image_url || imageUrl

    const totalPeopleInterested =
        eventItem.total_people_interested || eventItem.event_users?.length || 0
    const totalPeopleInterestedFormatted = formatInterestedCount(
        totalPeopleInterested
    )

    return {
        /* TODO: This needs to be cleaned-up */
        id: eventItem.id,
        slug: eventItem.slug,
        owner: eventItem.owner,
        title: eventItem.title,
        imageId: eventItem.image_id ? +eventItem.image_id : null,
        imageUrl,
        imageModifiedUrl: imageModifiedUrl || imageUrl,
        featuredImageUrl,
        videoId: eventItem.video ?.id || null,
        videoSrc: eventItem.video ?.modified || null,
        description: eventItem.description,
        type: eventItem.type,
        category: eventItem.category,
        tags: eventItem.tags || [],
        rsvpType: eventItem.rsvp_type || 0,
        maxCapacity: eventItem.max_capacity || null,
        capacityStatus: eventItem.capacity,
        setting: eventItem.setting,
        venueType: eventItem.venue_type,
        venueLocation: eventItem.venue_location,
        eventStart: moment(`${eventItem.event_start} ${eventItem.start_time}`),
        eventEnd: moment(`${eventItem.event_end} ${eventItem.end_time}`),
        timezone: eventItem.timezone,
        isPast: eventItem.is_past,
        streetAddress: eventItem.street_address,
        city: eventItem.city,
        state: eventItem.state,
        zipCode: eventItem.zip_code,
        country: eventItem.country,
        latitude: eventItem.latitude,
        longitude: eventItem.longitude,
        completeAddress,
        meetingSpot: eventItem.secondary_location,
        isPublished: Boolean(eventItem.is_published),
        qrCode: eventItem.qr_code,
        attendees: eventItem.event_users || [],
        attendeesCount: eventItem.attendees_count || 0,
        attendeesCountCheckedIn: eventItem.attendees_count_checked_in || 0,
        attendeesCountInvited: eventItem.attendees_count_invited || 0,
        rsvpdUsersCount: eventItem.rsvpd_users_count,
        rsvpdUsersAttendingCount: eventItem.rsvpd_users_attending_count || 0,
        rsvpdUsersWaitingCount: eventItem.rsvpd_users_waiting_count || 0,
        rsvpdUsersFlaggedCount: eventItem.rsvpd_users_flagged_count || 0,
        totalPeopleInterested,
        totalPeopleInterestedFormatted,
        isUserInterested: eventItem.is_user_interested || false,
        isOwner: Boolean(eventItem.is_owner),
        isGatekeeper: Boolean(eventItem.is_gatekeeper),
        isVip: Boolean(eventItem.is_vip),
        isTicketRevoked: eventItem.is_ticket_revoked,
        ticketDetails: eventItem.ticket_details,
        attendedAt: eventItem.attended_at,
        isChatEnabled: eventItem.is_chat_enabled,
        chatType: eventItem.chat_type,
        pastEvents: eventItem.past_events,
        admins: eventItem.roles ?.map((role) => ({
            id: role.user.id,
            primaryPhotoUrl: role.user.primary_photo,
            username: role.user.user_name,
            firstName: role.user.first_name,
            lastName: role.user.last_name,
            fullName: `${role.user.first_name} ${role.user.last_name}`,
            role: role.role.id,
            roleName: role.role.label,
            user: role.user,
            interests: role.user.interests || [],
            aboutMe: role.user.about_me || ''
        })),
        event_admins: eventItem.admins ?.map((role) => ({
            id: role.user.id,
            primaryPhotoUrl: role.user.primary_photo,
            username: role.user.user_name,
            firstName: role.user.first_name,
            lastName: role.user.last_name,
            fullName: `${role.user.first_name} ${role.user.last_name}`,
            role: role.role.id,
            roleName: role.role.label,
            user: role.user,
            interests: role.user.interests || [],
            aboutMe: role.user.about_me || ''
        })),
        canEditEvent: eventItem.abilities.includes('can_edit_event'),
        canAddGatekeepers: eventItem.abilities.includes('can_add_gatekeepers'),
        canScanQr: eventItem.abilities.includes('can_scan_qr'),
        canInviteVip: eventItem.abilities.includes('can_invite_vip'),
        cancelledAt: eventItem.cancelled_at,
        sentThanksAt: eventItem.sent_thanks_at,
        userRole: eventItem.user_role || '',
        gatheringType: eventItem.gathering_type,
        photosCount: eventItem.photos_count,
        albumsCount: eventItem.albums_count
    }
}

export const transformEventResources = (events) => {
    return map(events, (o) => transformEventResource(o))
}

export const formatEventDate = (value) => {
    const d = moment(value)
    return d.isValid() ? moment(value).format('ddd, MMM D, h:mma') : value
}

export const useHelper = () => {
    const page = usePage()

    const getCurrentPageUrl = () => {
        return new URL(
            /^axios/.test(page.url.value) ?
            page.url.value :
            window.location.origin + page.url.value
        )
    }
    const cleanSearchFields = (fields) => {
        return pickBy(fields, (value) => {
            return !!value
        })
    }
    const goPrevPage = () => window.history.back()

    return {
        getCurrentPageUrl,
        cleanSearchFields,
        goPrevPage
    }
}

export const useEvent = (type = 'event') => {
    const store = useStore()
    const page = usePage()

    const featuredEvent = ref(null)
    const eventItems = ref([])
    const userEvents = ref([])
    const userAttendingEvents = ref([])
    const isUserLoggedIn = computed(() => !!page.props.value.auth)

    // Computed
    const eventTypes = computed(() => useStore().state.event.eventTypes)
    const eventCategories = computed(
        () => useStore().state.event.eventCategories
    )

    const loadFeaturedEvents = async(params) => {
        const { data } = await store.dispatch('event/fetchFeaturedEvent', params)
        if (data) {
            featuredEvent.value = transformEventResource(data)
        }
    }

    // Methods
    const markAsInterested = (eventId, postToWall) => {
        return store.dispatch('event/markAsInterested', { eventId, postToWall })
    }
    const loadUserEvents = async(params = {}) => {
        const response = await store.dispatch('event/fetchUserEvents', {
            ...params,
            ... {
                type
            }
        })
        const { data } = response
        userEvents.value = transformEventResources(data || [])
        return response
    }
    const loadUserAttendingEvents = async(params = {}) => {
        const response = await store.dispatch(
            'event/fetchUserAttendingEvents',
            params
        )
        const { data } = response
        userAttendingEvents.value = transformEventResources(data || [])
        return response
    }
    const loadUserPastEvents = async(params = {}) => {
        const response = await store.dispatch('event/fetchUserPastEvents', params)
        const { data } = response
        userEvents.value = transformEventResources(data || [])
        return response
    }
    const loadUserAdminstratorRoles = async(params = {}) => {
        const response = await store.dispatch(
            'event/fetchUserAdministratorRole',
            params
        )
        const { data } = response
        userEvents.value = transformEventResources(data || [])
        return response
    }
    const removeFromEventInterest = (event) => {
        return store.dispatch('event/removeFromEventInterest', event.id)
    }
    const deleteUserEvent = (event) => {
        return store.dispatch('event/delete', event.id)
    }

    return {
        eventItems,
        featuredEvent,
        eventTypes,
        eventCategories,
        loadFeaturedEvents,
        markAsInterested,
        isUserLoggedIn,
        userEvents,
        loadUserEvents,
        deleteUserEvent,
        userAttendingEvents,
        loadUserAttendingEvents,
        removeFromEventInterest,
        loadUserPastEvents,
        loadUserAdminstratorRoles
    }
}

export const useRsvpBadge = (event) => {
    const rsvpTypeProperties = computed(() => ({
        [RSVP_TYPES.OPEN]: {
            label: 'Open',
            className: 'bg-dark-gray text-white'
        },

        [RSVP_TYPES.LIMITED_CAPACITY]: {
            label: 'Limited Capacity',
            className: event ?.value ?.capacityStatus === 'full' ?
                'bg-red text-white' :
                'bg-dark-gray text-white'
        },

        [RSVP_TYPES.VIP_ONLY]: {
            label: 'VIP Invite Only',
            className: 'bg-dark-gray text-white'
        }
    }))

    const rsvpTypeLabel = computed(
        () => rsvpTypeProperties.value[event ?.value ?.rsvpType] ?.label
    )

    const rsvpTypeClassName = computed(
        () => rsvpTypeProperties.value[event ?.value ?.rsvpType] ?.className
    )

    return {
        rsvpTypeLabel,
        rsvpTypeClassName
    }
}

export const useInterest = () => {
    const store = useStore()

    const isSettingInterestStatus = ref(false)
    const isUserInterested = ref(false)

    // Methods
    const markAsInterested = (eventId, postToWall) => {
        return store.dispatch('event/markAsInterested', {
            eventId,
            postToWall
        })
    }
    const markAsUninterested = (eventId) => {
        return store.dispatch('event/markAsUninterested', {
            eventId
        })
    }
    const markCurrentUserAsInterested = async(
        eventId,
        interested = true,
        dialog,
        postToWall = false
    ) => {
        isSettingInterestStatus.value = true
        try {
            let response
            if (interested) {
                response = await markAsInterested(eventId, postToWall)

                if (
                    response.data.capacity === 'full' &&
                    !response.data.ticket_details ?.qrCode
                ) {
                    dialog.show(
                        'This event is full. You have been added to the waiting list.', {
                            title: '',
                            buttons: [{
                                label: 'Close',
                                class: 'btn-gradient-gold'
                            }]
                        }
                    )
                }
            } else {
                response = await markAsUninterested(eventId)
            }

            isUserInterested.value = interested

            return response
        } catch (e) {
            const {
                response: { status, data }
            } = e
            if (status === 409) {
                isUserInterested.value = true
            } else if (dialog) {
                dialog.show(
                    'Unable to process your request. Please reload and try again.', {
                        title: 'Request error',
                        buttons: [{
                            label: 'Ok',
                            class: 'btn-nero'
                        }]
                    }
                )
            } else {
                throw e
            }

            return { data }
        } finally {
            isSettingInterestStatus.value = false
        }
    }

    return {
        isUserInterested,
        isSettingInterestStatus,
        markCurrentUserAsInterested
    }
}

export const useSearch = (props, { emit }) => {
    const store = useStore()
    const { getCurrentPageUrl } = useHelper()

    const searchForm = useForm('eventSearchForm', {
        search_type: 'all',
        keyword: undefined,
        start_date: undefined,
        start_time: undefined,
        end_date: undefined,
        end_time: undefined,
        venue: undefined,
        city: undefined,
        state: undefined,
        lat: undefined,
        lng: undefined,
        type: undefined,
        category: undefined,
        distance: undefined,
        advanced: undefined,
        gathering_type: undefined
    })

    const searchResults = reactive({
        hasMoreResult: false,
        data: []
    })
    const showAdvanceSearch = ref(false)
    const isSearching = ref(false)
    const searchQuery = reactive({
        page: 1,
        search_type: 'all',
        keyword: undefined,
        type: undefined,
        category: undefined,
        start_date: undefined,
        start_time: undefined,
        end_date: undefined,
        end_time: undefined,
        // distance: [0, 30],
        zipCode: undefined,
        venue: undefined,
        city: undefined,
        state: undefined,
        lat: undefined,
        lng: undefined,
        gathering_type: undefined
    })

    // Watchers
    watch(searchQuery, (value) => emit('update:modelValue', value), {
        deep: true
    })
    watch(isSearching, (value) => emit('update:isSearching', value))

    // Methods
    const initializeSearchForm = () => {
        searchForm.page = Number(getCurrentPageUrl().searchParams.get('page') || 1)
        searchForm.search_type =
            getCurrentPageUrl().searchParams.get('search_type')
        searchForm.keyword = getCurrentPageUrl().searchParams.get('keyword')
        searchForm.start_date = getCurrentPageUrl().searchParams.get('start_date')
        searchForm.start_time = getCurrentPageUrl().searchParams.get('start_time')
        searchForm.end_date = getCurrentPageUrl().searchParams.get('end_date')
        searchForm.end_time = getCurrentPageUrl().searchParams.get('end_time')
        searchForm.venue = getCurrentPageUrl().searchParams.get('venue')
        searchForm.city = getCurrentPageUrl().searchParams.get('city')
        searchForm.state = getCurrentPageUrl().searchParams.get('state')
        searchForm.lat = getCurrentPageUrl().searchParams.get('lat')
        searchForm.lng = getCurrentPageUrl().searchParams.get('lng')
        searchForm.type = getCurrentPageUrl().searchParams.get('type')
        searchForm.distance =
            getCurrentPageUrl().searchParams.get('distance') || 30
        searchForm.category = getCurrentPageUrl().searchParams.get('category')
        searchForm.advanced = getCurrentPageUrl().searchParams.get('advanced')
        searchForm.gathering_type = Number(
            getCurrentPageUrl().searchParams.get('gathering_type')
        )
    }
    const setSearchFormValues = (values) => {
        forEach(Object.keys(searchForm.data()), (key) => {
            if (Object.prototype.hasOwnProperty.call(values, key)) {
                searchForm[key] = values[key]
            }
        })
        searchForm.page = Number(getCurrentPageUrl().searchParams.get('page') || 1)
        return searchForm
    }
    const toggleAdvanceSearch = () => {
        showAdvanceSearch.value = !showAdvanceSearch.value
    }
    const setFormValues = () => {
        if (!props.modelValue) return

        forEach(Object.keys(searchQuery), (key) => {
            const query = props.modelValue
            searchQuery[key] = query[key]
        })
    }

    const loadEventOptions = async() => {
        setFormValues()
        try {
            await store.dispatch('event/loadEventOptions')
        } catch (e) {
            //
        }
    }

    // Methods
    const searchQueryUpdate = () => {
        emit('update:modelValue', searchQuery)
    }
    const clearSearchResults = () => {
        searchResults.data = []
        searchResults.hasMoreResult = false
    }
    const search = throttle(async(query = {}) => {
        isSearching.value = true
        try {
            const { data, links } = await store.dispatch('event/all', query)
            if (query.page === 1) {
                clearSearchResults()
            }
            searchResults.data = searchResults.data.concat(
                transformEventResources(data)
            )
            searchResults.hasMoreResult = links.next !== null
        } finally {
            isSearching.value = false
        }
    }, 250)

    return {
        searchForm,
        searchQuery,
        search,
        searchResults,
        showAdvanceSearch,
        toggleAdvanceSearch,
        setFormValues,
        searchQueryUpdate,
        isSearching,
        clearSearchResults,
        loadEventOptions,
        initializeSearchForm,
        setSearchFormValues
    }
}

export const useAttendee = () => {
    const store = useStore()

    return {
        fetchEventAttendee: (eventSlug, params) =>
            store.dispatch('event/fetchAttendeesBySlug', { eventSlug, params })
    }
}

export const useRelated = (event) => {
    const store = useStore()
    const relatedCount = ref(6)
    const allRelatedEvents = ref([])

    // computed
    const relatedEvents = computed(() =>
        allRelatedEvents.value.slice(0, relatedCount.value)
    )
    const hasRelatedEvent = computed(() => allRelatedEvents.value.length > 0)

    const loadRelatedEvents = async() => {
        const eventId = event ? event.id : undefined
        const { data } = await store.dispatch('event/related', eventId)
        allRelatedEvents.value = transformEventResources(data)

        return allRelatedEvents.value
    }

    return {
        relatedCount,
        relatedEvents,
        allRelatedEvents,
        loadRelatedEvents,
        hasRelatedEvent
    }
}

export const useEventUnpublish = (event) => {
    const store = useStore()
    store.dispatch('option/loadTimezones')
    const dialog = inject('dialog')
    const isUnpublishing = ref(false)
    const defaultUnpublishFormValues = {
        action: undefined,
        startDate: undefined,
        startTime: undefined,
        endDate: undefined,
        endTime: undefined,
        timezone: undefined
    }
    const unpublishForm = reactive(cloneDeep(defaultUnpublishFormValues))
    const hasValidForm = computed(() => {
        if (!unpublishForm.action) {
            return false
        }

        if (
            unpublishForm.action === UNPUBLISH_STATUSES.RESCHEDULED &&
            (!unpublishForm.startDate ||
                !unpublishForm.startTime ||
                !unpublishForm.endDate ||
                !unpublishForm.endTime ||
                !unpublishForm.timezone)
        ) {
            return false
        }

        return true
    })
    const formErrors = ref(null)
    const fieldOptions = computed(() => ({
        actions: [{
                label: 'Postpone',
                value: UNPUBLISH_STATUSES.POSTPONED
            },
            {
                label: 'Reschedule',
                value: UNPUBLISH_STATUSES.RESCHEDULED
            },
            {
                label: 'Cancel',
                value: UNPUBLISH_STATUSES.CANCELLED
            }
        ],
        timezones: store.getters['option/timezones']
    }))

    /* Methods */
    const resetUnpublishForm = () => {
        Object.assign(unpublishForm, cloneDeep(defaultUnpublishFormValues))
    }

    const unpublish = async() => {
        if (!hasValidForm.value) {
            dialog.show('Please make sure that the form is valid.', {
                title: 'Invalid Form'
            })
            return false
        }

        try {
            isUnpublishing.value = true
            await axios.post(`/api/events/unpublish/${event.id}`, {
                status: unpublishForm.action || undefined,
                new_event_start: unpublishForm.startDate || undefined,
                new_start_time: unpublishForm.startTime || undefined,
                new_event_end: unpublishForm.endDate || undefined,
                new_end_time: unpublishForm.endTime || undefined,
                new_time_zone: unpublishForm.timezone || undefined
            })
            return true
        } catch (e) {
            if (e.response.status === 422) {
                formErrors.value = e.response.data ?.error || null
            } else {
                dialog.show(
                    'We are unable to unpublish this event. Please try again.', {
                        title: 'Events'
                    }
                )
            }
            return false
        } finally {
            isUnpublishing.value = false
        }
    }

    return {
        isUnpublishing,
        unpublishForm,
        hasValidForm,
        formErrors,
        unpublish,
        fieldOptions,
        resetUnpublishForm
    }
}

export const useEventTypeLabel = (eventType) => {
    const eventTypeLabel = computed(() => {
        switch (eventType) {
            case 'event':
                return 'event'

            case 'huddle':
                return 'huddle'

            default:
                return ''
        }
    })

    const eventTypeLabelCap = computed(
        () =>
        eventTypeLabel.value.charAt(0).toUpperCase() +
        eventTypeLabel.value.substring(1)
    )

    return {
        eventTypeLabel,
        eventTypeLabelCap
    }
}

export const useDynamicWord = (event) => {
    const words = {
        event: {
            [GATHERING_TYPES.EVENT]: 'event',
            [GATHERING_TYPES.HUDDLE]: 'huddle'
        },
        Event: {
            [GATHERING_TYPES.EVENT]: 'Event',
            [GATHERING_TYPES.HUDDLE]: 'Huddle'
        },
        events: {
            [GATHERING_TYPES.EVENT]: 'events',
            [GATHERING_TYPES.HUDDLE]: 'huddles'
        },
        Events: {
            [GATHERING_TYPES.EVENT]: 'Events',
            [GATHERING_TYPES.HUDDLE]: 'Huddles'
        }
    }

    const dynamicWordFor = (word) => {
        return words[word] ?.[event.gatheringType] ?? word
    }

    return {
        dynamicWordFor
    }
}