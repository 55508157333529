<template>
  <div>
    <div class="card card-success">
      <div class="card-body">
         <span class="fw-bold color-black h1">User Tiers</span>
      </div>
    </div>
    <div class="card p-2">
      <div class="container-fluid">
        <div class="row justify-content-center h-100">
          <div class="col-2 border-right">
            <ul class="tabs__header">
              <li
                v-for="(tab, index) in userTierList"
                @click="changeTabAction(index)"
                :class="{ tab__selected: index == selectedIndex }"
                :key="tab.name"
              >
                {{ tab.name }}
              </li>
            </ul>
          </div>
          <div class="col">
            <div class="row">
              <div class="col-12">
                <ul class="subs_tabs_header fs-5">
                  <li
                    v-for="(subTab, index) in subTabs"
                    @click="changeSubTabAction(index)"
                    :class="{ tab__selected: index == selectedSubIndex }"
                    :key="subTab.value"
                  >
                    {{ subTab.label }}
                  </li>
                </ul>
              </div>
            </div>
            <div class="row sub_tab_content">
              <div class="col-12 p-3">
                <abilities-form
                  v-if="subTabs[selectedSubIndex].value == 'abilities'"
                  :role-id="selectedRoleId"
                  :ability-list="abilityList"
                  :current-abilities="form.abilities"
                  @success="saveAbilitiesSuccess"
                  @cancel="cancelChanges"
                  @selectAbilities="selectAbilities"
                />
                <prices-view
                  v-if="subTabs[selectedSubIndex].value == 'prices'"
                  :plan-id="form.plan_id"
                  :plan-name="form.plan_name"
                  :current-prices="form.prices"
                  @isLoading="isProcessLoading"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, onMounted, reactive, inject } from "vue";
import { useStore } from "vuex";
import AbilitiesForm from "@/Modules/Subscription/AbilitiesForm.vue";
import PricesView from "@/Modules/Subscription/PricesView.vue";

const subTabs = [
  {
    label: "Abilities",
    value: "abilities",
  },
  {
    label: "Prices",
    value: "prices",
  },
];

export default {
  methods: {},
  name: "App",
  components: {
    AbilitiesForm,
    PricesView,
  },
  emits: ["isLoading", "onGoHome"],
  setup(props, { emit }) {
    const store = useStore();
    const dialog = inject("dialog");
    const userTierList = ref([]);
    const abilityList = ref([]);

    const currentAbilities = ref([]);

    const selectedRoleId = ref(0);
    const selectedIndex = ref(0);
    const selectedSubIndex = ref(0);

    const formRef = ref(null);
    const form = reactive({
      plan_row_id: 0,
      plan_id: "",
      plan_name: "",
      abilities: [],
      prices: [],
    });

    const isProcessLoading = (status) => {
      emit("isLoading", status);
    };

    const saveAbilitiesSuccess = async (response) => {
      isProcessLoading(true);
      await loadUserTiers();
      isProcessLoading(false);
    };
    const savePriceSuccess = async (response) => {
      isProcessLoading(true);
      await loadUserTiers();
      isProcessLoading(false);
    };

    const showDialog = (i, positiveHandler) => {
      dialog.show(i.message, {
        title: i.title,
        size: "md",
        buttons: [
          {
            label: "Yes",
            class: "btn btn-gradient-gold",
            handler: positiveHandler,
          },
          {
            label: "No",
            class: "btn btn-light-gray",
            handler: (closeDialog) => {
              closeDialog();
            },
          },
        ],
      });
    };

    const selectAbilities = (data) => {
      form.abilities = data;
    };

    const selectTab = (i) => {
      selectedIndex.value = i;
      userTierList.value.forEach((tab, index) => {
        tab.isActive = index === i;
      });

      form.abilities = [];
      form.prices = [];
      if (userTierList.value[selectedIndex.value].abilities_for_admin !== null) {
        currentAbilities.value = userTierList.value[
          selectedIndex.value
        ].abilities.map((item) => {
          return item.id;
        });

        form.abilities = userTierList.value[selectedIndex.value].abilities_for_admin.map(
          (item) => {
            return item.id;
          }
        );

        form.prices = userTierList.value[selectedIndex.value].prices;
      }
      form.plan_row_id = userTierList.value[selectedIndex.value].id;
      form.plan_id = userTierList.value[selectedIndex.value].plan_id;
      form.plan_name = userTierList.value[selectedIndex.value].name;

      selectedSubIndex.value = 0;

      selectedRoleId.value = userTierList.value[selectedIndex.value].role_id;
    };

    const changeTabAction = (i) => {
      if (
        userTierList.value[selectedIndex.value].abilities_for_admin !== null &&
        form.abilities.length !=
          userTierList.value[selectedIndex.value].abilities_for_admin.length
      ) {
        showDialog(
          {
            title: "Discard changes?",
            message:
              "It looks like you have edited something.<br />If you leave before saving, your changes will be lost.",
          },
          async (closeDialog) => {
            selectTab(i);
            closeDialog();
          }
        );

        return false;
      }

      selectTab(i);
      return true;
    };

    const changeSubTabAction = (i) => {
      selectedSubIndex.value = i;
      subTabs.forEach((tab, index) => {
        tab.isActive = index === i;
      });
    };

    const cancelChanges = () => {
      showDialog(
        {
          title: "Discard changes?",
          message: "Are you sure you want to cancel your changes.",
        },
        async (closeDialog) => {
          selectTab(selectedIndex.value);
          closeDialog();
        }
      );
    };

    const loadUserTiers = async () => {
      let response = await store.dispatch("loadTiersList");
      userTierList.value = response.data;
      selectTab(0);
    };

    const loadAbilitiesList = async () => {
      let response = await store.dispatch("loadAbilitiesList");
       var makes={};
        response.data.forEach((item)=>{
        if(makes[item.group] === undefined){
            makes[item.group]=[];
            makes[item.group].push(item)
        } else {
          makes[item.group].push(item)
        }
      });
      abilityList.value = makes;
    };

    onMounted(async () => {
      isProcessLoading(true);
      await loadUserTiers();
      await loadAbilitiesList();
      isProcessLoading(false);
    });

    return {
      subTabs,
      userTierList,
      abilityList,
      changeTabAction,
      selectedIndex,
      formRef,
      form,
      cancelChanges,
      changeSubTabAction,
      selectedSubIndex,
      currentAbilities,
      selectAbilities,
      saveAbilitiesSuccess,
      savePriceSuccess,
      selectedRoleId,
      isProcessLoading,
    };
  },
};
</script>

<style scoped>
ul.tabs__header {
  display: block;
  list-style: none;
  margin: 0;
  padding: 0;
}
ul.tabs__header > li {
  padding: 15px 5px;
  margin: 0;
  display: block;
  margin-right: 5px;
  cursor: pointer;
}
ul.tabs__header > li.tab__selected {
  font-weight: bold;
  background-color: #343a40;
  color: #fff;
  /* border-radius: 10px 10px 0 0; */
  /* border-bottom: 8px solid transparent; */
}

ul.subs_tabs_header {
  display: block;
  list-style: none;
  margin: 0 0 0 20px;
  padding: 0;
}
ul.subs_tabs_header > li {
  padding: 15px 30px;
  border-radius: 10px;
  margin: 0;
  display: inline-block;
  margin-right: 5px;
  cursor: pointer;
}
ul.subs_tabs_header > li.tab__selected {
  font-weight: bold;
  border-radius: 10px 10px 0 0;
  border-bottom: 8px solid transparent;
}

.tab {
  display: inline-block;
  color: black;
  padding: 20px;
  border-radius: 10px;
  min-height: 400px;
}

.subs_tabs_header .tab {
  background-color: #fff;
}
.subs_tabs_header li {
  background-color: transparent;
  color: #aaa;
}
.subs_tabs_header li.tab__selected {
  background-color: #eaeaea;
  color: #000;
}
.sub_tab_content {
  background-color: #eaeaea;
  border-bottom-right-radius: 10px;
  color: #000;
}

.row-eq-height {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}
</style>
