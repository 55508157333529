<template>
  <ce-form
    ref="formRef"
    v-slot="{ submit }"
    v-model:is-valid="hasValidForm"
    :data="form"
    @submit="submitHandler"
  >
    <div class="row g-md-1 m-2 row-eq-height">
      <template v-for="rows in abilityList" :key="rows.in">
        <div class="col col-md-12 col-sm-12 mb-3">
          <span class="fw-bold text-uppercase">{{ rows[0]['group'] !== null ? rows[0]['group'] : 'NO GROUPS' }}</span>
          <div
            v-for="ability in rows"
            :key="ability.id"
          >
            <div class="form-check">
              <input
                class="form-check-input"
                type="checkbox"
                v-model="form.abilities"
                :value="ability.id"
                :id="ability.name"
                :checked="form.abilities.indexOf(ability.id) !== -1"
                :disabled="isSubmitting"
              />
              <label class="form-check-label" :for="ability.name">
                <span class="px-3">{{ ability.label }}</span>
              </label>
            </div>
          </div>
        </div>
      </template>
    </div>
    <div class="row justify-content-end">
      <div class="col-md-4 col-sm-12">
        <div class="row justify-content-end">
          <div class="col-6 col-sm-6 mb-3">
            <ce-button
              class="btn-lg btn btn-gray text-white text-bold w-100"
              @click="cancelChanges"
            >
              Cancel
            </ce-button>
          </div>
          <div class="col-6 col-sm-6 mb-3">
            <ce-button
              class="btn-lg btn-gradient-gold text-bold w-100"
              :is-loading="isSubmitting"
              :disabled="isSubmitting"
              @click="submit"
            >
              Save
            </ce-button>
          </div>
        </div>
      </div>
    </div>
  </ce-form>
</template>

<script>
import { reactive, ref, watch, inject } from "vue";
import http from "@/http.js";

export default {
  props: {
    roleId: {
      type: Number,
      required: true,
    },
    abilityList: {
      type: Object,
      required: true,
      default: () => {},
    },
    currentAbilities: {
      type: Object,
      default: () => {},
    },
  },
  emits: ["success", "isSubmitting", "selectAbilities", "cancel"],
  setup(props, { emit }) {
    const dialog = inject("dialog");
    const isLoading = ref(false);

    const hasValidForm = ref(false);
    const isSubmitting = ref(false);

    const formRef = ref(null);
    const form = reactive({
      abilities: [],
    });

    const submitHandler = () => {
      if (form.abilities.length === 0) {
        dialog.show(
          "Are you sure you want to remove all abilities for this Subscription type?",
          {
            title: "Remove all abilities?",
            size: "md",
            buttons: [
              {
                label: "Yes",
                class: "btn btn-gradient-gold",
                handler: async (closeDialog) => {
                  saveAbilities();
                  closeDialog();
                },
              },
              {
                label: "No",
                class: "btn btn-light-gray",
                handler: (closeDialog) => {
                  closeDialog();
                },
              },
            ],
          }
        );

        return false;
      }

      saveAbilities();
    };

    const saveAbilities = async () => {
      try {
        isSubmitting.value = true;
        const response = await http.post(
          `roles/${props.roleId}/attach-ability`,
          {
            ability_ids: form.abilities,
          }
        );
        emit("success", response);
      } catch (exception) {
        emit("fail", exception);
        if (exception.response?.status === 422) {
          dialog.show("Please make sure you enter valid details.", {
            title: "Invalid Details",
          });
        } else {
          dialog.show(
            "An unexpected error encountered. Please try again later.",
            {
              title: "System Error",
            }
          );
        }
      } finally {
        isSubmitting.value = false;
      }
    };

    const cancelChanges = () => {
      emit("cancel", true);
    };

    watch(
      () => props.currentAbilities,
      (newCurrentAbilities) => {
        form.abilities = newCurrentAbilities;
      },
      {
        immediate: true,
      }
    );

    watch(
      () => form.abilities,
      (newSelectedAbilities) => {
        emit("selectAbilities", newSelectedAbilities);
      },
      {
        immediate: true,
      }
    );

    return {
      form,
      formRef,
      isLoading,
      hasValidForm,
      isSubmitting,
      cancelChanges,
      submitHandler,
    };
  },
};
</script>
<style lang="scss" scoped>
@import "../../scss/customizations";
</style>
