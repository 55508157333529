<template>
  <div>
    <body class="hold-transition sidebar-mini sidebar-collapse">
      <div class="wrapper">
        <Preloader :is-loading="isLoading" />
        <Navbar />
        <Sidebar />

        <div class="content-wrapper pb-5">
          <div class="content-header">
            <div class="container-fluid" />
          </div>
          <section class="content">
            <div class="container-fluid">
              <router-view @isLoading="isProcessLoading" @onGoHome="onGoHome" />
            </div>
          </section>
        </div>

        <Footer />
        <ControlSidebar />
        <!-- <BaseLayout></BaseLayout> -->
      </div>
    </body>
  </div>
</template>

<script>
import { ref } from "vue";
import Preloader from "./Common/Preloader.vue";
import Navbar from "./Common/Navbar.vue";
import Sidebar from "./Common/Sidebar.vue";
import Footer from "./Common/Footer.vue";
import ControlSidebar from "./Common/ControlSidebar.vue";
// import BaseLayout from "./BaseLayout.vue"

import { useRouter } from "vue-router";

export default {
  name: "App",
  components: {
    Preloader,
    Navbar,
    Sidebar,
    Footer,
    ControlSidebar,
    // BaseLayout
  },
  setup() {
    const isLoading = ref(false);

    const router = useRouter();

    const isProcessLoading = (status) => {
      isLoading.value = status;
    };

    const onGoHome = () => {
      router.replace({
        name: "home",
      });
    };

    return {
      isLoading,
      isProcessLoading,
      onGoHome,
    };
  },
};
</script>

<style>
@import url("https://fonts.googleapis.com/css?family=Source+Sans+Pro:300,400,400i,700&display=fallback");
</style>
