<template>
  <div>
    <div ref="attachmentsContainerRef" />
    <teleport
      v-if="isReady"
      :to="computedAttachmentsContainer"
    >
      <template
        v-for="(attachment, index) in attachments"
        :key="attachment.id"
      >
        <template v-if="attachment.type === 'video'">
          <video-field
            v-model="attachment.mediaId"
            v-model:is-uploading="attachment.isUploading"
            class="mx-1"
            :upload="attachment.file"
            @removed="attachments.splice(index, 1)"
          />
        </template>
        <template v-else-if="attachment.type === 'photo'">
          <photo-field
            v-model="attachment.mediaId"
            v-model:is-uploading="attachment.isUploading"
            placeholder="/images/defaults/photo.png"
            class="mx-1"
            :upload="attachment.file"
            @removed="attachments.splice(index, 1)"
          />
        </template>
      </template>
    </teleport>
    <ce-visual-media-picker
      :file-types="acceptableFileTypes"
      :modal-container="baseLayoutRef"
      :multiple="multiple"
      @invalid-file-type="showInvalidFileTypePrompt()"
      @picked="addAttachments(multiple ? $event : [$event])"
    >
      <template #default="{ browseMedia }">
        <ce-button
          icon
          title="Attach Video or Photo"
          :disabled="disabled || (!multiple && attachments.length > 0)"
          @click="browseMedia"
        >
          <slot>
            <span class="fas fa-image" />
          </slot>
        </ce-button>
      </template>
      <template #browse-options="{ chooseFromFile, takeFromCamera }">
        <div class="row p-3">
          <slot name="browse-text">
            <p class="text-muted text-center font-monospace mb-3">
              Upload your Perfect Friends videos or photos.
            </p>
            <p
              v-if="modalDescription"
              class="text-muted text-center font-monospace mb-3"
            >
              {{ modalDescription }}
            </p>
          </slot>
          <div
            class="mb-3 mb-md-0"
            :class="[isMobileDevice ? 'col-md-6' : 'col-md-12']"
          >
            <ce-button
              type="button"
              class="btn btn-nero w-100"
              @click="chooseFromFile()"
            >
              <slot name="browse-button">
                Add Video or Photo
              </slot>
            </ce-button>
          </div>
          <div
            v-if="isMobileDevice"
            class="col-md-6"
          >
            <ce-button
              type="button"
              class="btn btn-nero w-100"
              @click="takeFromCamera()"
            >
              Take From Device
            </ce-button>
          </div>
        </div>
      </template>
    </ce-visual-media-picker>
  </div>
</template>

<script>
import {
  defineAsyncComponent,
  inject,
  ref,
  computed,
  watch,
  nextTick,
  onMounted
} from 'vue'
import uniqueId from 'lodash/uniqueId'
import { FILE_TYPES as VIDEO_FILE_TYPES, isAVideo } from '@/Use/use-video.js'
import { FILE_TYPES as PHOTO_FILE_TYPES, isAPhoto } from '@/Use/use-photo.js'
import { isMobileDevice } from '@/Use/use-user-agent.js'
import CeVisualMediaPicker from '../Utilities/CeVisualMediaPicker.vue'

export default {
  components: {
    PhotoField: defineAsyncComponent(() =>
      import('@/components/Inputs/PhotoField.vue')
    ),
    VideoField: defineAsyncComponent(() =>
      import('@/components/Inputs/VideoField.vue')
    ),
    CeVisualMediaPicker
  },
  props: {
    /**
     * v-model on this component is read-only.
     * Any changes from it will not reflect on this component.
     * When removing attachments (v-model) value,
     * use removeAttachments() method
     */
    modelValue: {
      type: Array,
      default: () => []
    },
    isUploading: {
      type: Boolean,
      default: false
    },
    attachmentsContainer: {
      type: HTMLElement,
      default: null
    },
    multiple: {
      type: Boolean,
      default: false
    },
    photo: {
      type: Boolean,
      default: false
    },
    video: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    additionalDescription: {
      type: String,
      default: null
    },
    maximumImages: {
      type: Number,
      default: 0
    },
    maximumFileLimitToUpload: {
      // total max limit per upload, total combine with image and videos
      type: Number,
      default: 10
    }
  },
  emits: ['update:modelValue', 'update:isUploading'],
  setup (props, { emit }) {
    const isReady = ref(false)
    const dialog = inject('dialog')
    const baseLayoutRef = inject('baseLayoutRef')
    const attachments = ref([])
    const attachmentsContainerRef = ref(null)
    const computedAttachmentsContainer = computed(
      () => props.attachmentsContainer || attachmentsContainerRef.value
    )
    const isUploadingAttachments = computed(() =>
      attachments.value.some((attachment) => attachment.isUploading)
    )
    const acceptableFileTypes = computed(() => {
      const fileTypes = []
      if (props.photo) {
        fileTypes.push(...PHOTO_FILE_TYPES)
      }
      if (props.video) {
        fileTypes.push(...VIDEO_FILE_TYPES)
      }
      return fileTypes
    })
    const maxNumberOfImage = ref(props.maximumImages)
    const hasReachedMaxNumberOfImage = computed(
      () =>
        attachments.value.reduce(
          (acc, curr) => (curr.type === 'photo' ? acc + 1 : acc),
          0
        ) >= maxNumberOfImage.value
    )
    const maxNumberOfVideo = 1
    const hasReachedMaxNumberOfVideo = computed(
      () =>
        attachments.value.reduce(
          (acc, curr) => (curr.type === 'video' ? acc + 1 : acc),
          0
        ) >= maxNumberOfVideo
    )
    const modalDescription = ref(props.additionalDescription)
    let attemptedToUploadMultipleVideos = false
    let attemptedToUploadMultipleImages = false

    /* Methods */
    const showInvalidFileTypePrompt = () => {
      dialog.show(
        'Please make sure to select only valid photo and video file types.',
        {
          title: 'Invalid file type detected'
        }
      )
    }

    const addAttachments = async (files) => {
      let fileAttachments = files
      let totalVideos = 0
      let totalImages = 0

      attemptedToUploadMultipleVideos = false
      attemptedToUploadMultipleImages = false

      // Filter the attachments, get the first maximumFileLimitToUpload upload
      if (
        fileAttachments.concat(attachments.value).length >
          props.maximumFileLimitToUpload &&
        props.maximumImages > 0 &&
        props.multiple
      ) {
        // initial total video by the current uploaded attachments
        totalVideos = attachments.value.filter((attachment) =>
          isAVideo(attachment.file)
        ).length

        // initial total images by the current uploaded attachments
        totalImages = attachments.value.filter((attachment) =>
          isAPhoto(attachment.file)
        ).length

        // Loop through all the attachments
        const tmpFiles = await fileAttachments.filter((file) => {
          // Check if file is video and not yet reached the file max limit upload and max video upload
          if (isAVideo(file) && props.video && totalVideos < maxNumberOfVideo) {
            totalVideos += 1
            return props.maximumFileLimitToUpload >= totalVideos + totalImages
          }

          // Check if file is image and not yet reached the file max limit upload and max image upload
          if (
            isAPhoto(file) &&
            props.photo &&
            totalImages < props.maximumImages
          ) {
            totalImages += 1
            return props.maximumFileLimitToUpload >= totalVideos + totalImages
          }

          return false
        })

        attemptedToUploadMultipleImages = true // show the modal notice
        fileAttachments = tmpFiles
      }

      await Promise.all(
        fileAttachments.map(async (file) => {
          await nextTick()
          if (isAVideo(file) && props.video) {
            if (!hasReachedMaxNumberOfVideo.value) {
              attachments.value.push({
                id: uniqueId('attachments'),
                type: 'video',
                isUploading: null,
                mediaId: null,
                file
              })
            } else {
              attemptedToUploadMultipleVideos = true
            }
          } else if (isAPhoto(file) && props.photo) {
            if (maxNumberOfImage.value === 0) {
              attachments.value.push({
                id: uniqueId('attachments-'),
                type: 'photo',
                isUploading: null,
                mediaId: null,
                file
              })

              return
            }

            if (!hasReachedMaxNumberOfImage.value) {
              attachments.value.push({
                id: uniqueId('attachments-'),
                type: 'photo',
                isUploading: null,
                mediaId: null,
                file
              })
            } else {
              attemptedToUploadMultipleImages = true
            }
          }
        })
      )

      if (attemptedToUploadMultipleVideos) {
        dialog.show(
          'Only 1 video upload is allowed at this moment and the first video from your selection is the one we accepted.',
          {
            title: 'Attachment Notice',
            buttons: [
              {
                label: 'Close',
                class: 'btn-light-gray',
                handler: (closeModal) => {
                  closeModal()
                }
              }
            ]
          }
        )
      }

      if (attemptedToUploadMultipleImages) {
        dialog.show(
          'Only 10 images can be uploaded at this time. The first 10 images selected will be used for this post.',
          {
            title: 'Attachment Notice',
            buttons: [
              {
                label: 'Close',
                class: 'btn-light-gray',
                handler: (closeModal) => {
                  closeModal()
                }
              }
            ]
          }
        )
      }
    }

    const removeAttachments = () => {
      attachments.value = []
    }

    /* Lifecycles */
    onMounted(() => {
      isReady.value = true
    })

    /* Watchers */
    watch(isUploadingAttachments, (newIsUploadingAttachments) => {
      emit('update:isUploading', newIsUploadingAttachments)
    })

    watch(
      attachments,
      (newAttachments) => {
        emit(
          'update:modelValue',
          props.multiple
            ? newAttachments.map((newAttachment) => newAttachment.mediaId)
            : newAttachments[0]?.mediaId
        )
      },
      { deep: true }
    )

    return {
      isReady,
      baseLayoutRef,
      isMobileDevice,
      attachmentsContainerRef,
      computedAttachmentsContainer,
      attachments,
      showInvalidFileTypePrompt,
      acceptableFileTypes,
      addAttachments,
      isUploadingAttachments,
      removeAttachments,
      modalDescription
    }
  }
}
</script>
