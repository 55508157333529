<template>
  <div class="row">
    <div class="col-sm-12">
      <div class="wysiwyg-editor px-2 py-2">
        <!-- attachment preview -->
        <div class="attachment-lists bg-light-gray" v-if="!isPreview">
          <div ref="attachmentsRef" class="attachmentsList">
            <div v-if="isUploadingPhoto" class="w-100 h-100 has-image mx-1">
              <div class="w-100 h-100 position-relative photo-picker">
                <img :src="placeholder" class="mw-100 mh-100 rounded-2" />
                <i class="fa fa-spinner"></i>
              </div>
            </div>
            <div
              v-for="(row, index) in attachmentMediaIds"
              :key="index"
              class="w-100 h-100 has-image mx-1"
            >
              <div class="w-100 h-100 has-image mx-1">
                <div class="w-100 h-100 position-relative photo-picker">
                  <input
                    type="file"
                    class="d-none"
                    accept="image/png,image/jpeg,image/gif,image/webp,image/svg+xml"
                  />
                  <button
                    type="button"
                    class="btn btn-transparent p-0 img-upload-btn position-relative overflow-hidden w-100 h-100"
                    :disabled="isUploadingPhoto"
                  >
                    <img
                      :src="row.original"
                      class="mw-100 mh-100 rounded-2"
                      :alt="row.client"
                    />
                  </button>
                  <template v-if="!isUploadingPhoto">
                    <button
                      type="button"
                      class="btn p-0 remove-btn"
                      @click="removePhoto(row)"
                      style="position: absolute"
                    >
                      <i class="fa-solid fa-trash-can"></i>
                    </button>
                  </template>
                </div>
              </div>
            </div>
          </div>
        </div>
        <ce-chat-box
          v-if="!isPreview"
          v-model="contentMessage"
          class="chat-box"
          placeholder="Write your message..."
          readonly
        />
        <div v-else>
          <div v-for="(row, index) in notes" :key="index" class="mb-2">
            <strong class="type-text-color"> 
              {{ 
                row.type === 'deactivate' ? 'Deactivated' 
                : row.type === 'flag' ? 'Flagged'
                : row.type === 'suspend' ? 'Suspended'
                : row.type === 'reactivate' ? 'Reactivated'
                : row.type === 'publish' ? 'Published'
                : row.type === 'remove-flag' ? 'Removed Flag'
                : row.type === 'unsuspend' ? 'Unsuspended'
                : row.type === 'delete' ? 'Deleted'
                : '' 
              }} </strong>
            <div class="content-notes">
              <div v-if="row.media !== null" >
                <img :src="row?.media?.location" class="rounded hw-50"/><br>
              </div>
              {{ row.notes }}
            </div>
            <div class="content-notes-text">Last edited: {{ moment(row.updated_at).format("MMMM D, YYYY") }}</div>
            <div class="content-notes-text">Edited by: {{ row.reported_by.first_name }} {{ row.reported_by.last_name }}</div>
            <hr>
          </div>
        </div>
      </div>
      <div
        class="modal-footer border-0 justify-content-between"
        v-if="!isPreview"
      >
        <div class="message-actions d-flex align-items-left">
          <input
            ref="fileBrowser"
            type="file"
            class="d-none"
            multiple="multiple"
            accept="image/*"
            @change="pick($event)"
          />
          <!-- <button class="border-0 bg-white" @click="$refs.fileBrowser.click()">
              <i class="fa-solid fa-paperclip"></i>
            </button> -->
          <button class="border-0 bg-white" @click="$refs.fileBrowser.click()">
            <i class="fa-solid fa-file-image"></i>
          </button>
        </div>
        <div>
          <span
            type="button"
            class="btn-outline"
            style="color: #6c757d"
            @click="cancelMessage"
            >CANCEL</span
          >
          <span
            type="button"
            class="send ml-2"
            :is-loading="isLoading"
            :disabled="isUploadingPhoto"
            @click="saveNotes"
            >SAVE</span
          >
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import CeChatBox from "@/components/Editors/CeChatBox.vue";
import { ref, watch } from "vue";
import { useStore } from "vuex";
import moment from "moment";
import $ from 'jquery'
export default {
  components: {
    CeChatBox,
  },
  props: {
    notesAccount: {
      type: Object,
      required: false,
      default: null,
    },
    placeholder: {
      type: String,
      default: "//via.placeholder.com/150",
    },
    isPreview: {
      type: Boolean,
      required: false,
    },
    isEdit: {
      type: Boolean,
      required: false,
    },
    notes: {
      type: [Array, Object],
      required: false,
    },
    notesType: {
      type: String,
      required: true,
    },
  },
  emits: ["done"],
  setup(props, { emit }) {
    const store = useStore();
    const isLoading = ref(false);
    const isUploadingPhoto = ref(false);
    const contentMessage = ref(null);
    const attachmentMediaIds = ref([]);
    const preview = ref(false);
    const cancelMessage = () => {
      contentMessage.value = "";
      preview.value = false;
      $("#notes-modal").modal("hide");
    };
    const saveNotes = async () => {
      const formData = {
        notable_type: props.notesType === "events" ? "event" : "huddle",
        notable_id: props.notesAccount.id,
        note: contentMessage.value,
        media_id: attachmentMediaIds.value,
      };
      const response = await store.dispatch("saveNotes", formData);
      if (response.status) {
        contentMessage.value = "";
        attachmentMediaIds.value = [];
        emit("done", true);
      }
    };
    const addAttachments = (files) => {
      const formData = new FormData();
      for (let ctr = 0; ctr < files.length; ctr += 1) {
        formData.append("file[]", files[ctr]);
      }
      submitFiles(formData);
    };
    const pick = (e) => {
      const mediaFiles = [...e.target.files];
      addAttachments(mediaFiles);
    };
    const submitFiles = async (formData) => {
      isUploadingPhoto.value = true;
      const response = await store.dispatch("multipleMediaUpload", formData);
      response.forEach((element) => {
        attachmentMediaIds.value.push(element);
      });
      isUploadingPhoto.value = false;
    };
    watch(
      () => props.notesAccount,
      (newValue) => {
        contentMessage.value = newValue.user_notes?.note;
        if (props.isEdit === true) {
          preview.value = false;
        }
        if (props.isPreview === true) {
          preview.value = true;
        }
        if (props.isEdit === false && props.isPreview === false) {
          preview.value = false;
        }
      }
    );
    return {
      isLoading,
      isUploadingPhoto,
      cancelMessage,
      saveNotes,
      contentMessage,
      attachmentMediaIds,
      addAttachments,
      pick,
      addAttachments,
      submitFiles,
      preview,
    };
  },
  created: function () {
    this.moment = moment;
  },  
};
</script>
<style lang="scss" scoped>
hr:not([size]) {
  height: 0px !important;
}
label.col-form-label {
  color: #505050;
  font-size: 14px;
  font-weight: 400 !important;
}
.bg-linear {
  background: linear-gradient(90deg, #b28f49 0%, #d6caaa 100%);
  border-radius: 4px 4px 0 0;
}
.attachmentsList {
  display: flex;
  > :deep(div) {
    margin: 0.25rem 0;
    width: 60px !important;
    height: 60px !important;
  }
  > :deep(div img) {
    background-color: gray;
  }
}
.remove-btn {
  display: none;
}
.photo-picker {
  &:hover .remove-btn {
    background: #e7e7e7;
    border-radius: 50%;
    box-shadow: 0 4px 10px rgba(black, 0.25);
    color: #212121;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 32px;
    height: 32px;
    position: absolute;
    top: 0;
    right: 0;
    font-size: 1rem;
    z-index: 2;
  }
}
.img-upload-btn {
  position: relative;
  > .icon-outline-plus {
    display: none;
  }
  &:hover {
    &::before {
      content: " ";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: #222;
      opacity: 0.5;
      z-index: 1;
    }
    > .icon-outline-plus {
      display: block;
      color: #fff;
      font-size: 2rem;
      z-index: 1;
    }
  }
}
.send {
  color: rgb(4, 98, 193);
}
.bg-light-gray {
  background-color: #e7e7e7;
}
.content-notes {
  min-height: 5rem; 
  border-radius: 5px; 
  padding: 5px; 
  background-color: #F8F8F8;  
  color: #AAAAAA;
  border: 1px solid #AAAAAA;
}
.content-notes-text {
  color: #AAAAAA;
}
.type-text-color {
  color: #D0463E;
}
.hw-50 {
  height: 50px; 
  width: 50px;
}
</style>
