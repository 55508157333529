<template>
  <div>
    <ce-button
      class="btn-red h-100"
      :class="{
        'btn-green text-white': !isPublished,
      }"
      :is-loading="isPublishing"
      :disabled="isPublishing"
      @click="askFirst()"
    >
      {{ isPublished ? 'Unpublish' : 'Publish' }}
    </ce-button>
  </div>
</template>
<script>
import { inject, ref } from 'vue'
// import { Inertia } from '@inertiajs/inertia';
import { useStore } from 'vuex'
import CeButton from '@/components/Buttons/CeButton.vue'
// import { useGroupPublishing, transformGroupResource } from '@/Use/groups.js';

export default {
  name: 'GroupPublishButton',
  components: {
    CeButton
  },
  props: {
    group: {
      type: Object,
      required: true
    }
  },
  setup (props) {
    const isPublished = ref(props.group.isPublished)
    const isPublishing = ref(false)
    const dialog = inject('dialog')
    const { publishGroup } = ref(null)
    // const { publishGroup } = useGroupPublishing(props.group.id);
    const store = useStore()
    const postToWall = ref(false)

    const publish = async () => {
      try {
        let successMessage
        isPublishing.value = true
        const { data } = await publishGroup({
          status: !isPublished.value ? 'publish' : 'unpublish',
          postToWall
        })
        // const groupResource = transformGroupResource(data);

        // isPublished.value = Boolean(groupResource.isPublished);
        isPublishing.value = false

        if (isPublished.value) {
          successMessage =
            'Your group has been published and is available for the world to see! ' +
            'You can go back and edit the group, or unpublished if needed.'
        } else {
          successMessage = 'Your group has been unpublished'
        }

        const dialogButtons = [
          {
            label: 'Close',
            class: 'btn-light-gray'
          }
        ]

        if (isPublished.value) {
          dialogButtons.push({
            label: 'Visit Group',
            class: 'btn-gradient-gold',
            handler: async (close) => {
              // await Inertia.visit(`/groups/${props.group.slug}`);
              close()
            }
          })

          // Listen to this group
          store.dispatch('listener/listenToNewGroup', props.group.id)
        } else {
          // remove listening to this group
          store.dispatch('listener/unlistenToGroup', props.group.id)
        }

        dialog.show(successMessage, {
          title: isPublished.value ? 'Group Published!' : 'Group Unpublished!',
          buttons: dialogButtons
        })
      } catch (e) {
        isPublished.value = false
        isPublishing.value = false
      }
    }

    const askFirst = () => {
      if (isPublished.value) {
        publish()
        return
      }

      const dialogButtons = [
        {
          label: 'Yes',
          class: 'btn-light-gray',
          handler: () => {
            postToWall.value = true
            publish()
          }
        },
        {
          label: 'No',
          class: 'btn-light-gray',
          handler: () => {
            publish()
          }
        }
      ]

      dialog.show('Do you want to post this on your wall?', {
        title: 'You are about to publish this group',
        buttons: dialogButtons
      })
    }

    return {
      isPublished,
      publish,
      askFirst,
      isPublishing
    }
  }
}
</script>
