<template>
  <ce-accordion-item>
    <template #header>
      <label class="d-block text-start small fw-semibold ls-1">HEIGHT</label>
    </template>
    <div class="pb-3">
      <ce-range
        v-model="localValue"
        :min="minHeight"
        :max="maxHeight"
      >
        <template #label="{ startValue, endValue }">
          <small>{{ heightInString(startValue) }} -
            {{ heightInString(endValue) }}</small>
        </template>
      </ce-range>
    </div>
  </ce-accordion-item>
</template>

<script>
import { ref, watch } from 'vue'
import useHeight from '@/Use/use-height.js'

export default {
  props: {
    modelValue: {
      type: Array,
      default: null
    }
  },
  emits: ['update:modelValue'],
  setup (props, { emit }) {
    const {
      toString: heightInString,
      min: minHeight,
      max: maxHeight
    } = useHeight()
    const localValue = ref([minHeight, maxHeight])

    /* Watchers */
    watch(
      localValue,
      (newLocalValue) => {
        emit('update:modelValue', newLocalValue)
      },
      {
        deep: true
      }
    )

    return {
      minHeight,
      maxHeight,
      heightInString,
      localValue
    }
  }
}
</script>
