<template>
  <div class="banner-block mb-3">
    <div class="">
      <ce-image
        :src="eventResource?.imageModifiedUrl?.sizes?.lg"
        class="banner-image mb-4"
      />
    </div>
    <div class="group--details px-3">
      <time
        :datetime="eventResource.eventStart"
        class="event-datetime d-inline-block mb-2"
        >{{ formatEventDate(eventResource.eventStart) }}</time
      >
      <div class="d-md-flex align-items-start mb-2">
        <div>
          <h1 class="detail-block-title text-break mb-3">
            {{ eventResource.title }}
          </h1>
          <div
            class="d-inline-flex flex-wrap align-items-center justify-content-center"
          >
            <template
              v-if="eventResource.gatheringType === GATHERING_TYPES.HUDDLE"
            >
              <span class="event-badge badge bg-gold">Huddle</span>
            </template>
            <template v-else>
              <span class="event-badge badge" :class="[rsvpTypeClassName]"
                >{{ rsvpTypeLabel }}
                {{
                  eventResource.rsvpType === RSVP_TYPES.LIMITED_CAPACITY
                    ? ": " + eventResource.maxCapacity
                    : ""
                }}
              </span>
              <template
                v-if="
                  eventResource.rsvpType === RSVP_TYPES.LIMITED_CAPACITY &&
                  eventResource.capacityStatus === 'full'
                "
              >
                <span class="event-badge badge border border-red text-red ms-2"
                  >Event is full</span
                >
              </template>
              <template
                v-if="
                  eventResource.rsvpType === RSVP_TYPES.LIMITED_CAPACITY &&
                  eventResource.capacityStatus !== 'full'
                "
              >
                <span class="event-badge badge bg-dark-gray text-white ms-2">
                  Remaining:
                  {{ eventResource.maxCapacity - eventResource.attendeesCount }}
                </span>
              </template>
            </template>
          </div>
          <template v-if="eventResource.canEditEvent">
            <img
              src="../../assets/badges/admin-badge.svg"
              alt="Admin"
              title="Admin"
              class="my-3 ms-3 my-md-0"
            />
          </template>
          <template
            v-if="!eventResource.canEditEvent && eventResource.canScanQr"
          >
            <img
              src="../../assets/badges/gatekeeper-badge.svg"
              alt="Admin"
              title="Admin"
              class="my-3 ms-3 my-md-0"
            />
          </template>
        </div>
      </div>
    </div>
    <div class="flex-shrink-0 mt-2 mt-md-0 ms-md-auto px-3">
      <span class="mx-auto"></span>
      <div
        class="d-flex flex-column flex-md-row align-items-md-start align-items-center my-n2 mx-md-n2"
      >
        <template v-if="shouldShowTicket">
          <button
            type="button"
            class="btn btn-gradient-gold my-2 mx-md-2"
            @click="eventTicketQrCodeModalRef.open()"
          >
            View Ticket
          </button>
        </template>
        <div class="my-2 mx-md-2 text-center">
          <event-interest-button
            :event="eventResource"
            :uninterested-text="
              eventResource.rsvpType === 2 &&
              eventResource.capacityStatus === 'full'
                ? `Add to Waiting List.`
                : undefined
            "
            :class-when-not-interested="
              eventResource.rsvpType === RSVP_TYPES.VIP_ONLY
                ? 'btn-gradient-gold text-black'
                : 'btn-green'
            "
            @rsvpStatus="rsvpStatus"
          />
          <template
            v-if="
              eventResource.rsvpType === RSVP_TYPES.VIP_ONLY &&
              !eventResource.admins.find(
                (admin) => admin.id === currentUserId
              ) &&
              eventResource.isUserInterested
            "
          >
            <div class="small fst-italic text-gray mt-1">
              Your request to be added to the VIP guest list is under review.<br />
              You will be notified by email and sent a ticket if your request is
              approved.
            </div>
          </template>
        </div>
      </div>
    </div>
    <div class="d-md-flex flex-wrap align-items-top m-n1 px-3">
      <group-chat-live
        v-if="!isPreview && isChatAvailable"
        :id="eventResource.id"
        :table-lookup="isUserRSVP || 3"
        class="m-1"
      >
        <template #label> Join Live Chat </template>
      </group-chat-live>
      <template
        v-if="
          (eventResource.isOwner || eventResource.canEditEvent) &&
          !eventResource.isPast
        "
      >
        <div class="m-1">
          <event-edit-button
            :event="eventResource"
            class="btn btn-gradient-gold w-100"
            >Edit {{ dynamicWordFor("Event") }}</event-edit-button
          ><br />
          <small class="detail-small-description"
            >(Only the event owner can access this option)</small
          >
        </div>
        <div class="m-1">
          <event-publish-button
            v-model:is-published="eventResource.isPublished"
            :event-id="eventResource.id"
            :slug="eventResource.slug"
            class="btn-red w-100"
          />
        </div>
        <template v-if="eventResource.gatheringType === GATHERING_TYPES.HUDDLE">
          <div class="m-1">
            <event-delete-button
              :event-id="eventResource.id"
              :slug="eventResource.slug"
              :type="eventResource.gatheringType"
              class="btn-red w-100"
              @deleted="showDeletedDialog"
            />
          </div>
        </template>
        <div class="m-1">
          <ce-button
            class="btn btn-gradient-gold w-100"
            :is-loading="isSendingNotificationRequest"
            @click="confirmSendingOfNotification()"
            >{{
              notificationSent ? "Notification Sent" : "Send Notification"
            }}</ce-button
          ><br />
          <small class="detail-small-description"
            >This will send an email notication</small
          >
        </div>
      </template>
      <template v-if="!isPreview">
        <span class="mx-auto"></span>
        <div class="mx-1 mt-md-0 mt-2">
          <ce-button
            v-if="allowedQrCodeScanning"
            icon
            class="fs-2 text-white my-3 my-md-0"
            @click="qrCodeScannerModalRef.open()"
          >
            <span class="icon-qr_code_scanner"></span>
          </ce-button>
          <ce-button
            v-if="eventResource.qrCode"
            icon
            class="fs-2 text-white ms-3 d-none d-md-inline-block"
            @click="eventQrCodeModalRef.open()"
          >
            <span class="fas fa-qrcode"></span>
          </ce-button>
        </div>
      </template>
      <group-chat-live
        v-if="!isPreview && eventResource.isOwner"
        :id="eventResource.id"
        :table-lookup="5"
        class="m-1"
      >
        <template #label> Message All Members </template>
      </group-chat-live>
    </div>
    <div class="text-break mt-4 mb-n3 px-3">
      <div class="flex-shrink-0 my-3">
        <label class="detail-block-label mb-2">Posted by:</label>
        <div class="detail-block-content">
          {{ eventResource.owner.first_name }}
          {{ eventResource.owner.last_name }}
        </div>
      </div>
      <div class="flex-shrink-0 my-3">
        <label class="detail-block-label mb-2">Location:</label>
        <div class="detail-block-content">
          {{ eventLocation }}
        </div>
      </div>
      <div class="flex-shrink-0 my-3">
        <template v-if="eventResource.meetingSpot">
          <label class="detail-block-label mb-2">Meeting Spot:</label>
          <div class="detail-block-content text-pre-line">
            {{ eventResource.meetingSpot }}
          </div>
        </template>
      </div>
      <div class="flex-shrink-0 my-3 pb-3">
        <label class="detail-block-label mb-2">About:</label>
        <div class="detail-block-content text-pre-line">
          {{ eventResource.description }}
        </div>
      </div>
    </div>
    <template v-if="event.videoSrc">
      <div class="mt-5 mb-3 d-flex justify-content-center mb-2">
        <ce-video-player
          :src="eventResource.videoSrc"
          class="event-video"
          :autoplay="false"
          :loop="true"
          preload="auto"
          fluid
          muted
        />
      </div>
    </template>
  </div>
  <section>
    <!-- <template v-if="activeSub === 'Members'">
      <group-members
        :group="group"
        :members="group?.members"
        :has-more-pages="false"
      />
    </template>
    <template v-else-if="activeSub === 'Wall'">
      <group-wall :group="group" />
    </template>
    <template v-else-if="activeSub === 'Discussion Board'">
      <discussion
        :entity-id="group.id"
        type="group"
        title="Group Discussion Board"
        :is-allowed-to-post="true"
      />
    </template>
    <template v-else-if="activeSub === 'Albums'">
      <albums
        entity="group"
        title="Group Albums"
        :entity-resource="group"
        :default-album="content?.album"
        :entity-id="group.id"
        :is-allowed-to-post="true"
      />
    </template> -->
  </section>
</template>

<script>
import { computed, inject, ref } from "vue";
import CeImage from "@/components/Images/CeImage.vue";
import CeAspectRatio from "@/components/Utilities/CeAspectRatio.vue";
// import GroupViewButtons from './GroupViewButtons.vue'
import SubpagesNav from "@/components/SubpagesNav.vue";
import CeVideoPlayer from "@/components/MediaPlayers/CeVideoPlayer.vue";
import {
  transformEventResource,
  formatEventDate,
  venueTypes,
  useRsvpBadge,
  RSVP_TYPES,
  EVENT_SETTINGS,
  GATHERING_TYPES,
  useDynamicWord,
} from "@/Use/events.js";

import GroupChatLive from "@/components/Buttons/GroupChatLive.vue";
import EventEditButton from "@/components/Events/EventEditButton.vue";
import EventDeleteButton from "@/components/Events/EventDeleteButton.vue";
import EventInterestButton from "@/components/Events/EventInterestButton.vue";
import EventPublishButton from "@/components/Events/EventPublishButton.vue";
// import GroupMembers from './GroupMembers2.vue'
// import GroupWall from '../../Modules/GroupWall/GroupWall.vue'
// import Discussion from '../../components/Discussion/Discussion.vue'
// import Albums from '../../components/Album/Albums.vue'

export default {
  name: "EventPreview",
  components: {
    CeImage,
    // GroupViewButtons,
    CeAspectRatio,
    SubpagesNav,
    CeVideoPlayer,
    GroupChatLive,
    EventEditButton,
    EventDeleteButton,
    EventInterestButton,
    EventPublishButton,
    // GroupMembers,
    // GroupWall,
    // Discussion,
    // Albums
  },
  props: {
    event: {
      type: Object,
      required: true,
    },
    subPages: {
      type: Object,
      required: true,
    },
    gatheringType: {
      type: Number,
      default: GATHERING_TYPES.EVENT,
    },
  },
  setup(props) {
    const isPublished = ref(false);
    const isPublishing = ref(false);
    const dialog = inject("dialog");
    const activeSub = ref("");
    const eventResource = ref(transformEventResource(props.event));
    const eventTicketQrCodeModalRef = ref(null);

    const active = (event) => {
      activeSub.value = event;
    };

    const { dynamicWordFor } = useDynamicWord({
      gatheringType: props.gatheringType,
    });

    const shouldShowTicket = computed(
      () =>
        eventResource.value.ticketDetails?.qrCode &&
        props.gatheringType !== GATHERING_TYPES.HUDDLE
    );

    return {
      isPublished,
      isPublishing,
      dialog,
      activeSub,
      active,
      eventResource,
      transformEventResource,
      formatEventDate,
      venueTypes,
      useRsvpBadge,
      RSVP_TYPES,
      EVENT_SETTINGS,
      GATHERING_TYPES,
      useDynamicWord,
      shouldShowTicket,
      eventTicketQrCodeModalRef,
      dynamicWordFor,
    };
  },
};
</script>

<style lang="scss" scoped>
@import "../../scss/index.scss";
.group--cover-photo {
  margin-right: calc(var(--bs-gutter-x) * -0.5 - 1px);
  margin-left: calc(var(--bs-gutter-x) * -0.5 - 1px);
  border-top-right-radius: 6px;
  overflow: hidden;
  max-height: 310px;

  img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.group--actions {
  .btn {
    margin-right: 0.75rem;
    min-width: 152px;

    &:last-child {
      margin-right: 0;
    }
  }
}

.group--details {
  .group-date {
    font-size: 1.125rem;
    color: #b4914d;
    line-height: 1.4375;
    margin-bottom: 1em;
    font-weight: 600;
  }

  .group-title {
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 30px;
  }
}

.grid-items {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  gap: 30px;
  margin-bottom: 2rem;

  .grid-item {
    overflow: hidden;
  }
}

.group-video {
  width: 320px;
  height: 180px;
}
.px-3 {
  padding-left: 1rem !important;
  padding-right: 1rem !important;
}
.banner-block {
  background-color: #2f2f2f;
  border-bottom-left-radius: 12px;
  border-top-right-radius: 12px;
  color: #fff;
  width: 100%;
}
.banner-image {
  border-top-right-radius: 12px;
  width: 100%;
}
.event-datetime {
  color: #b4914d;
  font-size: 1.125rem;
  font-weight: 600;
}
.fa-qrcode {
  color: white;
}
</style>
