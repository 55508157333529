<template>
  <section class="text-white information">
    <div class="d-flex">
      <section class="flex-fill">
        <div class="d-flex flex-wrap align-items-top">
          <h1 class="fs-2 ff-merriweather fw-bold mb-0">
            {{ user?.first_name }} {{ user?.last_name }}
          </h1>
        </div>
        <div
          class="ls-3 fw-semibold small text-gray"
          style="text-transform: uppercase"
        >
          {{ user?.subscription_type }} ACCOUNT
        </div>
        <div class="py-3 fw-semibold small">
          <span> {{ moment().diff(moment(user?.birth_date), "years") }} - </span
          >{{ user?.zodiac_sign?.value }} <br />
          {{ user?.gender.value }} <br />
          {{ user?.profile?.ethnicity?.value }} <br />
          {{ user?.profile?.city }} {{ user?.profile?.state }}
          {{ user?.profile?.zip_code }}
        </div>
      </section>
      <div />
    </div>
    <div class="mt-2">
      <div class="d-flex align-items-center mb-2">
        <h2 class="fs-6 ff-merriweather fw-semibold">
          Why I'm the Perfect Friend
        </h2>
      </div>
      <div class="description-display">
        {{ user.profile.about_me }}
      </div>
    </div>
    <hr class="bg-dark-gray my-5" />
    <section>
      <div class="pt-2">
        <photo-gallery
          class="photo-gallery"
          :photos="user.photos"
          image-key="modified"
        />
      </div>
    </section>
    <section class="mt-5">
      <div class="d-flex align-items-center mb-2">
        <h2 class="fs-6 ff-merriweather fw-semibold mb-0">
          What I’m looking for in a Perfect Friend
        </h2>
      </div>
      <div class="description-display">
        {{ user.profile.what_type_of_friend_are_you_looking_for }}
      </div>
    </section>
    <section class="mt-5">
      <div class="d-flex align-items-center mb-2">
        <h2 class="fs-6 ff-merriweather fw-semibold mb-0">
          The events and activities I’d like to do with my Perfect Friends
        </h2>
      </div>
      <div class="description-display">
        {{ user.profile.identify_events_activities }}
      </div>
    </section>
    <hr class="bg-dark-gray my-5" />
    <section>
      <div class="d-flex align-items-center mb-3">
        <h2 class="fs-6 ff-merriweather fw-semibold mb-0">Interests</h2>
      </div>
      <div class="d-flex flex-wrap mb-n3">
        <span
          v-for="(row, index) in user.interests"
          :key="index"
          class="badge rounded-pill interest request border border-dark-gray bg-dark-gray d-flex align-items-center px-4 py-3 me-3 mb-3 fs-6 fw-normal"
          >{{ row }}</span
        >
      </div>
    </section>
  </section>
  <hr />
  <div class="justify-content-between">
    <span class="float-left link-profile cancel-profile" @click="cancel"
      >Cancel</span
    >
    <span
      class="float-right link-profile view-profile"
      @click="viewProfile(user.user_name)"
      >View Profile</span
    >
  </div>
</template>
<script>
import { ref } from "vue";
import moment from "moment";
import ProfileImageComponent from "@/components/ProfileImageComponent.vue";
import router from "@/router";
import PhotoGallery from "@/components/Galleries/PhotoGallery.vue";

export default {
  components: { ProfileImageComponent, PhotoGallery },
  props: {
    user: {
      type: Object,
      default: ref({}),
      required: true,
    },
  },
  emits: ["cancel"],
  setup(props, { emit }) {
    const viewProfile = async (username) => {
      emit("cancel");
      router.push(`/user/update-profile/${username}`);
    };

    const cancel = () => {
      emit("cancel");
    };

    return {
      moment,
      viewProfile,
      cancel,
    };
  },
};
</script>
<style lang="scss" scoped>
.information {
  height: 253px !important;
  overflow-y: auto;
}
.information::-webkit-scrollbar {
  width: 0.2em;
}

.information::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(70, 69, 69, 0.3);
}

.information::-webkit-scrollbar-thumb {
  background-color: #b4914d;
  outline: 1px solid #b4914d;
  border-radius: 2px;
}
.fw-semibold {
  font-weight: 600 !important;
}
.link-profile:hover {
  cursor: pointer;
}
.view-profile {
  color: #b18d47;
}
.cancel-profile {
  color: #878787;
}
.photo-gallery {
  :deep(.photos-grid) {
    grid-template-columns: auto auto;
  }
}
</style>
