<template>
  <div>
    <div class="row">
      <div 
        v-for="price in priceList"
        :key="price.id"
        class="col-xl-3 col-lg-6">
          <div class="card">
              <i class="fa fa-solid fa-pen-to-square" @click="editPrice(price)"></i>
              <div class="card-statistic-3 p-4">
                  <div class="mb-4">
                      <h5 class="card-title mb-0">{{ price.name }}</h5>
                  </div>
                  <div class="row align-items-center mb-2 d-flex">
                      <div class="col-8">
                          <h2 class="d-flex align-items-center mb-0">
                              {{ currencyUSD(price.price) }}
                          </h2>
                      </div>
                  </div>
              </div>
          </div>
      </div>

      <div class="col-xl-3 col-lg-6">
        <div class="card">
                <div class="card-statistic-3 add-price">
                    <a
                      class="w-100 h-100 stretched-link text-decoration-none position-absolute top-50 start-50 translate-middle"
                      href="#"
                      @click="addPrice"
                    >
                      <span
                        class="card-title icon-add position-absolute top-50 start-50 translate-middle"
                      ></span>
                    </a>
                </div>
            </div>
      </div>
    </div>
    <subscription-price-modal
      :modal-id="`subscription-price-modal`"
      :plan-id="planId"
      :plan-name="planName"
      :set-price="price"
      :existing-tags="existingTags"
      :is-edit="isEdit"
      @actionSubmit="addPlanPrice"
      @closeModal="closeModal"
    />
  </div>
</template>

<script>
import { reactive, ref, inject, onMounted } from "vue";
import http from "@/http.js";
import SubscriptionPriceModal from "@/components/Modal/SubscriptionPriceModal.vue";
import { useStore } from 'vuex'

export default {
  components: {
    SubscriptionPriceModal,
  },
  props: {
    planId: {
      type: String,
      required: true,
    },
    planName: {
      type: String,
      required: true,
    },
    currentPrices: {
      type: Object,
      default: () => {},
    },
  },
  emits: ["fail", "isLoading"],
  setup(props, { emit }) {
    const store = useStore();
    const dialog = inject("dialog");
    const price = ref([]);
    const isEdit = ref(false);

    const priceList = ref(props.currentPrices);
    const existingTags = ref([]);

    const formRef = ref(null);
    const form = reactive({
      abilities: [],
      plan_name: "",
    });

    const currencyUSD = (value) => {
      const formatter = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });
      return formatter.format(value);
    };

    const addPrice = () => {
      $("#subscription-price-modal").modal("show")
    };

    const loadPrices = async () => {
      emit("isLoading", true);
      // priceList.value = [];
      let response = await store.dispatch("loadTiersList");
      let list = response.data.find((value) => {
        return value.name === props.planName;
      });
      if (response) {
        priceList.value = list.prices;
        emit("isLoading", false);
      }
    }

    const addPlanPrice = async (data) => {
      try {
        emit("isLoading", true);
        const form = {
          plan_id: props.planId,
          name: `${data._value.name}`,
          description: data._value.description,
          price: data._value.price,
          interval_count: data._value.interval,
          status: data._value.status,
          tags: data._value.tags,
        }
        
        if (data._value.id !== '') {
          await http.put(`prices/${data._value.id}`, form);
        } else {
          await http.post(`prices`, form);
        }
        loadPrices();
        closeModal();
      } catch (exception) {
        emit("fail", exception);
        if (exception.response?.status === 422) {
          dialog.show("Please make sure you enter valid details.", {
            title: "Invalid Details",
          });
        } else {
          dialog.show(
            "An unexpected error encountered. Please try again later.",
            {
              title: "System Error",
            }
          );
        }
      }
    };

    const setPriceStatus = async (data) => {
      try {
        emit("isLoading", true);
        let response = await http.put(`prices/${data.id}`, {
          status: data.status,
        });
        const findIndex = priceList.value.findIndex(
          (i) => i.id === response.data.data.id
        );
        priceList.value.splice(findIndex, 1, response.data.data);
      } catch (exception) {
        emit("fail", exception);
        if (exception.response?.status === 422) {
          dialog.show("Please make sure you enter valid details.", {
            title: "Invalid Details",
          });
        } else {
          dialog.show(
            "An unexpected error encountered. Please try again later.",
            {
              title: "System Error",
            }
          );
        }
      } finally {
        emit("isLoading", false);
      }
    };

    const editPrice = (row) => {
      price.value = row;
      existingTags.value = [];
      if (row.tags !== null) {
        row.tags.forEach((data) => {
          existingTags.value.push({ value: data });
        })
      }
      isEdit.value = true;
      $("#subscription-price-modal").modal("show");
    }

    const closeModal = () => {
      isEdit.value = !isEdit.value;
      existingTags.value = [];
      price.value = [];
    }

    return {
      form,
      formRef,
      currencyUSD,
      addPrice,
      addPlanPrice,
      setPriceStatus,
      priceList,
      editPrice,
      price,
      existingTags,
      loadPrices,
      isEdit,
      closeModal
    };
  },
};
</script>
<style lang="scss" scoped>
@import "../../scss/customizations";

.icon-add {
  font-size: 100px;
}

.disabled-card {
  background-color: rgba(163, 163, 163, 0.1);
}
.card {
    background-color: #fff;
    border-radius: 10px;
    border: none;
    position: relative;
    margin-bottom: 30px;
    box-shadow: 0 0.46875rem 2.1875rem rgba(90,97,105,0.1), 0 0.9375rem 1.40625rem rgba(90,97,105,0.1), 0 0.25rem 0.53125rem rgba(90,97,105,0.12), 0 0.125rem 0.1875rem rgba(90,97,105,0.1);
}

.l-bg-green-dark {
    background: linear-gradient(to right, #0a504a, #38ef7d) !important;
    color: #fff;
}

.card .card-statistic-3 .card-icon-large .fas, .card .card-statistic-3 .card-icon-large .far, .card .card-statistic-3 .card-icon-large .fab, .card .card-statistic-3 .card-icon-large .fal {
    font-size: 110px;
    margin-top: -10px;
    margin-right: 160px;
}

.card .card-statistic-3 .card-icon {
    text-align: center;
    line-height: 50px;
    margin-left: 15px;
    color: #000;
    position: absolute;
    right: -5px;
    top: 20px;
    opacity: 0.1;
}

.l-bg-green {
    background: linear-gradient(135deg, #23bdb8 0%, #43e794 100%) !important;
    color: #fff;
}

.add-price {
  height: 119px !important;
}

.fa-pen-to-square {
  color: $color-black;
  right: 0;
  position: absolute;
  top: 5px;
  margin-right: 5px;
  font-size: 20px;
}
.fa-pen-to-square:hover {
  cursor: pointer;
}
</style>
