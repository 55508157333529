import axios from 'axios'

const setupHeaderConfig = (config) => {
  // -------------------------------------------------------------
  // Note: This will be uncommented once I finish the auth state / store
  // -------------------------------------------------------------
  // const token
  const token = localStorage.getItem('token')
  // !config.headers ? config.headers = {} : null\
  if (config.headers === undefined) {
    config.headers.Authorization = 'Bearer ' + token
  } else {
    config.headers.Authorization = 'Bearer ' + token
  }

  return config
}

export default ({
  state: {

  },
  mutations: {},
  getters: {},
  actions: {
    async loadAlbumByGroup ({ commit }, params, config = { headers: {} }) {
      config.params = params
      const newConfig = setupHeaderConfig(config)
      return await axios.get('/' + params.url + '?sort=' + params.sort + '&&page=' + params.page, newConfig).then((response) => {
        return response.data
      })
    }
  }
})
