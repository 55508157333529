import { computed } from 'vue'
import router from '@/router'

export default () => {
  const queryParamsString = computed(() => {
    const urlParts = router.currentRoute._value.href.split('?')
    return urlParts[1] ? urlParts[1] : ''
  })

  const queryParamsObject = computed(() => {
    const urlSearchParams = new URLSearchParams(queryParamsString.value)
    const paramsObject = {}
    urlSearchParams.forEach((value, key) => {
      let newKey = key
      const isArray = key.slice(-2) === '[]'
      if (isArray) {
        newKey = key.substring(0, key.length - 2)
      }

      if (paramsObject[newKey] === undefined) {
        if (isArray) {
          paramsObject[newKey] = [value]
        } else {
          paramsObject[newKey] = value
        }
      } else {
        // Meaning it is an array and originally has brackets "[]" on its key
        paramsObject[newKey].push(value)
      }
    })
    return paramsObject
  })

  const queryParamsObjectAsString = computed(() =>
    new URLSearchParams(queryParamsObject.value).toString()
  )

  /* Methods */
  const addQueryParam = (param, value) => {
    queryParamsObject.value[param] = value
  }

  const removeQueryParam = (param) => {
    delete queryParamsObject.value[param]
  }

  return {
    queryParamsString,
    queryParamsObject,
    addQueryParam,
    removeQueryParam,
    queryParamsObjectAsString
  }
}
