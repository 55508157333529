<template>
  <div class="banner-block mb-3">
    <div class="">
      <ce-image
        :src="group?.image_modified_url?.sizes?.lg"
        class="banner-image mb-4"
      />
    </div>
    <div class="group--details px-3">
      <div class="d-flex text-break align-items-center">
        <h1 class="detail-block-title mb-0">
          {{ group?.name }}
        </h1>
        <span class="mx-auto" />
      </div>
      <div class="mt-3">
        <group-view-buttons :group="group" />
      </div>
      <div class="py-3 text-break mt-4 mb-n3 mb-xl-0 mx-xl-n3">
        <section class="my-3 mx-xl-3 mb-3">
          <h4 class="detail-block-label mb-2">
            Moderator:
          </h4>
          <div class="detail-block-content">
            {{ group?.user.first_name }} {{ group?.user.last_name }}
          </div>
        </section>
        <section class="my-3 mx-xl-3 mb-2">
          <h4 class="detail-block-label mb-2">
            About this group:
          </h4>
          <div class="detail-block-content text-pre-line">
            {{ group?.description }}
          </div>
        </section>
        <template v-if="group?.video_url">
          <div class="mt-5 mb-3 d-flex justify-content-center">
            <template v-if="group?.video_is_transcoding">
              <div
                class="position-relative w-100"
                style="max-width: 480px"
              >
                <ce-aspect-ratio
                  :aspect-ratio="16 / 9"
                  class="bg-black rounded-3"
                />
                <div
                  class="position-absolute top-50 start-50 translate-middle text-white px-3 w-100 text-center"
                >
                  <div
                    class="d-flex align-items-center justify-content-center fs-4"
                  >
                    <span class="animation-loading-spinner me-2" /> Video
                    Converting....
                  </div>
                  Your video needs to be converted before it is posted. Please
                  check back later to view your post.
                </div>
              </div>
            </template>
            <template v-else>
              <ce-video-player
                :src="group?.video_url"
                class="group-video rounded-2 overflow-hidden mb-3"
                :autoplay="true"
                :loop="true"
                preload="auto"
              />
            </template>
          </div>
        </template>
        <div class="mt-5">
          <subpages-nav
            :sub-pages="subPages"
            @active="active($event)"
          />
        </div>
      </div>
    </div>
  </div>
  <section>
    <template v-if="activeSub === 'Members'">
      <group-members
        :group="group"
        :members="group?.members"
        :has-more-pages="false"
      />
    </template>
    <template v-else-if="activeSub === 'Wall'">
      <group-wall :group="group" />
    </template>
    <template v-else-if="activeSub === 'Discussion Board'">
      <discussion
        :entity-id="group.id"
        type="group"
        title="Group Discussion Board"
        :is-allowed-to-post="true"
      />
    </template>
    <template v-else-if="activeSub === 'Albums'">
      <albums
        entity="group"
        title="Group Albums"
        :entity-resource="group"
        :default-album="content?.album"
        :entity-id="group.id"
        :is-allowed-to-post="true"
      />
    </template>
  </section>
</template>

<script>
import { inject, ref } from 'vue'
import CeImage from '@/components/Images/CeImage.vue'
import CeAspectRatio from '@/components/Utilities/CeAspectRatio.vue'
import GroupViewButtons from './GroupViewButtons.vue'
import SubpagesNav from '@/components/SubpagesNav.vue'
import GroupMembers from './GroupMembers2.vue'
import CeVideoPlayer from '@/components/MediaPlayers/CeVideoPlayer.vue'
import GroupWall from '../../Modules/GroupWall/GroupWall.vue'
import Discussion from '../../components/Discussion/Discussion.vue'
import Albums from '../../components/Album/Albums.vue'

export default {
  name: 'GroupPreview',
  components: {
    CeImage,
    GroupViewButtons,
    CeAspectRatio,
    SubpagesNav,
    GroupMembers,
    CeVideoPlayer,
    GroupWall,
    Discussion,
    Albums
  },
  props: {
    group: {
      type: Object,
      required: true
    },
    subPages: {
      type: Object,
      required: true
    }
  },
  setup (props) {
    const isPublished = ref(false)
    const isPublishing = ref(false)
    const dialog = inject('dialog')
    const activeSub = ref('')
    const groupResource = ref('')

    const active = (event) => {
      activeSub.value = event
    }

    return {
      isPublished,
      isPublishing,
      dialog,
      activeSub,
      active,
      groupResource
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../../scss/index.scss';
.group--cover-photo {
  margin-right: calc(var(--bs-gutter-x) * -0.5 - 1px);
  margin-left: calc(var(--bs-gutter-x) * -0.5 - 1px);
  border-top-right-radius: 6px;
  overflow: hidden;
  max-height: 310px;

  img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.group--actions {
  .btn {
    margin-right: 0.75rem;
    min-width: 152px;

    &:last-child {
      margin-right: 0;
    }
  }
}

.group--details {
  .group-date {
    font-size: 1.125rem;
    color: #B4914D;
    line-height: 1.4375;
    margin-bottom: 1em;
    font-weight: 600;
  }

  .group-title {
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 30px;
  }
}

.grid-items {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  gap: 30px;
  margin-bottom: 2rem;

  .grid-item {
    overflow: hidden;
  }
}

.group-video {
  width: 320px;
  height: 180px;
}
.px-3 {
  padding-left: 1rem!important;
  padding-right: 1rem!important;
}
.banner-block {
  background-color: #2f2f2f;
  border-bottom-left-radius: 12px;
  border-top-right-radius: 12px;
  color: #fff;
  width: 100%;
}
.banner-image {
  border-top-right-radius: 12px;
  width: 100%;
}
</style>
