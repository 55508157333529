<template>
  <div>
    <span type="button" data-toggle="dropdown">
      Download {{ type.charAt(0).toUpperCase() + type.slice(1) }}
    </span>
    <ul class="dropdown-menu">
      <li><span class="dropdown-item">Select Columns</span></li>
      <li><hr class="dropdown-divider" /></li>
      <li class="dropdown-item">
        <div class="form-check">
          <input
            v-model="allSelected"
            class="form-check-input"
            type="checkbox"
            @change="selectAll"
          />
          <label class="form-check-label" for="flexCheckChecked">
            Select All
          </label>
        </div>
      </li>
      <li
        v-for="(row, index) in headerSelected"
        :key="index"
        class="dropdown-item"
      >
        <div class="form-check">
          <input
            id="flexCheckChecked"
            v-model="csvHeaderId"
            class="form-check-input"
            type="checkbox"
            :value="row.id"
          />
          <label class="form-check-label" for="flexCheckChecked">
            {{ row.name }}
          </label>
        </div>
      </li>
      <li v-if="hasDownloadButton" class="dropdown-item">
        <button
          type="button"
          class="btn btn-block btn-primary btn-sm"
          @click="downloadCSV"
        >
          Download Now
        </button>
      </li>
    </ul>
  </div>
</template>
<script>
import { ref, watch } from "vue";
export default {
  components: {},
  props: {
    csvHeader: {
      type: Object,
      default: () => {},
    },
    type: {
      type: String,
      required: true,
      default: "users",
    },
    userId: {
      type: Number,
      required: false,
    },
  },
  setup(props) {
    const csvHeaderId = ref([]);
    const csvHeaderAndColumn = ref([]);
    const csvHeaderAndHeading = ref([]);
    const hasDownloadButton = ref(false);
    const allSelected = ref(false);
    const headerSelected = ref(props.csvHeader);

    watch(
      csvHeaderId,
      () => {
        hasDownloadButton.value = csvHeaderId.value.length > 0;
      },
      {
        deep: true,
      }
    );

    const selectAll = async () => {
      if (allSelected.value) {
        const selected = headerSelected.value.map((u) => u.id);
        csvHeaderId.value = selected;
      } else {
        csvHeaderId.value = [];
        allSelected.value = false;
      }
    };

    const downloadCSV = async () => {
      csvHeaderAndColumn.value = [];
      csvHeaderAndHeading.value = [];
      headerSelected.value.forEach((value) => {
        csvHeaderId.value.forEach((value1) => {
          if (value.id === value1) {
            csvHeaderAndColumn.value.push(value.id);
            csvHeaderAndHeading.value.push(value.name);
          }
        });
      });

      const listOfObjects = Object.keys(csvHeaderAndColumn.value).map((key) => {
        return csvHeaderAndColumn.value[key];
      });

      const listOfHeading = Object.keys(csvHeaderAndHeading.value).map(
        (key) => {
          return csvHeaderAndHeading.value[key];
        }
      );

      try {
        const link = document.createElement("a");
        link.href =
          process.env.VUE_APP_API_URL +
          `download/${props.type}/CSV?columns=${listOfObjects}&&headings=${listOfHeading}&&user_id=${props.userId}`;
        link.setAttribute(
          "download",
          `${props.type}-${new Date().toLocaleDateString()}.csv`
        );
        document.body.appendChild(link);
        link.click();
        csvHeaderId.value = [];
        allSelected.value = false;
      } catch (error) {
        // eslint-disable-next-line no-alert
        alert("There is an error while downloading your files.");
      }
      return true;
    };

    return {
      headerSelected,
      csvHeaderId,
      hasDownloadButton,
      downloadCSV,
      selectAll,
      allSelected,
    };
  },
};
</script>
