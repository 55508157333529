<template>
  <Preloader :is-loading="isLoading"></Preloader>
  <div class="col-12">
    <div class="float-right mb-5 col-4">
      <label>Search: </label>
      <input type="text" class="form-control" v-model="searchString"/>
    </div>
    <div class="col-12 card p-0 mt-5">
      <div class="table-responsive">
        <table class="table table-items">
          <thead>
            <tr>
              <th scope="col">
                <h1 class="fs-14 fw-bold color-gray">ABILITY</h1>
              </th>
              <th scope="col">
                <h1 class="fs-14 fw-bold color-gray">STATUS CODE</h1>
              </th>
              <th scope="col">
                <h1 class="fs-14 fw-bold color-gray">RESPONSE MESSAGE</h1>
              </th>
              <th scope="col">
                <h1 class="fs-14 fw-bold color-gray text-center">TAKE ACTION</h1>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="row in featureBlockerList" :key="row.in" class="fs-14">

              <td>{{ row.ability.label }}</td>
              <td>{{ row.code }}</td>
              <td>{{ row.response }} </td>
              <td>
              <a class="nav-link" href="#"  @click="goToAction('edit_response', row)">
                  Edit Response
                </a>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <pagination-component
        class="mt-3 mb-2 mr-5"
        :total-pages="totalPage"
        :per-page="25"
        :current-page="currentPage"
        @pagechanged="onPageChange"
      />
  </div>
  </div>
   <feature-blocker-modal
    :modal-id="`feature-blocker-modal`"
    :feature-blocker="featureBlockerSelected"
    @updateFeature="loadFeatureBlockerList"
  />

</template>
<script>
import { onMounted, ref, watch, nextTick } from "vue";
import $ from "jquery";
import { useStore } from "vuex";
import moment from "moment";
import ModalComponent from "@/components/ModalComponent.vue";
import Preloader from "../../Layouts/Common/Preloader.vue";
import PaginationComponent from "../PaginationComponent.vue";
import FeatureBlockerModal from "../Modal/FeatureBlockerModal.vue";
import debounce from 'lodash/debounce';
export default {
  name: "App",
  components: {
    ModalComponent,
    Preloader,
    PaginationComponent,
    FeatureBlockerModal
  },
  emits: ["done"],
  setup(props, { emit }) {
    const store = useStore();
    const featureBlockerList = ref([]);
    const featureBlockerSelected = ref("");
    const isLoading = ref(false);
    const currentPage = ref(1);
    const totalPage = ref(null);
    const searchString = ref(null);

    const loadFeatureBlockerList = async () => {
        isLoading.value = true;
        let response = await store.dispatch("loadFeatureBlockersList", {
          perPage: 25,
          page: currentPage.value,
          keyword: searchString.value,
        });
      featureBlockerList.value = response.data;
      if (response) {
        isLoading.value = false;
        totalPage.value = response.meta.last_page;
      } else {
        alert("something went wrong");
      }
    };
    
    onMounted(() => {
        loadFeatureBlockerList();
    });

    const goToAction = async (action, featureBlocker) => {
      if (action === "edit_response") {

         editResponse(featureBlocker);

      }
    };

    const editResponse = (featureBlocker) => {

     featureBlockerSelected.value = featureBlocker;
      nextTick().then(() => {
        $("#feature-blocker-modal").modal("show");
      }, 250);
    };

    const onPageChange = (page) => {
      currentPage.value = page;
    };

    watch(
      () => currentPage.value,
      () =>
      {
        loadFeatureBlockerList()
      }
    );

    watch(
      () => searchString.value,
      debounce(() => {
        loadFeatureBlockerList()
      }, 750)
    );

    return {
      featureBlockerSelected,
      featureBlockerList,
      goToAction,
      isLoading,
      currentPage,
      onPageChange,
      totalPage,
      loadFeatureBlockerList,
      searchString
    };
  },
  created: function () {
    this.moment = moment;
  },
};
</script>
<style scoped>
.color-gray {
  color: #727e8b;
}
.form-control:focus {
  box-shadow: none;
}
.btn:focus {
  box-shadow: none;
}
.color-gold {
  color: #b4914d;
  text-decoration: none;
  font-weight: 600;
}
.top-70 {
  top: 0% !important;
}
.start-58 {
  left: 58% !important;
}
.card {
  border: 0;
}
.widget-user-header {
  padding: 0;
  height: auto !important;
}
.widget-user .widget-user-header {
  padding: 0px;
}
.downloadAndMessageAlign {
  text-align: center;
  position: inherit;
  margin-top: 15px;
}
.dropdown-menu-lg {
  min-width: auto;
}
.link-name {
  color: rgb(85, 85, 194);
}
.link-name:hover {
  cursor: pointer;
}
.fa {
  color: black;
}
.fas:hover {
  cursor: pointer;
}
.fs-14 {
  font-size: 14px !important;
}

.table-responsive {
  margin-top: -35px;
}
.tbl-ll-date {
  color: #6c757d;
  position: relative;
  left: 25px;
  float: left;
  margin-top: -5px;
}
.tbl-img {
  min-width: 30px;
  max-width: 30px;
  min-height: 30px;
  max-height: 30px;
}
.profile-img {
  object-fit: contain;
  border-radius: 0.25em 0.25em 0em 0px;
  width: 100%;
}
.table-items th {
  padding-bottom: 2px;
  border-top: 2px solid transparent;
  border-bottom: 2px solid transparent;
}
.table-items tbody,
tr,
td {
  white-space: nowrap !important;
}
.background-gold {
  background: gold;
}
.txt-color-gray {
  color: #6c757d;
}
.fa-circle-plus,
.fa-pen-to-square,
.fa-magnifying-glass-plus:hover {
  cursor: pointer;
}
</style>
