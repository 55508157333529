<template>
  <div
    class="form-text"
    :class="{ 'text-red': wasError }"
  >
    <slot>
      <span
        v-if="wasError"
        class="text-red"
      >{{ errorMessage }}</span>
      <span v-else>{{ message }}</span>
    </slot>
  </div>
</template>

<script>
import get from 'lodash/get'
import { computed } from 'vue'

export default {
  name: 'FormText',
  props: {
    message: {
      type: String,
      required: false,
      default: null
    },
    errors: {
      type: Object,
      required: false,
      default: () => null
    },
    name: {
      type: String,
      required: false,
      default: null
    }
  },
  setup (props) {
    const fieldErrors = computed(() =>
      props.name && props.errors ? get(props.errors, props.name, null) : null
    )

    const wasError = computed(() => !!fieldErrors.value)
    const errorMessage = computed(() =>
      Array.isArray(fieldErrors.value) && fieldErrors.value.length > 0
        ? fieldErrors.value[0]
        : null
    )

    return {
      fieldErrors,
      wasError,
      errorMessage
    }
  }
}
</script>
