<template>
  <modal-component
    :modal-id="modalId"
    :title="`Change Response Message`"
    :header-style="`bg-linear`"
    :size="`modal-md`"
    :profile="false"
  >
    <template v-slot:modalBody>
      <div class="modal-body">
         <div class="container">
            <div class="row">
                <div class="col-sm-3">
                    <label
                    for="inputEmail3"
                    class="col-form-label ml-2 mt-2 mb-1"
                    >Ability:  </label>
                </div>
                <div class="col col-form-label ml-2 mt-2 mb-1" v-if="featureBlocker">
                    <p>{{ featureBlocker.ability.label }}</p>
                </div>
            </div>
             <div class="row">
                <div class="col-sm-12">
                    <textarea v-if="featureBlocker"
                        :value="featureBlocker.response"
                        class="message-text-area"
                        @input="event => messageResponse = event.target.value"
                        />
                </div>
             </div>
        </div>
      </div>
    </template>
    <template v-slot:modalFooter>
      <div class="modal-footer">
        <button
          type="button"
          class="btn btn-link text-decoration-none text-secondary"
          data-dismiss="modal"
        >
          Cancel
        </button>
        <button
          type="button"
          class="btn btn-link text-decoration-none text-primary"
          @click="nextAction"
          data-dismiss="modal"
        >
          Update
        </button>
      </div>
    </template>
  </modal-component>
</template>

<script>
import { ref } from "vue";
import ModalComponent from "@/components/ModalComponent.vue";
import CeRadio from "@/components/Inputs/CeRadio.vue";
import { useStore } from 'vuex';

export default {
  components: {
    ModalComponent,
    CeRadio,
  },
  props: {
    modalId: {
      type: String,
      default: "",
    },
    featureBlocker: {
      type: Object,
      default: "",
    },
  },
  emits: ["updateFeature"],
  setup(props, { emit }) {
    const store = useStore();
    const resetType = ref(null);
    let messageResponse =ref(null);

    const  nextAction = async () => {
        await store.dispatch("updateFeatureBlockersList", {
          ability_id: props.featureBlocker.ability.id,
          http_code: props.featureBlocker.code,
          message: messageResponse.value
        });

       emit('updateFeature');
    };

    return {
      resetType,
      nextAction,
      messageResponse,
    };
  },
};
</script>
<style lang="scss" scoped>
@import "../../scss/custom";

.form-check-label {
  background-color: transparent !important;
  color: #000000 !important;
}
.form-check-label.active {
  background-color: #2f2f2f !important;
  color: #ffffff !important;
}

.message-text-area{
    height: 150px; width: 100%; padding: 15px;border-radius: 5px; border-color: #6c757d
}

.message-text-area:focus-visible{
    border-color: #343a40;
    //  border: none;
    outline: none;
}

.message-text-area:focus{
    border-color: #343a40;
    //  border: none;
    outline: none;
}

</style>
