<template>
  <table
    id="datatable"
    class="table"
  >
    <thead>
      <tr>
        <th>EVENTS</th>
        <th>DESCRIPTION</th>
        <th>EVENT OWNER</th>
        <th># OF MEMBERS</th>
        <th>STATUS</th>
        <th>TAKE ACTION</th>
        <th>NOTES</th>
      </tr>
    </thead>
    <tbody>
      <tr
        v-for="row in list"
        :key="row.in"
      >
        <td>
          <div
            href="#"
            class="clearfix row-name"
            style=""
          >
            <div class="icheck-primary d-inline">
              <input
                id="checkboxPrimary1"
                type="checkbox"
              >
              <span
                class="ml-3 link-name"
                data="modal"
                @click="showEvent(row.event)"
              >
                <img
                  class="rounded mr-3"
                  :src="row.event?.image_modified_url?.sizes?.sm"
                  style="min-width: 30px; max-width: 30px; min-height: 30px; max-height: 30px"
                  @error="imageUrlAlt"
                >
                {{ row.event.title }}
              </span>
            </div>
          </div>
        </td>
        <td>
          <span
            class="ml-3 link-name"
            data="modal"
            @click="showDescription(row.event.description)"
          >View Description</span>
        </td>
        <td>
          <span
            class="ml-3 link-name"
            data="modal"
            @click="showProfile(row.event?.owner)"
          >
            <img
              class="rounded mr-3"
              :src="row.event?.owner?.primary_photo?.sizes?.sm"
              style="min-width: 30px; max-width: 30px; min-height: 30px; max-height: 30px"
              @error="imageUrlAlt"
            >
            {{ row.event?.owner?.first_name }} {{ row.event?.owner?.last_name }}
          </span>
        </td>
        <td> {{ row.event.total_members }} Members</td>
        <td>{{ row.event.status === 'flag' ? 'Flagged' : row.event.status === 'suspend' ? 'Suspended' : row.event.status === 'deactivate' ? 'Deactivated' : row.event.is_published === true ? 'Active' : row.event.deleted_at !== null ? 'Deleted' : row.event.is_published === false ? 'Unpublished' : '' }}</td>
        <td class="dropdown">
          <a
            class="nav-link p-0"
            data-toggle="dropdown"
            href="#"
          >
            Take Action
          </a>
          <div class="dropdown-menu dropdown-menu-left">
            <a
              v-for="(type, k) in takeAction"
              :key="k"
              href="#"
              class="dropdown-item p-2"
              @click="goToAction(type.link, row.event)"
            > {{ type.label }} </a>
          </div>
        </td>
        <td>Edit/View</td>
      </tr>
    </tbody>
  </table>

  <modal-component
    :modal-id="`modalId`"
  >
    <template #modalBody>
      <div class="modal-body">
        <div class="row">
          <div class="col-md-12 col-lg-12 col-sm-12">
            <div class="row">
              <div class="col-md-5 col-lg-5 col-sm-12">
                <profile-image-component
                  v-if="userAccount"
                  :image-src="`${userAccount?.primary_photo?.modified}`"
                />
              </div>
              <div class="col-md-7 col-lg-7 col-sm-12">
                <user-information-component
                  v-if="userAccount"
                  :user="userAccount"
                  @cancel="cancel"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
  </modal-component>

  <modal-component
    :modal-id="`event-preview`"
    :size="`modal-md`"
  >
    <template #modalBody>
      <div class="modal-body p-0">
        <div class="row">
          <div class="col-md-12 col-lg-12 col-sm-12">
            <div class="w-100 h-100 position-relative">
              <div class="ce-utilities-aspect-ratio">
                <div class="ce-utilities-aspect-ratio-wrapper restricted-aspect-ratio">
                  <img
                    :src="event?.image_modified_url?.modified"
                    alt="Perfect Friends"
                    class="mw-100 h-100"
                    style="object-fit: contain; border-radius: 0.25em 0.25em 0em 0px;width: 100%;"
                  >
                </div>
              </div>
              <div class="col p-3">
                <div class="mt-2">
                  <h3>{{ event?.title }}</h3>
                </div>
                <div class="mt-4">
                  <strong>Moderator</strong>
                </div>
                <div class="mt-1">
                  <span>{{ event?.user?.first_name }} {{ event?.user?.last_name }}</span>
                </div>
                <div class="mt-4">
                  <strong>About</strong>
                </div>
                <div class="mt-1">
                  <span>{{ event?.description }}</span>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer border-0 float-right">
            <span
              class="float-left link-profile cancel-profile"
              @click="cancelPreview"
            >Cancel</span>
            <span
              class="float-right link-profile view-profile"
              @click="viewProfile(user.user_name)"
            >View Event</span>
          </div>
        </div>
      </div>
    </template>
  </modal-component>

  <modal-component
    :modal-id="`selectMessageType`"
    :title="`Who would you like to send a message to?`"
    :header-style="`bg-linear`"
    :size="`modal-md`"
    :profile="false"
  >
    <template #modalBody>
      <div class="modal-body">
        <div class="row">
          <div class="col-md-12 col-lg-12 col-sm-12">
            <div class="row">
              <div class="form-group">
                <div
                  class="card card-success"
                  :class="birthdayMessage ? 'selected-message-type' : '' "
                  @click="selectMessageType('selected_birthday')"
                >
                  <div class="p-3">
                    <div class="custom-control custom-radio">
                      <input
                        id="selected_birthday"
                        class="custom-control-input"
                        type="radio"
                        name="selectMessage"
                        disabled
                      >
                      <label
                        for="selected_birthday"
                        class="custom-control-label"
                        :style="birthdayMessage ? 'color: #cacaca' : '' "
                      >People who are celebrating birthday today</label>
                    </div>
                    <small style="color: #6c757d;position: relative; left: 25px; float: left; margin-top: -5px;">{{ moment().format("ll") }}</small>
                  </div>
                </div>
                <div
                  class="card card-success"
                  :class="selectedUser ? 'selected-message-type' : '' "
                  @click="selectMessageType('selected_user')"
                >
                  <div class="p-3">
                    <div class="custom-control custom-radio">
                      <input
                        id="selected_user"
                        class="custom-control-input"
                        type="radio"
                        name="selectMessage"
                        disabled
                        style="background: gold"
                      >
                      <label
                        for="selected_user"
                        class="custom-control-label"
                        :style="selectedUser ? 'color: #cacaca' : '' "
                      >Selected Users</label>
                    </div>
                  </div>
                </div>
              </div>
              <div class="modal-footer border-0 float-right">
                <span
                  type="button"
                  class="btn-outline"
                  style="color: #6c757d;"
                  @click="cancelMessageType"
                > CANCEL </span>
                <span
                  type="button"
                  class="send"
                  @click="selectedUser || birthdayMessage ? nextMessage() : ''"
                > NEXT </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
  </modal-component>

  <modal-component
    :modal-id="`event-description`"
    :title="`Event Description`"
    :header-style="`bg-linear`"
    :size="`modal-md`"
    :profile="false"
  >
    <template #modalBody>
      <div class="col p-3">
        <textarea
          v-model="description"
          disabled
          style="height: 213px; width: 100%; padding: 15px; color: #999"
        />
      </div>
    </template>
  </modal-component>

  <modal-component
    :modal-id="`message`"
    :title="`Message User?`"
    :header-style="`bg-linear`"
    :size="`modal-md`"
    :profile="false"
  >
    <template #modalBody>
      <message-modal
        :message-to="type"
        @cancelMessage="cancelMessage"
      />
    </template>
  </modal-component>
</template>
<script>
import { ref } from 'vue'
import $ from 'jquery'
import { useStore } from 'vuex'
import moment from 'moment'
import router from '@/router'
import { userEnums } from '@/composable/enums.js'
import ModalComponent from '@/components/ModalComponent.vue'
import ProfileImageComponent from '../../components/ProfileImageComponent.vue'
import UserInformationComponent from '../../Pages/User/UserInformationModal.vue'
import MessageModal from '../../components/Modal/MessageModal.vue'

export default {
  name: 'App',

  components: {
    ModalComponent,
    ProfileImageComponent,
    UserInformationComponent,
    MessageModal
  },
  props: {
    groupType: {
      type: String
    },
    list: {
      type: Array
    }
  },
  emits: ['done'],
  setup (props) {
    const store = useStore()
    const userAccount = ref('')
    const { memberships, takeAction } = userEnums()
    const modalId = ref('view-profile')
    const isShowModal = ref(false)
    const event = ref(null)
    const description = ref('')
    const showModal = () => {
      $('#modalId').modal('show')
    }

    const cancel = () => {
      isShowModal.value = false
      $('#modalId').modal('hide')
    }

    const selectMessageToSend = () => {
      $('#selectMessageType').modal('show')
    }

    const imageUrlAlt = (event) => {
      event.target.src = '//via.placeholder.com/150'
    }

    const checkFlag = (row) => {
      const isFlag = row.filter((value) => value.type === 'flag')
      return isFlag.length > 0
    }

    const goToAction = async (type, event) => {
      // if (type === 'delete') {
      //     const response = await store.dispatch("deleteAccount", group);
      //     if (response.status === 200) {
      //         await store.dispatch("group/loadGroupStatistics")
      //         $(document).Toasts('create', {
      //             class: 'bg-success',
      //             title: 'Delete Account',
      //             body: 'Account successfully deleted!',
      //         })
      //     }
      // } else {
      localStorage.setItem('prevURL', router.currentRoute._value.href)
      localStorage.setItem('event', JSON.stringify(event))
      router.push(`/event/${type}/${event.slug}`)
      // }
    }

    const showProfile = async (user) => {
      const response = await store.dispatch('loadUserProfile', { value: user.user_name })
      localStorage.setItem('profile', response)
      userAccount.value = response
      modalId.value = 'user-' + user.id
      $('#modalId').modal('show')
    }

    const showEvent = (row) => {
      event.value = JSON.parse(JSON.stringify(row))
      $('#event-preview').modal('show')
    }

    const cancelPreview = () => {
      $('#event-preview').modal('hide')
    }

    const showDescription = (desc) => {
      description.value = desc
      $('#event-description').modal('show')
    }

    return {
      checkFlag,
      goToAction,
      memberships,
      takeAction,
      showProfile,
      userAccount,
      showModal,
      cancel,
      selectMessageToSend,
      imageUrlAlt,
      showEvent,
      event,
      cancelPreview,
      showDescription,
      description
    }
  },
  created: function () {
    this.moment = moment
  }
}
</script>

<style scoped>
    .color-gray{
        color:#727E8B
    }
    .form-control:focus {
        box-shadow: none;
    }
    .btn:focus {
        box-shadow: none;
    }
    .color-gold {
        color: #B4914D;
        text-decoration: none;
        font-weight: 600;
    }
    .top-70 {
        top: 0%!important;
    }
    .start-58 {
        left: 58%!important;
    }
    .card {
        border: 0;
    }
    .widget-user-header {
        padding: 0;
        height: auto !important;
    }
    .widget-user .widget-user-header {
        padding: 0px;
    }
    .downloadAndMessageAlign {
        text-align: center;
        position: inherit;
        margin-top: 15px;
    }
    .dropdown-menu-lg {
        min-width: auto;
    }
    .dataTable tbody, tr, td {
        white-space: nowrap !important;
    }
    .link-name {
        color:rgb(85, 85, 194)
    }
    .link-name:hover {
        cursor: pointer;
    }
    .row-name {
        width: 20em;white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    .view-profile {
        color: #B18D47;
    }
    .cancel-profile {
        color: #878787;
    }
    .link-profile:hover {
        cursor: pointer;
    }
</style>
