<template>
  <div class="ce-slider">
    <div
      :id="customSliderId"
      ref="carouselRef"
      class="carousel slide carousel-fade"
      data-bs-interval="0"
    >
      <div class="carousel-indicators">
        <button
          v-for="(_, index) in images"
          :id="`${customSliderId}-indicator-${index}`"
          :key="index"
          type="button"
          :data-bs-target="`#${customSliderId}`"
          :data-bs-slide-to="index"
          :class="{ active: activeSlide === index }"
          aria-current="true"
          :aria-label="`Profile Gallery`"
        />
      </div>
      <div class="carousel-inner">
        <div
          v-for="(image, index) in images"
          :key="index"
          class="carousel-item"
          :class="{ active: activeSlide === index }"
        >
          <ce-image
            :src="image"
            class="d-block w-100"
          />
        </div>
      </div>
      <button
        class="carousel-control carousel-control-prev"
        type="button"
        :data-bs-target="`#${customSliderId}`"
        data-bs-slide="prev"
      >
        <span
          class="carousel-control-prev-icon"
          aria-hidden="true"
        />
        <span class="visually-hidden">Previous</span>
      </button>
      <button
        class="carousel-control carousel-control-next"
        type="button"
        :data-bs-target="`#${customSliderId}`"
        data-bs-slide="next"
      >
        <span
          class="carousel-control-next-icon"
          aria-hidden="true"
        />
        <span class="visually-hidden">Next</span>
      </button>
    </div>
  </div>
</template>

<script>
import { computed, getCurrentInstance, onMounted, ref } from 'vue'
import map from 'lodash/map'
import Carousel from 'bootstrap/js/dist/carousel.js'
import CeImage from '@/components/Images/CeImage.vue'

export default {
  name: 'CeHeroSlider',
  components: { CeImage },
  props: {
    images: {
      type: Array,
      required: true
    },
    sliderId: {
      type: String,
      required: false,
      default: undefined
    }
  },
  emits: ['ready'],
  setup (props, { emit }) {
    const carousel = ref(null)
    const carouselRef = ref(null)
    const activeSlide = ref(0)
    const itemNames = computed(() =>
      map(props.images, (item, index) => `image-${index}`)
    )
    const customSliderId = `${
      props.sliderId || `hero-slider-${getCurrentInstance().uid}`
    }`

    const initializeCarousel = () => {
      carousel.value = Carousel.getOrCreateInstance(carouselRef.value)
      emit('ready', carousel.value)
    }
    const goToSlide = (index) => {
      carousel.value.to(index)
    }
    const prev = () => {
      carousel.value.prev()
    }
    const next = () => {
      carousel.value.next()
    }

    onMounted(() => {
      initializeCarousel()
    })

    return {
      carousel,
      itemNames,
      carouselRef,
      customSliderId,
      activeSlide,
      goToSlide,
      prev,
      next
    }
  }
}
</script>

<style scoped lang="scss">

.slide-item-image {
  object-fit: cover;
}

.carousel {
  .carousel-control {
    &:hover {
      transition: background-color 0.25s ease;
      background-color: rgba(#212121, 0.2);
    }
  }
}
</style>
