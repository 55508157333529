<template>
  <div class="d-flex text-break border-bottom mb-2">
    <div class="flex-grow-1 my-2">
      <button
        class="btn btn-transparent p-0 title font-weight-bold"
        @click.prevent="toggleBody()"
      >
        Topic: {{ title }}
      </button>
    </div>
    <div
      v-if="showToolBar"
      class="p-2"
    >
      <span
        v-if="commentsCount > 0"
        class="comment-count start-100 badge rounded-pill bg-red"
      >{{ commentsCount }}</span>
      <a
        v-if="showBody"
        href="#"
        class="unlink"
        @click.prevent="toggleComments()"
      >
        {{ showComments ? 'Hide' : 'Show' }} Comments
      </a>
      |
      <a
        href="#"
        class="unlink"
        @click.prevent="toggleBody()"
      >{{ showBody ? 'Collapse' : 'Expand' }}
      </a>
      <template v-if="isOwner">
        |
        <ce-button
          class="delete-button text-red no-padding"
          :title="`Delete Topic`"
          @click.prevent="deleteTopic"
        >
          <ce-icon icon="trash" />
        </ce-button>
      </template>
    </div>
  </div>
  <div v-if="showBody">
    <comment
      :name="name"
      :message="message"
      :avatar="defaultAvatar"
      :type="type"
      :entity-id="entityId"
      :discussion-id="item.id"
      :timestamp="item.updated_at"
      :is-allowed-to-post="isAllowedToPost"
      :author-id="authorId"
      :author-username="authorUsername"
      :is-allowed-to-edit="false"
      :show-share="showShare"
      :can-reply="canReply"
      :attachments="item.attachments"
      @new-comment-created="newCommentCreated"
    />
    <div v-if="showComments && canViewComment">
      <comment
        v-for="comment in comments"
        :key="comment.id"
        :comment-id="comment.id"
        :level="2"
        :name="comment.posted_by.first_name + ' ' + comment.posted_by.last_name"
        :message="comment.comment"
        :avatar="comment.posted_by.primary_photo.modified"
        :type="type"
        :discussion-id="item.id"
        :entity-id="entityId"
        :super-parent-id="comment.id"
        :timestamp="comment.updated_at"
        :is-allowed-to-post="isAllowedToPost"
        :author-id="comment.posted_by.id"
        :author-username="comment.posted_by.user_name"
        :created-at="comment.created_at"
        :attachments="comment.attachments"
      />
    </div>
  </div>
</template>

<script>
import { inject, ref } from 'vue'
import Comment from '@/components/Discussion/Comment.vue'
import CeIcon from '@/components/UI/CeIcon.vue'
import CeButton from '@/components/Buttons/CeButton.vue'

export default {
  components: {
    Comment,
    CeButton,
    CeIcon
  },
  props: {
    type: {
      type: String,
      required: true
    },
    item: {
      type: Object,
      required: true
    },
    entityId: {
      type: Number,
      required: true
    },
    isAllowedToPost: {
      type: Boolean,
      required: false,
      default: false
    },
    showToolBar: {
      type: Boolean,
      default: true
    },
    showShare: {
      type: Boolean,
      default: true
    },
    canViewComment: {
      type: Boolean,
      default: true
    },
    canReply: {
      type: Boolean,
      default: true
    }
  },
  emits: ['delete-topic'],
  setup (props, { emit }) {
    const name = ref(
      `${props.item.posted_by.first_name} ${props.item.posted_by.last_name}`
    )
    const title = ref(props.item.title)
    const message = ref(props.item.discussion)
    const defaultAvatar = ref(props.item.posted_by.primary_photo.modified)
    const comments = ref(props.item.comments)
    const commentsCount = ref(props.item.comments_count)
    const showComments = ref(true)
    const showBody = ref(true)
    const authorId = ref(props.item.posted_by.id)
    const authorUsername = ref(props.item.posted_by.user_name)
    const isOwner = ref(props.item.is_owner)
    const dialog = inject('dialog')

    const newCommentCreated = (data) => {
      if (!data.parentId) {
        // append to the comment
        comments.value.unshift(data)
      }
    }

    const deleteTopic = () => {
      dialog.show('Are you sure?', {
        title: 'You are about to remove this topic',
        buttons: [
          {
            label: 'Yes',
            class: 'btn-gradient-gold',
            handler: (closeDialog) => {
              closeDialog()
              emit('delete-topic', props.item.id)
            }
          },
          {
            label: 'Cancel',
            class: 'btn-outline-gold'
          }
        ]
      })
    }

    const toggleComments = () => {
      showComments.value = !showComments.value
    }

    const toggleBody = () => {
      showBody.value = !showBody.value
    }

    return {
      defaultAvatar,
      name,
      message,
      title,
      comments,
      newCommentCreated,
      showComments,
      showBody,
      toggleComments,
      toggleBody,
      authorId,
      authorUsername,
      commentsCount,
      isOwner,
      deleteTopic
    }
  }
}
</script>

<style scoped>
.title {
  font-weight: 800;
}

.unlink {
  text-decoration: none;
}
.comment-count {
  position: relative;
  left: -2px !important;
  top: -10px;
}
.no-padding {
  padding: 0 !important;
}
</style>
