<template>
  <form class="dropzone">
    <!-- Not displayed, just for Dropzone's `dictDefaultMessage` option -->
    <div
      id="dropzone-message"
      style="display: none"
    >
      <i
        class="fa-solid fa-cloud-arrow-up"
        style="color: gray"
      />
      <br>
      <span class="dropzone-title">Drop files here or click to select</span>
    </div>
  </form>
</template>

<script>
import Dropzone from 'dropzone'
import '../../node_modules/dropzone/dist/dropzone.css'
import axios from 'axios'

const setupHeaderConfig = (config) => {
  // -------------------------------------------------------------
  // Note: This will be uncommented once I finish the auth state / store
  // -------------------------------------------------------------
  // const token = store.getters['auth/accessToken']
  const token = localStorage.getItem('token')
  // !config.headers ? config.headers = {} : null\
  if (config.headers === undefined) {
    config.headers.Authorization = 'Bearer ' + token
  } else {
    config.headers.Authorization = 'Bearer ' + token
  }
  return config
}

Dropzone.autoDiscover = false
export default {
  name: 'Dropzone',
  mounted () {
    const vm = this
    const options = {
      // The URL will be changed for each new file being processing
      url: '/',
      // Since we're going to do a `PUT` upload to S3 directly
      method: 'POST',

      maxFiles: 1,
      // Hijack the xhr.send since Dropzone always upload file by using formData
      // ref: https://github.com/danialfarid/ng-file-upload/issues/743
      sending (file, xhr) {
        const _send = xhr.send
        xhr.send = () => {
          _send.call(xhr, file)
        }
      },
      uploadMultiple: false,
      // Content-Type should be included, otherwise you'll get a signature
      // mismatch error from S3. We're going to update this for each file.
      header: '',
      // Customize the wording
      dictDefaultMessage: document.querySelector('#dropzone-message').innerHTML,
      // We're going to process each file manually (see `accept` below)
      autoProcessQueue: false,
      // Here we request a signed upload URL when a file being accepted
      accept (file, done, config = { headers: {} }) {
        const newConfig = setupHeaderConfig(config)
        vm.$emit('uploading', true)
        const data = new FormData()
        data.append('file', file)
        axios.post('media', data, newConfig, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        }).then((response) => {
          vm.$emit('complete', response.data)
          document.querySelector('.dz-progress').remove()
          vm.$emit('uploading', false)
          done()
        }).catch((err) => {
          // eslint-disable-next-line no-console
          /* eslint-disable */
          console.log(err);
        })
      },
    }
    // Instantiate Dropzone
    this.dropzone = new Dropzone(this.$el, options)
    // Set signed upload URL for each file
    vm.dropzone.on('processing', (file) => {
      vm.dropzone.options.url = file.uploadURL
    })

  }
}
</script>
<style scoped>
    /* Just add this CSS to your project */
    .dropzone {
        border: 2px dashed #dedede;
        border-radius: 5px;
        background: #f5f5f5;
    }
    .dropzone-title {
      color: rgb(121, 112, 112)
    }
    .dropzone i{
        font-size: 5rem;
    }
    .dropzone .dz-message {
        color: rgba(0,0,0,.54);
        font-weight: 500;
        font-size: initial;
        text-transform: uppercase;
    }
    .dropzone .dz-preview.dz-image-preview {
      background-color: #f5f5f5 !important;
    }
    .dz-progress {
      display: none !important;
    }
</style>
