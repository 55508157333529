<template>
  <modal-component
    :modal-id="modalId"
    :title="`Reset Password`"
    :header-style="`bg-linear`"
    :size="`modal-md`"
    :profile="false"
  >
    <template v-slot:modalBody>
      <div class="modal-body">
        <label
          class="form-check-label border border-secondary rounded p-3 px-2 w-100"
          :class="{ active: resetType == 'auto-generate-password' }"
        >
          <ce-radio
            v-model="resetType"
            :name="`resetPassType`"
            :value="`auto-generate-password`"
          >
            <span class="px-3">Auto-generated password</span>
          </ce-radio>
        </label>
        <label
          class="form-check-label border border-secondary rounded p-3 px-2 w-100 mt-3"
          :class="{ active: resetType == 'create-password' }"
        >
          <ce-radio
            v-model="resetType"
            :name="`resetPassType`"
            :value="`create-password`"
          >
            <span class="px-3">Create password</span>
          </ce-radio>
        </label>
      </div>
    </template>
    <template v-slot:modalFooter>
      <div class="modal-footer">
        <button
          type="button"
          class="btn btn-link text-decoration-none text-secondary"
          data-dismiss="modal"
        >
          Cancel
        </button>
        <button
          type="button"
          class="btn btn-link text-decoration-none text-primary"
          @click="nextAction"
          data-dismiss="modal"
        >
          Next
        </button>
      </div>
    </template>
  </modal-component>
</template>

<script>
import { ref, onMounted, nextTick } from "vue";
import ModalComponent from "@/components/ModalComponent.vue";
import CeRadio from "@/components/Inputs/CeRadio.vue";

export default {
  components: {
    ModalComponent,
    CeRadio,
  },
  props: {
    modalId: {
      type: String,
      default: "",
    },
    userName: {
      type: Object,
      default: "",
    },
  },
  emits: ["actionNext"],
  setup(props, { emit }) {
    const resetType = ref(null);

    const nextAction = () => {
      emit("actionNext", { user_name: props.userName, type: resetType.value });
    };

    return {
      resetType,
      nextAction,
    };
  },
};
</script>
<style lang="scss" scoped>
@import "../../scss/custom";

.form-check-label {
  background-color: transparent !important;
  color: #000000 !important;
}
.form-check-label.active {
  background-color: #2f2f2f !important;
  color: #ffffff !important;
}
</style>
