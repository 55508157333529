<template>
  <div style="background-color: #f4f4f4">
    <div
      v-if="!currentAlbum"
      class="border p-3"
    >
      <div class="card ">
        <h3 class="title">
          {{ title
          }}<span
            v-if="!isLoading"
            class="badge count"
          >{{ entityResource.albums_count }}</span>
        </h3>
      </div>
      <div
        v-if="isLoading"
        class="my-2"
      >
        Loading albums...
      </div>
      <div class="card album-container mt-3">
        <ce-infinite-result
          v-if="albums"
          :items="albums"
          :api-callback="apiCallback"
          :has-more-pages="hasMorePages"
        >
          <template #default="{ item: album, index: index }">
            <div
              class="grid-item album"
              @click="openAlbum(album)"
            >
              <template v-if="album.id === 0">
                <ce-image-albums
                  :src="album.cover"
                  style="object-fit: none; background-color: #e7e7e7"
                />
                <span class="folder-title">{{ album.name }}</span>
              </template>
              <template v-else>
                <ce-image-albums
                  :src="album.cover"
                  :alt="`Perfect Friends - ${album.resource_title} - ${album.name} posted by ${album.user.first_name} ${album.user.last_name} last updated on ${album.updated_at}`"
                />
                <ce-button
                  class="btn-delete"
                  :disabled="isBusy"
                  :is-icon="'true'"
                  :is-loading="isBusy"
                  @click.stop="deleteItem(album, index)"
                >
                  <span class="fas fa-trash-can" />
                </ce-button>
                <span class="folder-title">{{ album.name }}</span>
                <span class="subtitle">{{ album.total_count }} items</span>
              </template>
            </div>
          </template>
        </ce-infinite-result>
      </div>
      <teleport :to="baseLayoutRef">
        <create-album-modal
          ref="createAlbumModalRef"
          :entity-id="entityId"
          :entity="entity"
          :is-allowed-to-post="isAllowedToPost"
          :disabled-message="disabledMessage"
          :modal-id="`createAlbumModal`"
          @newAlbumCreated="newAlbumCreated"
          @close="closeAlbumModal"
        />
      </teleport>
    </div>
    <div
      v-else
      class="border p-3"
    >
      <button
        v-if="!loadingPage"
        class="btn my-2 p-0"
        @click.stop="goBack()"
      >
        <span class="fas fa-chevron-left" /> Back
      </button>
      <album
        ref="albumRef"
        :entity-id="entityId"
        :entity="entity"
        :album="currentAlbum"
        :is-allowed-to-post="isAllowedToPost"
      />
    </div>
  </div>
</template>

<script>
import { ref, computed, inject, onMounted } from 'vue'
import Dropdown from 'bootstrap/js/dist/dropdown.js'
import { Inertia } from '@inertiajs/inertia'
import { useStore } from 'vuex'
import axios from 'axios'
import CeInfiniteResult from '../Utilities/CeInfiniteResult.vue'
import CeButton from '../Buttons/CeButton.vue'
import CeImageAlbums from '../Images/CeImageAlbums.vue'
import Album from './Album.vue'
import CreateAlbumModal from '../Modal/CreateAlbumModal.vue'
import $ from 'jquery'

export default {
  components: {
    CeInfiniteResult,
    CeButton,
    CeImageAlbums,
    Album,
    CreateAlbumModal
  },
  props: {
    entityId: {
      type: Number,
      required: true
    },
    entity: {
      type: String,
      required: true
    },
    entityResource: {
      type: Object,
      default: null
    },
    title: {
      type: String,
      required: false,
      default: 'Event Albums'
    },
    description: {
      type: String,
      required: false,
      default: ''
    },
    isAllowedToPost: {
      type: Boolean,
      required: false,
      default: false
    },
    defaultAlbum: {
      type: Object,
      default: null
    }
  },
  setup (props) {
    const store = useStore()
    const title = ref(props.title)
    const description = ref(props.description)
    const sortBy = ref('new-albums')
    const createAlbumModalRef = ref(null)
    const baseLayoutRef = inject('baseLayoutRef')
    const albumRef = ref(null)

    const dropdownToggleRef = ref(null)
    const dropdownToggleInstance = ref(null)
    const disabledMessage = ref(
      props.type === 'event'
        ? 'Please RSVP first'
        : 'Only group members are allowed to view the albums'
    )

    const albums = ref([])
    const isLoading = ref(false)
    const currentAlbum = ref(props.defaultAlbum)
    const dialog = inject('dialog')
    const isBusy = ref(false)
    const hasMorePages = ref(false)
    const auth = computed(() => store.state.user.authUser)
    const userId = auth.value?.id || -1
    const loadingPage = ref(false)

    /* Lifecycles */
    onMounted(() => {
      if (dropdownToggleRef.value) {
        dropdownToggleInstance.value = new Dropdown(dropdownToggleRef.value)
      }
    })

    const defaultTitle = computed({
      get: () =>
        !title.value
          ? `${props.type.charAt(0).toUpperCase() + props.type.slice(1)} Albums`
          : title.value,
      set: (value) => {
        title.value = value
      }
    })

    const defaultDescription = computed({
      get: () => (!description.value ? 'Albums' : description.value),
      set: (value) => {
        defaultDescription.value = value
      }
    })

    const currentPage = ref(1)

    const meta = ref({ current_page: currentPage, total: 0, per_page: 15 })
    // TODO: refactor, keep it DRY
    const fetchAlbums = async (_sort = 'new-albums', page = 1) => {
      isLoading.value = true

      sortBy.value = _sort

      const params = {
        url: `${props.entity}s/${props.entityId}/albums`,
        sort: _sort,
        page: page
      }

      const data = await store.dispatch('loadAlbumByGroup', params)
      if (data) {
        meta.value = data.meta
        if (page === 1) {
          albums.value = data.data
        } else {
          albums.value = albums.value.concat(data.data)
        }
        console.log(page === 1 && props.isAllowedToPost)
        currentPage.value = meta.value.current_page
        hasMorePages.value = data.links.next !== null

        if (page === 1 && props.isAllowedToPost) {
          albums.value.unshift({
            id: 0,
            name: 'Create Album',
            cover: 'https://perfectfriends.com/images/btn-add.png',
            total_count: 0
          })
        }
      }

      isLoading.value = false

      return data
    }

    fetchAlbums()

    const newAlbumCreated = (item) => {
      $('#createAlbumModal').modal('hide')
      // fetchAlbums();
      // add new item
      albums.value.push(item.data)
      meta.value.total += 1
      Inertia.reload({ only: ['subPages', 'event'] })
    }

    const sort = (value) => {
      if (value === sortBy.value) return

      sortBy.value = value
      fetchAlbums(value)
    }

    const openAlbum = (album) => {
      if (album && album.id === 0) {
        $('#createAlbumModal').modal('show')
      } else {
        // should be updated later for better sharing capability
        const l = window.location.pathname.split('/')

        if (l[l.length - 1] !== 'albums') {
          l.splice(-1)
        }
        const urlString = l.join('/')
        Inertia.visit(`${urlString}/${album.id}`, {
          preserveScroll: true,
          onFinish: () => {
            // currentAlbum.value = album;
            loadingPage.value = false
          },
          onStart: () => {
            loadingPage.value = true
          }
        })
      }
    }

    const apiCallback = async (page) => {
      const data = await fetchAlbums(sortBy.value, page)
      data.data = []
      return {
        data
      }
    }

    const canDelete = (item) => {
      // can't delete if it is a wall item
      // can refactor below items once the permissions are finalized
      if (item.is_wall) {
        return false
      }

      // for events, only the event owner can delete an album
      if (props.entity === 'event') {
        return !item.is_wall && props.isAllowedToPost
      }

      // if the user is the owner of this entity is the same user, allow
      if (props.entityResource.isOwner) {
        return true
      }

      return (
        item.user.id === userId ||
        (props.isAllowedToPost && item.user_id === userId)
      )
    }

    const deleteItem = (album, index) => {
      const url = `api/${props.entity}s/${props.entityId}/albums/${album.id}`
      dialog.show(album.name, {
        title: 'Do you want to delete this album?',
        buttons: [
          {
            label: 'No',
            class: 'btn-light-gray',
            handler: async (closeModal) => {
              closeModal()
            }
          },
          {
            label: 'Delete',
            class: 'btn-red',
            handler: async (closeModal) => {
              if (isBusy.value) {
                return
              }
              isBusy.value = true
              axios
                .delete(url)
                .then(() => {
                  isBusy.value = false
                  // remove it from the array
                  albums.value.splice(index, 1)
                  meta.value.total -= 1

                  Inertia.reload({ only: ['subPages', 'event'] })
                })
                .catch((err) => {
                  /* eslint-disable no-console */
                  console.error(err)
                  /* eslint-enable no-console */
                })
              closeModal()
            }
          }
        ]
      })
    }

    const goBack = () => {
      currentAlbum.value = null
      // update the route
      const l = window.location.pathname.split('/')
      l.splice(-1)
      const urlString = l.join('/')
      Inertia.visit(urlString, { preserveState: true, preserveScroll: true })
      // this can be optimized
      // by just updating the count locally
      currentPage.value = 1
      fetchAlbums()
    }

    const closeAlbumModal = () => {
      $('#createAlbumModal').modal('hide')
    }

    return {
      defaultTitle,
      defaultDescription,
      sortBy,
      createAlbumModalRef,
      albumRef,
      baseLayoutRef,
      newAlbumCreated,
      dropdownToggleRef,
      dropdownToggleInstance,
      sort,
      disabledMessage,
      albums,
      fetchAlbums,
      currentPage,
      openAlbum,
      currentAlbum,
      isLoading,
      apiCallback,
      deleteItem,
      isBusy,
      goBack,
      meta,
      hasMorePages,
      canDelete,
      loadingPage,
      closeAlbumModal
    }
  }
}
</script>

<style lang="scss" scoped>

.subline {
  font-size: 0.8em;
  color: darkgray;
}

.back-btn {
  cursor: pointer;
}

.ce-utilities-infinite-result :deep(.items-grid) {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1rem;
  /* Extra small devices (phones, 600px and down) */
  @media only screen and (max-width: 600px) {
    grid-template-columns: repeat(2, 1fr);
  }
  /* Small devices (portrait tablets and large phones, 600px and up) */
  @media only screen and (min-width: 600px) {
    grid-template-columns: repeat(2, 1fr);
  }
  /* Medium devices (landscape tablets, 768px and up) */
  @media only screen and (min-width: 768px) {
    grid-template-columns: repeat(3, 1fr);
  }
  /* Large devices (laptops/desktops, 992px and up) */
  @media only screen and (min-width: 992px) {
    grid-template-columns: repeat(3, 1fr);
  }
  /* Extra large devices (large laptops and desktops, 1200px and up) */
  @media only screen and (min-width: 1200px) {
    grid-template-columns: repeat(3, 1fr);
  }
}

/* purgecss start ignore */
:deep(.content-grid) {
  grid-template-columns: auto;
  @media only screen and (min-width: 1200px) {
    grid-template-columns: 110px minmax(110px, auto);
  }
}

.title {
  margin-left: 20px;
  margin-top: 20px;
  margin-bottom: 20px;
  margin-right: 5px;
}

.count {
  margin-left: 5px;
  background-color: #5451de;
  color: white;
  border-radius: 1.1rem;
}

.album-container {
  padding: 20px;
}

.btn-delete {
  width: 30px;
  height: 30px;
  color: white;
  background-color: lightgrey;
  padding: 0;
  border-radius: 15px;
  z-index: 2;
  right: -10px;
  float: right;
  top: -120px;
   /* Extra small devices (phones, 600px and down) */
  @media only screen and (max-width: 600px) {
    top: -150px;
  }
  /* Small devices (portrait tablets and large phones, 600px and up) */
  @media only screen and (min-width: 600px) {
    top: -180px;
  }
  /* Medium devices (landscape tablets, 768px and up) */
  @media only screen and (min-width: 768px) {
    top: -180px;
  }
}

.album {
  text-align: left;
  height: auto;
  width: 105px;
  .folder-title {
    display: block;
    color: black;
    font-size: 16px;
  }
  .subtitle {
    display: block;
    font-size: 14px;
    color: #aaa;
  }
  img {
    border-radius: 10px;
    object-fit: cover;
    border: 1px solid lightgrey;
    height: 105px;
    width: 105px;
  }

  @media only screen and (min-width: 600px) {
    width: 140px;
    img {
      height: 140px;
      width: 140px;
    }
  }
 @media only screen and (min-width: 768px) {
    width: 170px;
    img {
      height: 170px;
      width: 170px;
    }
  }
  @media only screen and (min-width: 992px) {
    width: 170px;
    img {
      height: 170px;
      width: 170px;
    }
  }
}
.btn-delete {
  position:relative;
  z-index:10
}
.fa-trash-can {
  color: white;
}
</style>
