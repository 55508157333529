<template>
  <div>
    <div class="card">
      <h3 class="title m-3">
        {{ album.name }}<span class="badge count">{{ totalCount }}</span>
      </h3>
      <span class="subline ms-3 mb-3">Updated {{ updatedAtText }}</span>
    </div>

    <div class="card album-container mt-3">
      <album-item-form
        v-if="isAllowedToPost && !album.is_wall"
        class="mb-4"
        :submit-url="postUrl"
        @new-item-posted="newItemPosted"
      />

      <ce-album-infinite-result
        v-if="items"
        :items="items"
        :api-callback="apiCallback"
        :has-more-pages="hasMorePages"
      >
        <template #default="{ item, index }">
          <div
            v-if="item.visual_files"
            class="grid-item album-item"
          >
            <template v-if="item.visual_files.media_type_id === 1">
              <ce-video-player
                :src="item.visual_files.location"
                :alt="
                  item.user
                    ? `Perfect Friends - ${album.resource_title} - ${
                      album.name
                    } photo ${index + 1} posted by ${item.user.first_name} ${
                      item.user.last_name
                    } last updated on ${item.updated_at}`
                    : 'deleted'
                "
                class="w-100 h-100"
                readonly
                @click="openSlider(item, index)"
              />
              <template v-if="contentIsReady">
                <span
                  class="icon-play fs-1 text-white position-absolute top-50 start-50 translate-middle pe-none"
                />
              </template>
            </template>
            <template v-else>
              <ce-image
                class="img-thumbnail"
                :alt="
                  item.user
                    ? `Perfect Friends - ${album.resource_title} - ${
                      album.name
                    } photo ${index + 1} posted by ${item.user.first_name} ${
                      item.user.last_name
                    } last updated on ${item.updated_at}`
                    : 'Deleted'
                "
                :src="item.visual_files.location"
                @click="openSlider(item, index)"
              />
            </template>
            <!--
            <ce-button
              v-if="canDelete(item)"
              class="btn-delete"
              :icon="'true'"
              :disabled="isBusy"
              :is-loading="isBusy"
              @click="deleteItem(item, index)"
              ><span class="icon-trash"></span
            ></ce-button> -->
          </div>
        </template>
      </ce-album-infinite-result>

      <teleport
        v-if="!editable"
        to="body"
      >
        <ce-modal
          ref="modalRef"
          size="lg"
          persistent
        >
          <ce-hero-slider
            ref="sliderRef"
            :images="images"
          />
        </ce-modal>
      </teleport>
    </div>
  </div>
</template>

<script>
import { ref, watch, computed, inject } from 'vue'
import map from 'lodash/map'
import { Fancybox } from '@fancyapps/ui'
import filters from '@/Filters/index.js'
// import Carousel from 'bootstrap/js/dist/carousel.js';
// import CePagination from '@/Components/Paginations/CePagination.vue';
import axios from 'axios'
import AlbumItemForm from '@/components/Album/AlbumItemForm.vue'
import CeModal from '@/components/Modal/CeModal.vue'
import CeHeroSlider from '@/components/Sliders/CeHeroSlider.vue'
import { useStore } from 'vuex'

export default {
  components: {
    // CePagination,
    AlbumItemForm,
    CeModal,
    CeHeroSlider
  },
  props: {
    entityId: {
      type: Number,
      required: true
    },
    entity: {
      type: String,
      required: true
    },
    album: {
      type: Object,
      required: true
    },
    isAllowedToPost: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  setup (props) {
    const store = useStore()
    const moment = inject('moment')
    const items = ref([])
    const deletedIndex = ref()
    const deletedItem = ref([])
    const deletedItems = ref([])
    const currentPage = ref(1)
    const meta = ref({ current_page: currentPage, total: 0, per_page: 15 })
    const sortBy = ref('new-items')
    const modalRef = ref(null)
    const sliderRef = ref(null)
    const imageKey = computed(() => props.imageKey)
    const images = ref([])
    const hasMorePages = ref(false)
    const hasMorePagesFetched = ref([])
    const totalCount = ref(props.album.total_count)
    const dialog = inject('dialog')
    const isBusy = ref(false)
    const auth = computed(() => store.state.user.authUser)
    const userId = auth.value?.data?.id || -1

    const fetchItems = async (sort = 'new-items', page = 1) => {
      sortBy.value = sort

      const { data } = await axios.get(
        `api/${props.entity}s/${props.entityId}/albums/${props.album.id}/items`,
        {
          params: {
            sort,
            page
          }
        }
      )

      if (data) {
        meta.value = data.meta
        totalCount.value = meta.value.total
        if (page === 1) {
          items.value = data.data
          hasMorePagesFetched.value = data.data
        } else {
          items.value = items.value.concat(data.data)
          hasMorePagesFetched.value = data.data
        }
        currentPage.value = meta.value.current_page
        hasMorePages.value = data.links.next !== null
      }

      return data
    }

    const canDelete = (item) => {
      // can't delete if it is a wall item
      // can refactor below items once the permissions are finalized
      if (props.album.is_wall) {
        return false
      }
      // for events, only the event owner can delete an album
      if (props.entity === 'event') {
        return !props.album.is_wall && props.isAllowedToPost
      }

      // if the user is not an owner, then check existence of the user
      // this item might have been deleted recently
      if (!item.user) {
        return false
      }

      return (
        props.album.user.id === userId ||
        (props.isAllowedToPost && item.user.id === userId)
      )
    }

    const deleteItem = (item, index, cb) => {
      // check the ability to edit
      if (!canDelete(item)) {
        // console.log('User cannot delete');
        return
      }
      const url = `api/${props.entity}s/${props.entityId}/albums/${props.album.id}/items/${item.id}`
      dialog.show(item.name, {
        title: 'Do you want to delete this item?',
        buttons: [
          {
            label: 'No',
            class: 'btn-light-gray',
            handler: async (closeModal) => {
              closeModal()
            }
          },
          {
            label: 'Delete',
            class: 'btn-red',
            handler: async (closeModal) => {
              if (isBusy.value) {
                return
              }
              isBusy.value = true
              axios
                .delete(url)
                .then(() => {
                  isBusy.value = false
                  // remove it from the array
                  items.value.splice(index, 1)
                  images.value.splice(index, 1)

                  // add to deleted items
                  deletedItem.value = {
                    index,
                    id: 0,
                    text: 'deleted',
                    visual_files: {
                      media_type_id: 0
                    }
                  }

                  deletedIndex.value = index

                  if (totalCount.value > 0) {
                    totalCount.value -= 1
                  }
                  // fetchItems();

                  if (cb) {
                    cb(true)
                  }
                })
                .catch((err) => {
                  /* eslint-disable no-console */
                  console.error(err)
                  /* eslint-enable no-console */
                  if (cb) {
                    cb(false)
                  }
                })
              closeModal()
            }
          }
        ]
      })
    }

    /**
     * Opens the fancy box to display media like images or videos
     * @param {number} index - Index of the attachment being opened
     */
    function openFancyBox (index) {
      const displayItems = [
        { id: 'prev', position: 'center' },
        { id: 'counter', position: 'center' },
        { id: 'next', position: 'center' },
        'zoom',
        'slideshow',
        'thumbs',
        'delete',
        'close'
      ]

      const showFancyBox = (sliderItems) => {
        let sliderIndex = index

        if (deletedIndex.value) {
          sliderIndex = deletedIndex.value
        }

        const toggleDeleteButtonVisibility = (hide) => {
          let display = 'none'
          if (!hide) {
            display = 'block'
          }
          document.getElementsByClassName(
            'carousel__button fancybox__button--delete'
          )[0].style.display = display
        }

        Fancybox.show(
          sliderItems.value.map((item) => {
            if (item.id !== 0) {
              return {
                src: item.visual_files.location
              }
            }

            return {
              html: 'Deleted',
              type: 'html'
            }
          }),
          {
            startIndex: sliderIndex,
            hideScrollbar: false,
            Toolbar: {
              display: displayItems
            }
          }
        )
          .on('closing', () => {
            if (deletedItem.value.length === 0) {
              fetchItems()
            }

            deletedItems.value = deletedItems.value.concat(deletedItem.value)

            deletedItem.value = []
          })
          .on('Carousel.Panzoom.startAnimation', () => {
            toggleDeleteButtonVisibility(true)
          })
          .on('Carousel.Panzoom.endAnimation', (fancybox) => {
            const slideIndex = fancybox.getSlide().index
            toggleDeleteButtonVisibility(!canDelete(items.value[slideIndex]))
          })
          .on('done', (fancybox, slide) => {
            // get the current slide
            const slideIndex = fancybox.getSlide().index
            // check if it's the same with the slide in question
            if (slideIndex === slide.index) {
              toggleDeleteButtonVisibility(
                !canDelete(items.value[slide.index])
              )
            }
          })
      }

      Fancybox.Plugins.Toolbar.defaults.items.delete = {
        type: 'button',
        class: 'fancybox__button--delete',
        label: 'Delete',
        html: '<span class="icon-trash"></span>',
        click (event) {
          event.preventDefault()
          if (isBusy.value) {
            return
          }
          const slide = this.fancybox.getSlide()
          const i = slide.index
          const item = items.value[i]
          deleteItem(item, i, (deleted) => {
            if (deleted) {
              this.fancybox.clearContent(slide)
              this.fancybox.setContent(
                slide,
                '<span style="color: white;">Deleted</span>'
              )
              this.fancybox.next()

              Fancybox.close()
              if (items.value.length > 0) {
                const itemsWithDeleted = ref(items.value)

                const uniqueDeletedItems = ref([
                  ...new Map(
                    deletedItems.value.map((itemDeleted, key) => [
                      itemDeleted[key],
                      itemDeleted
                    ])
                  ).values()
                ])

                uniqueDeletedItems.value.map((deletedSlide) =>
                  itemsWithDeleted.value.push(deletedSlide)
                )

                // sort index with deleted
                itemsWithDeleted.value.sort((a, b) => a.index - b.index)

                showFancyBox(itemsWithDeleted)
              }
            }
          })
        }
      }

      items.value.map((item, itemIndex) => {
        item.index = itemIndex
        return true
      })

      showFancyBox(items)
    }

    // TODO: to refactor this after the main ticket/feature is accepted
    const openSlider = (_, itemKey) => {
      // modalRef.value.open();

      // const carousel = Carousel.getInstance(sliderRef.value);
      // console.log(sliderRef.value);
      // sliderRef.value.goToSlide(itemKey);
      openFancyBox(itemKey)
      /*
      if (carousel) {
        console.log(carousel);
        carousel.to(itemKey);
      } */
    }

    fetchItems()

    watch(items, () => {
      images.value = map(items.value, (o) => o.visual_files.location)
    })

    const postUrl = ref(
      `api/${props.entity}s/${props.entityId}/albums/${props.album.id}/items`
    )

    const newItemPosted = (data) => {
      items.value = items.value.concat(data)
      totalCount.value += data.length
    }

    const apiCallback = async (page) => {
      // this is somewhat hackish, should check better way
      const data = await fetchItems(sortBy.value, page)
      data.data = hasMorePagesFetched.value
      return {
        data
      }
    }

    const { relativeTime } = filters
    relativeTime(props.album.updated_at)
    const updatedAtText = ref(
      moment.utc(props.album.updated_at).local().format('LLL')
    )

    return {
      items,
      meta,
      currentPage,
      fetchItems,
      postUrl,
      newItemPosted,
      apiCallback,
      hasMorePages,
      openSlider,
      modalRef,
      sliderRef,
      imageKey,
      images,
      totalCount,
      deleteItem,
      isBusy,
      userId,
      updatedAtText,
      canDelete
    }
  }
}
</script>

<style>
/* purgecss start ignore */
div.fancybox__content {
  background: none;
  color: #fff;
}

/* purgecss end ignore */
</style>

<style lang="scss" scoped>

/* purgecss start ignore */
@import '@fancyapps/ui/dist/fancybox.css';

.ce-utilities-infinite-result :deep(.items-grid) {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1rem;
  /* Extra small devices (phones, 600px and down) */
  @media only screen and (max-width: 600px) {
    grid-template-columns: repeat(2, 1fr);
  }
  /* Small devices (portrait tablets and large phones, 600px and up) */
  @media only screen and (min-width: 600px) {
    grid-template-columns: repeat(2, 1fr);
  }
  /* Medium devices (landscape tablets, 768px and up) */
  @media only screen and (min-width: 768px) {
    grid-template-columns: repeat(3, 1fr);
  }
  /* Large devices (laptops/desktops, 992px and up) */
  @media only screen and (min-width: 992px) {
    grid-template-columns: repeat(3, 1fr);
  }
  /* Extra large devices (large laptops and desktops, 1200px and up) */
  @media only screen and (min-width: 1200px) {
    grid-template-columns: repeat(3, 1fr);
  }
}

/* purgecss start ignore */
:deep(.content-grid) {
  grid-template-columns: auto;
   @media only screen and (min-width: 1200px) {
    grid-template-columns: 110px minmax(110px, auto);
  }
}

.album-item {
  margin: 0 auto;
  width: 110px;
  height: 110px;
  .folder-title {
    display: block;
    color: black;
    font-size: 16px;
  }
  .subtitle {
    display: block;
    font-size: 14px;
    color: #aaa;
  }
  img {
    width: 110px;
    height: 110px;
    border-radius: 10px;
    object-fit: cover;
  }
}

/* purgecss end ignore */

.album-container {
  padding: 20px;
}

.title {
  color: #505050;
}

.count {
  margin-left: 5px;
  background-color: #5451de;
  color: white;
  border-radius: 1.1rem;
}

.btn-delete {
  width: 30px;
  height: 30px;
  color: white;
  background-color: lightgray;
  padding: 0;
  border-radius: 15px;
  z-index: 2;
  right: -10px;
  float: right;
  top: -140px;
}

.subline {
  color: #aaa;
}
</style>
