<template>
  <event-media-form
    class="form-1"
    :type="gatheringType"
    :event-id="eventForm.id"
    :data="{
      bannerPhotoId: Number(eventForm.image),
      previewVideoId: eventForm.video_id,
    }"
    @success="eventCreate"
  >
    <template #submit="{ submit, isSubmitting }">
      <div class="row">
        <div class="col-6 col-sm-6 mb-3">
          <ce-button
            class="btn-lg btn btn-gray text-white text-bold w-100"
            @click="$emit('cancelEdit')"
          >
            Cancel
          </ce-button>
        </div>
        <div class="col-6 col-sm-6 mb-3">
          <ce-button
            class="btn-lg btn-gradient-gold text-bold w-100"
            :is-loading="isSubmitting"
            :disabled="isSubmitting"
            @click="submit"
          >
            {{ isPublished ? "Save" : "Continue" }}
          </ce-button>
        </div>
      </div>
    </template>
  </event-media-form>
</template>

<script>
import { defineAsyncComponent, computed, ref } from "vue";
import { useStore } from "vuex";
import { useRoute, useRouter } from "vue-router";
import { GATHERING_TYPES, useDynamicWord } from "@/Use/events.js";

export default {
  components: {
    EventMediaForm: defineAsyncComponent(() =>
      import("@/Modules/Event/EventMediaForm.vue")
    ),
  },
  props: {
    gatheringType: {
      type: Number,
      default: GATHERING_TYPES.EVENT,
    },
  },
  emits: ["createEvent", "eventIsPublished", "isLoading", "cancelEdit"],
  async setup(props, { emit }) {
    const store = useStore();
    const route = useRoute();
    const router = useRouter();

    const eventId = ref(router.currentRoute._value.params.id);
    const isLoaded = ref(false);

    const { dynamicWordFor } = useDynamicWord({
      gatheringType: props.gatheringType,
    });

    emit("isLoading", true);
    const getEventInfoResource = await store.dispatch("eventEdit", {
      id: eventId.value,
      api: "media",
    });
    emit("isLoading", false);

    const eventCreate = (response) => {
      emit("createEvent", response.data, {
        steps: "media",
        isPublished: getEventInfoResource.data.isPublished,
      });
    };

    const eventForm = computed(() => getEventInfoResource.data.form);
    const gatheringType = computed(
      () => getEventInfoResource.data.gatheringType
    );
    const isPublished = computed(() => getEventInfoResource.data.isPublished);

    emit("eventIsPublished", isPublished);

    return {
      dynamicWordFor,
      isPublished,
      eventCreate,
      eventForm,
      isLoaded,
    };
  },
};
</script>

<style lang="scss" scoped>
@import "../../scss/customizations";

:deep() {
  @import "../../scss/form/form-1";
}

.container {
  max-width: 680px;
}

/* purgecss start ignore */
:deep(.ce-inputs-textarea textarea) {
  $number-of-rows: 5;
  $line-height: 1.5rem;
  $border-width-total: 2px;
  $padding-total: 1.5rem;

  min-height: textarea-height(
    $number-of-rows,
    $line-height,
    $border-width-total,
    $padding-total
  );
}

/* purgecss end ignore */
</style>
