<template>
  <vue-google-autocomplete
    id="map2"
    ref="refAddress"
    classname="form-control form-control-sm pf-text"
    :placeholder="placeholder"
    types="(cities)"
    country="us"
    type="search"
    @placechanged="getAddressData"
  />
</template>
<script>
import { ref, watch } from 'vue'
import VueGoogleAutocomplete from 'vue-google-autocomplete'

export default {
  components: {
    VueGoogleAutocomplete
  },
  props: {
    modelValue: {
      type: Object,
      default: () => {}
    },
    placeholder: {
      type: String,
      default: 'Please type your address'
    }
  },
  emits: ['update:modelValue'],
  setup (props, { emit }) {
    const streetAddress = ref(null)
    const localValue = ref({
      city: '',
      state: '',
      zipCode: '',
      lat: 0,
      lng: 0
    })

    const getAddressData = (addressData, placeResultData) => {
      streetAddress.value = placeResultData.formatted_address
      localValue.value.city = placeResultData.address_components[0].long_name
      localValue.value.state = placeResultData.address_components[2].long_name
      localValue.value.country = addressData.country
      localValue.value.zipCode = addressData.postal_code
      localValue.value.lat = addressData.latitude
      localValue.value.lng = addressData.longitude
    }

    /* Watchers */
    watch(
      localValue,
      (newLocalValue) => {
        emit('update:modelValue', newLocalValue)
      },
      {
        deep: true
      }
    )

    watch(
      () => props.modelValue,
      (newModelValue) => {
        localValue.value = newModelValue
      },
      {
        immediate: true
      }
    )

    return {
      getAddressData,
      streetAddress,
      localValue
    }
  }
}
</script>
