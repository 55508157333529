<template>
  <div>
    <div class="card card-success">
      <div class="card-body">
        <header-stories-component
          :title="`${header}`"
          :show-search="false"
          :show-total="false"
        />
      </div>
    </div>
    <div class="card card-success">
      <div class="card-body color-gray">
        <div class="row">
          <div class="col-md-12 col-lg-12 col-xl-12">
            <div class="row">
              <user-info-component
                v-if="resource === 'user'"
                :name="`${account?.first_name} ${account?.last_name}`"
                :src="`${account?.primary_photo?.sizes?.thumbnail}`"
                :account-type="`${accountType}`"
              />
              <user-info-component
                v-if="resource === 'group'"
                :name="`${group?.name}`"
                :src="group?.image_modified_url?.sizes?.sm"
                :has-type="false"
              />
              <user-info-component
                v-if="resource === 'event' || resource === 'huddle'"
                :name="`${event?.title}`"
                :src="event?.image_modified_url?.sizes?.sm"
                :has-type="false"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="card card-default">
      <div class="card-header">
        <h3 class="card-title color-gray">DETAILS</h3>
      </div>
      <form>
        <div class="card-body">
          <div class="form-group mb-4">
            <textarea
              v-model="details"
              class="form-control h-25"
              rows="5"
              :placeholder="
                type === 'flag'
                  ? `Please describe the reason why your going to flaged this ${resource}...`
                  : type === 'suspend'
                  ? `Please describe the reason why your going to suspend this ${resource}...`
                  : type === 'deactivate'
                  ? `Please describe the reason why your going to deactivate this ${resource}...`
                  : type === 'reactivate'
                  ? `Please describe the reason why your going to reactivate this ${resource}...`
                  : type === 'publish'
                  ? `Please describe the reason why your going to published this ${resource}...`
                  : type === 'remove-flag'
                  ? `Please describe the reason why your going to removed the flag for this ${resource}...`
                  : type === 'unsuspend'
                  ? `Please describe the reason why your going to unsuspend this ${resource}...`
                  : ''
              "
              @keyup="onKeyPress"
            />
            <small class="float-right">{{ stringNumber }}/1000</small>
          </div>
          <dropzone-component
            @complete="afterComplete"
            @uploading="uploading"
          />
        </div>
        <div class="card-footer">
          <div class="float-right">
            <button
              @click="cancelAction"
              class="btn btn-nero pl-5 pr-5 mr-1"
              :disabled="isLoading"
            >
              Cancel
            </button>
            <button
              type="submit"
              class="btn btn-green pl-5 pr-5"
              :disabled="isLoading"
              @click="submit"
            >
              Submit
            </button>
          </div>
        </div>
      </form>
    </div>
    <modal-component
      :modal-id="`success-modal-action`"
      :header-style="`bg-linear`"
      :size="`modal-md`"
      :profile="false"
    >
      <template v-slot:modalBody>
        <div class="modal-body">
          <div class="mr-4 ml-4">
            <h3 class="row text-center">
              <strong>
                <p>
                  {{ headerAction }}
                  {{
                    type === "flag"
                      ? "has been successfully flagged"
                      : type === "suspend"
                      ? "has been successfully suspended"
                      : type === "deactivate"
                      ? "has been successfully deactivated"
                      : type === "reactivate"
                      ? "has been successfully Reactivated"
                      : type === "publish"
                      ? "has been successfully published"
                      : type === "remove-flag"
                      ? "flag has been successfully removed"
                      : type === "unsuspend"
                      ? " has been successfully unsuspended"
                      : "has been successfully deleted"
                  }}.
                </p>
              </strong>
            </h3>
          </div>
          <div class="mt-3 text-center">
            <button
              type="submit"
              class="btn-gradient-gold mt-2 mb-2 close-gradient-gold-color"
              @click="closeModal"
            >
              Close
            </button>
          </div>
        </div>
      </template>
    </modal-component>
  </div>
</template>
<script>
import HeaderStoriesComponent from "@/components/HeaderStoriesComponent.vue";
import DropzoneComponent from "@/components/DropzoneComponent.vue";
import UserInfoComponent from "@/components/UserInfoComponent.vue";
import { onMounted, ref } from "vue";
import { useRouter, useRoute } from "vue-router";
import { useStore } from "vuex";
import $ from "jquery";
import ModalComponent from "@/components/ModalComponent.vue";
export default {
  name: "App",
  components: {
    HeaderStoriesComponent,
    DropzoneComponent,
    UserInfoComponent,
    ModalComponent,
  },
  props: {
    moduleName: {
      type: String,
      default: null,
      required: false,
    },
    queryFilter: {
      type: String,
      default: null,
      required: false,
    },
  },
  emits: ["isLoading"],
  setup(props, { emit }) {
    const account = ref({});
    const router = useRouter();
    const route = useRoute();
    const accountType = ref(null);
    const store = useStore();
    const userNameOrSlug = ref(null);
    const userId = ref(null);
    const details = ref("");
    const attachment = ref([]);
    const isLoading = ref(false);
    const header = ref(router.currentRoute.value.name);
    const type = ref(null);
    const resource = ref(null);
    const group = ref({});
    const event = ref({});
    const headerAction = ref(null);
    const stringNumber = ref(0);

    onMounted(async () => {
      emit("isLoading", true);
      const split = router.currentRoute.value.path.split("/");
      resource.value = split[1];
      type.value = split[2];
      userNameOrSlug.value = split[3];
      header.value =
        type.value.charAt(0).toUpperCase() +
        type.value.slice(1) +
        " " +
        resource.value.charAt(0).toUpperCase() +
        resource.value.slice(1);
      if (resource.value === "user") {
        const getUser = await store.state.user.userList.find(
          (user) => user.user_name === userNameOrSlug.value
        );
        accountType.value =
          getUser.subscription_type.charAt(0).toUpperCase() +
          getUser.subscription_type.slice(1) +
          " Account";
        userId.value = getUser.id;
        account.value = getUser;
        headerAction.value = getUser.first_name + " " + getUser.last_name;
      }
      if (resource.value === "group") {
        await store
          .dispatch("group/loadGroupBySlug", { slug: userNameOrSlug.value })
          .then((response) => {
            group.value = response.data.group;
            headerAction.value = group.value.name;
          });
      }
      if (resource.value === "event" || resource.value === "huddle") {
        await store
          .dispatch("loadEventBySlug", { slug: userNameOrSlug.value })
          .then((response) => {
            event.value = response.data.event;
            headerAction.value = event.value.title;
          });
      }
      emit("isLoading", false);
    });
    const afterComplete = (file) => {
      attachment.value.push(file.data.id);
    };
    const uploading = (loading) => {
      isLoading.value = loading;

      emit("isLoading", loading);
    };
    const submit = async () => {
      isLoading.value = true;
      emit("isLoading", true);
      const form = {
        userId: userId?.value
          ? userId.value
          : group?.value.user?.id
          ? group?.value.user?.id
          : event?.value.owner?.id
          ? event?.value.owner?.id
          : null,
        notes: details.value,
        type: type.value,
        attachments: attachment.value,
        resource_id: group?.value.id
          ? group?.value.id
          : event?.value.id
          ? event?.value.id
          : userId?.value,
        resource: resource.value,
      };
      const response = await store.dispatch("reportAccount", form);
      if (response) {
        isLoading.value = false;
        emit("isLoading", false);
        details.value = "";
        attachment.value = [];
        $("#success-modal-action").modal("show");
      }
    };
    const closeModal = () => {
      $("#success-modal-action").modal("hide");
      const prevURL = localStorage.getItem("prevURL");

      if (props.moduleName === "event" || props.moduleName === "huddle") {
        router.push({
          path: `/manage-${props.moduleName}s`,
          query: {
            filter: props.queryFilter
          },
        });
      } else if (
        (props.moduleName === "group" && type.value === "reactivate")
      ) {
        router.push("/manage-groups?filter=active");
      } else if (
        (props.moduleName === "group" && type.value === "deactivate")
      ) {
        router.push("/manage-groups?filter=deactivated");
      } else if (props.moduleName === "group" && type.value === "flag")
       {
        router.push("/manage-groups?filter=flagged");
      } else if (
        props.moduleName === "user" && 
        (type.value === 'unsuspend' || type.value === 'reactivate' || type.value === 'remove-flag')
      ) {
          router.push("/manage-users?filter=active");
      } else if (props.moduleName === "user" && type.value === 'flag') {
          router.push("/manage-users?filter=flagged");
      } else if (props.moduleName === "user" && type.value === 'suspend') {
          router.push("/manage-users?filter=suspended");
      } else if (props.moduleName === "user" && type.value === 'deactivate') {
          router.push("/manage-users?filter=deactivated");
      } else if (props.moduleName === "user" && type.value === 'delete') {
          router.push("/manage-users?filter=deleted");
      } else {
        router.push(prevURL);
      }
    };

    const onKeyPress = (evt) => {
      stringNumber.value = details.value.length;
      if (details.value.length >= 1000) {
        stringNumber.value = 1000;
        details.value = details.value.substring(0, 1000);
      }
    };

    const cancelAction = () => {
      if (resource.value === "user" && type.value === "flag") {
        router.push("/manage-users?filter=active");
      } else if (resource.value === "user" && (type.value === "delete" || type.value === "deactivate")) {
        router.push("/manage-users?filter=deactivate");
      } else if (resource.value === "user" && type.value === "suspend") {
        router.push("/manage-users?filter=suspend");
      } else if (resource.value === "user" && type.value === "reactivate") {
        router.push("/manage-users?type=reactivate");
      } else if (resource.value === "group") {
          router.push(`/manage-groups?filter=${type.value}`);
      } else if (
        props.moduleName === "event" ||
        props.moduleName === "huddle"
      ) {
        router.push({
          path: `/manage-${props.moduleName}s`,
          query: {
            type: route.query.page_type,
            filter: route.query.page_filter,
          },
        });
      }
    };

    return {
      account,
      accountType,
      details,
      afterComplete,
      uploading,
      isLoading,
      attachment,
      submit,
      header,
      type,
      resource,
      group,
      event,
      closeModal,
      headerAction,
      onKeyPress,
      stringNumber,
      cancelAction,
    };
  },
};
</script>
<style scoped>
.color-gray {
  color: #727e8b;
}
.form-control:focus {
  box-shadow: none;
}
.btn:focus,
.info-box {
  box-shadow: none;
}
.info-box {
  margin-bottom: 0;
  min-height: 0;
}
</style>
