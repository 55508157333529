<template>
  <div class="card card-success">
    <div class="card-body">
      <span class="fw-bold color-black h1">Reports</span>
    </div>
  </div>
  <report-table
    :is-load="isLoad"
  />
</template>

<script>
import ReportTable from '@/components/Tables/ReportTable.vue';
export default {
  name: "App",
  components: {
    ReportTable,
  },
  setup() {
   
    return {
    };
  },
};
</script>

<style scoped>
.downloadAndMessageAlign {
  text-align: center;
  position: inherit;
  margin-top: 15px;
}
.dropdown-menu-lg {
  min-width: auto;
}
.table td,
.table th {
  border-top: 0 !important;
}
.table td {
  vertical-align: middle;
}
.custom-control-label {
  font-weight: 500 !important;
}
.send {
  color: rgb(4, 98, 193);
}
.selected-message-type {
  background-color: #333;
  color: #cacaca !important;
}
</style>
