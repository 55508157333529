<template>
  <div
    v-cloak
    class="list-group p-1"
  >
    <div class="container">
      <div class="d-flex text-center mt-4 ms-3">
        <div class="flex-grow-1">
          <ce-text
            v-model="searchFriends"
            :placeholder="'Search a perfect friends'"
          >
            <template #prepend>
              <span
                class="input-group-text icon-search px-3 border-0 bg-light-gray"
              />
            </template>
            <template #append>
              <span
                class="input-group-text icon-close px-3 border-0 bg-light-gray"
                @click="clearText"
              />
            </template>
          </ce-text>
        </div>
      </div>
    </div>

    <ul
      v-if="totalFriends > 0"
      class="list-group mt-3"
    >
      <li
        v-for="friend in friendLists.data"
        :key="friend.id"
        class="list-group-item d-flex justify-content-between align-items-start border-0"
      >
        <invite-card
          :friend="friend"
          :group-id="group.id"
          :query-params="queryParams"
        />
      </li>
      <li class="list-group-item border-0 m-2">
        <div class="d-flex justify-content-center">
          <ce-pagination
            v-model:current-page="currentPage"
            :per-page="perPage"
            :total="totalRecords"
            :max-bullet-pages="5"
          />
        </div>
      </li>
    </ul>
    <ul v-else>
      <li class="text-center list-group-item border-0 p-3">
        There are currently no users available to invite.
      </li>
    </ul>
    <div class="container">
      <div class="mt-3 mb-4 text-center">
        <ce-button
          class="btn-gradient-gold"
          data-bs-dismiss="modal"
          aria-label="Close"
        >
          Close
        </ce-button>
      </div>
    </div>
  </div>
</template>
<script>
import { computed, onMounted, ref, watch } from 'vue'
import debounce from 'lodash/debounce.js'
// import { useFriendInvites } from '@/Use/groups.js';
import CePagination from '@/components/Paginations/CePagination.vue'
import InviteCard from '@/Pages/Group/MemberInviteCard.vue'

export default {
  name: 'MemberListsInvite',
  components: {
    CePagination,
    InviteCard
  },
  props: {
    group: {
      type: Object,
      required: true
    }
  },
  setup (props) {
    const currentPage = ref(1)
    const totalRecords = ref(0)
    const isLoading = ref(false)
    const perPage = 9
    const searchFriends = ref(null)
    const totalFriends = ref(0)

    const queryParams = computed(() => ({
      page: currentPage.value,
      perPage,
      keyword: searchFriends.value
    }))

    // const { friendLists, fetchFriendLists } = useFriendInvites();

    const loadFriendLists = async () => {
      isLoading.value = true
      try {
        // await fetchFriendLists(props.group.id, queryParams.value);
        // totalRecords.value = friendLists.value.meta.total;
      } finally {
        isLoading.value = false
      }
    }

    const clearText = () => {
      searchFriends.value = ''
      currentPage.value = 1
    }

    watch(
      () => friendLists.value,
      (value) => {
        totalFriends.value = value.data.length
      }
    )

    onMounted(() => {
      loadFriendLists()
    })

    watch(
      () => searchFriends.value,
      debounce(() => {
        currentPage.value = 1
        loadFriendLists()
      }, 1000)
    )

    watch(
      () => currentPage.value,
      debounce(() => {
        loadFriendLists()
      }, 1000)
    )

    return {
      currentPage,
      perPage,
      totalRecords,
      friendLists,
      queryParams,
      totalFriends,
      searchFriends,
      clearText
    }
  }
}
</script>
<style lang="scss" scoped>
@import '~bootstrap/scss/bootstrap-utilities';
.btn {
  min-width: 100%;
}
@include media-breakpoint-up(md) {
  .btn {
    min-width: 152px !important;
  }
}
</style>
