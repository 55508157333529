<template>
  <div ref="visualMediaPickerRef">
    <input
      ref="fileBrowserRef"
      type="file"
      class="d-none"
      :accept="acceptedFileTypes"
      :capture="computedInputCapture"
      :multiple="multiple"
      @change="pick($event)"
    >
    <slot
      :browse-media="browseMedia"
      :dropzone-ref="
        (el) => {
          dropzoneRef = el;
        }
      "
    >
      <button
        ref="dropzoneRef"
        type="button"
        @click="browseMedia()"
      >
        Click to Upload
      </button>
    </slot>

    <teleport
      :to="modalContainer || visualMediaPickerRef"
    >
      <div
        ref="sourceModalRef"
        class="modal fade"
        tabindex="-1"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content">
            <slot
              :choose-from-file="chooseFromFile"
              :take-from-camera="takeFromCamera"
              name="browse-options"
            >
              <div class="d-flex justify-content-around">
                <button
                  type="button"
                  class="btn"
                  @click="chooseFromFile()"
                >
                  Select Media
                </button>
                <button
                  type="button"
                  class="btn"
                  @click="takeFromCamera()"
                >
                  Take From Device
                </button>
              </div>
            </slot>
          </div>
        </div>
      </div>
    </teleport>
  </div>
</template>

<script setup>
import { ref, computed, onMounted, nextTick, onBeforeUnmount } from 'vue'
import Modal from 'bootstrap/js/dist/modal.js'

const props = defineProps({
  fileTypes: {
      type: Array,
      default: () => [
        'video/mp4',
        'video/quicktime',
        'video/3gpp',
        'video/x-msvideo',
        'image/png',
        'image/jpeg',
        'image/gif',
        'image/webp'
      ]
    },
    multiple: {
      type: Boolean,
      default: false
    },
    modalContainer: {
      type: Object,
      default: undefined,
      required: false
    }
});

const emit = defineEmits(['picked', 'closed', 'invalidFileType']);

const isReady = ref(false)
const visualMediaPickerRef = ref(null)
const fileBrowserRef = ref(null)
let sourceModalRef = ref(null)
const modalInstance = ref(false)
const browseMode = ref(null)
const dropzoneRef = ref(null)

let thisModalObj = null;

onMounted(() => {
    thisModalObj = new Modal(sourceModalRef.value, {
        background: 'static',
        keyboard: true
    })

    sourceModalRef.value.addEventListener('hidden.bs.modal', () => {
        emit('closed')
    })

    activeDropzone()

    // If back button is pressed hide modal overlay
    window.onpopstate = () => {
        const overlayDark = document.getElementsByClassName(
            'modal-backdrop fade show'
        )

        if (overlayDark[0]) {
            overlayDark[0].classList.remove('show')
        }

        const overlayLight = document.getElementsByClassName(
            'modal-backdrop fade'
        )

        if (overlayLight[0]) {
            overlayLight[0].remove()
        }
    }
});

onBeforeUnmount(() => {
    deactivateDropzone()
})

function _show() {
  thisModalObj.show();
}

function _hide() {
  thisModalObj.hide();
}


const acceptedFileTypes = computed(() =>
    Array.isArray(props.fileTypes) ? props.fileTypes.join(',') : null
)

const computedInputCapture = computed(() => {
    switch (browseMode.value) {
    case 'file':
        return null

    case 'camera':
        // user = Front-camera
        // environment = Back-camera
        return 'user'

    default:
        return null
    }
})

/* Methods */
const browseMedia = () => {
    if (browseMode.value === null) {
        _show()
        return false
    }
    fileBrowserRef.value.value = null
    fileBrowserRef.value.click()
    browseMode.value = null
    _hide()
    return true
}

const chooseFromFile = () => {
    browseMode.value = 'file'
    _hide()
    browseMedia()
}

const takeFromCamera = async () => {
    browseMode.value = 'camera'
    await nextTick()
    browseMedia()
}

const pick = async (e) => {
    const mediaFiles = [...e.target.files]
    const hasInvalidFileType = mediaFiles.some(
        (mediaFile) => !props.fileTypes.includes(mediaFile.type)
    )

    if (hasInvalidFileType) {
        emit('invalidFileType')
        return false
    }

    emit('picked', props.multiple ? mediaFiles : mediaFiles[0])
    return true
}

const onDropHandler = async (e) => {
    e.preventDefault()
    fileBrowserRef.value.files = e.dataTransfer.files
    fileBrowserRef.value.dispatchEvent(new Event('change'))
}

const dragOverHandler = (e) => {
    e.preventDefault()
}

const activeDropzone = () => {
    if (dropzoneRef.value) {
        dropzoneRef.value.addEventListener('drop', onDropHandler)
        dropzoneRef.value.addEventListener('dragover', dragOverHandler)
    }
}

const deactivateDropzone = () => {
    if (dropzoneRef.value) {
        dropzoneRef.value.removeEventListener('drop', onDropHandler)
        dropzoneRef.value.removeEventListener('dragover', dragOverHandler)
    }
}

defineExpose({
    show: _show,
    hide: _hide
});
</script>
