<template>
  <div class="pf-card card rounded-3 shadow">
    <div class="card-body p-3">
      <div class="content-grid">
        <div class="placeholder-glow">
          <ce-aspect-ratio
            class="position-relative rounded-3 overflow-hidden"
            :class="{ placeholder: !photoIsLoaded }"
            :aspect-ratio="1 / 1"
          >
            <a
              :href="`/${perfectFriend.username}${
                keyword ? '?keyword=' + keyword : ''
              }`"
              class="nav-link p-0 bg-light-gray w-100 h-100 d-flex justify-content-center"
            >
              <ce-image
                v-show="photoIsLoaded"
                class="h-100"
                :src="photoSrc"
                :blur-hash="perfectFriend.photoBlurHash"
                :alt="`Perfect Friends ${
                  perfectFriend.accountType === 2 ? 'influencer' : ''
                }  ${name}`"
                placeholder="/images/defaults/gender-other.png"
                @load="photoIsLoaded = true"
              />
            </a>
            <favorite-button
              v-model:is-favorite="isFavorite"
              class="favorite-btn position-absolute p-0 rounded-circle bg-white shadow-sm d-flex align-items-center justify-content-center"
              :user-id="perfectFriend.id"
            >
              <template #default="{ value }">
                <span
                  class="fa fa-heart"
                  :class="value ? 'text-red-heart' : 'text-gray'"
                />
              </template>
            </favorite-button>
          </ce-aspect-ratio>
        </div>
        <div class="d-flex flex-column overflow-hidden">
          <div class="flex-fill">
            <div class="d-flex">
              <div class="w-100">
                <a
                  :href="`/${perfectFriend.username}${
                    keyword ? '?keyword=' + keyword : ''
                  }`"
                  class="flex-fill nav-link p-0 text-white ff-merriweather fw-bold full-name text-truncate"
                  v-html="nameWithHighlight"
                />

                <small v-if="roleName">({{ roleName }})</small>
              </div>

              <template v-if="$slots.menu || withFriendButton">
                <div class="dropdown">
                  <ce-button
                    icon
                    dropdown
                  >
                    <span class="icon-ellipsis-vertical text-white" />
                  </ce-button>
                  <ul class="dropdown-menu dropdown-menu-end text-end">
                    <template v-if="withFriendButton">
                      <li>
                        <friend-button
                          v-model:friendship-status="friendshipStatus"
                          class="d-block"
                          button-class="dropdown-item"
                          accept-request-class="dropdown-item"
                          reject-request-class="dropdown-item"
                          :user-id="perfectFriend.id"
                        />
                      </li>
                    </template>
                    <slot name="menu" />
                  </ul>
                </div>
              </template>
            </div>
            <!-- <template v-if="interests">
              <div class="interest-block mt-1">
                <label>Interests:</label>
                <div class="text-truncate">
                  {{ interests }}
                </div>
              </div>
            </template> -->
            <template v-if="perfectFriend.aboutMe">
              <div class="about-block mt-2">
                <label>What makes me a perfect friend?</label>
                <div class="about-me">
                  {{ perfectFriend.aboutMe }}
                </div>
              </div>
            </template>
          </div>
          <div
            :class="
              buttonsContainerClass ||
                'd-flex flex-wrap align-items-center justify-content-between mt-3'
            "
          >
            <slot name="contactButton">
              <template v-if="isLoggedIn">
                <profile-button-send-message
                  class="btn-sm btn-gradient-gold"
                  :user-id="perfectFriend.id"
                >
                  <template #label>
                    Contact Me
                  </template>
                </profile-button-send-message>
              </template>
              <template v-else>
                <a
                  class="btn-sm btn-gradient-gold text-decoration-none not-login-user"
                  href="/login?redirect=browse"
                >
                  <b>Contact Me</b>
                </a>
              </template>
            </slot>
            <a
              :href="`/user/update-profile/${perfectFriend.username}${
                keyword ? '?keyword=' + keyword : ''
              }`"
              class="btn btn-sm btn-transparent color-gold fw-normal ms-auto"
            >
              Read more
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, ref, watch, inject } from 'vue'
import CeAspectRatio from '@/components/Utilities/CeAspectRatio.vue'
import CeImage from '@/components/Images/CeImage.vue'
import FavoriteButton from './FavoriteButton.vue'
import CeButton from '../../components/Buttons/CeButton.vue'
import ProfileButtonSendMessage from '../Profile/ProfileButtonSendMessage.vue'

export default {
  components: {
    // FriendButton: defineAsyncComponent(() =>
    //   import('@/Modules/PerfectFriend/FriendButton.vue')
    // ),
    // FavoriteButton: defineAsyncComponent(() =>
    //   import('@/Modules/PerfectFriend/FavoriteButton.vue')
    // ),
    // ProfileButtonSendMessage: defineAsyncComponent(() =>
    //   import('@/Modules/Profile/ProfileButtonSendMessage.vue')
    // )
    CeAspectRatio,
    CeImage,
    FavoriteButton,
    CeButton,
    ProfileButtonSendMessage
  },
  props: {
    perfectFriend: {
      type: Object,
      default: () => {}
    },
    withFriendButton: {
      type: Boolean,
      default: false
    },
    withFavoriteButton: {
      type: Boolean,
      default: false
    },
    keyword: {
      type: String,
      default: ''
    },
    buttonsContainerClass: {
      type: String,
      default: ''
    }
  },
  emits: ['friend-status-changed', 'is-favorite-changed'],
  setup (props, { emit }) {
    const moment = inject('moment')

    const name = computed(() => {
      return props.perfectFriend.name || ''
    })

    const nameWithHighlight = computed(() => {
      if (!props.keyword) {
        return name.value
      }

      const matchStart = name.value
        .toLowerCase()
        .search(props.keyword.toLowerCase())
      const matchEnd = matchStart + props.keyword.length
      const highlight = name.value.substring(matchStart, matchEnd)
      const prefix = `${name.value.substring(0, matchStart)}`
      const suffix = name.value.substring(matchEnd, name.value.length)
      const addSpace = name.value.substring(matchEnd, matchEnd + 1) === ' '

      if (props.keyword.toLowerCase() === highlight.toLowerCase()) {
        if (matchStart === 0) {
          if (addSpace) {
            return `<span style="color: #B4914D">${highlight} </span>${suffix}`
          }
          return `<span style="color: #B4914D">${highlight}</span>${suffix}`
        }
        return `${prefix}<span style="color: #B4914D">${highlight}</span>${suffix}`
      }
      return name.value
    })

    const roleName = computed(() => {
      return props.perfectFriend.roleName || ''
    })

    const age = computed(() => {
      return moment().diff(moment(props.perfectFriend.birthDate), 'years')
    })

    const photoSrc = computed(() => {
      return props.perfectFriend.photoSrc || ''
    })

    const photoIsLoaded = ref(false)

    const interests = computed(() => {
      return props.perfectFriend.interests?.join(', ') || ''
    })

    const friendshipStatus = ref(props.perfectFriend.friendshipStatus)
    const isFavorite = ref(props.perfectFriend.isFavorite)
    const isLoggedIn = ref(true)

    /* Watchers */
    watch(friendshipStatus, (newFriendshipStatus) => {
      emit('friend-status-changed', newFriendshipStatus)
    })

    watch(isFavorite, (newIsFavorite) => {
      emit('is-favorite-changed', newIsFavorite)
    })

    return {
      name,
      age,
      photoSrc,
      photoIsLoaded,
      interests,
      friendshipStatus,
      isFavorite,
      isLoggedIn,
      roleName,
      nameWithHighlight
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/scss/customizations';

.card {
  background-color: #2f2f2f !important;
  color: #fff;
}

.content-grid {
  display: grid;
  gap: 1rem;
  @media (min-width: 576px) {
    grid-template-columns: 160px minmax(200px, auto);
  }
}

.favorite-btn {
  font-size: 0.9rem;
  width: 32px;
  height: 32px;
  top: 10px;
  right: 10px;

  > :deep(span),
  > :deep(span > span) {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.full-name {
  font-size: 1.125rem;
  line-height: 1.2;
}

.interest-block,
.about-block {
  font-size: 0.875rem;

  label {
    color: #B4914D;
  }
}

.about-block {
    height: 42px; // To provide consistent height of card
}

.dropdown-menu {
  min-width: auto;
  > :deep(li > div > button) {
    font-size: 0.9em;
  }
}
.not-login-user:hover {
  color: #000;
}

.btn-transparent:hover {
  color: #B4914D;
}
</style>
