<template>
  <span
    class="badge rounded-pill interest request border border-dark-gray bg-dark-gray d-flex align-items-center px-4 py-3 me-3 mb-3 fs-6 fw-normal"
    :class="{
      'is-selected': isInterested && showSelected,
    }"
    @click.stop="
      isInterested
        ? unselectInterest(interestId)
        : selectInterest(interestId)
    "
  >
    {{ name }}
    <button
      v-if="hasIcon"
      type="button"
      is-loading="false"
      class="ce-buttons-button btn btn-sm p-0 text-white d-flex align-items-center ms-3"
      @click.stop="
        isInterested
          ? unselectInterest(interestId)
          : selectInterest(interestId)
      "
    >
      <span>
        <span :class="`fa fa-${icon}`" />
      </span>
    </button>
  </span>
</template>
<script>
export default {
  props: {
    name: {
      type: String,
      required: true
    },
    interestId: {
      type: Number,
      required: false
    },
    hasIcon: {
      type: Boolean,
      default: false
    },
    icon: {
      type: String,
      required: true,
      default: 'heart'
    },
    isInterested: {
      type: Boolean,
      default: false
    },
    showSelected: {
      type: Boolean,
      default: false
    }
  },
  emits: ['add', 'remove'],
  setup (props, { emit }) {
    const unselectInterest = (id) => {
      emit('remove', id)
    }
    const selectInterest = (id) => {
      emit('add', id)
    }
    return {
      unselectInterest,
      selectInterest
    }
  }
}
</script>
<style scoped>
    .rounded-pill:hover {
        cursor: pointer;
    }
</style>
