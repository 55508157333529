<template>
  <div ref="videoPlayerContainerRef" class="ce-media-players-video">
    <video
      ref="videoPlayerRef"
      class="video-js mw-100 mh-100 vjs-big-play-centered"
      playsinline
      />
  </div>
</template>

<script>
import videojs from 'video.js';
import { ref, onMounted, watch } from 'vue';

export default {
  props: {
    src: {
      type: [String, Array],
      default: '',
    },
    autoplay: {
      type: [Boolean, String],
      default: false,
    },
    muted: {
      type: Boolean,
      default: false,
    },
    loop: {
      type: Boolean,
      default: false,
    },
    preload: {
      type: String,
      default: undefined,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    fluid: {
      type: Boolean,
      default: undefined,
    },
    aspectRatio: {
      type: String,
      default: undefined,
    },
  },
  emits: ['ready'],
  setup(props, { emit }) {
    const videoPlayerContainerRef = ref(null);
    const videoPlayerRef = ref(null);
    const videoPlayer = ref(null);

    /* Watchers */
    watch(
      () => props.src,
      (newSrc) => {
        videoPlayer.value.src(newSrc);
      }
    );

    /* Lifecycles */
    onMounted(() => {
      videoPlayer.value = videojs(videoPlayerRef.value, {
        controls: !props.readonly,
        sources: props.src,
        autoplay: props.autoplay === true,
        muted: props.muted || props.autoplay === 'visible',
        loop: props.loop,
        preload: props.preload,
        fluid: props.fluid,
        aspectRatio: props.aspectRatio,
      });
      videoPlayer.value.on('loadeddata', () => {
        emit('ready');
      });

      if (props.autoplay === 'visible') {
        const observer = new window.IntersectionObserver(
          ([entry]) => {
            if (entry.isIntersecting) {
              videoPlayer.value.play();
            } else {
              videoPlayer.value.pause();
            }
          },
          {
            root: null,
            threshold: 0.1,
          }
        );
        observer.observe(videoPlayerContainerRef.value);
      }
    });

    return {
      videoPlayerContainerRef,
      videoPlayerRef,
      videoPlayer,
    };
  },
};
</script>

<style scoped>
@import 'video.js/dist/video-js.min.css';
</style>
