<template>
  <div class="border p-3">
    <div class="border-bottom pb-3">
      <h5 class="text-gold">
        {{ defaultTitle }}
      </h5>
      <p>{{ defaultDescription }}</p>

      <div class="d-flex">
        <ce-button
          type="button"
          class="btn-green text-white mb-3 mb-lg-0"
          @click="createNewDiscussion"
        >
          <span class="d-flex align-items-center">
            <span class="fas fa-pen-to-square fs-5" />
            <span class="ms-2 fw-normal">Start a Discussion</span>
          </span>
        </ce-button>
        <div class="ms-auto">
          <div class="dropdown">
            <button
              ref="dropdownToggleRef"
              type="button"
              class="btn btn-transparent dropdown-toggle normal-white-space"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              {{
                sortBy == 'new-discussion' ? 'Newest Discussions' : 'Hot Topics'
              }}
            </button>
            <ul class="dropdown-menu dropdown-menu-end shadow border-0">
              <li>
                <button
                  type="button"
                  class="dropdown-item"
                  @click="sort('new-discussion')"
                >
                  Newest Discussions
                </button>
              </li>
              <li>
                <button
                  type="button"
                  class="dropdown-item"
                  @click="sort('hot-topics')"
                >
                  Hot Topics
                </button>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>

    <discussion-container
      ref="discussionContainerRef"
      :entity-id="entityId"
      :type="type"
      :is-allowed-to-post="isAllowedToPost"
    />

    <create-discussion-modal
      ref="createDiscussionModalRef"
      :modal-id="`create-new-discussion`"
      :entity-id="entityId"
      :type="type"
      :is-allowed-to-post="isAllowedToPost"
      :disabled-message="disabledMessage"
      @newDiscussionCreated="newDiscussionCreated"
      @close="closeModalDiscussion"
    />
  </div>
</template>

<script>
import { ref, computed, inject, onMounted } from 'vue'
import Dropdown from 'bootstrap/js/dist/dropdown.js'
import DiscussionContainer from '@/components/Discussion/DiscussionContainer.vue'
import CreateDiscussionModal from '../Modal/CreateDiscussionModal.vue'
import CeButton from '../Buttons/CeButton.vue'
import $ from 'jquery'

export default {
  components: {
    DiscussionContainer,
    CreateDiscussionModal,
    CeButton
  },
  props: {
    entityId: {
      type: Number,
      required: true
    },
    type: {
      type: String,
      required: true
    },
    title: {
      type: String,
      required: false,
      default: 'Discussion Board'
    },
    description: {
      type: String,
      required: false,
      default: ''
    },
    isAllowedToPost: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  setup (props) {
    const title = ref(props.title)
    const description = ref(props.description)
    const sortBy = ref('new-discussion')
    const createDiscussionModalRef = ref(null)
    const baseLayoutRef = inject('baseLayoutRef')
    const discussionContainerRef = ref(null)

    const dropdownToggleRef = ref(null)
    const dropdownToggleInstance = ref(null)
    const disabledMessage = ref(
      props.type === 'event'
        ? 'Please RSVP first'
        : 'Only group members are allowed to participate on discussions'
    )

    /* Lifecycles */
    onMounted(() => {
      dropdownToggleInstance.value = new Dropdown(dropdownToggleRef.value)
    })

    const defaultTitle = computed({
      get: () =>
        !title.value
          ? `${
              props.type.charAt(0).toUpperCase() + props.type.slice(1)
            } Discussion Board`
          : title.value,
      set: (value) => {
        title.value = value
      }
    })

    const defaultDescription = computed({
      get: () =>
        !description.value
          ? "Introduce yourself to attendees by starting a discussion topic on what you're looking forward to doing and who you're looking forward to meeting at the event."
          : description.value,
      set: (value) => {
        defaultDescription.value = value
      }
    })

    const newDiscussionCreated = () => {
      discussionContainerRef.value.fetchDiscussions()
    }

    const sort = (value) => {
      if (value === sortBy.value) return

      sortBy.value = value
      discussionContainerRef.value.fetchDiscussions(value)
    }

    const createNewDiscussion = () => {
      $('#create-new-discussion').modal('show')
    }

    const closeModalDiscussion = () => {
      $('#create-new-discussion').modal('hide')
    }
    return {
      defaultTitle,
      defaultDescription,
      sortBy,
      createDiscussionModalRef,
      discussionContainerRef,
      baseLayoutRef,
      newDiscussionCreated,
      dropdownToggleRef,
      dropdownToggleInstance,
      sort,
      disabledMessage,
      createNewDiscussion,
      closeModalDiscussion
    }
  }
}
</script>
<style scoped>
.normal-white-space {
  white-space: normal !important;
}
.fa-pen-to-square {
  color: #FFF;
}
</style>
