<template>
  <form @submit.prevent="search()">
    <div ref="basicSearchContainerRef">
      <input
        v-model="searchForm.keyword"
        type="search"
        class="form-control form-control-sm"
        placeholder="Enter keyword"
      />
      <button
        type="submit"
        class="btn btn-gradient-gold w-100 me-md-2 mt-2 mb-2"
      >
        Search
      </button>

      <span
        type="button"
        class="btn-transparent color-gold text-center w-100"
        @click="reset()"
      >
        Reset All
      </span>
      <div class="flex-fill mt-2">
        <div class="p-3 p-md-0">
          <label class="d-block text-start small ls-1"
            >LOCATION (CITY & STATE)</label
          >
          <location-field v-model="computedLocation" />
        </div>
        <div v-show="computedLocation.lat" class="p-3 p-md-0 mt-2">
          <label class="d-block text-start small ls-1">DISTANCE</label>
          <ce-range v-model="computedDistance" :min="0" :max="100" fixed-start>
            <template #label="{ startValue, endValue }">
              <small>{{ startValue }} - {{ endValue }} Miles</small>
            </template>
          </ce-range>
        </div>

        <hr class="bg-light-gray mt-2" />
        <div>
          <browse-prefer-influencer-field v-model="searchForm.influencer" />
        </div>
        <hr class="bg-light-gray m-0" />
        <div>
          <browse-prefer-viewer-field v-model="searchForm.viewer" />
        </div>
        <hr class="bg-light-gray m-0" />
        <div>
          <browse-prefer-ethnicity-field v-model="searchForm.ethnicity" />
        </div>
        <hr class="bg-light-gray m-0" />
        <div>
          <browse-prefer-gender-field v-model="searchForm.gender" />
        </div>
        <hr class="bg-light-gray m-0" />
        <div>
          <browse-prefer-age-field v-model="computedAge" />
        </div>
        <hr class="bg-light-gray m-0" />
        <div>
          <browse-prefer-birthday-field v-model="computedBirthday" />
        </div>
        <hr class="bg-light-gray m-0" />
        <div>
          <browse-prefer-zodiac-sign-field v-model="searchForm.zodiacSign" />
        </div>
        <hr class="bg-light-gray m-0" />
        <div>
          <browse-prefer-smoking-field v-model="searchForm.smoking" />
        </div>
        <hr class="bg-light-gray m-0" />
        <div>
          <browse-prefer-favorite-field v-model="searchForm.favorite" />
        </div>
        <hr class="bg-light-gray m-0" />
        <div>
          <browse-prefer-drinking-field v-model="searchForm.drinking" />
        </div>
        <hr class="bg-light-gray m-0" />
        <div>
          <browse-prefer-children-field v-model="searchForm.children" />
        </div>
        <hr class="bg-light-gray m-0" />
        <div>
          <browse-prefer-education-level-field
            v-model="searchForm.educationLevel"
          />
        </div>
        <hr class="bg-light-gray m-0" />
        <div>
          <browse-prefer-relationship-status-field
            v-model="searchForm.relationshipStatus"
          />
        </div>
        <hr class="bg-light-gray m-0" />
        <div>
          <browse-prefer-body-type-field v-model="searchForm.bodyType" />
        </div>
        <hr class="bg-light-gray m-0" />
        <div>
          <browse-prefer-height-field v-model="computedHeight" />
        </div>
      </div>
    </div>
  </form>
</template>
<script>
import { computed, inject, onMounted, provide, ref, watch } from "vue";
import useAge from "@/Use/use-age.js";
import useHeight from "@/Use/use-height.js";
import useUrlQueryParams from "@/Use/use-url-query-params.js";
import intersection from "lodash/intersection";
// import snakeCase from 'lodash/snakeCase';
// import omitBy from 'lodash/omitBy';
import debounce from "lodash/debounce";
import LocationField from "./SingleComponent/LocationField.vue";
import BrowsePreferInfluencerField from "./Browse/BrowsePreferInfluencerField.vue";
import BrowsePreferGenderField from "./Browse/BrowsePreferGenderField.vue";
import BrowsePreferEthnicityField from "./Browse/BrowsePreferEthnicityField.vue";
import BrowsePreferViewerField from "./Browse/BrowsePreferViewerField.vue";
import BrowsePreferAgeField from "./Browse/BrowsePreferAgeField.vue";
import BrowsePreferBirthdayField from "./Browse/BrowsePreferBirthdayField.vue";
import BrowsePreferZodiacSignField from "./Browse/BrowsePreferZodiacSignField.vue";
import BrowsePreferSmokingField from "./Browse/BrowsePreferSmokingField.vue";
import BrowsePreferFavoriteField from "./Browse/BrowsePreferFavoriteField.vue";
import BrowsePreferDrinkingField from "./Browse/BrowsePreferDrinkingField.vue";
import BrowsePreferChildrenField from "./Browse/BrowsePreferChildrenField.vue";
import BrowsePreferEducationLevelField from "./Browse/BrowsePreferEducationLevelField.vue";
import BrowsePreferRelationshipStatusField from "./Browse/BrowsePreferRelationshipStatusField.vue";
import BrowsePreferBodyTypeField from "./Browse/BrowsePreferBodyTypeField.vue";
import BrowsePreferHeightField from "./Browse/BrowsePreferHeightField.vue";
import CeRange from "./Inputs/CeRange.vue";

export default {
  components: {
    LocationField,
    BrowsePreferInfluencerField,
    BrowsePreferGenderField,
    BrowsePreferEthnicityField,
    BrowsePreferViewerField,
    BrowsePreferBirthdayField,
    BrowsePreferAgeField,
    BrowsePreferZodiacSignField,
    BrowsePreferSmokingField,
    BrowsePreferFavoriteField,
    BrowsePreferRelationshipStatusField,
    BrowsePreferBodyTypeField,
    BrowsePreferDrinkingField,
    BrowsePreferChildrenField,
    BrowsePreferEducationLevelField,
    CeRange,
    BrowsePreferHeightField,
  },
  emits: ["search", "reset"],
  setup(props, { emit }) {
    const {
      toString: heightInString,
      min: minHeight,
      max: maxHeight,
    } = useHeight();
    const localValue = ref([minHeight, maxHeight]);
    const browseLabelRef = ref(null);
    provide("browseLabel", browseLabelRef);
    const { queryParamsObject } = useUrlQueryParams();
    const isReady = ref(false);
    const basicSearchContainerRef = ref(null);
    const advanceSearchContainerRef = ref(null);
    const { min: minAge, max: maxAge } = useAge();
    const searchDebounceWait = 750;
    const isResetting = ref(false);
    const formPage = ref(window.location.pathname);

    const fallbackValues = {
      keyword: "",
      month: "",
      day: "",
      city: "",
      state: "",
      zipCode: "",
      lat: null,
      lng: null,
      distance: 100,
      influencer: false,
      eventCheckedIn: 0,
      eventRsvpStatus: 0,
      eventInvite: 0,
      userFlagged: 0,
      incomeLevel: [],
      ethnicity: [],
      gender: [],
      ageFrom: minAge,
      ageTo: maxAge,
      zodiacSign: [],
      smoking: 0,
      drinking: [],
      children: 0,
      educationLevel: [],
      relationshipStatus: [],
      bodyType: [],
      heightFrom: minHeight,
      heightTo: maxHeight,
      date: "",
    };

    const searchFormValues = computed(() => ({
      keyword:
        props.searchParams?.keyword ||
        props.defaultValues?.keyword ||
        fallbackValues.keyword,
      month:
        props.searchParams?.month ||
        props.defaultValues?.month ||
        fallbackValues.month,
      day:
        props.searchParams?.day ||
        props.defaultValues?.day ||
        fallbackValues.day,
      city:
        props.searchParams?.city ||
        props.defaultValues?.city ||
        fallbackValues.city,
      state:
        props.searchParams?.state ||
        props.defaultValues?.state ||
        fallbackValues.state,
      zipCode:
        props.searchParams?.zip_code ||
        props.defaultValues?.zip_code ||
        fallbackValues.zipCode,
      lat:
        props.searchParams?.lat ||
        props.defaultValues?.lat ||
        fallbackValues.lat,
      lng:
        props.searchParams?.lng ||
        props.defaultValues?.lgn ||
        fallbackValues.lng,
      distance:
        props.searchParams?.distance ||
        props.defaultValues?.distance ||
        fallbackValues.distance,
      viewer:
        !!props.searchParams?.viewer || !!props.defaultValues?.viewer || false,
      favorite:
        !!props.searchParams?.favorite ||
        !!props.defaultValues?.favorite ||
        false,
      influencer:
        props.searchParams?.influencer !== undefined
          ? props.searchParams?.influencer === "true"
          : !!props.defaultValues?.influencer || fallbackValues.influencer,
      eventCheckedIn:
        props.searchParams?.event_checked_in ||
        props.defaultValues?.event_checked_in ||
        fallbackValues.eventCheckedIn,
      eventRsvpStatus:
        props.searchParams?.event_rsvp_status ||
        props.defaultValues?.event_rsvp_status ||
        fallbackValues.eventRsvpStatus,
      eventInvite:
        props.searchParams?.event_invite ||
        props.defaultValues?.event_invite ||
        fallbackValues.eventInvite,
      userFlagged:
        props.searchParams?.user_flagged ||
        props.defaultValues?.user_flagged ||
        fallbackValues.userFlagged,
      incomeLevel:
        props.searchParams?.income_level ||
        props.defaultValues?.income_level ||
        fallbackValues.incomeLevel,
      ethnicity:
        props.searchParams?.ethnicity ||
        props.defaultValues?.ethnicity ||
        fallbackValues.ethnicity,
      gender:
        props.searchParams?.gender ||
        props.defaultValues?.gender ||
        fallbackValues.gender,
      ageFrom:
        props.searchParams?.age_from ||
        props.defaultValues?.age_from ||
        fallbackValues.ageFrom,
      ageTo:
        props.searchParams?.age_to ||
        props.defaultValues?.age_to ||
        fallbackValues.ageTo,
      zodiacSign:
        props.searchParams?.zodiac_sign ||
        props.defaultValues?.zodiac_sign ||
        fallbackValues.zodiacSign,
      smoking:
        props.searchParams?.smoking ||
        props.defaultValues?.smoking ||
        fallbackValues.smoking,
      drinking:
        props.searchParams?.drinking ||
        props.defaultValues?.drinking ||
        fallbackValues.drinking,
      children:
        props.searchParams?.children ||
        props.defaultValues?.children ||
        fallbackValues.children,
      educationLevel:
        props.searchParams?.education_level ||
        props.defaultValues?.education_level ||
        fallbackValues.educationLevel,
      relationshipStatus:
        props.searchParams?.relationship_status ||
        props.defaultValues?.relationship_status ||
        fallbackValues.relationshipStatus,
      bodyType:
        props.searchParams?.body_type ||
        props.defaultValues?.body_type ||
        fallbackValues.bodyType,
      heightFrom:
        props.searchParams?.height_from ||
        props.defaultValues?.height_from ||
        fallbackValues.heightFrom,
      heightTo:
        props.searchParams?.height_to ||
        props.defaultValues?.height_to ||
        fallbackValues.heightTo,
      date:
        props.searchParams?.date ||
        props.defaultValues?.date ||
        fallbackValues.date,
    }));
    const searchForm = ref(searchFormValues.value);

    const computedLocation = computed({
      get: () => {
        return {
          city: searchForm.value.city,
          state: searchForm.value.state,
          zipCode: searchForm.value.zipCode,
          lat: searchForm.value.lat,
          lng: searchForm.value.lng,
        };
      },
      set: (newLocation) => {
        searchForm.value.city = newLocation.city || "";
        searchForm.value.state = newLocation.state || "";
        searchForm.value.zipCode = newLocation.zipCode || "";
        searchForm.value.lat =
          newLocation.lat === undefined ? null : newLocation.lat;
        searchForm.value.lng =
          newLocation.lng === undefined ? null : newLocation.lng;
      },
    });

    const computedDistance = computed({
      get: () => {
        return [0, searchForm.value.distance];
      },
      set: ([, newDistance]) => {
        searchForm.value.distance = newDistance;
      },
    });

    const computedAge = computed({
      get: () => {
        return [searchForm.value.ageFrom, searchForm.value.ageTo];
      },
      set: ([newAgeFrom, newAgeTo]) => {
        searchForm.value.ageFrom = newAgeFrom;
        searchForm.value.ageTo = newAgeTo;
      },
    });

    const computedBirthday = computed({
      get: () => {
        return { month: searchForm.value.month, day: searchForm.value.day };
      },
      set: (birthday) => {
        searchForm.value.month = birthday.month;
        searchForm.value.day = birthday.day;
      },
    });

    const computedHeight = computed({
      get: () => {
        return [searchForm.value.heightFrom, searchForm.value.heightTo];
      },
      set: ([newHeightFrom, newHeightTo]) => {
        searchForm.value.heightFrom = newHeightFrom;
        searchForm.value.heightTo = newHeightTo;
      },
    });

    const isAdvanceSearchOpen = ref(false);

    const isLocationFieldVisible = computed(
      () =>
        props.fields.length === 0 ||
        intersection(props.fields, ["city", "state", "zipCode", "lat", "lng"])
          .length > 0
    );

    // const isSearchRequest = ref(false);

    const reset = async () => {
      searchForm.value = [];
      computedLocation.value = [];
      computedDistance.value = [];
      computedAge.value = [];
      computedBirthday.value = [];
      computedHeight.value = [];
      emit("reset");
    };
    /* Methods */
    const search = () => {
      // const searchValue = searchForm.value;
      const newSearch = searchForm.value;

      const arr = [];
      Object.keys(newSearch).map((key) => {
        let newVal = null;
        if (Array.isArray(newSearch[key]) && newSearch[key]?.length > 0) {
          newSearch[key].forEach((value) => {
            newVal = snakeCaseString(key) + "[]=" + value;
            arr.push(newVal);
          });
        } else {
          if (key === "keyword") {
            newVal = snakeCaseString(key) + "=" + newSearch[key];
            arr.push(newVal);
          }
        }
      });

      emit("search", arr.toString().replace(/,/g, "&"));
    };

    const snakeCaseString = (str) => {
      return (
        str &&
        str
          .match(
            /[A-Z]{2,}(?=[A-Z][a-z]+[0-9]*|\b)|[A-Z]?[a-z]+[0-9]*|[A-Z]|[0-9]+/g
          )
          .map((s) => s.toLowerCase())
          .join("_")
      );
    };

    watch(
      [
        () => computedLocation.value,
        () => computedDistance.value,
        () => searchForm.value.keyword,
        () => searchForm.value.viewer,
        () => searchForm.value.favorite,
        () => searchForm.value.influencer,
        () => searchForm.value.eventCheckedIn,
        () => searchForm.value.eventRsvpStatus,
        () => searchForm.value.eventInvite,
        () => searchForm.value.incomeLevel,
        () => searchForm.value.ethnicity,
        () => searchForm.value.gender,
        () => computedAge.value,
        () => computedBirthday.value,
        () => searchForm.value.zodiacSign,
        () => searchForm.value.smoking,
        () => searchForm.value.drinking,
        () => searchForm.value.children,
        () => searchForm.value.educationLevel,
        () => searchForm.value.relationshipStatus,
        () => searchForm.value.bodyType,
        () => computedHeight.value,
        () => searchForm.value.date,
      ],
      debounce(() => {
        search();
      }, searchDebounceWait),
      { deep: true }
    );

    watch(
      () => props.searchParams,
      () => {
        Object.assign(searchForm.value, searchFormValues.value);
      },
      {
        deep: true,
      }
    );

    /* Lifecycles */
    onMounted(() => {
      isReady.value = true;
    });

    return {
      minHeight,
      maxHeight,
      heightInString,
      localValue,
      browseLabelRef,
      isReady,
      queryParamsObject,
      computedLocation,
      basicSearchContainerRef,
      advanceSearchContainerRef,
      searchDebounceWait,
      isResetting,
      formPage,
      isAdvanceSearchOpen,
      isLocationFieldVisible,
      computedDistance,
      computedAge,
      computedBirthday,
      computedHeight,
      search,
      searchForm,
      reset,
      snakeCaseString,
    };
  },
};
</script>
