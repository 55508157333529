<template>
  <div>
    <template v-if="!hasInvited">
      <div class="card-body">
        <div class="container">
          <h1 class="fs-1 text-center">
            Don't forget to invite the rest of your friends!
          </h1>
        </div>
      </div>
      <template v-if="myFriends.data?.length > 0 || true">
        <group-invite-friends-form
          class="form-1"
          :group-id="groupForm.id"
          :my-friends="myFriends.data"
          :my-friends-total-count="myFriends.data?.length"
          :has-more-friends="myFriends.links?.next"
          @success="hasInvited = true"
          @skip="viewGroup"
        >
          <template #submit="{ submit, isSubmitting }">
            <div class="row">
              <template v-if="!isSubmitting">
                <div class="col-6 col-sm-6 mb-3">
                  <ce-button
                    class="btn-lg btn btn-gray text-white text-bold w-100"
                    @click="cancelGroup()"
                  >
                    Cancel
                  </ce-button>
                </div>
                <div class="col-6 col-sm-6 mb-3">
                  <ce-button
                    class="btn-lg btn-gradient-gold text-bold w-100"
                    :is-loading="isSubmitting"
                    :disabled="isSubmitting"
                    @click="submit"
                  >
                    Continue
                  </ce-button>
                </div>
              </template>
              <template v-else>
                <div class="col-12 mb-3">
                  <ce-button
                    class="btn-lg btn-gradient-gold text-bold w-100"
                    :is-loading="isSubmitting"
                    :disabled="isSubmitting"
                    @click="submit"
                  >
                    Continue
                  </ce-button>
                </div>
              </template>
            </div>
          </template>
        </group-invite-friends-form>
      </template>
      <template v-else>
        <div class="card-body">
          <div class="container">
            <h1 class="fs-1 ff-merriweather fw-bold mb-3 text-center" />
          </div>
        </div>
        <div class="card-footer text-muted form-footer">
          <div class="container">
            <div class="row">
              <div class="col-12 col-sm-12 mb-3">
                <ce-button
                  class="btn-lg btn-gradient-gold text-bold w-100"
                  @click="viewGroup()"
                >
                  Cancel
                </ce-button>
              </div>
            </div>
          </div>
        </div>
      </template>
    </template>
    <template v-else>
      <div class="card-body">
        <div class="container">
          <h1 class="fs-1 text-center">
            Congratulations,<br />your invites have been sent!
          </h1>
          <img src="../../assets/images/invites-sent.svg" class="w-100" />
        </div>
      </div>
      <div class="card-footer text-muted form-footer">
        <div class="container">
          <div class="row">
            <div class="col-6 col-sm-6 mb-3">
              <ce-button
                class="btn-lg btn-gradient-gold text-bold w-100"
                @click="viewGroup()"
              >
                Visit Group
              </ce-button>
            </div>
            <div class="col-6 col-sm-6 mb-3">
              <ce-button
                class="btn-lg btn-gradient-gold text-bold w-100"
                @click="cancelGroup()"
              >
                Return to Admin Panel
              </ce-button>
            </div>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import { defineAsyncComponent, computed, ref } from "vue";
import { useStore } from "vuex";
import { useRoute, useRouter } from "vue-router";

export default {
  components: {
    GroupInviteFriendsForm: defineAsyncComponent(() =>
      import("@/Modules/Group/GroupInviteFriendsForm.vue")
    ),
  },
  props: {
    id: {
      type: Number,
      default: null,
    },
    slug: {
      type: String,
      default: null,
    },
    pastEvents: {
      type: Object,
      default: () => {},
    },
    backUrl: {
      type: String,
      default: "",
    },
  },
  emits: ["createGroup", "isLoading", "cancelEdit"],
  async setup(props, { emit }) {
    const store = useStore();
    const router = useRouter();

    const hasInvited = ref(false);

    const slug = ref(router.currentRoute._value.params.slug);

    emit("isLoading", true);
    const getGroupInfoResource = await store.dispatch("group/groupEdit", {
      slug: slug.value,
      api: "review-and-publish",
    });
    const getInviteFriendsResource = await store.dispatch(
      "group/inviteFriends",
      { slug: slug.value }
    );
    emit("isLoading", false);

    const myFriends = computed(() => getInviteFriendsResource);

    const groupForm = computed(() => getGroupInfoResource.data);

    const publishedJustNow = computed(
      () => getInviteFriendsResource.data.publishedJustNow
    );

    const viewGroup = () => {
      router.replace(`/group/${slug.value}/preview`);
    };

    const cancelGroup = () => {
      emit("cancelEdit");
    };

    return {
      hasInvited,
      groupForm,
      publishedJustNow,
      myFriends,
      slug,
      viewGroup,
      cancelGroup,
    };
  },
};
</script>

<style lang="scss" scoped>
@import "../../scss/customizations";

:deep() {
  @import "../../scss/form/form-1";
}

.container {
  max-width: 680px;
}
.form-footer {
  background-color: #3a3b44;
  color: #fff;
  min-height: 83px;
}
.form-footer .ce-buttons-button {
  min-height: 50px;
}
</style>
