<template>
  <div class="dataTables_paginate paging_simple_numbers">
    <ul class="pagination">
      <li
        class="paginate_button page-item"
        :class="isInFirstPage ? 'disabled' : ''"
      >
        <button
          type="button"
          class="page-link"
          :disabled="isInFirstPage"
          @click="onClickPreviousPage"
        >
          Previous
        </button>
      </li>

      <!-- Visible Buttons Start -->

      <li
        v-for="page in pages"
        :key="page.name"
        class="paginate_button page-item"
      >
        <button
          class="page-link"
          type="button"
          :disabled="page.isDisabled"
          :class="{ active: isPageActive(page.name) }"
          @click="onClickPage(page.name)"
        >
          {{ page.name }}
        </button>
      </li>

      <!-- Visible Buttons End -->

      <li
        class="paginate_button page-item"
        :class="isInLastPage ? 'disabled' : ''"
      >
        <button
          class="page-link"
          type="button"
          :disabled="isInLastPage"
          @click="onClickNextPage"
        >
          Next
        </button>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  props: {
    maxVisibleButtons: {
      type: Number,
      required: false,
      default: 5,
    },
    totalPages: {
      type: Number,
      required: true,
      default: 1,
    },
    perPage: {
      type: Number,
      required: true,
    },
    currentPage: {
      type: Number,
      required: true,
    },
  },
  computed: {
    startPage() {
      // When on the first page
      if (this.currentPage === 1) {
        return 1;
      }

      // When on the last page
      if (this.currentPage === this.totalPages) {
        return this.totalPages - this.maxVisibleButtons;
      }

      // When inbetween
      return this.currentPage - 1;
    },
    pages() {
      const range = [];
      const newMax = this.maxVisibleButtons - 1;
      let sPage = this.startPage;
      let length = 0;
      if (this.maxVisibleButtons > this.totalPages) {
        sPage = 1;
        length = Math.min(
          this.startPage + this.maxVisibleButtons,
          this.totalPages
        );
      } else {
        length = Math.min(this.startPage + newMax, this.totalPages);
      }

      for (let i = sPage; i <= length; i++) {
        range.push({
          name: i,
          isDisabled: i === this.currentPage,
        });
      }

      return range;
    },
    isInFirstPage() {
      return this.currentPage === 1;
    },
    isInLastPage() {
      return this.currentPage === this.totalPages;
    },
  },
  methods: {
    onClickPreviousPage() {
      this.$emit("pagechanged", this.currentPage - 1);
    },
    onClickPage(page) {
      this.$emit("pagechanged", page);
    },
    onClickNextPage() {
      this.$emit("pagechanged", this.currentPage + 1);
    },
    isPageActive(page) {
      return this.currentPage === page;
    },
  },
};
</script>
<style>
.pagination {
  list-style-type: none;
  margin: 2px 0;
  white-space: nowrap;
  justify-content: flex-end;
}

.pagination-item {
  display: inline-block;
}

.active.page-link {
  z-index: 3;
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}
</style>
