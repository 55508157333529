<template>
  <div class="photo-gallery">
    <div class="photos-grid">
      <slot name="items" :items="galleryPhotos">
        <template v-for="(item, itemIndex) in galleryPhotos" :key="itemIndex">
          <slot
            :name="`item-${itemIndex}`"
            :item="item"
            :item-index="itemIndex"
          >
            <photo-gallery-item
              :photo="item"
              :empty-photo-url="emptyPhotoUrl"
              :image-key="imageKey"
              :image-index="itemIndex + 1"
              :is-edit-photo="isEditPhoto"
              @itemCheckUpdate="itemCheckUpdate"
            />
          </slot>
        </template>
      </slot>
    </div>
  </div>
</template>

<script>
import { computed, ref } from "vue";
import map from "lodash/map";
import filter from "lodash/filter";
import CeModal from "@/components/Modal/CeModal.vue";
import PhotoGalleryItem from "@/components/Galleries/PhotoGalleryItem.vue";

export default {
  name: "PhotoGallery",
  components: {
    PhotoGalleryItem,
    CeModal,
  },
  props: {
    photos: {
      type: Array,
      required: true,
    },
    isEditPhoto: {
      type: Boolean,
      required: false,
      default: false,
    },
    editable: {
      type: Boolean,
      required: false,
      default: false,
    },
    emptyPhotoUrl: {
      type: String,
      required: false,
      default:
        process.env.VUE_APP_API_BASE + "/images/defaults/gender-other.png",
    },
    imageKey: {
      type: String,
      required: false,
      default: "original",
    },
    imageKeyFull: {
      type: String,
      required: false,
      default: "full",
    },
  },
  emits: ["galleryCheckUpdate"],
  setup(props, { emit }) {
    const imageKey = computed(() => props.imageKey);

    const imageKeyFull = computed(() => props.imageKeyFull);
    const galleryPhotos = computed(() =>
      filter(props.photos, (o) => !!o[imageKey.value])
    );

    const images = computed(() =>
      map(
        galleryPhotos.value,
        (o) => o[imageKeyFull.value] || props.emptyPhotoUrl
      )
    );

    const itemCheckUpdate = (event) => {
      emit("galleryCheckUpdate", event);
    };

    return {
      images,
      galleryPhotos,
      itemCheckUpdate,
    };
  },
};
</script>

<style scoped lang="scss">
@import "~bootstrap/scss/bootstrap-utilities";

.photos-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1rem;

  @include media-breakpoint-up(md) {
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  }
}
</style>
