<template>
  <div class="card card-success">
    <div class="card-body">
      <h3 class="fw-bold color-black">Feature Blocker</h3>
    </div>
  </div>
  <feature-blocker-table
    @done="isDone($event)"
  />
  <div id="confirm" />
</template>

<script>
import { ref } from "vue";
import FeatureBlockerTable from "@/components/Tables/FeatureBlockerTable.vue";
export default {
  name: "App",
  components: {
    FeatureBlockerTable,
  },
  setup() {
    const dataTableUser = ref("");
    const isLoading = ref(false);

    const isDone = (event) => {
      isLoading.value = event;
    };

    return {
      dataTableUser,
      isLoading,
      isDone,
    };
  },
};
</script>

<style scoped>
.downloadAndMessageAlign {
  text-align: center;
  position: inherit;
  margin-top: 15px;
}
.dropdown-menu-lg {
  min-width: auto;
}
.table td,
.table th {
  border-top: 0 !important;
}
.table td {
  vertical-align: middle;
}
.custom-control-label {
  font-weight: 500 !important;
}
.send {
  color: rgb(4, 98, 193);
}
.selected-message-type {
  background-color: #333;
  color: #cacaca !important;
}
</style>
