<template>
  <Preloader :is-loading="isLoading"></Preloader>

  <div>
    <div class="card card-success mb-4">
      <div class="card-body">
        <header-stories-component
          :title="`Reset Password`"
          :search-for="`users`"
          :show-search="false"
          :show-total="false"
        />
      </div>
    </div>
    <div class="card card-success mb-4">
      <div class="card-body">
        <div class="row">
          <div class="col-md-12 col-lg-8 col-xl-3">
            <div class="row">
              <user-info-component
                :name="`${account.first_name} ${account.last_name}`"
                :src="`${account.primary_photo?.sizes?.thumbnail}`"
                :account-type="`${accountType}`"
              />
            </div>
          </div>
        </div>
      </div>
    </div>

    <form @submit.prevent="submitPassword">
      <template v-if="resetType == 'create-password'">
        <div class="row" style="margin-left: 1.25rem">
          <h1 class="fs-6 fw-bold color-gray">CREATE PASSWORD</h1>
        </div>
        <div class="card card-success mb-4">
          <div class="card-body">
            <div class="row mt-3">
              <div class="col-4 col-sm-4">
                <span for="exampleInputEmail1">Create Password</span>
                <input
                  type="password"
                  class="form-control"
                  v-model="userPassword"
                  @blur="$v.userPassword.$touch()"
                />
                <template v-if="$v.userPassword.$error && !isSubmitted">
                  <div class="form-text text-red mt-3">
                    <p
                      v-for="$error in $v.userPassword.$errors"
                      :key="$error.$property"
                    >
                      {{ $error.$message }}
                    </p>
                  </div>
                </template>
              </div>
            </div>
            <div class="row mt-3">
              <div class="col-4 col-sm-4">
                <span for="exampleInputPassword1">Confirm Password</span>
                <input
                  type="password"
                  class="form-control"
                  v-model="userConfirmPassword"
                  @blur="$v.userConfirmPassword.$touch()"
                />
                <template v-if="$v.userConfirmPassword.$error && !isSubmitted">
                  <div class="form-text text-red mt-3">
                    <p
                      v-for="$error in $v.userConfirmPassword.$errors"
                      :key="$error.$property"
                    >
                      {{ $error.$message }}
                    </p>
                  </div>
                </template>
              </div>
            </div>
            <div class="row mt-3">
              <div class="col-4 col-sm-4">
                <span for="exampleInputPassword1" class="fs-6 fw-bold"
                  >Email the new password to the following new recipients*</span
                >
                <input
                  type="text"
                  class="form-control"
                  v-model="userEmail"
                  @blur="$v.userEmail.$touch()"
                />
                <template v-if="$v.userEmail.$error && !isSubmitted">
                  <div class="form-text text-red mt-3">
                    <p
                      v-for="$error in $v.userEmail.$errors"
                      :key="$error.$property"
                    >
                      {{ $error.$message }}
                    </p>
                  </div>
                </template>
              </div>
            </div>
          </div>

          <div class="card-footer form-footer bg-transparent">
            <div>
              <div class="row">
                <div class="col-4"></div>
                <div class="col-4"></div>
                <div class="col-4">
                  <div class="row">
                    <div class="col-6 col-sm-6">
                      <button
                        class="btn-lg btn btn-gray text-white text-bold w-100"
                        type="button"
                        @click="cancelResetPassword"
                      >
                        Cancel
                      </button>
                    </div>
                    <div class="col-6 col-sm-6">
                      <button
                        class="btn-lg btn btn-green text-white text-bold w-100"
                        type="submit"
                        :disabled="$v.$invalid"
                      >
                        Send Email
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>
      <template v-else-if="resetType == 'auto-generate-password'">
        <div class="row" style="margin-left: 1.25rem">
          <div class="col-4 col-sm-4">
            <h1 class="fs-6 fw-bold color-gray">AUTO GENERATE PASSWORD</h1>
          </div>
          <div class="col-4 col-sm-4">
            <h1 class="fs-6 fw-bold color-gray">PASSWORD</h1>
          </div>
          <div class="col-4 col-sm-4"></div>
        </div>
        <div class="card card-success mb-4">
          <div class="card-body">
            <div class="row mt-3">
              <div class="col-4 col-sm-4">
                <h1 class="fs-6 fw-bold">{{ account.email }}</h1>
              </div>
              <div class="col-4 col-sm-4">
                <h1 class="fs-6 fw-bold ml-2">{{ userPassword }}</h1>
              </div>
            </div>
            <div class="row mt-3">
              <div class="col-4 col-sm-4">
                <span for="exampleInputPassword1" class="fs-6 fw-bold"
                  >Email the new password to the following new recipients*</span
                >
                <input
                  type="text"
                  class="form-control"
                  v-model="userEmail"
                  @blur="$v.userEmail.$touch()"
                />
                <template v-if="$v.userEmail.$error">
                  <div class="form-text text-red mt-3">
                    <p
                      v-for="$error in $v.userEmail.$errors"
                      :key="$error.$property"
                    >
                      {{ $error.$message }}
                    </p>
                  </div>
                </template>
              </div>
            </div>
          </div>
          <div class="card-footer form-footer bg-transparent">
            <div>
              <div class="row">
                <div class="col-4"></div>
                <div class="col-4"></div>
                <div class="col-4">
                  <div class="row">
                    <div class="col-6 col-sm-6">
                      <button
                        class="btn-lg btn btn-gray text-white text-bold w-100"
                        type="button"
                        @click="cancelResetPassword"
                      >
                        Cancel
                      </button>
                    </div>
                    <div class="col-6 col-sm-6">
                      <button
                        class="btn-lg btn btn-green text-white text-bold w-100"
                        type="submit"
                        :disabled="$v.$invalid"
                      >
                        Send Email
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>
    </form>
  </div>

  <modal-component
    :modal-id="`success-modal`"
    :header-style="`bg-linear`"
    :size="`modal-md`"
    :profile="false"
  >
    <template v-slot:modalBody>
      <div class="modal-body">
        <div class="mr-4 ml-4">
          <h3 class="row text-center text-break">
            <strong>
              <p>Password successfully sent to {{ account.email }}</p>
            </strong>
          </h3>
        </div>
        <center class="mt-3">
          <button
            type="submit"
            class="btn-gradient-gold mt-2 mb-2 close-gradient-gold-color"
            @click="closeModal"
          >
            Close
          </button>
        </center>
      </div>
    </template>
  </modal-component>
</template>
<script>
import HeaderStoriesComponent from "@/components/HeaderStoriesComponent.vue";
import UserInfoComponent from "@/components/UserInfoComponent.vue";
import Preloader from "@/Layouts/Common/Preloader.vue";
import { onMounted, ref, reactive, toRefs, inject } from "vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import ModalComponent from "../../components/ModalComponent.vue";

import useVuelidate from "@vuelidate/core";
import {
  required,
  helpers,
  email,
  minLength,
  maxLength,
  sameAs,
} from "@vuelidate/validators";

export default {
  name: "App",
  components: {
    HeaderStoriesComponent,
    UserInfoComponent,
    Preloader,
    ModalComponent,
  },
  setup() {
    const dialog = inject("dialog");
    const account = ref({});
    const router = useRouter();
    const store = useStore();
    const userName = ref(router.currentRoute.value.params.user_name);
    const resetType = ref(router.currentRoute.value.params.reset_type);
    const userId = ref(null);
    const accountType = ref(null);
    const isLoading = ref(false);
    const emailToSend = ref(null);

    const isSubmitted = ref(false);

    const state = reactive({
      userEmail: "",
      userPassword: "",
      userConfirmPassword: "",
    });

    const stateAsRefs = toRefs(state);

    const rules = {
      userEmail: {
        required,
        email,
        $autoDirty: true,
      },
      userPassword: {
        required,
        minLength: minLength(8),
        maxLength: maxLength(1000),
      },
      userConfirmPassword: {
        sameAsPassword: helpers.withMessage(
          "The value must be equal to Created Password",
          sameAs(stateAsRefs.userPassword)
        ),
      },
    };

    const $v = useVuelidate(rules, state);

    const handleBlur = (key) => {
      $v[key].$dirty = true;
    };

    onMounted(() => {
      const getUser = store.state.user.userList.find(
        (user) => user.user_name === userName.value
      );
      accountType.value =
        getUser.subscription_type.charAt(0).toUpperCase() +
        getUser.subscription_type.slice(1) +
        " Account";
      userId.value = getUser.id;
      account.value = getUser;

      stateAsRefs.userEmail.value = getUser.email;

      if (resetType.value == "auto-generate-password") {
        generatePassword();
      }
    });

    const submitPassword = async () => {
      $v.$dirty = true;
      if (!$v.$error) {
        try {
          isLoading.value = true;
          const form = {
            id: userId.value,
            email: stateAsRefs.userEmail.value,
            password: stateAsRefs.userPassword.value,
          };
          emailToSend.value = stateAsRefs.userEmail.value;
          const response = await store.dispatch("updatePassword", form);
          if (response.status === 200) {
            isLoading.value = false;
            isSubmitted.value = true;
            stateAsRefs.userPassword.value = "";
            stateAsRefs.userConfirmPassword.value = "";
            $("#success-modal").modal({
              backdrop: "static",
              keyboard: false,
            });
          }
        } catch (exception) {
          emit("fail", exception);
          if (exception.response?.status === 422) {
            dialog.show("Please make sure you enter valid details.", {
              title: "Invalid Details",
            });
          }
          isSubmitted.value = false;
        } finally {
          isLoading.value = false;
          isSubmitted.value = true;
        }
      }

      return true;
    };

    const generatePassword = () => {
      const randomPass = Math.random().toString(20).substr(2, 10);

      stateAsRefs.userPassword.value = randomPass;
      stateAsRefs.userConfirmPassword.value = randomPass;
    };

    const closeModal = () => {
      $("#success-modal").modal("hide");
      router.push(localStorage.getItem("prevURL") ?? `/manage-users`);
    };

    const cancelResetPassword = () => {
      router.push(localStorage.getItem("prevURL") ?? `/manage-users`);
    };

    return {
      account,
      userName,
      resetType,
      accountType,
      submitPassword,
      userId,
      isLoading,
      generatePassword,
      closeModal,
      emailToSend,
      handleBlur,
      ...stateAsRefs,
      $v,
      isSubmitted,
      cancelResetPassword,
    };
  },
};
</script>
<style scoped>
.form-control:focus {
  box-shadow: none;
}
.btn:focus,
.info-box {
  box-shadow: none;
}
.info-box {
  margin-bottom: 0;
  min-height: 0;
}
.color-gold {
  color: #b4914d;
  text-decoration: none;
  font-weight: 600;
}
</style>
