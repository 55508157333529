export default () => {
  const CHAT_TYPES = Object.freeze({
    PRIVATE: 0, // Personal message
    EVENT: 1,
    GROUP: 2,
    EVENT_INQUIRY: 3, // For user who is not a member of the event and use the chat, it will be tag as inquiry only, to separate the user from the event group chat
    CUSTOM_GROUP: 4,
    PERSONAL_MESSAGE_EVENTS: 5, // Personal message events
    PERSONAL_MESSAGE_GROUPS: 6, // Personal message groups
    TALK: 7, // Personal message groups
    PERSONAL_MESSAGE_TO_ALL_USER: 8, // Personal message groups
    // Chat tabs
    INBOX: 1,
    LIVE_CHATS: 2
  })

  return {
    CHAT_TYPES
  }
}
