
import ManageGroup from '@/Pages/Group/ManageGroup.vue'
import ManageFlagGroup from '@/Pages/Group/ManageFlagGroup.vue'

import GroupAction from '@/Pages/Report/UserAction.vue'
import PreviewGroup from '@/Pages/Group/PreviewGroup.vue'

import AddGroup from '@/Pages/Group/AddGroup.vue'

import EditGroup from '@/Pages/Group/EditGroup.vue'
import EditName from '@/Pages/Group/EditName.vue'
import EditCategoryInterest from '@/Pages/Group/EditCategoryInterest.vue'
import EditDescription from '@/Pages/Group/EditDescription.vue'
import EditMedia from '@/Pages/Group/EditMedia.vue'
import EditAdmin from '@/Pages/Group/EditAdmin.vue'

import ReviewAndPublish from '@/Pages/Group/ReviewAndPublish.vue'

import InvitePastEvents from '@/Pages/Group/InvitePastEvents.vue'
import InviteFriends from '@/Pages/Group/InviteFriends.vue'

export default [{
        path: '/manage-groups',
        name: 'ManageGroup',
        props: { moduleName: `group` },
        component: ManageGroup
    },
    {
        path: '/group/flag',
        props: { moduleName: `group` },
        component: ManageFlagGroup
    },
    {
        path: '/group/add',
        props: { moduleName: `group` },
        component: AddGroup
    },
    {
        path: '/group/:slug/edit',
        name: 'group-edit',
        props: { moduleName: `group` },
        component: EditGroup,
        children: [{
                path: '',
                name: 'group-edit-index',
                component: ReviewAndPublish
            },
            {
                path: 'name',
                name: 'group-edit-name',
                component: EditName
            },
            {
                path: 'category-interest',
                name: 'group-edit-category-interest',
                component: EditCategoryInterest
            },
            {
                path: 'description',
                name: 'group-edit-description',
                component: EditDescription
            },
            {
                path: 'media',
                name: 'group-edit-media',
                component: EditMedia
            },
            {
                path: 'permission',
                name: 'group-edit-permission',
                component: EditMedia
            },
            {
                path: 'admin',
                name: 'group-edit-admin',
                component: EditAdmin
            },
            {
                path: 'review-and-publish',
                name: 'group-edit-review-and-publish',
                component: ReviewAndPublish
            },
            {
                path: 'invite-past-events',
                name: 'group-edit-invite-past-events',
                component: InvitePastEvents
            },
            {
                path: 'invite-friends',
                name: 'group-edit-invite-friends',
                component: InviteFriends
            }
        ]
    },
    {
        path: '/group/publish-success',
        props: { moduleName: `group` },
        component: AddGroup
    },
    {
        path: '/group/:slug/preview',
        name: 'group-preview',
        props: { moduleName: `group` },
        component: PreviewGroup
    },
    {
        path: '/group/:slug/wall',
        name: 'GroupWall',
        props: { moduleName: `group` },
        component: PreviewGroup
    },
    {
        path: '/group/:slug/discussion-board',
        name: 'GroupDiscussion',
        props: { moduleName: `group` },
        component: PreviewGroup
    },
    {
        path: '/group/:slug/albums',
        name: 'GroupAlbum',
        props: { moduleName: `group` },
        component: PreviewGroup
    },
    {
        path: '/group/flag/:slug',
        name: 'GroupFlag',
        props: { moduleName: `group` },
        component: GroupAction
    },
    {
        path: '/group/suspend/:slug',
        name: 'GroupSuspend',
        props: { moduleName: `group` },
        component: GroupAction
    },
    {
        path: '/group/deactivate/:slug',
        name: 'GroupDeactivate',
        props: { moduleName: `group` },
        component: GroupAction
    },
    {
        path: '/group/reactivate/:slug',
        name: 'GroupReactivate',
        props: { moduleName: `group` },
        component: GroupAction
    },
    {
        path: '/group/publish/:slug',
        name: 'GroupPublish',
        props: { moduleName: `group` },
        component: GroupAction
    },
    {
        path: '/group/delete/:slug',
        name: 'GroupDelete',
        props: { moduleName: `group` },
        component: GroupAction
    },
    {
        path: '/group/unsuspend/:slug',
        name: 'GroupUnsuspend',
        props: { moduleName: `group` },
        component: GroupAction
    },
    {
        path: '/group/remove-flag/:slug',
        name: 'GroupRemoveFlag',
        props: { moduleName: `group` },
        component: GroupAction
    }
]
