import axios from 'axios'

export const adminLogin = (data) => {
  return axios.post(`${process.env.VUE_APP_API_URL}admin-panel/login`, data, {
    headers: {
      'Access-Control-Allow-Origin': '*',
      'Content-Type': 'application/json'
    }
  })
}

export const adminOtpVerify = (data) => {
  const token = localStorage.getItem('token')
  if (token) {
    axios.defaults.headers.common.Authorization = `Bearer ${token}`
  }

  return axios.post(`${process.env.VUE_APP_API_URL}admin-panel/otp-verify`, data)
}

/* Methods */
export const adminLogout = () => {
  const token = localStorage.getItem('token')
  if (token) {
    axios.defaults.headers.common.Authorization = `Bearer ${token}`
  }
  return axios.post(`${process.env.VUE_APP_API_URL}logout`, {
    headers: { 'Access-Control-Allow-Origin': '*' }
  })
}

export const checkExistence = (username) => {
  const url = `${process.env.VUE_APP_API_URL}admin-panel/existence-check`
  console.log(url)
  return axios.post(url, { email: username }, {
    headers: {
      'Access-Control-Allow-Origin': '*',
      'Content-Type': 'application/json'
    }
  })
}

export default null
