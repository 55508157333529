<template>
  <modal-component
    :modal-id="modalId"
    :title="`Add Subscription`"
    :header-style="`bg-linear`"
    :size="`modal-md`"
    :profile="false"
  >
    <template v-slot:modalBody>
      <div class="modal-body">
        <div class="form-group required mb-2">
          <label for="plan-name" class="fs-6 fw-bold mb-1">Name</label>
          <input type="text" v-model="name" class="form-control rounded-0" @keyup="onKeyupName"/>
          <span class="animation-loading-spinner" v-if="isSearching"/>
          <div v-if="hasName && subscriber.length > 0" class="list-suggestion-container">
            <div class="list-suggestion-body">
              <div v-for="(row, index) in subscriber" :key="index" class="col-md-12 border-bottom mt-2 subscriber" @click="getSubscriber(row)">
                <span>{{ row.first_name }} {{ row.last_name }}</span>
              </div>
            </div>
            <span class="float-right color-gold close-suggestion" @click="closeSuggestion">Close</span>
          </div>
        </div>
        <div class="form-group required mb-2">
          <label for="plan-description" class="fs-6 fw-bold mb-1"
            >Plan</label
          >
          <select v-model="form.id" class="form-select">
            <option 
              v-for="(row, index) in plan" 
              :key="index" 
              :value="row.id"> 
                {{ row.plan }} ({{ row.name }}) 
            </option>
          </select>
        </div>
      </div>
    </template>
    <template v-slot:modalFooter>
      <div class="modal-footer">
        <button
          type="button"
          class="btn btn-link text-decoration-none text-secondary"
          @click="closeModal"
        >
          Cancel
        </button>
        <button
          type="button"
          class="btn btn-link text-decoration-none text-primary"
          @click="nextAction"
          data-dismiss="modal"
        >
          Submit
        </button>
      </div>
    </template>
  </modal-component>
</template>

<script>
import { onMounted, reactive, ref } from "vue";
import debounce from "lodash/debounce";
import ModalComponent from "@/components/ModalComponent.vue";
import CeText from '../Inputs/CeText.vue';
import { useStore } from 'vuex'
import $ from 'jquery'

export default {
  components: {
    ModalComponent,
    CeText,
  },
  props: {
    modalId: {
      type: String,
      default: "",
    },
    planId: {
      type: String,
      default: "",
    },
    planName: {
      type: String,
      default: "",
    },
  },
  emits: ["actionSubmit"],
  setup(props, { emit }) {
    const store = useStore();
    const form = reactive({
      email: null,
      id: ""
    });
    const plan = ref([]);
    const name = ref(null);
    const hasName = ref(false);
    const subscriber = ref(false);
    const isSearching = ref(false);

    const onKeyupName = debounce(async () => {
      isSearching.value = true;
      if (name.value !== '') {
        const response = await store.dispatch("searchSubscriber", `search_field=${name.value}&&isSubscriber=${true}`);
        subscriber.value = response;
        hasName.value = true;
      } else {
        hasName.value = false;
      }
      isSearching.value = false;
    }, 750);

    const closeSuggestion = () => {
      hasName.value = false;
    }

    const getSubscriber = (data) => {
      name.value = data.first_name + ' ' + data.last_name;
      form.email = data.email;
      closeSuggestion();
    }

    const closeModal = () => {
      form.email = '';
      name.value = '';
      form.id = '';
      $('#'+props.modalId).modal('hide');
    }

    const nextAction = () => {
      emit("actionSubmit", form);
      closeModal();
    };

    onMounted( async () => {
      plan.value = await store.dispatch("loadPriceList");
    })

    return {
      form,
      nextAction,
      plan,
      onKeyupName,
      name,
      hasName,
      subscriber,
      closeSuggestion,
      getSubscriber,
      isSearching,
      closeModal
    };
  },
};
</script>
<style lang="scss" scoped>
@import "../../scss/custom";

.field-highlight {
  background: $color-light-gray;
  border-radius: 6px;
  padding: 0.5rem 0.875rem;
}
.input-group-text {
  background-color: #e7e7e7 !important;
}
:deep(.form-text.text-red) {
  font-size: 14px !important;
  font-weight: bold;
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.list-suggestion-container {
  position: absolute;
  background: $color-white;
  height: 170px;
  width: 95%;
  z-index: 1;
  padding: 5px;
}
.list-suggestion-body {
  height: 140px;
  overflow-x: auto;
}
.close-suggestion {
  position: absolute;
  bottom: 0;
  right: 0;
}
.close-suggestion,.subscriber:hover {
 cursor: pointer;
}

.animation-loading-spinner {
    position: absolute;
    right: 0;
    margin-right: 30px;
    margin-top: -30px;
}

</style>
