<template>
  <div class="ce-inputs-range">
    <div>
      <slot
        name="label"
        :start-value="startValue"
        :end-value="endValue"
      />
    </div>
    <div class="range-wrapper">
      <div
        class="range-track"
        :style="{
          left: `${trackStart}%`,
          width: `${trackWidth}%`,
        }"
      />
      <input
        v-model="localValue[0]"
        type="range"
        :min="min"
        :max="max"
        step="1"
        class="form-range range-first"
        :class="{ 'fixed-start': fixedStart }"
      >
      <input
        v-model="localValue[1]"
        type="range"
        :min="min"
        :max="max"
        step="1"
        class="form-range"
      >
    </div>
  </div>
</template>

<script>
import { ref, watch, computed } from 'vue'
import debounce from 'lodash/debounce'

export default {
  props: {
    modelValue: {
      type: Array,
      default: () => [0, 1]
    },
    min: {
      type: Number,
      default: 0
    },
    max: {
      type: Number,
      default: 1
    },
    fixedStart: {
      type: Boolean,
      default: false
    }
  },
  emits: ['update:modelValue'],
  setup (props, { emit }) {
    const getValidValue1 = (value) => {
      return props.fixedStart || value <= props.min ? props.min : value
    }

    const getValidValue2 = (value) => {
      const value2 = value >= props.max ? props.max : value
      return value2 < props.modelValue[1] ? props.modelValue[1] : value2
    }

    const localValue = ref([
      getValidValue1(props.modelValue[0]),
      getValidValue2(props.modelValue[1])
    ])

    const totalWidth = computed(() => {
      return props.max - props.min
    })

    const startValue = computed(() => {
      return Math.min(localValue.value[0], localValue.value[1])
    })

    const endValue = computed(() => {
      return Math.max(localValue.value[0], localValue.value[1])
    })

    const totalRange = computed(() => {
      return Math.abs(localValue.value[0] - localValue.value[1])
    })

    const trackStart = computed(() => {
      return ((startValue.value - props.min) / totalWidth.value) * 100
    })

    const trackWidth = computed(() => {
      return (totalRange.value / totalWidth.value) * 100
    })

    /* Watchers */
    watch(
      localValue,
      (newLocalValue) => {
        emit('update:modelValue', newLocalValue)
      },
      {
        deep: true
      }
    )

    watch(
      () => props.modelValue,
      debounce((newModelValue) => {
        localValue.value = [
          getValidValue1(newModelValue[0]),
          getValidValue2(newModelValue[1])
        ]
      }, 250)
    )

    return {
      localValue,
      totalWidth,
      startValue,
      endValue,
      totalRange,
      trackStart,
      trackWidth
    }
  }
}
</script>

<style lang="scss" scoped>
.range-wrapper {
  position: relative;
  display: block;
  align-items: center;
  height: 1.4rem; // same height of .custom-range / input[type="range"]

  &::before,
  & > .range-track {
    content: ' ';
    position: absolute;
    top: 0;
    bottom: 0;
    height: 0.5rem;
    margin: auto;
    background: #dee2e6;
    border-radius: 1rem;
  }

  &::before {
    left: 0;
    width: 100%;
    background: #dee2e6;
  }

  & > .range-track {
    background: #000;
    pointer-events: none;
  }

  & > input {
    pointer-events: none;
    position: relative;
  }

  & > input::-moz-range-track {
    background: transparent;
  }

  & > input::-moz-range-thumb,
  & > input::-webkit-slider-runnable-track {
    pointer-events: all;
  }

  & > input::-moz-range-thumb,
  & > input::-webkit-slider-thumb {
    background: #000;
  }

  & > input.fixed-start::-moz-range-thumb,
  & > input.fixed-start::-webkit-slider-thumb {
    visibility: hidden;
  }

  & > input.range-first {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
  }
}
</style>
<style>
.range-wrapper input::-webkit-slider-runnable-track {
  background: transparent;
  pointer-events: none;
}

.range-wrapper > input::-webkit-slider-thumb {
  z-index: 3;
  pointer-events: all;
}

.range-wrapper > input.fixed-start::-webkit-slider-thumb {
  visibility: hidden;
}
</style>
