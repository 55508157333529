<template>
  <div class="photo-gallery--item position-relative">
    <input
      v-if="isEditPhoto"
      type="checkbox"
      class="image-checkbox"
      @change="check($event)"
      :value="photo.id"
    />
    <ce-aspect-ratio
      :aspect-ratio="1"
      class="bg-light-gray rounded-2 overflow-hidden"
    >
      <div
        class="d-flex align-items-center justify-content-center w-100 h-100 bg-light-gray"
      >
        <ce-image
          :src="photo[imageKey]"
          :blur-hash="photo.blurhash"
          :placeholder="emptyPhotoUrl"
          class="mw-100"
        />
      </div>
    </ce-aspect-ratio>
  </div>
</template>

<script>
import { computed, inject, nextTick } from "vue";
import CeAspectRatio from "@/components/Utilities/CeAspectRatio.vue";
import CeImage from "@/components/Images/CeImage.vue";

export default {
  name: "PhotoGalleryItem",
  components: { CeImage, CeAspectRatio },
  props: {
    photo: {
      type: Object,
      required: true,
    },
    emptyPhotoUrl: {
      type: String,
      required: false,
      default:
        process.env.VUE_APP_API_BASE + "/images/defaults/gender-other.png",
    },
    imageKey: {
      type: String,
      required: false,
      default: "original",
    },
    imageIndex: {
      type: Number,
      required: false,
      default: 0,
    },
    isEditPhoto: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  emits: ["itemCheckUpdate"],
  setup(props, { emit }) {
    const reactiveProfile = inject("reactiveProfile", null);
    const moment = inject("moment");
    const altText = computed(() => {
      return `Gallery photo ${props.imageIndex} for ${
        reactiveProfile?.first_name
      } ${reactiveProfile?.last_name} ${moment(
        props.photo?.created_at || reactiveProfile?.created_at
      ).format("LL")}`;
    });

    const check = (event) => {
      nextTick(() => {
        emit("itemCheckUpdate", event);
      });
    };

    return {
      altText,
      check,
    };
  },
};
</script>

<style scoped lang="scss">
@import "~@/scss/customizations";
@import "~@/scss/icomoon/variables";

.photo-gallery--item {
  &::before {
    content: "";
    position: absolute;
    display: block;
    background-color: $modal-backdrop-bg;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    visibility: hidden;
    opacity: 0;
    z-index: 1;
  }
  &::after {
    content: "#{$icon-picture}";
    font-family: "#{$icomoon-font-family}";
    left: 50%;
    top: 50%;
    position: absolute;
    transform: translate(-50%, -50%);
    visibility: hidden;
    opacity: 0;
    z-index: 2;
  }
}
.image-checkbox {
  position: absolute;
  right: 10px;
  z-index: 9999;
  top: 2px;
  width: 20px;
  height: 20px;
}
</style>
