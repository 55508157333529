<template>
  <div>
    <template v-if="!hasInvited">
      <!-- <template v-if="publishedJustNow"> -->
      <div class="card-body">
        <div class="container">
          <h1 class="fs-1 mb-3 text-center">
            Congratulations, your group is now published!
          </h1>
        </div>
      </div>
      <template v-if="pastEvents?.data?.length > 0">
        <group-invite-past-events-form
          class="form-1"
          :group-id="groupForm.id"
          :my-past-events="pastEvents?.data"
          :my-past-events-total-count="pastEvents?.data?.length"
          :has-more-past-events="!!pastEvents?.links?.next"
          @success="hasInvited = true"
          @skip="skip"
        >
          <template #submit="{ submit, isSubmitting }">
            <div class="row">
              <template v-if="!isSubmitting">
                <div class="col-6 col-sm-6 mb-3">
                  <ce-button
                    class="btn-lg btn btn-gray text-white text-bold w-100"
                    @click="cancelGroup()"
                  >
                    Cancel
                  </ce-button>
                </div>
                <div class="col-6 col-sm-6 mb-3">
                  <ce-button
                    class="btn-lg btn-gradient-gold text-bold w-100"
                    :is-loading="isSubmitting"
                    :disabled="isSubmitting"
                    @click="submit"
                  >
                    Continue
                  </ce-button>
                </div>
              </template>
              <template v-else>
                <div class="col-12 mb-3">
                  <ce-button
                    class="btn-lg btn-gradient-gold text-bold w-100"
                    :is-loading="isSubmitting"
                    :disabled="isSubmitting"
                    @click="submit"
                  >
                    Continue
                  </ce-button>
                </div>
              </template>
            </div>
          </template>
        </group-invite-past-events-form>
      </template>
      <template v-else>
        <div class="card-body">
          <div class="container">
            <h1 class="fs-1 ff-merriweather fw-bold mb-3 text-center" />
          </div>
        </div>
        <div class="card-footer text-muted form-footer">
          <div class="container">
            <div class="row">
              <div class="col-12 col-sm-12 mb-3">
                <ce-button
                  class="btn-lg btn-gradient-gold text-bold w-100"
                  @click="viewGroup()"
                >
                  Finish
                </ce-button>
              </div>
            </div>
          </div>
        </div>
      </template>
    </template>
    <template v-else>
      <div class="card-body">
        <div class="container">
          <h1 class="fs-1 text-center">
            Congratulations,<br />your invites have been sent!
          </h1>
          <img src="../../assets/images/invites-sent.svg" class="w-100" />
        </div>
      </div>
      <div class="card-footer text-muted form-footer">
        <div class="container">
          <div class="row">
            <div class="col-6 col-sm-6 mb-3">
              <ce-button
                class="btn-lg btn-gradient-gold text-bold w-100"
                @click="viewGroup()"
              >
                Visit Group
              </ce-button>
            </div>
            <div class="col-6 col-sm-6 mb-3">
              <ce-button
                class="btn-lg btn-gradient-gold text-bold w-100"
                @click="goContinue()"
              >
                Continue
              </ce-button>
            </div>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import { defineAsyncComponent, computed, ref } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";

export default {
  components: {
    GroupInvitePastEventsForm: defineAsyncComponent(() =>
      import("@/Modules/Group/GroupInvitePastEventsForm.vue")
    ),
  },
  props: {
    id: {
      type: Number,
      default: null,
    },
    slug: {
      type: String,
      default: null,
    },
    pastEvents: {
      type: Object,
      default: () => {},
    },
    backUrl: {
      type: String,
      default: "",
    },
  },
  emits: ["createGroup", "isLoading", "cancelEdit"],
  async setup(props, { emit }) {
    const store = useStore();
    const router = useRouter();
    const hasInvited = ref(false);

    const slug = ref(router.currentRoute._value.params.slug);

    emit("isLoading", true);
    const getGroupInfoResource = await store.dispatch("group/groupEdit", {
      slug: slug.value,
      api: "review-and-publish",
    });
    const getInvitePastEventsResource = await store.dispatch(
      "group/invitePastEvents",
      { slug: slug.value }
    );
    emit("isLoading", false);

    store.dispatch("group/setGroupInfo", getGroupInfoResource.data);

    const groupForm = computed(() => getGroupInfoResource.data);

    const pastEvents = computed(() => getInvitePastEventsResource);

    const groupReviewAndPublish = (response) => {
      emit("createGroup", response, { steps: "review-and-publish" });
    };

    const publishedJustNow = computed(
      () => getInvitePastEventsResource.data.publishedJustNow
    );

    const skip = () => {
      emit("cancelEdit");
    };
    const cancelGroup = () => {
      router.replace({
        name: "home",
      });
    };

    const goContinue = () => {
      router.push({
        name: `group-edit-invite-friends`,
        params: { slug: slug.value },
      });
    };
    const viewGroup = () => {
      router.replace({
        name: "group-preview",
        params: { slug: slug.value },
      });
    };

    return {
      groupForm,
      publishedJustNow,
      pastEvents,
      slug,
      hasInvited,
      skip,
      cancelGroup,
      goContinue,
      viewGroup,
      groupReviewAndPublish,
    };
  },
};
</script>

<style lang="scss" scoped>
@import "../../scss/customizations";

:deep() {
  @import "../../scss/form/form-1";
}

.container {
  max-width: 680px;
}
.form-footer {
  background-color: #3a3b44;
  color: #fff;
  min-height: 83px;
}
.form-footer .ce-buttons-button {
  min-height: 50px;
}
</style>
