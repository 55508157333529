<template>
  <div>
    <div class="card card-success mb-4">
      <div class="card-body">
        <h1 class="m-0 ff-merriweather fs-1 text-nero fw-bold">Edit Group</h1>
      </div>
    </div>
    <div class="card border-bottom-1 mh-100">
      <Suspense>
        <div>
          <div>
            <button
              class="btn text-decoration-none text-nero fw-semibold d-flex align-items-center"
              style="background-color: transparent"
              @click="goBack"
            >
              <span class="icon-arrow-left-alt me-1" /> Go Back
            </button>
          </div>

          <router-view
            :back-url="backUrl"
            @createGroup="createGroup"
            @groupIsPublished="groupIsPublished"
            @isLoading="isProcessLoading"
            @cancelEdit="isCancelEdit"
          />
        </div>

        <template #fallback>
          <div class="text-center mh-100 d-inline-block m-5">
            <span class="animation-loading-spinner m-5" />
          </div>
        </template>
      </Suspense>
    </div>
  </div>
</template>

<script>
import { ref } from "vue";
import { useRouter } from "vue-router";
import { transformGroupResource, liveChatEnabledTypes } from "@/Use/groups.js";

export default {
  props: {
    groupInfo: {
      type: Object,
      default: () => {},
    },
  },
  emits: ["isLoading", "onGoHome"],
  setup(props, { emit }) {
    const router = useRouter();

    const backUrl = ref("");
    const isPublished = ref(false);

    const isCancelEdit = () => {
      emit("onGoHome", true);
    };

    const isProcessLoading = (status) => {
      emit("isLoading", status);
    };

    const groupIsPublished = (response) => {
      isPublished.value = response;
    };

    const createGroup = async (response, page) => {
      if (page.steps === "name") {
        router.push({
          name: `group-edit-category-interest`,
          params: { slug: response.data.slug },
        });
      }
      if (page.steps === "category_interest") {
        router.push({
          name: `group-edit-description`,
          params: { slug: response.data.slug },
        });
      }
      if (page.steps === "description") {
        router.push({
          name: `group-edit-media`,
          params: { slug: response.data.slug },
        });
      }
      if (page.steps === "media") {
        router.push({
          name: `group-edit-admin`,
          params: { slug: response.data.slug },
        });
      }
      if (page.steps === "permission") {
        router.push({
          name: `group-edit-admin`,
          params: { slug: response.data.slug },
        });
      }
      if (page.steps === "admin") {
        router.push({
          name: `group-edit-review-and-publish`,
          params: { slug: response.data.slug },
        });
      }
      if (page.steps === "review-and-publish") {
        router.push({
          name: `group-edit-invite-past-events`,
          params: { slug: response.data.slug },
        });
      }
    };

    const liveChatEnabledOptions = [
      {
        label: "No",
        value: liveChatEnabledTypes.NO,
      },
      {
        label: "Yes",
        value: liveChatEnabledTypes.YES,
      },
    ];

    const goBack = () => {
      if (localStorage.getItem("prevURL")) {
        router.push(localStorage.getItem("prevURL"));
      } else {
        router.go(-1);
      }
    };

    return {
      isProcessLoading,
      goBack,
      createGroup,
      groupIsPublished,
      transformGroupResource,
      liveChatEnabledOptions,
      backUrl,
      isPublished,
      isCancelEdit,
    };
  },
};
</script>
<style lang="scss" scoped>
@import "../../scss/customizations";

.back-button {
  color: $color-nero;
  text-decoration: none;
  line-height: 1.2;
}

.form-column-wrap {
  max-width: 680px;
}
</style>
