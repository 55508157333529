<template>
  <div class="ce-editor">
    <div class="editor-wrap position-relative" disa>
      <editor-content
        :editor="editor"
        class="editor"
        @keypress="editorKeypress"
      />
    </div>
    <div class="toolbox">
      <button
        :class="{ 'is-active': checkStateActive('bold') }"
        @click="toggleBold()"
      >
        <i class="fa fa-bold" />
      </button>
      <button
        :class="{ 'is-active': checkStateActive('italic') }"
        @click="toggleItalic()"
      >
        <i class="fa fa-italic" />
      </button>
      <button
        :class="{ 'is-active': checkStateActive('underline') }"
        @click="toggleUnderline()"
      >
        <i class="fa fa-underline" />
      </button>
      <div class="divider vertical" />
      <button
        :class="{ 'is-active': checkStateActive('bulletList') }"
        @click="toggleBulletList()"
      >
        <i class="fa fa-list-ul" />
      </button>
      <button
        :class="{ 'is-active': checkStateActive('orderedList') }"
        @click="toggleOrderedList()"
      >
        <i class="fa fa-list-ol" />
      </button>
      <button
        :class="{ 'is-active': checkStateActive({ textAlign: 'left' }) }"
        @click="setTextAlign('left')"
      >
        <i class="fa fa-align-left" />
      </button>
      <button
        :class="{ 'is-active': checkStateActive({ textAlign: 'center' }) }"
        @click="setTextAlign('center')"
      >
        <i class="fa fa-align-center" />
      </button>
      <button
        :class="{ 'is-active': checkStateActive({ textAlign: 'right' }) }"
        @click="setTextAlign('right')"
      >
        <i class="fa fa-align-right" />
      </button>
      <button
        :class="{ 'is-active': checkStateActive({ textAlign: 'justify' }) }"
        @click="setTextAlign('justify')"
      >
        <i class="fa fa-align-justify" />
      </button>
    </div>
    <slot :editor="editor" />
  </div>
</template>

<script>
import { computed, ref, watch } from "vue";
import { EditorContent, useEditor } from "@tiptap/vue-3";
import StarterKit from "@tiptap/starter-kit";
import TextAlign from "@tiptap/extension-text-align";
import Document from "@tiptap/extension-document";
import Paragraph from "@tiptap/extension-paragraph";
import Heading from "@tiptap/extension-heading";
import Text from "@tiptap/extension-text";
import Underline from "@tiptap/extension-underline";
import Placeholder from "@tiptap/extension-placeholder";

export default {
  name: "CeChatBox",
  components: { EditorContent },
  props: {
    modelValue: {
      type: String,
      required: false,
      default: "",
    },
  },
  emits: ["update:modelValue"],
  setup(props, { emit }) {
    const content = ref("");
    const editor = useEditor({
      content: null,
      extensions: [
        StarterKit,
        Document,
        Paragraph,
        Text,
        Heading,
        Underline,
        Placeholder.configure({
          placeholder: computed(() => props.placeholder),
        }),
        TextAlign.configure({
          types: ["heading", "paragraph"],
        }),
      ],
      onUpdate(ctx) {
        content.value = ctx.editor.getHTML();
        emit("update:modelValue", ctx.editor.getHTML());
      },
    });

    watch(
      () => props.modelValue,
      (value) => {
        content.value = value || "";
      }
    );
    const editorKeypress = () => {};

    return {
      content,
      editor,
      editorKeypress,
    };
  },
  watch: {
    modelValue(value) {
      const isSame = this.editor.getHTML() === value;

      if (isSame) {
        return;
      }

      this.editor.commands.setContent(this.modelValue, false);
    },
  },
  beforeUnmount() {
    this.editor.destroy();
  },
  methods: {
    checkStateActive(property) {
      return this.editor?.isActive?.(property) || false;
    },
    toggleBold() {
      this.editor.chain().focus().toggleBold().run();
    },
    toggleItalic() {
      this.editor.chain().focus().toggleItalic().run();
    },
    toggleStrike() {
      this.editor.chain().focus().toggleStrike().run();
    },
    toggleUnderline() {
      this.editor.chain().focus().toggleUnderline().run();
    },
    toggleBulletList() {
      this.editor.chain().focus().toggleBulletList().run();
    },
    toggleOrderedList() {
      this.editor.chain().focus().toggleOrderedList().run();
    },
    setTextAlign(alignment) {
      this.editor.chain().focus().setTextAlign(alignment).run();
    },
  },
};
</script>

<style lang="scss" scoped>
.editor {
  padding: 1rem;
  background-color: #e7e7e7;
  height: 125px;
  max-width: 100%;
  overflow: auto;
  word-break: break-word;

  :deep(.ProseMirror) {
    outline: 0;
    height: 100%;

    .is-editor-empty {
      &::before {
        content: "Write your message...";
        color: #6c757d;
      }
    }

    &.ProseMirror-focused {
      .is-editor-empty {
        &::before {
          display: none;
        }
      }
    }
  }
}

.toolbox {
  display: flex;
  background-color: #d8d8d8;
  height: 35px;

  button {
    border: 0;
    padding: 0.5em;
    background: transparent;
    transition: all 0.1s ease;

    &:active {
      transform: scale(0.9);
    }

    .span {
      color: #878787;
    }

    &.is-active {
      .span {
        color: rgb(240, 189, 23);
      }
    }
  }

  .divider {
    background-color: #b4b4b4;
    width: 1px;
    height: 100%;
    margin: 0 0.5rem;
  }
}
.fas,
.fa {
  color: #222 !important;
}
</style>
