export const FILE_TYPES = [
  'image/png',
  'image/jpeg',
  'image/gif',
  'image/webp',
  'image/svg+xml'
]

export const isAPhoto = (file) => FILE_TYPES.includes(file.type)

export default null
