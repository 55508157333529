import { GATHERING_TYPES } from "@/Use/events.js";

import ManageHuddle from '@/Pages/Event/ManageEvent.vue'
import HuddleAction from '@/Pages/Report/UserAction.vue'

import AddHuddle from '@/Pages/Event/AddEvent.vue'

import EditHuddle from '@/Pages/Event/EditEvent.vue'
import EditNameLocation from '@/Pages/Event/EditNameLocation.vue'
import EditCategoryInterest from '@/Pages/Event/EditCategoryInterest.vue'
import EditDescription from '@/Pages/Event/EditDescription.vue'
import EditMedia from '@/Pages/Event/EditMedia.vue'
import EditAdmin from '@/Pages/Event/EditAdmin.vue'
import EditSchedule from '@/Pages/Event/EditSchedule.vue'

import ReviewAndPublish from '@/Pages/Event/ReviewAndPublish.vue'
import InvitePastHuddles from '@/Pages/Event/InvitePastEvents.vue'
import InviteFriends from '@/Pages/Event/InviteFriends.vue'

import PreviewHuddle from '@/Pages/Event/PreviewEvent.vue'

export default [{
        path: '/manage-huddles',
        name: 'ManageHuddle',
        component: ManageHuddle,
        props: { moduleName: `huddle`, gatheringType: GATHERING_TYPES.HUDDLE },
        meta: { requiresAuth: true },
    },
    {
        path: '/huddle/add',
        name: 'add-huddle',
        component: AddHuddle,
        props: { moduleName: `huddle`, gatheringType: GATHERING_TYPES.HUDDLE },
        meta: { requiresAuth: true },
    },
    {
        path: '/huddle/:id/edit',
        name: 'edit-huddle',
        component: EditHuddle,
        props: { moduleName: `huddle`, gatheringType: GATHERING_TYPES.HUDDLE },
        meta: { requiresAuth: true },
        children: [{
                path: '',
                name: 'huddle-edit-index',
                component: ReviewAndPublish,
                meta: { requiresAuth: true },
            },
            {
                path: 'name-location',
                name: 'huddle-edit-name-location',
                component: EditNameLocation,
                meta: { requiresAuth: true },
            },
            {
                path: 'category-interest',
                name: 'huddle-edit-category-interest',
                component: EditCategoryInterest,
                meta: { requiresAuth: true },
            },
            {
                path: 'description',
                name: 'huddle-edit-description',
                component: EditDescription,
                meta: { requiresAuth: true },
            },
            {
                path: 'media',
                name: 'huddle-edit-media',
                component: EditMedia,
                meta: { requiresAuth: true },
            },
            {
                path: 'admin',
                name: 'huddle-edit-admin',
                component: EditAdmin,
                meta: { requiresAuth: true },
            },
            {
                path: 'schedule',
                name: 'huddle-edit-schedule',
                component: EditSchedule,
                meta: { requiresAuth: true },
            },
            {
                path: 'review-and-publish',
                name: 'huddle-edit-review-and-publish',
                component: ReviewAndPublish,
                meta: { requiresAuth: true },
            },
            {
                path: 'invite-past-events',
                name: 'huddle-edit-invite-past-events',
                component: InvitePastHuddles,
                meta: { requiresAuth: true },
            },
            {
                path: 'invite-friends',
                name: 'huddle-edit-invite-friends',
                component: InviteFriends,
                meta: { requiresAuth: true },
            }
        ]
    },
    {
        path: '/huddle/publish-success',
        component: AddHuddle,
        props: { moduleName: `huddle`, gatheringType: GATHERING_TYPES.HUDDLE },
        meta: { requiresAuth: true },
    },
    {
        path: '/huddle/:slug',
        name: 'PreviewHuddle',
        component: PreviewHuddle,
        props: { moduleName: `huddle`, gatheringType: GATHERING_TYPES.HUDDLE },
        meta: { requiresAuth: true },
    },
    {
        path: '/huddle/:slug/wall',
        name: 'HuddleWall',
        component: PreviewHuddle,
        props: { moduleName: `huddle`, gatheringType: GATHERING_TYPES.HUDDLE },
        meta: { requiresAuth: true },
    },
    {
        path: '/huddle/:slug/discussion-board',
        name: 'HuddleDiscussion',
        component: PreviewHuddle,
        props: { moduleName: `huddle`, gatheringType: GATHERING_TYPES.HUDDLE },
        meta: { requiresAuth: true },
    },
    {
        path: '/huddle/:slug/albums',
        name: 'HuddleAlbum',
        component: PreviewHuddle,
        props: { moduleName: `huddle`, gatheringType: GATHERING_TYPES.HUDDLE },
        meta: { requiresAuth: true },
    },
    {
        path: '/huddle/flag/:slug',
        name: 'HuddleFlag',
        component: HuddleAction,
        props: { moduleName: `huddle`, queryFilter:`flagged`, gatheringType: GATHERING_TYPES.HUDDLE },
        meta: { requiresAuth: true },
    },
    {
        path: '/huddle/suspend/:slug',
        name: 'HuddleSuspend',
        component: HuddleAction,
        props: { moduleName: `huddle`, queryFilter:`suspended`, gatheringType: GATHERING_TYPES.HUDDLE },
        meta: { requiresAuth: true },
    },
    {
        path: '/huddle/deactivate/:slug',
        name: 'HuddleDeactivate',
        component: HuddleAction,
        props: { moduleName: `huddle`, queryFilter:`deactivated`, gatheringType: GATHERING_TYPES.HUDDLE },
        meta: { requiresAuth: true },
    },
    {
        path: '/huddle/reactivate/:slug',
        name: 'HuddleReactivate',
        component: HuddleAction,
        props: { moduleName: `huddle`, queryFilter:`active`, gatheringType: GATHERING_TYPES.HUDDLE },
        meta: { requiresAuth: true },
    },
    {
        path: '/huddle/delete/:slug',
        name: 'HuddleDelete',
        component: HuddleAction,
        props: { moduleName: `huddle`, queryFilter:`deleted`, gatheringType: GATHERING_TYPES.HUDDLE },
        meta: { requiresAuth: true },
    },
    {
        path: '/huddle/publish/:slug',
        name: 'HuddlePublish',
        component: HuddleAction,
        props: { moduleName: `huddle`, queryFilter:`active`, gatheringType: GATHERING_TYPES.HUDDLE },
        meta: { requiresAuth: true },
    },
    {
        path: '/huddle/remove-flag/:slug',
        name: 'HuddleRemoveFlag',
        component: HuddleAction,
        props: { moduleName: `huddle`, queryFilter:`active`, gatheringType: GATHERING_TYPES.HUDDLE },
        meta: { requiresAuth: true },
    },
    {
        path: '/huddle/unsuspend/:slug',
        name: 'HuddleUnsuspend',
        component: HuddleAction,
        props: { moduleName: `huddle`, queryFilter:`active`, gatheringType: GATHERING_TYPES.HUDDLE },
        meta: { requiresAuth: true },
    },
]
