import IMask from 'imask/esm/imask'
import MaskedRange from 'imask/esm/masked/range'
import MaskedEnum from 'imask/esm/masked/enum'

export default (app) => {
  app.directive('mask', {
    beforeMount (el, binding) {
      if (binding.value) {
        const defaultOptions = {
          mask: binding.value,
          blocks: {
            HH: {
              mask: MaskedRange,
              placeholderChar: '0',
              from: 0,
              to: 12,
              maxLength: 2
            },
            MM: {
              mask: MaskedRange,
              placeholderChar: '0',
              from: 0,
              to: 59,
              maxLength: 2
            },
            A: {
              mask: MaskedEnum,
              placeholderChar: 'X',
              enum: ['AM', 'PM']
            }
          }
        }
        const options =
          typeof binding.value === 'object'
            ? {
                ...defaultOptions,
                ...binding.value
              }
            : defaultOptions
        IMask(el, options)
      }
    }
  })
}
