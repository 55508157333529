import { createApp } from 'vue'
import App from './App.vue'
import router from './router/index.js'
import store from './store';
import $ from 'jquery';

import components from '@/components/Setup/index.js';
window.axios = require('axios');
import directives from './Directives/index.js'

import moment from 'moment'
import 'moment-timezone';

import { useCookies } from 'vue3-cookies'

import VueFlatPickr from 'vue-flatpickr-component';
import 'flatpickr/dist/flatpickr.css';


// import adminlte scripts
import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap'

// import adminlte styles
import 'datatables.net-bs4/css/dataTables.bootstrap4.min.css'
import 'admin-lte/dist/css/adminlte.min.css'
import 'admin-lte/plugins/overlayScrollbars/css/OverlayScrollbars.min.css'
import 'admin-lte/plugins/sweetalert2/sweetalert2.min.css'
import 'admin-lte/plugins/toastr/toastr.min.css'

import 'admin-lte/plugins/bootstrap/js/bootstrap.bundle.min.js'
import 'admin-lte/plugins/datatables/jquery.dataTables.min.js'
import 'admin-lte/plugins/datatables-bs4/js/dataTables.bootstrap4.min.js'
import 'admin-lte/plugins/datatables-responsive/js/dataTables.responsive.min.js'
import 'admin-lte/plugins/jquery/jquery.min.js'
import 'admin-lte/plugins/select2/js/select2.full.min.js'
import 'admin-lte/dist/js/adminlte.min.js'
import 'admin-lte/plugins/sweetalert2/sweetalert2.min.js'
import 'admin-lte/plugins/toastr/toastr.min.js'

import './assets/css/custom.css'
import './assets/css/button.css'

window.axios = require('axios')

window.$ = window.jQuery = require('jquery')
const { cookies } = useCookies()

$(function() {
    // Initialize Select2 Elements
    $('.select2').select2()

    // Initialize Select2 Elements
    $('.select2bs4').select2({
        theme: 'bootstrap4'
    })
})

const app = createApp(App).use(router).use(store)

app.use(components)

app.use(VueFlatPickr);

window.axios.defaults.baseURL = process.env.VUE_APP_API_URL

window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest'
window.axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*'

app.use(directives)

app.provide('moment', moment)

import VueCropper from 'vue-cropperjs';
import 'cropperjs/dist/cropper.css';
app.component(VueCropper);

// import VideoJs from 'video.js'
// import 'video.js/dist/video-js.min.css';
// app.use(VideoJs);

app.mount('#app')