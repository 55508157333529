import moment from 'moment'
import map from 'lodash/map'
import forEach from 'lodash/forEach'
import throttle from 'lodash/throttle'
import pickBy from 'lodash/pickBy'
import { useStore } from 'vuex'
import { computed, reactive, ref, watch } from 'vue'
import { useForm, usePage } from '@inertiajs/inertia-vue3'

export const GATHERING_TYPES = Object.freeze({
  EVENT: 1,
  HUDDLE: 2
})

export const RSVP_TYPES = Object.freeze({
  OPEN: 1,
  LIMITED_CAPACITY: 2,
  VIP_ONLY: 3
})

export const RSVP_STATUSES = Object.freeze({
  RSVP: 0,
  ATTENDING: 1,
  WAIT_LISTED: 2,
  ATTENDEES: 3
})

export const UNPUBLISH_STATUSES = Object.freeze({
  POSTPONED: 'postponed',
  RESCHEDULED: 'rescheduled',
  CANCELLED: 'cancelled'
})

export const GROUP_SETTINGS = Object.freeze({
  VENUE: 1,
  ONLINE_EVENT: 2,
  TO_BE_ANNOUNCED: 3
})

export const GROUP_ALBUM_SETTINGS = Object.freeze({
  PUBLIC: 1,
  GROUP_ADMINS: 2,
  ME_ONLY: 3
})

export const GROUP_TAKE_ACTION = Object.freeze({
    EDIT: "edit",
    FLAG: "flag",
    REMOVE_FLAG: "remove_flag",
    SUSPEND: "suspend",
    UNSUSPEND: "unsuspend",
    REACTIVATE: "reactivate",
    DEACTIVATE: "deactivate",
    PUBLISH: "publish",
    DELETE: "delete"
})

export const venueTypes = Object.freeze({
  VENUE: 'Venue',
  ONLINE_EVENT: 'Online Event',
  TO_BE_ANNOUNCED: 'To be announced'
})

export const liveChatEnabledTypes = Object.freeze({
  NO: 0,
  YES: 1
})

export const liveChatTypes = Object.freeze({
  OPEN_TO_ALL_PARTICIPANTS: 0,
  RSVP: 1,
  CHECK_INS: 2
})

export const ADMIN_ROLES = Object.freeze({
  ADMINISTRATOR: 1,
  PARTIAL_ADMIN: 2,
  GATEKEEPER: 3,
  TICKET_DISTRIBUTOR: 4,
  PREMIUM: 5,
  INFLUENCER_BASIC: 6,
  INFLUENCER_PRO: 7
})
/*
 * Reactive data, for components that share composables
 */
const friendLists = ref(null)
const groupPendingInviteLists = ref([])
// use when update has made to the group, such as when join or leave group
const groupUpdateInfo = ref([])
const userGroups = ref([])
const userAllGroups = ref([])

export const groupNotificationTypes = Object.freeze({
  GroupInvitationReceived:
    'App\\Notifications\\GroupInvitationReceivedNotification',
  GroupNewMemberJoined: 'App\\Notifications\\GroupNewMemberNotification',
  GroupInviteAccepted: 'App\\Notifications\\GroupInviteAcceptedNotification'
})

export const formatGroupCount = (value) => {
  if (value > 10 ** 3) {
    const number = Number((value / 10 ** 3).toFixed(1))
    return `+${number}k`
  }
  if (value > 10 ** 6) {
    const number = Number((value / 10 ** 6).toFixed(1))
    return `+${number}m`
  }
  return `+${Number(value.toFixed(1))}`
}

export const transformGroupResource = (groupItem) => {
  const imageUrl = groupItem.image_url
  const imageModifiedUrl = groupItem.image_modified_url
  const videoUrl = groupItem.video_url
  const videoId = groupItem.video_id

  const totalMembers = groupItem.total_members || 0
  const totalMembersFormatted = formatGroupCount(totalMembers)

  return {
    id: groupItem.id,
    name: groupItem.name,
    slug: groupItem.slug,
    description: groupItem.description,
    moderator: `${groupItem.user?.first_name || ''} ${
      groupItem.user?.last_name || ''
    }`,
    totalMembers,
    totalMembersFormatted,
    imageUrl,
    modifiedImageUrl: imageModifiedUrl || imageUrl,
    videoId,
    videoUrl,
    videoIsTranscoding: groupItem.video_is_transcoding,
    type: groupItem.type,
    category: groupItem.category,
    tags: groupItem.tags,
    members: groupItem.members || [],
    canEdit: Boolean(groupItem.can_edit),
    isOwner: Boolean(groupItem.is_user_owner),
    isMember: Boolean(groupItem.is_user_member),
    isPublished: groupItem.is_published,
    date: groupItem.published_date,
    isChatEnabled: groupItem.is_chat_enabled,
    isExclusive: Boolean(groupItem.is_exclusive),
    photosCount: groupItem.photos_count,
    albumsCount: groupItem.albums_count
  }
}

export const transformGroupResources = (groups) => {
  return map(groups, (o) => transformGroupResource(o))
}

export const formatGroupDate = (value) => {
  const d = moment(value)
  return d.isValid() ? moment(value).format('ddd, MMM D, h:mma') : value
}

export const useHelper = () => {
  const page = usePage()

  const getCurrentPageUrl = () => {
    return new URL(
      /^http/.test(page.url.value)
        ? page.url.value
        : window.location.origin + page.url.value
    )
  }
  const cleanSearchFields = (fields) => {
    return pickBy(fields, (value) => {
      return !!value
    })
  }
  const goPrevPage = () => window.history.back()

  return {
    getCurrentPageUrl,
    cleanSearchFields,
    goPrevPage
  }
}

export const useGroup = () => {
  const store = useStore()
  const page = usePage()
  const userJoinedGroups = ref([])
  const isUserLoggedIn = computed(() => !!page.props.value.auth)

  // Computed
  const groupTypes = computed(() => useStore().state.group.groupTypes)
  const groupCategories = computed(
    () => useStore().state.group.groupCategories
  )

  /* Get all joined and created groups by user */
  const loadUserAllGroups = async (params = {}) => {
    const response = await store.dispatch('fetchUserAllGroups', params)
    const { data } = response
    userAllGroups.value = userAllGroups.value.concat(
      transformGroupResources(data || [])
    )
    userAllGroups.value.meta = response.meta
    return response
  }

  /* Get joined groups */
  const loadUserJoinedGroups = async (params = {}) => {
    const response = await store.dispatch(
      'fetchUserJoinedGroups',
      params
    )
    const { data } = response
    userJoinedGroups.value = transformGroupResources(data || [])
    return response
  }

  /* Get all groups created by user */
  const loadUserGroups = async (params = {}) => {
    const response = await store.dispatch('fetchUserGroups', params)
    const { data } = response
    userGroups.value = userGroups.value.concat(
      transformGroupResources(data || [])
    )
    return response
  }

  /* delete user group */
  const deleteUserGroup = (group) => {
    userGroups.value = userGroups.value.filter((mygroup) => {
      return mygroup.id !== group.id
    })
    /* update all groups */
    userAllGroups.value = userAllGroups.value.filter((allgroup) => {
      return allgroup.id !== group.id
    })
    return store.dispatch('delete', group.id)
  }

  return {
    loadUserGroups,
    loadUserJoinedGroups,
    loadUserAllGroups,
    deleteUserGroup,
    userGroups,
    groupTypes,
    groupCategories,
    isUserLoggedIn,
    userAllGroups,
    userJoinedGroups
  }
}

export const useJoinGroup = () => {
  const store = useStore()

  const joinGroup = async (groupId, postToWall = false) => {
    const { data } = await store.dispatch('join', {
      groupId,
      params: { postToWall }
    })
    groupUpdateInfo.value = data
    return data
  }

  const leaveGroup = async (groupId) => {
    const { data } = await store.dispatch('leave', groupId)
    groupUpdateInfo.value = data
    /* update all groups */
    userAllGroups.value = userAllGroups.value.filter((group) => {
      return group.id !== groupId
    })
    return data
  }

  return {
    joinGroup,
    leaveGroup,
    groupUpdateInfo
  }
}

export const useSearch = (props, { emit }) => {
  const store = useStore()
  const { getCurrentPageUrl } = useHelper()

  const searchForm = useForm('groupSearchForm', {
    search_type: 'all',
    keyword: undefined,
    type_id: undefined,
    category_id: undefined,
    advanced: undefined
  })

  const searchResults = reactive({
    hasMoreResult: false,
    data: []
  })
  const showAdvanceSearch = ref(false)
  const isSearching = ref(false)
  const searchQuery = reactive({
    page: 1,
    search_type: 'all',
    keyword: undefined,
    type_id: undefined,
    category_id: undefined
  })

  // Watchers
  watch(searchQuery, (value) => emit('update:modelValue', value), {
    deep: true
  })
  watch(isSearching, (value) => emit('update:isSearching', value))

  // Methods
  const initializeSearchForm = () => {
    searchForm.page = Number(getCurrentPageUrl().searchParams.get('page') || 1)
    searchForm.keyword = getCurrentPageUrl().searchParams.get('keyword')
    searchForm.type_id = getCurrentPageUrl().searchParams.get('type_id')
    searchForm.category_id =
      getCurrentPageUrl().searchParams.get('category_id')
    searchForm.advanced = getCurrentPageUrl().searchParams.get('advanced')
  }
  const setSearchFormValues = (values) => {
    forEach(Object.keys(searchForm.data()), (key) => {
      if (Object.prototype.hasOwnProperty.call(values, key)) {
        searchForm[key] = values[key]
      }
    })
    searchForm.page = Number(getCurrentPageUrl().searchParams.get('page') || 1)
    return searchForm
  }
  const toggleAdvanceSearch = () => {
    showAdvanceSearch.value = !showAdvanceSearch.value
  }
  const setFormValues = () => {
    if (!props.modelValue) return

    forEach(Object.keys(searchQuery), (key) => {
      const query = props.modelValue
      searchQuery[key] = query[key]
    })
  }

  const loadGroupOptions = async () => {
    setFormValues()
    try {
      await store.dispatch('loadGroupOptions')
    } catch (e) {
      //
    }
  }

  // Methods
  const searchQueryUpdate = () => {
    emit('update:modelValue', searchQuery)
  }
  const clearSearchResults = () => {
    searchResults.data = []
    searchResults.hasMoreResult = false
  }
  const search = throttle(async (query = {}) => {
    isSearching.value = true
    try {
      const { data, links } = await store.dispatch('all', query)
      if (query.page === 1) {
        clearSearchResults()
      }
      searchResults.data = searchResults.data.concat(
        transformGroupResources(data)
      )
      searchResults.hasMoreResult = links.next !== null
    } finally {
      isSearching.value = false
    }
  }, 250)

  return {
    searchForm,
    searchQuery,
    search,
    searchResults,
    showAdvanceSearch,
    toggleAdvanceSearch,
    setFormValues,
    searchQueryUpdate,
    isSearching,
    clearSearchResults,
    loadGroupOptions,
    initializeSearchForm,
    setSearchFormValues
  }
}

export const useMember = () => {
  const store = useStore()

  return {
    fetchGroupMember: (groupId, params) =>
      store.dispatch('fetchMembersByGroupId', { groupId, params })
  }
}

export const useRelated = (group) => {
  const store = useStore()
  const relatedCount = ref(6)
  const allRelatedGroups = ref([])

  // computed
  const relatedGroups = computed(() =>
    allRelatedGroups.value.slice(0, relatedCount.value)
  )
  const hasRelatedGroup = computed(() => allRelatedGroups.value.length > 0)

  const loadRelatedGroups = async () => {
    const groupId = group ? group.id : undefined
    const { data } = await store.dispatch('related', groupId)
    allRelatedGroups.value = transformGroupResources(data)

    return allRelatedGroups.value
  }

  return {
    relatedCount,
    relatedGroups,
    allRelatedGroups,
    loadRelatedGroups,
    hasRelatedGroup
  }
}

/** Use for showing friends and sending invites * */
export const useFriendInvites = () => {
  const store = useStore()

  /* get friend lists */
  const fetchFriendLists = async (groupId, params) => {
    friendLists.value = await store.dispatch('fetchFriendLists', {
      groupId,
      params
    })
  }

  /* send invite */
  const sendFriendInvite = async (groupId, userId) => {
    await store.dispatch('sendFriendInvite', { groupId, userId })
  }

  /* remove from the lists after sending a invite */
  const removeFriendFromLists = (userId) => {
    friendLists.value.data = friendLists.value.data.filter((friend) => {
      return friend.id !== userId
    })
  }

  return {
    friendLists,
    fetchFriendLists,
    sendFriendInvite,
    removeFriendFromLists
  }
}

/** Use for publishing / unpublished of groups * */
export const useGroupPublishing = (groupId) => {
  const store = useStore()

  /* Publish a group */
  const publishGroup = (params) =>
    store.dispatch('publish', { groupId, params })

  return {
    publishGroup
  }
}

/** Accept / reject group invites * */
export const useGroupInviteActions = (inviteId = 0) => {
  const store = useStore()

  /* Fetch all group pending invites */
  const fetchGroupInvites = async (params = {}) => {
    const { data, meta } = await store.dispatch(
      'fetchGroupInvites',
      params
    )
    groupPendingInviteLists.value = data
    groupPendingInviteLists.value.meta = meta
  }

  /* Accept invite */
  const acceptInvite = (invitationId) =>
    store.dispatch('acceptGroupInvite', invitationId || inviteId)

  /* Reject invite */
  const rejectInvite = (invitationId) =>
    store.dispatch('rejectGroupInvite', invitationId || inviteId)

  /* remove invite from lists when accept or reject is clicked */
  const removeInviteFromLists = () => {
    groupPendingInviteLists.value = groupPendingInviteLists.value.filter(
      (invite) => {
        return invite.id !== inviteId
      }
    )
    store.commit(
      'setPendingInviteCount',
      store.state.group.pendingInviteCount - 1
    )
  }

  return {
    fetchGroupInvites,
    acceptInvite,
    rejectInvite,
    removeInviteFromLists,
    groupPendingInviteLists
  }
}
