<template>
  <div class="card card-success">
    <div class="card-body">
      <span class="fw-bold color-black h1">Abilities</span>
      <button 
      type="button" 
      class="btn-gradient-gold mt-2 mb-2 close-gradient-gold-color float-right fw-bold" 
      @click="addAbilitiesModal">Add Abilities</button>
      </div>
    </div>
  <abilities-table
    :is-load="isLoad"
    @updateAbilities="updateAbilities"
  />
  <div id="confirm" />

   
  <add-abilities-modal
      :modal-id="`add-abilities-modal`"
      :form-data="ability"
      :ability-id="abilityId"
      @actionSubmit="addAbilities"
      @closeModal="closeModal"
    />
</template>

<script>
import { ref } from 'vue'
import moment from "moment";
import { useStore } from "vuex";
import AddAbilitiesModal from '@/components/Modal/AddAbilitiesModal.vue';
import AbilitiesTable from '@/components/Tables/AbilitiesTable.vue';

export default {
  name: "Abilities",
  components: {
    AddAbilitiesModal,
    AbilitiesTable,
  },
  setup() {
    const ability = ref({});
    const isLoad = ref(false);
    const store = useStore();
    const abilityId = ref(null);

    const addAbilitiesModal = () => {
      isLoad.value = false;
      ability.value = {};
      abilityId.value = null;
      $("#add-abilities-modal").modal("show");
    }

    const addAbilities = async(form) => {
      let data = {
        name: form.name,
        label: form.label,
        group: form.group,
        is_important: form.isImportant,
        status: form.status,
        sort_number: parseInt(form.sortNumber),
        hint: form.hint
      }

      if (abilityId.value !== null) {
        await store.dispatch("updateAbilities", {form: data, id: abilityId.value});
      } else {
        await store.dispatch("saveAbilities", data);
      }
      ability.value = {};
      isLoad.value = !isLoad.value;
    }

    const updateAbilities = (row) => {
      // isLoad.value = false;
      ability.value = row;
      abilityId.value = row.id;
      $("#add-abilities-modal").modal("show");
    }

    const closeModal = (row) => {
      ability.value = {};
      abilityId.value = null;
      $("#add-abilities-modal").modal("hide");
    }

    return {
      addAbilitiesModal,
      addAbilities,
      updateAbilities,
      ability,
      isLoad,
      abilityId,
      closeModal
    };
  },
  created: function () {
    this.moment = moment;
  },
};
</script>

<style scoped>
.downloadAndMessageAlign {
  text-align: center;
  position: inherit;
  margin-top: 15px;
}
.dropdown-menu-lg {
  min-width: auto;
}
.table td,
.table th {
  border-top: 0 !important;
}
.table td {
  vertical-align: middle;
}
.custom-control-label {
  font-weight: 500 !important;
}
.send {
  color: rgb(4, 98, 193);
}
.selected-message-type {
  background-color: #333;
  color: #cacaca !important;
}
</style>
