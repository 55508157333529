<template>
  <div class="w-100 h-100 position-relative">
    <div class="ce-utilities-aspect-ratio">
      <div class="ce-utilities-aspect-ratio-wrapper restricted-aspect-ratio">
        <div>
          <img
            :src="imageSrc"
            @error="imageUrlAlt"
            alt="Perfect Friends"
            class="mw-100 h-100 rounded-2"
            style="object-fit: contain; width: 100%"
          >
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    imageSrc: {
      type: String,
      default: '//via.placeholder.com/150'
    }
  },
  setup () {
    const imageUrlAlt = (event) => {
      event.target.src = "//via.placeholder.com/150";
    };

    return { imageUrlAlt }
  }
}
</script>
<style scoped>

</style>
