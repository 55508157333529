<template>
  <div class="ce-inputs-checkbox form-check">
    <input
      :id="uniqueId"
      v-model="localValue"
      :name="name"
      class="form-check-input"
      type="radio"
      :value="value"
    >
    <label
      class="form-check-label"
      :for="uniqueId"
    >
      <span><slot /></span>
    </label>
  </div>
</template>

<script>
import { ref, getCurrentInstance, watch } from 'vue'

export default {
  name: 'CeRadio',
  props: {
    modelValue: {
      type: [Boolean, Number, String],
      required: false,
      default: false
    },
    name: {
      type: String,
      required: false,
      default: undefined
    },
    value: {
      type: [String, Boolean, Number],
      required: false,
      default: undefined
    }
  },
  emits: ['update:modelValue'],
  setup (props, { emit }) {
    const localValue = ref(null)
    const uniqueId = `ce-checkbox-${getCurrentInstance().uid}`

    /* Watchers */
    watch(localValue, (newLocalValue) => {
      emit('update:modelValue', newLocalValue)
    })

    watch(
      () => props.modelValue,
      (newModelValue) => {
        localValue.value = newModelValue
      },
      {
        immediate: true
      }
    )

    return {
      uniqueId,
      localValue
    }
  }
}
</script>
