<template>
  <!-- Preloader -->
  <div
    v-if="isLoading"
    class="preloader flex-column justify-content-center align-items-center"
  >
    <img
      src="../../assets/Spin-0.5s-224px.gif"
      alt="AdminLTELogo"
      height="60"
      width="60"
    >
    <span style="color: #999"> {{ processText }} </span>
  </div>
</template>

<script>
export default {
  props: {
    isLoading: {
      type: Boolean,
      default: false
    },
    processText: {
      type: String,
      default: 'Please wait while we submit your request!'
    }
  }
}
</script>

<style>

</style>
