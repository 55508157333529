<template>
  <nav class="subpages-nav mt-4 collapse-links">
    <template
      v-for="(subPage, index) in subPages"
      :key="index"
      template
    >
      <!-- <a
        :href="subPage.link"
        @click="isLinkActive(subPage.label)"
        :class="subPage.label === activeTab ? `active` : ''"
        preserve-scroll
        >{{ subPage.label }}
        <span v-if="subPage.label == 'Albums'" class="badge count">{{
          subPage.count
        }}</span>
      </a> -->
      <a
        :href="subPage.link"
        :class="subPage.label === activeTab ? `active` : ''"
        preserve-scroll
      >{{ subPage.label }}
        <span
          v-if="subPage.label == 'Albums'"
          class="badge count"
        >{{
          subPage.count
        }}</span>
      </a>
    </template>
  </nav>
</template>
<script>
import { onMounted, ref } from 'vue'
import router from '@/router'
export default {
  props: {
    subPages: {
      type: Object,
      required: true
    }
  },
  emits: ['active'],
  setup (props, { emit }) {
    const pageUrl = ref(router.currentRoute._value.href)
    const splitUrl = pageUrl.value.split('/')
    const activeTab = ref('Members')

    const isLinkActive = (link) => {
      activeTab.value = link
      // emit('active', link);
    }

    onMounted(() => {
      const url = pageUrl.value.split('/')
      if (url[3] === 'albums') {
        activeTab.value = 'Albums'
      } else if (url[3] === 'discussion-board') {
        activeTab.value = 'Discussion Board'
      } else if (url[3] === 'wall') {
        activeTab.value = 'Wall'
      } else {
        activeTab.value = 'Members'
      }
      emit('active', activeTab.value)
    })

    return {
      pageUrl,
      isLinkActive,
      activeTab,
      splitUrl
    }
  }
}
</script>
<style lang="scss">
.subpages-nav {
    display: flex;
    flex-direction: column;
    text-align: center;

    @media (min-width: 768px) {
      flex-direction: row;
      flex-wrap: wrap;
    }
  }

  .subpages-nav a {
    display: none;
    color: gray;
    font-weight: 600;
    text-decoration: none;
    padding-bottom: 0.75rem;
    margin: 0 1rem;
    @media (min-width: 768px) {
      display: inline-block;
      border-bottom: 4px solid transparent;
      padding-bottom: 1.5rem;
    }

    &:hover {
      color: #B4914D;
    }

    &.active {
      color: #B4914D;
      display: inline-block;
      order: -1;

      @media (min-width: 768px) {
        border-color: #B4914D;
        order: unset;
      }
    }
  }

  .subpages-nav.collapse-links a {
    display: inline-block;

    &.active {
      order: unset;
    }
  }

  .subpages-nav + div {
    @media (min-width: 768px) {
      display: none;
    }
  }
</style>
