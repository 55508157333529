<template>
  <ce-accordion-item>
    <template #header>
      <label class="d-block text-start small fw-semibold ls-1">CHILDREN</label>
    </template>
    <ce-checkboxes
      v-model="localValue"
      type="radio"
      class="preference-checkboxes pb-3"
      :options="$store.getters['option/childrenStatuses']"
      :option-value-key="'id'"
      :option-label-key="'value'"
      placeholder="No Preference"
    />
  </ce-accordion-item>
</template>

<script>
import { ref, watch } from 'vue'
import { useStore } from 'vuex'

export default {
  props: {
    modelValue: {
      type: [String, Number],
      default: null
    }
  },
  emits: ['update:modelValue'],
  setup (props, { emit }) {
    const store = useStore()
    store.dispatch('option/loadChildrenStatuses')
    const localValue = ref(null)

    /* Watchers */
    watch(
      localValue,
      (newLocalValue) => {
        emit('update:modelValue', newLocalValue)
      },
      {
        deep: true
      }
    )

    watch(
      () => props.modelValue,
      (newModelValue) => {
        localValue.value = newModelValue
      },
      {
        immediate: true
      }
    )

    return {
      localValue
    }
  }
}
</script>

<style lang="scss" scoped>
// @import '~@/scss/browse/browse-prefer-multiple-field';
</style>
