<template>
  <ce-button
    class="btn-sm btn-gradient-gold"
    type="button"
    @click="sendClick()"
  >
    <slot name="label">
      Send Message
    </slot>
  </ce-button>
</template>

<script>
import { computed, onMounted, ref } from 'vue'
// import { Inertia } from '@inertiajs/inertia';
// import { usePage } from '@inertiajs/inertia-vue3';
import { useStore } from 'vuex'
import CeButton from '@/components/Buttons/CeButton.vue'

const useConversation = () => {
  const store = useStore()
  const createConversation = (userId) =>
    store.dispatch('messaging/conversation/create', { friendId: userId })
  const smartAddWidget = (options) =>
    store.commit('messaging/widget/smartAddWidget', options)
  const sendHappyBirthday = (conversationId, userId) =>
    store.dispatch('messaging/conversation/sendConversationMessage', {
      conversationId,
      content: 'Happy Birthday!',
      userId
    })

  return {
    createConversation,
    smartAddWidget,
    sendHappyBirthday
  }
}

export default {
  name: 'ProfileButtonSendMessage',
  components: {
    CeButton
  },
  props: {
    userId: {
      type: Number,
      default: null
    },
    sendHappyBirthday: {
      type: Boolean,
      default: false
    }
  },
  setup (props) {
    const { createConversation, smartAddWidget, sendHappyBirthday } =
      useConversation()
    const premiumAccountAlert = ref(null)
    const redirectUrl = ref(null)
    const isProcessing = ref(false)
    const isVisible = computed(props.userId)
    // to re-enable later
    /*
    const hasMessagingAccess = computed(
      () => usePage().props.value.messaging.can_access_messaging
    );
    */
    const hasMessagingAccess = ref(false)

    // Methods
    const sendClick = async () => {
      if (!isVisible.value) {
        // Inertia.visit(`/login?redirect=${usePage().url.value}`);
        return false
      }

      // if (hasMessagingAccess.value) {
      isProcessing.value = true
      // create conversation and chat widget
      try {
        const conversation = await createConversation(props.userId)
        smartAddWidget({
          conversation,
          widgetOpen: true
        })

        if (props.sendHappyBirthday) {
          await sendHappyBirthday(conversation.id, props.userId)
        }
      } finally {
        isProcessing.value = false
      }
      // } else {
      //   premiumAccountAlert.value.open();
      // }
      return true
    }

    // Lifecycle Hooks
    onMounted(() => {
      redirectUrl.value = `${window.location.origin}/inbox?send_message_to=${props.userId}`
    })

    return {
      isVisible,
      hasMessagingAccess,
      premiumAccountAlert,
      sendClick,
      redirectUrl
    }
  }
}
</script>
