<template>
  <div>
    <div class="card card-success mb-4">
      <div class="card-body">
        <h1 class="m-0 ff-merriweather fs-1 text-nero fw-bold">Add a Group</h1>
      </div>
    </div>
    <div class="card border-bottom-1">
      <group-name-form class="form-1" @success="groupNameCreated">
        <template #title>
          <h1 class="fs-1 ff-merriweather fw-bold mb-5">
            Let’s get your group created!
          </h1>
        </template>
        <template #submit="{ submit, isSubmitting }">
          <div class="row">
            <div class="col-6 col-sm-6 mb-3">
              <ce-button
                class="btn-lg btn btn-gray text-white text-bold w-100"
                @click="cancelCreate()"
              >
                Cancel
              </ce-button>
            </div>
            <div class="col-6 col-sm-6 mb-3">
              <ce-button
                class="btn-lg btn-gradient-gold text-bold w-100"
                :is-loading="isSubmitting"
                :disabled="isSubmitting"
                @click="submit"
              >
                {{ isPublished ? "Save" : "Continue" }}
              </ce-button>
            </div>
          </div>
        </template>
      </group-name-form>
    </div>
  </div>
</template>

<script>
import { ref, defineAsyncComponent } from "vue";
import { useRouter } from "vue-router";
import { transformGroupResource, liveChatEnabledTypes } from "@/Use/groups.js";

export default {
  components: {
    GroupNameForm: defineAsyncComponent(() =>
      import("@/Modules/Group/GroupNameForm.vue")
    ),
  },
  props: {
    groupInfo: {
      type: Object,
      default: () => {},
    },
  },
  emits: ["isLoading", "onGoHome"],
  setup(props, { emit }) {
    const router = useRouter();
    const isPublished = ref(false);

    const groupNameCreated = async (response, page) => {
      router.replace({
        name: `group-edit-category-interest`,
        params: { slug: response.data.slug },
      });
    };

    const cancelCreate = () => {
      emit("onGoHome", true);
    };

    const liveChatEnabledOptions = [
      {
        label: "No",
        value: liveChatEnabledTypes.NO,
      },
      {
        label: "Yes",
        value: liveChatEnabledTypes.YES,
      },
    ];

    return {
      cancelCreate,
      isPublished,
      groupNameCreated,
      transformGroupResource,
      liveChatEnabledOptions,
    };
  },
};
</script>
<style lang="scss" scoped>
@import "../../scss/customizations";

:deep() {
  @import "../../scss/form/form-1";
}

.container {
  max-width: 680px;
}
.card-footer {
  background-color: #3a3b44;
  color: #fff;
  min-height: 83px;
}
.back-button {
  color: $color-nero;
  text-decoration: none;
  line-height: 1.2;
}

.form-column-wrap {
  max-width: 680px;
}
</style>
