<template>
  <div class="login-page">
    <img src="@/assets/img/bg_paw.png" class="paw-1" />
    <img src="@/assets/img/bg_paw.png" class="paw-2" />
    <img src="@/assets/img/bg_paw.png" class="paw-3" />
    <img src="@/assets/img/bg_paw.png" class="paw-4" />
    <img src="@/assets/img/bg_paw.png" class="paw-5" />
    <img src="@/assets/img/bg_paw.png" class="paw-6" />
    <img src="@/assets/img/dog_1.png" class="dog-1" />
    <img src="@/assets/img/cat_1.png" class="cat-1" />
    <div class="dog-2">
      <img src="@/assets/img/dog_2.png" />
      <div v-if="showCodeContainer" class="entercode-container">
        <img src="@/assets/img/bg_entercode.png" class="entercode" />
        <input
          v-model="passCode"
          class="inputcode"
          type="password"
          @keyup.enter="verifyOtp"
        />
      </div>
    </div>
    <img src="@/assets/img/cat_2.png" class="cat-2" />
    <img src="@/assets/img/paw_7.png" class="paw-7" @click="toggleLogin()" />
    <img src="@/assets/img/paw_8.png" class="paw-8" />
    <img src="@/assets/img/paw_9.png" class="paw-9" />
    <div v-if="showLogin" class="funny-login">
      <input v-model="username" type="password" @keyup.enter="checkUsername" />
    </div>
    <div v-if="showPass" class="funny-pass">
      <input v-model="password" type="password" @keyup.enter="login" />
    </div>
    <h1 class="login-header">
      Puppies and Kittens are The Best PerfectFriends!
    </h1>
    <div class="search-box-container">
      <div class="search-input-container">
        <i class="fa fa-search icon" />
        <input
          v-model="searchInput"
          class="search-box"
          type="text"
          @keyup.enter="submitSearchInput"
        />
      </div>
      <span v-if="showSearchInputResponse" class="search-response"
        >Thank you for your request!</span
      >
    </div>

    <div class="login-box" style="display: none">
      <div class="card card-outline card-primary">
        <div class="card-body">
          <p class="login-box-msg">Sign in to start your session</p>
          <form @submit.prevent="submit">
            <div class="input-group mb-3">
              <input
                v-model="username"
                type="text"
                class="form-control"
                placeholder="Email"
              />
              <div class="input-group-append">
                <div class="input-group-text">
                  <span class="fas fa-envelope" />
                </div>
              </div>
            </div>
            <div class="input-group mb-3">
              <input
                v-model="password"
                type="password"
                class="form-control"
                placeholder="Password"
              />
              <div class="input-group-append">
                <div class="input-group-text">
                  <span class="fas fa-lock" />
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-12 text-center mt-2 mb-3">
                <button type="submit" class="btn btn-primary btn-block">
                  Sign In
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
  <modal-component :modal-id="`loginModal`">
    <template #modalContent>
      <div
        class="modal-content"
        style="width: 300px; margin-left: auto; margin-right: auto"
      >
        <div class="row">
          <div class="col-md-12 col-lg-12 col-sm-12" style="text-align: center">
            <div class="message">
              <p>Puppies and Kittens!</p>
              <ce-button class="btn-gradient-gold text-center" @click="closeModal">Close</ce-button>
            </div>
          </div>
        </div>
      </div>
    </template>
  </modal-component>
  <modal-component :modal-id="`invalidOtpModal`">
    <template #modalContent>
      <div
        class="modal-content"
        style="width: 300px; margin-left: auto; margin-right: auto"
      >
        <div class="row">
          <div class="col-md-12 col-lg-12 col-sm-12" style="text-align: center">
            <div class="message">
              <p>Invalid {{ type }}!</p>
              <ce-button class="btn-gradient-gold text-center" @click="closeModal">Close</ce-button>
            </div>
          </div>
        </div>
      </div>
    </template>
  </modal-component>
</template>
<script>
import { ref } from "vue";
import {
  adminLogin,
  checkExistence,
  adminOtpVerify,
} from "@/composable/use-login.js";
import router from "@/router";
import ModalComponent from "@/components/ModalComponent.vue";
import $ from "jquery";
import { useStore } from "vuex";

export default {
  components: {
    ModalComponent,
  },
  setup() {
    const store = useStore();
    const isModalVisible = ref(false);
    const username = ref("");
    const password = ref("");
    const passCode = ref("");
    const isLoginShown = ref(false);
    const isSubmitting = ref(false);
    const showLogin = ref(false);
    const showPass = ref(false);
    const searchInput = ref(null);
    const showSearchInputResponse = ref(false);
    const submitSearchInput = () => {
      showSearchInputResponse.value = true;
      console.log("JQD", "submitSearchInput");
      searchInput.value = "";

      setTimeout(() => {
        showSearchInputResponse.value = false;
      }, 2000);
    };
    const showCodeContainer = ref(false);
    const type = ref('Password');

    localStorage.removeItem("token");
    localStorage.removeItem("user");

    /* Methods */
    const submit = async () => {
      try {
        isSubmitting.value = true;
        const form = {
          user_name: username.value,
          password: password.value,
          remember: true,
        };
        const login = await adminLogin(form);
        localStorage.setItem("token", login.data.data.token);
        localStorage.setItem("user", JSON.stringify(login.data.data.user));
        store.commit("SET_AUTH_USER", login.data.data.user);

        showCodeContainer.value = true;
        showPass.value = false;
      } catch (error) {
        password.value = "";
        showInvalidOtpModal('Password');
        isSubmitting.value = false;
      }
    };

    const verifyOtp = async () => {
      const user = JSON.parse(localStorage.getItem("user"));

      const form = {
        otp: passCode.value,
        uid: user.id,
      };

      adminOtpVerify(form)
        .then((result) => {
          localStorage.removeItem("user");
          window.location.href = '/home';
        })
        .catch((err) => {
          showInvalidOtpModal('OTP');
        });
    };

    const toggleLogin = () => {
      if (!isLoginShown.value) {
        showLogin.value = !showLogin.value;
      }
    };

    const showInvalidOtpModal = (data) => {
      type.value = data;
      $("#invalidOtpModal").modal("show");
    };

    const showModal = () => {
      $("#loginModal").modal("show");
    };

    const cancel = () => {
      isModalVisible.value = false;
      $("#loginModal").modal("hide");
    };

    const checkUsername = () => {
      checkExistence(username.value)
        .then((result) => {
          if (result.data.success === true) {
            showPass.value = true;
            showLogin.value = false;

            isLoginShown.value = true;
          }
        })
        .catch((err) => {
          showModal();
        });
    };

    const closeModal = () => {
      $("#loginModal").modal("hide");
      $("#invalidOtpModal").modal("hide");
    }

    const login = () => {
      submit();
    };

    return {
      passCode,
      password,
      username,
      isSubmitting,
      submit,
      showLogin,
      showPass,
      toggleLogin,
      checkUsername,
      showModal,
      cancel,
      login,
      submitSearchInput,
      searchInput,
      showSearchInputResponse,
      showCodeContainer,
      verifyOtp,
      showInvalidOtpModal,
      closeModal,
      type
    };
  },
};
</script>

<style lang="scss" scoped>
.login-page {
  background: rgb(218, 189, 133);
  background: linear-gradient(
    11deg,
    rgba(218, 189, 133, 1) 0%,
    rgba(255, 246, 222, 1) 100%
  );
  justify-content: start;
  padding-top: 40px;
  width: 100%;
  position: absolute;
}

.login-header {
  color: #b4914d;
  font-weight: 900;
  font-family: "Merriweather", serif;
  text-align: center;
}
.search-box-container {
  max-width: 500px;
  width: 80%;
  display: flex;
  flex-direction: column;
  margin: 20px;
}

.search-input-container {
  display: flex;
  flex-direction: row;
  border: 1px solid #f4deb1;
  border-radius: 25px;
  box-sizing: border-box;
  background-color: white;
  padding-left: 10px;
  padding-right: 20px;
}

.search-response {
  color: #b4914d;
  margin-left: 20px;
}

input[type="text"],
input[type="password"],
select,
textarea {
  width: 100%;
  padding: 11px;
  border: 0;
  resize: vertical;
}

input:focus {
  outline: none;
}

.icon {
  padding-top: 15px;
  padding-bottom: 15px;
  padding-left: 5px;
  background: transparent;
  color: #dfd0b3;
  min-width: 10px;
  text-align: center;
}

.paw-1 {
  position: absolute;
  z-index: 0;
  left: 415px;
  top: 196px;
}

.paw-2 {
  position: absolute;
  z-index: 0;
  left: 309px;
  top: 180px;
  width: 64px;
  transform: rotate(-10deg);
}

.paw-3 {
  position: absolute;
  z-index: 0;
  left: 92px;
  top: 267px;
  width: 140px;
  transform: rotate(-45deg);
}

.paw-4 {
  position: absolute;
  z-index: 0;
  right: 150px;
  top: 217px;
  width: 95px;
  transform: rotate(-45deg);
}

.paw-5 {
  position: absolute;
  z-index: 0;
  right: 150px;
  bottom: 57px;
  width: 64px;
  transform: rotate(-45deg);
}

.paw-6 {
  position: absolute;
  z-index: 0;
  right: 500px;
  bottom: 0px;
  width: 104px;
  transform: rotate(-45deg);
}

.dog-1 {
  position: absolute;
  z-index: 0;
  left: 110px;
  top: 311px;
}

.dog-2 {
  position: absolute;
  z-index: 0;
  left: 475px;
  bottom: 10px;

  .entercode {
    position: absolute;
    bottom: 30px;
    left: -60px;
  }

  .inputcode {
    position: absolute;
    bottom: 60px;
    left: 0;
    height: 35px;
    border-radius: 10px;
  }
}

.cat-1 {
  position: absolute;
  z-index: 0;
  left: 134px;
  bottom: 0px;
}

.cat-2 {
  position: absolute;
  z-index: 0;
  right: 20px;
  top: 365px;
}

.paw-9 {
  position: absolute;
  z-index: 0;
  right: 0px;
  top: 134px;
}

.paw-8 {
  position: absolute;
  z-index: 0;
  right: 100px;
  bottom: 0px;
}

.paw-7 {
  position: absolute;
  z-index: 0;
  bottom: 100px;
  left: 288px;
}

.funny-login {
  position: absolute;
  top: 200px;
  right: 200px;
  z-index: 999;
  width: 320px;
  height: 353px;
  background-image: url("~@/assets/img/bg_login.png");
  text-align: center;

  input[type="password"] {
    width: 280px;
    position: relative;
    top: 140px;
    border-radius: 14px;
    height: 30px;
  }
}

.funny-pass {
  position: absolute;
  bottom: 0;
  right: 200px;
  z-index: 999;
  width: 382px;
  height: 298px;
  background-image: url("~@/assets/img/bg_bone.png");
  text-align: center;

  input[type="password"] {
    width: 280px;
    position: relative;
    top: 105px;
    border-radius: 14px;
    height: 35px;
  }
}

.message {
  background: white;
  border: #b4914d;
  padding: 50px;
  width: 300px !important;
  text-align: center;
  border-radius: 5px;
  margin-left: auto;
  margin-right: auto;
  color: #b4914d;
  font-weight: 700;
  font-family: "Merriweather", serif;
}
</style>
