import { defineAsyncComponent } from 'vue'

export default {
  install: (app) => {
    app.component(
      'CeForm',
      defineAsyncComponent(() => import('@/components/Forms/CeForm.vue'))
    )

    app.component(
      'CeButton',
      defineAsyncComponent(() => import('@/components/Buttons/CeButton.vue'))
    )

    app.component(
      'CeText',
      defineAsyncComponent(() => import('@/components/Inputs/CeText.vue'))
    )

    app.component(
        'CeTextSearchAutoComplete',
        defineAsyncComponent(() => import('@/components/Inputs/CeTextSearchAutoComplete.vue'))
      )

    app.component(
      'CeTextarea',
      defineAsyncComponent(() => import('@/components/Inputs/CeTextarea.vue'))
    )

    app.component(
      'CeSelect',
      defineAsyncComponent(() => import('@/components/Inputs/CeSelect.vue'))
    )

    app.component(
      'CeCheckbox',
      defineAsyncComponent(() => import('@/components/Inputs/CeCheckbox.vue'))
    )

    app.component(
      'CeCheckboxes',
      defineAsyncComponent(() => import('@/components/Inputs/CeCheckboxes.vue'))
    )

    app.component(
      'CeRange',
      defineAsyncComponent(() => import('@/components/Inputs/CeRange.vue'))
    )

    app.component(
      'CeSlider',
      defineAsyncComponent(() => import('@/components/Inputs/CeSlider.vue'))
    )

    app.component(
      'CeImage',
      defineAsyncComponent(() => import('@/components/Images/CeImage.vue'))
    )

    // app.component(
    //   'CeNotificationCount',
    //   defineAsyncComponent(() =>
    //     import('@/components/Utilities/CeNotificationCount.vue')
    //   )
    // );

    app.component(
      'CeVisualMediaPicker',
      defineAsyncComponent(() =>
        import('@/components/Utilities/CeVisualMediaPicker.vue')
      )
    )

    app.component(
      'CePhotoCropper',
      defineAsyncComponent(() =>
        import('@/components/Utilities/CePhotoCropper.vue')
      )
    )

    app.component(
      'CeAspectRatio',
      defineAsyncComponent(() =>
        import('@/components/Utilities/CeAspectRatio.vue')
      )
    )

    app.component(
      'CeInfiniteResult',
      defineAsyncComponent(() =>
        import('@/components/Utilities/CeInfiniteResult.vue')
      )
    )

    app.component(
      'CeAlbumInfiniteResult',
      defineAsyncComponent(() =>
        import('@/components/Utilities/CeAlbumInfiniteResult.vue')
      )
    )

    app.component(
      'CeAccordion',
      defineAsyncComponent(() =>
        import('@/components/Accordion/CeAccordion.vue')
      )
    )

    app.component(
      'CeAccordionItem',
      defineAsyncComponent(() =>
        import('@/components/Accordion/CeAccordionItem.vue')
      )
    )

    app.component(
      'CeVideoPlayer',
      defineAsyncComponent(() =>
        import('@/components/MediaPlayers/CeVideoPlayer.vue')
      )
    )

    app.component(
      'CeModal',
      defineAsyncComponent(() => import('@/components/Modal/CeModal.vue'))
    )

    // app.component(
    //   'CeToast',
    //   defineAsyncComponent(() => import('@/components/Toasts/CeToast.vue'))
    // );

    // app.component(
    //   'CeCarousel',
    //   defineAsyncComponent(() =>
    //     import('@/components/Carousels/CeCarousel.vue')
    //   )
    // );

    app.component(
      'FormText',
      defineAsyncComponent(() => import('@/components/Forms/FormText.vue'))
    )
  }
}
