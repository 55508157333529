import http from '@/http.js'

export default {
  upload: (file) => {
    const data = new FormData()
    data.append('file', file)
    return http.post('/media', data, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
  },

  uploadCropFor: (id, file) => {
    const data = new FormData()
    data.append('file', file)
    return http.post(`/media/crop/${id}`, data, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
  },

  uploadOriginalAndModified: (originalFile, modifiedFile) => {
    const data = new FormData()
    data.append('full_image', originalFile)
    data.append('image', modifiedFile)
    return http.post('/image', data, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
  },

  get: (id) => http.get(`/media/${id}`),
  mediaUploadVideo: (file) => {
    const data = new FormData()
    data.append('file', file)
    return http.post('/media/video', data, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
  }
}
