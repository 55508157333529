<template>
  <event-admin-form
    class="form-1"
    :type="gatheringType"
    :event-id="eventForm.id"
    :event-owner="eventForm.owner"
    :is-owner="eventForm.is_owner"
    :abilities="eventForm.abilities"
    :data="{
      admins: eventForm.roles.map((admin) => ({
        id: admin.user.id,
        firstName: admin.user.first_name,
        lastName: admin.user.last_name,
        primaryPhotoUrl: admin.user.primary_photo?.sizes?.thumbnail,
        username: admin.user.user_name,
        role: admin.role.id,
      })),
    }"
    @success="eventCreate"
  >
    <template #title>
      <h1 class="fs-1">
        Identify admins for your {{ dynamicWordFor("event") }}
      </h1>
    </template>
    <template #submit="{ submit, isSubmitting }">
      <div class="row">
        <div class="col-6 col-sm-6 mb-3">
          <ce-button
            class="btn-lg btn btn-gray text-white text-bold w-100"
            @click="$emit('cancelEdit')"
          >
            Cancel
          </ce-button>
        </div>
        <div class="col-6 col-sm-6 mb-3">
          <ce-button
            class="btn-lg btn-gradient-gold text-bold w-100"
            :is-loading="isSubmitting"
            :disabled="isSubmitting"
            @click="submit"
          >
            {{ isPublished ? "Save" : "Continue" }}
          </ce-button>
        </div>
      </div>
    </template>
  </event-admin-form>
</template>

<script>
import { defineAsyncComponent, computed, ref, watch } from "vue";
import { useStore } from "vuex";
import { useRoute, useRouter } from "vue-router";
import { GATHERING_TYPES, useDynamicWord } from "@/Use/events.js";

export default {
  components: {
    EventAdminForm: defineAsyncComponent(() =>
      import("@/Modules/Event/EventAdminForm.vue")
    ),
  },
  props: {
    gatheringType: {
      type: Number,
      default: GATHERING_TYPES.EVENT,
    },
  },
  emits: ["createEvent", "eventIsPublished", "isLoading", "cancelEdit"],
  async setup(props, { emit }) {
    const store = useStore();
    const route = useRoute();
    const router = useRouter();

    const eventId = ref(router.currentRoute._value.params.id);
    const isLoaded = ref(false);

    const { dynamicWordFor } = useDynamicWord({
      gatheringType: props.gatheringType,
    });

    emit("isLoading", true);
    const getEventInfoResource = await store.dispatch("eventEdit", {
      id: eventId.value,
      api: "admin",
    });
    emit("isLoading", false);

    const eventCreate = (response) => {
      emit("createEvent", response.data, {
        steps: "admin",
        isPublished: getEventInfoResource.data.isPublished,
      });
    };

    const eventForm = computed(() => getEventInfoResource.data.form);
    const gatheringType = computed(
      () => getEventInfoResource.data.gatheringType
    );
    const isPublished = computed(() => getEventInfoResource.data.isPublished);

    emit("eventIsPublished", isPublished);

    return {
      dynamicWordFor,
      isPublished,
      eventCreate,
      eventForm,
      isLoaded,
    };
  },
};
</script>

<style lang="scss" scoped>
:deep() {
  @import "../../scss/form/form-1";
}

.container {
  max-width: 680px;
}
</style>
