<template>
  <template v-if="(event.isOwner || event.canEditEvent) && !event.isPast">
    <router-link :to="`/event/${event.slug}/edit`"
      ><slot></slot
    ></router-link>
  </template>
</template>

<script>
export default {
  props: {
    event: {
      type: Object,
      required: true,
    },
  },
  setup() {},
};
</script>
