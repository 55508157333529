<template>
  <event-review-publish-form
    class="form-1"
    :event-id="eventForm.id"
    :type="gatheringType"
    :slug="slug"
    :data="{
      bannerPhotoSrc: eventForm.banner_photo?.modified,
      previewVideoSrc: eventForm.preview_video?.original,
      name: eventForm.name,
      setting: eventForm.setting,
      venue: {
        venue: eventForm.venue?.name,
        city: eventForm.venue?.city,
        state: eventForm.venue?.state,
        zipCode: eventForm.venue?.zip_code,
        lat: eventForm.venue?.latitude,
        lng: eventForm.venue?.longitude,
      },
      secondary_location: eventForm.secondary_location,
      rsvp_type: eventForm.rsvp_type,
      max_capacity: eventForm.max_capacity,
      chat_enabled: !!eventForm.live_chat_enabled,
      chat_type: eventForm.live_chat_type,
      interests: eventForm.interests.map((interest) => interest.interest),
      category: eventForm.category,
      type: eventForm.type,
      description: eventForm.description,
      admins: eventForm.roles.map((admin) => ({
        id: admin.user.id,
        firstName: admin.user.first_name,
        lastName: admin.user.last_name,
        primaryPhotoUrl: admin.user.primary_photo,
        username: admin.user.user_name,
        role: admin.role.id,
      })),
      invites: eventForm.roles.map((invite) => ({
        id: invite.user.id,
        firstName: invite.user.first_name,
        lastName: invite.user.last_name,
        primaryPhotoUrl: invite.user.primary_photo,
        username: invite.user.user_name,
      })),
      start: {
        date: eventForm.event_start,
        time: eventForm.start_time,
      },
      end: {
        date: eventForm.event_end,
        time: eventForm.end_time,
      },
      timezone: eventForm.timezone?.label,
    }"
    :is-published="reviewAndPublish.isPublished"
    @success="eventCreate"
    @fail="eventFail"
  >
    <template #title>
      <h1 class="fs-1 mb-5">Please review the information you provided</h1>
    </template>
    <template #invite>
      <template v-if="reviewAndPublish.isPublished">
        <hr class="bg-light-gray my-5" />

        <div class="row align-items-center mt-5">
          <div class="col-sm">
            <h2 class="fs-2">Invite People</h2>
          </div>
        </div>
        <div class="row pb-5 mb-5 mt-2 gy-2">
          <div class="col-md-6">
            <router-link
              class="btn btn-gradient-gold w-100 h-100 d-flex align-items-center justify-content-center"
              :to="{
                name: `${dynamicWordFor('event')}-edit-invite-past-events`,
                params: { id: eventForm.id },
              }"
            >
              Invite from Groups, past events and huddles
            </router-link>
          </div>
          <div class="col-md-6">
            <router-link
              class="btn btn-gradient-gold w-100 h-100 d-flex align-items-center justify-content-center"
              :to="{
                name: `${dynamicWordFor('event')}-edit-invite-friends`,
                params: { id: eventForm.id },
              }"
            >
              Invite your remaining friends
            </router-link>
          </div>
        </div>
      </template>
    </template>
    <template #submit="{ submit, isSubmitting }">
      <div class="row">
        <div
          class="mb-3"
          :class="
            reviewAndPublish.isPublished ? 'col-12 col-sm-12' : 'col-6 col-sm-6'
          "
        >
          <ce-button
            class="btn-lg btn btn-gray text-white text-bold w-100"
            @click="$emit('cancelEdit')"
          >
            {{ reviewAndPublish.isPublished ? "Close" : "Cancel" }}
          </ce-button>
        </div>
        <div class="col-6 col-sm-6 mb-3" v-if="!reviewAndPublish.isPublished">
          <ce-button
            class="btn-lg btn-gradient-gold text-bold w-100"
            :is-loading="isSubmitting"
            :disabled="isSubmitting"
            @click="submit"
          >
            {{ reviewAndPublish.isPublished ? "Continue" : "Publish" }}
          </ce-button>
        </div>
      </div>
    </template>
  </event-review-publish-form>
</template>

<script>
import { defineAsyncComponent, computed, ref, reactive } from "vue";
import { useStore } from "vuex";
import { useRoute, useRouter } from "vue-router";
import { GATHERING_TYPES, useDynamicWord } from "@/Use/events.js";

export default {
  components: {
    EventReviewPublishForm: defineAsyncComponent(() =>
      import("@/Modules/Event/EventReviewPublishForm.vue")
    ),
  },
  props: {
    gatheringType: {
      type: Number,
      default: GATHERING_TYPES.EVENT,
    },
  },
  emits: ["createEvent", "eventIsPublished", "isLoading", "cancelEdit"],
  async setup(props, { emit }) {
    const store = useStore();
    const route = useRoute();
    const router = useRouter();

    const eventId = ref(router.currentRoute._value.params.id);
    const isLoaded = ref(false);

    const { dynamicWordFor } = useDynamicWord({
      gatheringType: props.gatheringType,
    });

    emit("isLoading", true);
    const getEventInfoResource = await store.dispatch("eventEdit", {
      id: eventId.value,
      api: "review-and-publish",
    });
    emit("isLoading", false);

    const slug = computed(() => getEventInfoResource.data.slug);

    const eventForm = computed(() => getEventInfoResource.data.form);
    const gatheringType = computed(
      () => getEventInfoResource.data.gatheringType
    );

    let hasPublished = false;

    const reviewAndPublish = reactive({
      isPublished: computed(() => getEventInfoResource.data.isPublished),
    });

    const eventCreate = (response) => {
      if (!reviewAndPublish.isPublished) {
        router.push({
          name: `${dynamicWordFor("event")}-edit-invite-past-events`,
          params: { id: getEventInfoResource.data.id },
        });
      } else {
        reviewAndPublish.isPublished = computed(
          () => response.data.data.is_published
        );
      }
    };
    const eventFail = (response) => {};

    emit("eventIsPublished", reviewAndPublish.isPublished);

    return {
      dynamicWordFor,
      reviewAndPublish,
      eventCreate,
      eventFail,
      eventForm,
      isLoaded,
      gatheringType,
      slug,
      hasPublished,
    };
  },
};
</script>

<style lang="scss" scoped>
:deep() {
  @import "../../scss/form/form-1";
}

.container {
  max-width: 680px;
}
</style>
