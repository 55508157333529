<template>
  <template v-if="!event.isOwner && !event.isPast">
    <ce-button
      v-if="!isUserAdmin"
      class="text-white position-relative"
      :class="[isUserInterested ? classWhenInterested : classWhenNotInterested]"
      :is-loading="isSettingInterestStatus"
      :disabled="isSettingInterestStatus"
      @click.stop="askFirst()"
    >
      <!-- <transition name="fade">
        <ce-icon
          v-if="interestRequestStatusIcon"
          :icon="interestRequestStatusIcon"
          class="interest-request-status"
        />
      </transition> -->
      <slot :is-interested="isUserInterested">
        <template v-if="event.gatheringType === GATHERING_TYPES.EVENT">
          <template v-if="event.rsvpType === RSVP_TYPES.VIP_ONLY">
            {{
              isUserInterested
                ? 'VIP request submitted'
                : 'Submit a request to be added to the VIP Guest List'
            }}
          </template>
          <template v-else>
            {{
              isUserInterested ? 'You have RSVP’d to this event!' : 'RSVP Now'
            }}
          </template>
        </template>
        <template v-else-if="event.gatheringType === GATHERING_TYPES.HUDDLE">
          {{ isUserInterested ? 'Leave Huddle' : 'Join Huddle' }}
        </template>
      </slot>
    </ce-button>
  </template>
</template>

<script>
import { inject, onMounted, ref, computed } from 'vue'
import { useStore } from 'vuex'
import debounce from 'lodash/debounce.js'
import { Inertia } from '@inertiajs/inertia'
import { usePage } from '@inertiajs/inertia-vue3'
import {
  useInterest,
  useEvent,
  GATHERING_TYPES,
  RSVP_TYPES
} from '@/Use/events.js'

export default {
  name: 'EventInterestButton',
  props: {
    event: {
      type: Object,
      required: true
    },
    classWhenInterested: {
      type: String,
      default: 'btn-red'
    },
    classWhenNotInterested: {
      type: String,
      default: 'btn-green'
    }
  },
  emits: ['rsvpStatus', 'rsvpd', 'unrsvpd'],
  setup (props, { emit }) {
    const store = useStore()
    const {
      isSettingInterestStatus,
      isUserInterested,
      markCurrentUserAsInterested
    } = useInterest()
    const { isUserLoggedIn } = useEvent()
    const interestRequestStatusIcon = ref(null)
    const interestRequestMessage = ref('')

    const dialog = inject('dialog')
    const postToWall = ref(false)

    const clearInterestRequestState = debounce(() => {
      interestRequestStatusIcon.value = null
      interestRequestMessage.value = null
    }, 1500)

    const admins = ref(props.event.admins)
    const currentUserId = computed(() => usePage().props.value.auth.data.id)

    // Methods
    const isUserAdmin = computed(() => {
      const findUser = admins.value.find(
        (admin) => admin.id === currentUserId.value
      )
      return !!findUser
    })

    const rsvpNow = async (eventId) => {
      if (isUserLoggedIn.value) {
        interestRequestStatusIcon.value = null
        interestRequestMessage.value = ''

        const status = !isUserInterested.value
        try {
          // Will move this at the top to be visible when emiting
          emit('rsvpStatus', status)

          await markCurrentUserAsInterested(
            eventId,
            status,
            dialog,
            postToWall.value
          )

          interestRequestStatusIcon.value = 'check'
          // Add a listener to this new event
          if (status) {
            store.dispatch('listener/listenToNewEvent', eventId)
            emit('rsvpd')
          } else {
            store.dispatch('listener/unlistenToEvent', eventId)
            emit('unrsvpd')
          }
        } finally {
          isSettingInterestStatus.value = false
          clearInterestRequestState()
        }
      } else {
        await Inertia.visit(
          `/login?redirect=events/${props.event.slug}?auto-join=1&postToWall=${postToWall.value}`
        )
      }
    }

    const markCurrentUserAsInterestedIfLoggedIn = (eventId) => {
      if (isUserInterested.value) {
        const texts = {
          [GATHERING_TYPES.EVENT]: () =>
            props.event.rsvpType === RSVP_TYPES.VIP_ONLY
              ? 'You are about to cancel your VIP Guest List request'
              : 'You are about to cancel your RSVP on this event',
          [GATHERING_TYPES.HUDDLE]: () => 'You are about to leave this huddle'
        }

        dialog.show('Are you sure about this?', {
          title: texts[props.event.gatheringType](),
          buttons: [
            {
              label: 'Yes',
              class: 'btn-gradient-gold',
              handler: (closeDialog) => {
                rsvpNow(eventId) // Cancel RSVP
                closeDialog()
              }
            },
            {
              label: 'No',
              class: 'btn-light-gray',
              handler: (closeDialog) => {
                closeDialog()
              }
            }
          ]
        })
        return
      }

      rsvpNow(eventId)
    }

    onMounted(() => {
      isUserInterested.value = props.event.isUserInterested
    })

    const askFirst = () => {
      if (isUserInterested.value) {
        markCurrentUserAsInterestedIfLoggedIn(props.event.id)
        return
      }

      const dialogButtons = [
        {
          label: 'Yes',
          class: 'btn-gradient-gold',
          handler: (closeDialog) => {
            postToWall.value = true
            markCurrentUserAsInterestedIfLoggedIn(props.event.id)
            closeDialog()
          }
        },
        {
          label: 'No',
          class: 'btn-light-gray',
          handler: (closeDialog) => {
            markCurrentUserAsInterestedIfLoggedIn(props.event.id)
            closeDialog()
          }
        }
      ]

      const texts = {
        [GATHERING_TYPES.EVENT]: () =>
          props.event.rsvpType === RSVP_TYPES.VIP_ONLY
            ? 'Your request is being submitted for approval. You will be notified by email and sent a ticket if approved'
            : 'You are about to RSVP to this event',
        [GATHERING_TYPES.HUDDLE]: () => 'You are about to join this huddle'
      }

      dialog.show('Do you want to post this on your wall?', {
        title: texts[props.event.gatheringType](),
        buttons: dialogButtons
      })
    }

    return {
      dialog,
      GATHERING_TYPES,
      RSVP_TYPES,
      isSettingInterestStatus,
      isUserInterested,
      interestRequestStatusIcon,
      interestRequestMessage,
      markCurrentUserAsInterestedIfLoggedIn,
      askFirst,
      isUserAdmin
    }
  }
}
</script>

<style scoped>
.interest-request-status {
  position: absolute;
  top: 50%;
  transform: translate(-130%, -40%);
  font-size: 0.8rem;
}
</style>
