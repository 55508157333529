<template>
    <ce-checkbox
        v-model="localValue"
        is-switch
        style="margin-left: 40px;"
    >
        <template #prepend="{ value }">
        {{ value ? 'Active' : 'Inactive' }}
        </template>
    </ce-checkbox>
</template>

<script>
import { ref, watch } from 'vue'

export default {
  props: {
    modelValue: {
      type: Boolean,
      default: false
    }
  },
  emits: ['update:modelValue'],
  setup (props, { emit }) {
    const localValue = ref(false)

    /* Watchers */
    watch(
      localValue,
      (newLocalValue) => {
        emit('update:modelValue', newLocalValue)
      },
      {
        deep: true
      }
    )

    watch(
      () => props.modelValue,
      (newModelValue) => {
        localValue.value = newModelValue
      },
      {
        immediate: true
      }
    )

    return {
      localValue
    }
  }
}
</script>
