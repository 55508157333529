import axios from 'axios'

export const { CancelToken } = axios

const token = localStorage.getItem('token')
if (token) {
  axios.defaults.headers.common.Authorization = 'Bearer ' + token
}

export default axios.create({
  baseURL: process.env.VUE_APP_API_URL || window.location.origin
})
