<template>
  <ce-accordion-item disabled>
    <template #header>
      <div class="d-flex align-items-center justify-content-between">
        <label
          class="d-block text-start small fw-semibold ls-1"
        >VIEWED ME</label>
        <ce-checkbox
          v-model="localValue"
          is-switch
        >
          <template #prepend="{ value }">
            {{ value ? 'On' : 'Off' }}
          </template>
        </ce-checkbox>
      </div>
    </template>
  </ce-accordion-item>
</template>

<script>
import { ref, watch } from 'vue'

export default {
  props: {
    modelValue: {
      type: Boolean,
      default: false
    }
  },
  emits: ['update:modelValue'],
  setup (props, { emit }) {
    const localValue = ref(false)

    /* Watchers */
    watch(
      localValue,
      (newLocalValue) => {
        emit('update:modelValue', newLocalValue)
      },
      {
        deep: true
      }
    )

    watch(
      () => props.modelValue,
      (newModelValue) => {
        localValue.value = newModelValue
      },
      {
        immediate: true
      }
    )

    return {
      localValue
    }
  }
}
</script>
