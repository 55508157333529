<template>
  <Preloader :is-loading="isLoading"></Preloader>
  <div class="card">
    <div class="card-body">
      <table id="report-table" class="table table-items">
        <thead>
          <tr>
            <th scope="col" style="width: 10px !important">
              <h1 class="fs-14 fw-bold color-gray">
                REPORTED CONTENT
              </h1>
            </th>
            <th scope="col" style="width: 10px !important">
              <h1 class="fs-14 fw-bold color-gray">
                TYPE
              </h1>
            </th>
            <th scope="col">
              <h1 class="fs-14 fw-bold color-gray">
                REMARKS
              </h1>
            </th>
            <th scope="col">
              <h1 class="fs-14 fw-bold color-gray">ATTACHMENT</h1>
            </th>
            <th scope="col">
              <h1 class="fs-14 fw-bold color-gray">
                CATEGORY
              </h1>
            </th>
            <th scope="col">
              <h1 class="fs-14 fw-bold color-gray">
                REPORTER
              </h1>
            </th>
            <th scope="col">
              <h1 class="fs-14 fw-bold color-gray">TIMESTAMP</h1>
            </th>
            <th scope="col">
              <h1 class="fs-14 fw-bold color-gray">STATUS</h1>
            </th>
            <th scope="col">
              <h1 class="fs-14 fw-bold color-gray ml-3">ACTION</h1>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="row in reports" :key="row.in" class="fs-14">
            <td>
              <span class="d-none">{{ row.id }}</span>
              <span v-if="row?.report?.type === REPORT.USER || row?.report?.type === REPORT.USERPROFILE">
                {{ row?.report?.details?.first_name + ' ' + row?.report?.details?.last_name}}
              </span>
              <span v-if="row?.report?.type === REPORT.EVENT">
                {{ row?.report?.details?.title }}
              </span>
              <span class="text-capitalize" v-if="row?.report?.type === REPORT.CONVERSATION">
               {{ 
                row?.report?.details?.table_lookup === CHAT_TYPES.GROUP  ? 'Group' 
                : row?.report?.details?.table_lookup === CHAT_TYPES.EVENT  ? 'Event' 
                : row?.report?.details?.table_lookup === CHAT_TYPES.PRIVATE ? 'Personal Message' 
                : row?.report?.details?.table_lookup === CHAT_TYPES.EVENT_INQUERY ? 'Event Inquery' 
                : row?.report?.details?.table_lookup === CHAT_TYPES.CUSTOM_GROUP ? 'Group' 
                : row?.report?.details?.table_lookup === CHAT_TYPES.PERSONAL_MESSAGE_EVENTS ? 'Personal Message Event' 
                : row?.report?.details?.table_lookup === CHAT_TYPES.PERSONAL_MESSAGE_GROUP ? 'Personal Message Group' 
                : row?.report?.details?.table_lookup === CHAT_TYPES.TALK ? 'Talk' 
                : 'Message to all' }} : 
                <span 
                  v-if="
                    row?.report?.details?.table_lookup === CHAT_TYPES.PERSONAL_MESSAGE_EVENTS || 
                    row?.report?.details?.table_lookup === CHAT_TYPES.PERSONAL_MESSAGE_GROUP"
                > {{ row?.report?.details?.name }} 
                </span>
                <span v-else> 
                  {{ row?.report?.details?.name?.first_name + ' ' + row?.report?.details?.name?.last_name }} 
                </span>
              </span>
              <span v-if="row?.report?.type === REPORT.CHATMESSAGE">
                {{ row?.report?.details?.message }}
              </span>
              <span v-if="row?.report?.type === REPORT.GAME">
                {{ row?.report?.details?.name }}
              </span>
              <span v-if="row?.report?.type === REPORT.TALK">
                {{ row?.report?.details?.title }}
              </span>
              <span v-if="row?.report?.type === REPORT.BADGE">
                {{ row?.report?.details?.name }}
              </span>
              <span v-if="row?.report?.type === REPORT.EVENTWALLDISCUSSION">
                {{ row?.report?.details?.body }}
              </span>
              <span v-if="row?.report?.type === REPORT.EVENTWALLDISCUSSIONCOMMENT">
                {{ row?.report?.details?.comment }}
              </span>
              <span v-if="row?.report?.type === REPORT.EVENTDISCUSSIONBOARD">
                {{ row?.report?.details?.title }}
              </span>
              <span v-if="row?.report?.type === REPORT.EVENTDISCUSSIONCOMMENT">
                {{ row?.report?.details?.comment }}
              </span>
              <span v-if="row?.report?.type === REPORT.GROUP">
                {{ row?.report?.details?.name }}
              </span>
              <span v-if="row?.report?.type === REPORT.GROUPWALLDISCUSSION">
                {{ row?.report?.details?.body }}
              </span>
              <span v-if="row?.report?.type === REPORT.GROUPWALLDISCUSSIONCOMMENT">
                {{ row?.report?.details?.comment }}
              </span>
              <span v-if="row?.report?.type === REPORT.GROUPDISCUSSION">
                {{ row?.report?.details?.title }}
              </span>
              <span v-if="row?.report?.type === REPORT.GROUPCOMMENT">
                {{ row?.report?.details?.comment   }}
              </span>
              <span v-if="row?.report?.type === REPORT.MEDIA">
                <ce-image :src="row?.report?.details?.sizes?.sm" @click="showReportedMedia(row?.report?.details?.sizes?.sm)" class="rounded mr-3 tbl-img"/>
              </span>
              <span v-if="row?.report?.type === REPORT.USERWALL">
                {{ row?.report?.details?.body }}
              </span>
            </td>
            <td class="text-capitalize">
              <span v-if="row?.report?.type === REPORT.EVENTWALLDISCUSSION || row?.report?.type === REPORT.EVENTDISCUSSIONBOARD ||row?.report?.type === REPORT.GROUPWALLDISCUSSION || row?.report?.type === REPORT.GROUPDISCUSSION">
                Discussion
              </span>
              <span v-else-if="row?.report?.type === REPORT.EVENTWALLDISCUSSIONCOMMENT || row?.report?.type === REPORT.EVENTDISCUSSIONCOMMENT ||row?.report?.type === REPORT.GROUPWALLDISCUSSIONCOMMENT || row?.report?.type === REPORT.GROUPCOMMENT">
                Comment
              </span>
              <span v-else>
                {{ row?.report?.type.replace('_', ' ') }}
              </span>
            </td>
            <td>{{ row.notes }}</td>
            <td>
              <a
                v-if="row?.attachment.length > 0"
                href="#"
                class="text-decoration-none"
                @click="showAttachment(row?.attachment)"
              >
                View Attachment
              </a>
            </td>
            <td>
              <span v-if="row?.category.length > 0">
                {{ row.category[0].value }}
              </span>
            </td>
            <td>
              <!-- <ce-checkbox> -->
              {{ row.reported_by.first_name + ' ' + row.reported_by.last_name }} 
              <!-- </ce-checkbox> -->
            </td>
            <td> 
              <span style="display: none">{{ moment(row.created_at).format('MM/DD/YYYY HH:mm A') }}</span>
              {{ moment(row.created_at).format('lll') }}
            </td>
            <td>{{ row.status === null ? '' : row.status === 0 ? 'Declined' : 'Approved' }}</td>
            <td>
              <a class="nav-link" data-toggle="dropdown" href="#">
                    Take Action
              </a>
              <div class="dropdown-menu dropdown-menu-left">
                <a href="#"
                  class="dropdown-item p-2"
                  @click="changeStatus(row.id, 'approved')"
                >
                  Approved
                </a>
                <a
                  href="#"
                  class="dropdown-item p-2"
                  @click="changeStatus(row.id, 'declined')"
                >
                  Disapproved
                </a>
                </div>
            </td>
          </tr>
        </tbody>
      </table>
      <pagination-component
        class="mt-3 mb-2"
        :total-pages="totalPage"
        :per-page="25"
        :current-page="currentPage"
        @pagechanged="onPageChange"
      />
    </div>

    <modal-component
    :modal-id="`attachment-report`"
    :title="`Attachment`"
    :header-style="`bg-linear`"
    :size="`modal-md`"
    :profile="false"
    @closeModal="closeModal"
  >
    <template #modalBody>
      <div class="col p-3">
        <ce-hero-slider ref="sliderRef" :images="images" />
      </div>
    </template>
  </modal-component>

  <modal-component
    :modal-id="`reported-content`"
    :title="`Reported Content`"
    :header-style="`bg-linear`"
    :size="`modal-md`"
    :profile="false"
    @closeModal="closeReportedModal"
  >
    <template #modalBody>
      <div class="col p-3">
        <ce-image :src="reportedContent" class="w-100"/>
      </div>
    </template>
  </modal-component>
  </div>

</template>
<script>
import { nextTick, onMounted, ref, watch } from "vue";
import { useStore } from "vuex";
import moment from "moment";
import { userEnums, REPORT } from "@/composable/enums.js";
import ChatTypes from '@/Use/use-chat-types.js'
import ProfileImageComponent from "../ProfileImageComponent.vue";
import UserInformationComponent from "../../Pages/User/UserInformationModal.vue";
import Preloader from "../../Layouts/Common/Preloader.vue";
import PaginationComponent from "../PaginationComponent.vue";
import $ from 'jquery'
import ModalComponent from '../ModalComponent.vue';
import CeHeroSlider from '../Sliders/CeHeroSlider.vue';

export default {
  name: "App",
  components: {
    ProfileImageComponent,
    UserInformationComponent,
    Preloader,
    PaginationComponent,
    ModalComponent,
    CeHeroSlider,
  },
  props: {
    isLoad: {
      type: Boolean,
      required: true,
      default: false
    }
  },
  emits: ["done"],
  setup(props) {
    const store = useStore(); 
    const { CHAT_TYPES } = ChatTypes();
    const reports = ref([]);
    const { memberships } = userEnums();
    const isLoading = ref(false);
    const currentPage = ref(1);
    const totalPage = ref(null);
    const page = ref(null);
    const images = ref([]);
    const reportedContent = ref(null);

    const imageUrlAlt = (event) => {
      event.target.src = "//via.placeholder.com/150";
    };

    const onPageChange = (page) => {
      currentPage.value = page;
    };

    const loadReports = async () => {
      isLoading.value = true;
      let response = await store.dispatch("getReport", {
        perPage: 25,
        page: currentPage.value,
      });

      reports.value = response.data;
      totalPage.value = response.meta.last_page;
      page.value = response.meta.last_page;
      isLoading.value = false;

      nextTick().then(() => {
        $.fn.dataTableExt.oSort['time-date-sort-pre'] = function(value) {      
            return Date.parse(value);
        };
        $.fn.dataTableExt.oSort['time-date-sort-asc'] = function(a,b) {      
            return a-b;
        };
        $.fn.dataTableExt.oSort['time-date-sort-desc'] = function(a,b) {
            return b-a;
        };
        let table = $("#report-table").DataTable({
                      "columnDefs": [
                        { "width": "20%", "targets": 0 },
                      ],
                      destroy: true,
                      "responsive": true, "lengthChange": false, "autoWidth": false,
                      "bPaginate": false,
                      "bInfo" : false,
                      order: [[0, 'desc']],
                    });
        table.on( 'draw', function (test) {
          let textSearch = $("input[type=search]").val();
          if (textSearch === '') {
            totalPage.value = page.value
          } else {
            let rows = table.rows( {search:'applied'} ).count();
            if ( rows > 25) {
              totalPage.value = totalPage.value = page.value;
            } else {
              totalPage.value = rows >= 1 ? 1 : '';
            }
          }
        } );
      }, 250);
        
    }

    const showAttachment = (data) => {
      data.forEach((file) => {
        images.value.push(file.location);
      })
      $('#attachment-report').modal('show');
    }

    const closeModal = () => {
      images.value = [];
      $('#attachment-report').modal('hide');
    }

    const showReportedMedia = (data) => {
      reportedContent.value = data;
      $('#reported-content').modal('show');
    }
    const closeReportedModal = () => {
      reportedContent.value = [];
      $('#reported-content').modal('hide');
    }

    const changeStatus = async (reportId, status) => {
      let response = await store.dispatch("reportAction", {
        reportId: reportId,
        status: status,
      });

      if (response) {
        loadReports();
      }
    }
    
    onMounted( async () => {
      loadReports();
    })

    watch(
      () => currentPage.value,
      () => loadReports()
    );

    watch(
      () => props.isLoad,
      () => loadReports()
    );

    return {
      reports,
      memberships,
      imageUrlAlt,
      isLoading,
      currentPage,
      onPageChange,
      totalPage,
      showAttachment,
      images,
      closeModal,
      changeStatus,
      REPORT,
      reportedContent,
      closeReportedModal,
      showReportedMedia,
      CHAT_TYPES,
      page
    };
  },
  created: function () {
    this.moment = moment;
  },
};
</script>
<style scoped>
.color-gray {
  color: #727e8b;
}
.form-control:focus {
  box-shadow: none;
}
.btn:focus {
  box-shadow: none;
}
.color-gold {
  color: #b4914d;
  text-decoration: none;
  font-weight: 600;
}
.top-70 {
  top: 0% !important;
}
.start-58 {
  left: 58% !important;
}
.card {
  border: 0;
}
.widget-user-header {
  padding: 0;
  height: auto !important;
}
.widget-user .widget-user-header {
  padding: 0px;
}
.downloadAndMessageAlign {
  text-align: center;
  position: inherit;
  margin-top: 15px;
}
.dropdown-menu-lg {
  min-width: auto;
}
.link-name {
  color: rgb(85, 85, 194);
}
.link-name:hover {
  cursor: pointer;
}
.fa {
  color: black;
}
.fas:hover {
  cursor: pointer;
}
.fs-14 {
  font-size: 14px !important;
}

.tbl-ll-date {
  color: #6c757d;
  position: relative;
  left: 25px;
  float: left;
  margin-top: -5px;
}
.tbl-img {
  min-width: 30px;
  max-width: 30px;
  min-height: 30px;
  max-height: 30px;
}
.profile-img {
  object-fit: contain;
  border-radius: 0.25em 0.25em 0em 0px;
  width: 100%;
}
.background-gold {
  background: gold;
}
.txt-color-gray {
  color: #6c757d;
}
.fa-circle-plus,
.fa-pen-to-square,
.fa-magnifying-glass-plus:hover {
  cursor: pointer;
}

.tbl-img:hover {
  cursor: pointer;
}

</style>
