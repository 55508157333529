<template>
  <Preloader :is-loading="isLoading" />
  <div class="card card-success">
    <div class="card-body">
      <header-stories-component
        :title="`Flag a Group`"
        :module-name="`group`"
        :search-for="`groups`"
        :query-type="queryType"
        :total="$store.state.group.groupStatistics[0].data"
      />
    </div>
  </div>
  <div class="card card-success">
    <div class="card-body color-gray">
      <div class="row">
        <div class="col-md-12 col-lg-8 col-xl-9">
          <div class="row">
            <statistics-card-component
              :card="statics"
              @change-status="changeStatus($event)"
            />
          </div>
        </div>
        <div class="col-md-12 col-lg-4 col-xl-3 border-start">
          <div class="row downloadAndMessageAlign">
            <div class="col-sm-6">
              <csv-button-component
                :csv-header="GroupCSVHeader"
                :type="`group`"
              />
            </div>
            <div class="col-sm-6">
              <messenger
                :message-to="`Group`"
                :select-message-title="`Message Group`"
                :message-title="`Message Group`"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="card card-success">
    <div class="card-body">
      <group-table
        :user-type="dataTableGroup"
        :view-type="`flag`"
        @done="isDone($event)"
        :query-type="queryType"
        :query-filter="queryFilter"
      />
      <div id="confirm" />
    </div>
  </div>
</template>

<script>
import { ref } from "vue";
import $ from "jquery";
import { userEnums } from "@/composable/enums.js";
import GroupTable from "@/components/Tables/GroupTable.vue";
import CsvButtonComponent from "@/components/CsvButtonComponent.vue";
import StatisticsCardComponent from "@/components/StatisticsCardComponent.vue";
import HeaderStoriesComponent from "@/components/HeaderStoriesComponent.vue";
import { useStore } from "vuex";
import { useRoute } from "vue-router";
import moment from "moment";
import Preloader from "../../Layouts/Common/Preloader.vue";
import Messenger from "../Message/Messenger.vue";

export default {
  name: "App",
  components: {
    GroupTable,
    CsvButtonComponent,
    StatisticsCardComponent,
    HeaderStoriesComponent,
    Preloader,
    Messenger,
  },
  setup() {
    const status = ref("active");
    const { GroupCSVHeader } = userEnums();
    const store = useStore();

    const route = useRoute();
    const queryType = route.query.type;
    const queryFilter = route.query.filter;

    const statics = ref([
      {
        label: "Active",
        value: store.state.group.groupStatistics[0].heading.Active,
      },
      {
        label: "Deactivated",
        value: store.state.group.groupStatistics[0].heading.Deactivated,
      },
      {
        label: "Unpublished",
        value: store.state.group.groupStatistics[0].heading.Unpublished,
      },
      {
        label: "Flagged",
        value: store.state.group.groupStatistics[0].heading.Flagged,
      },
      {
        label: "Suspended",
        value: store.state.group.groupStatistics[0].heading.Suspended,
      },
      {
        label: "Deleted",
        value: store.state.group.groupStatistics[0].heading.Deleted,
      },
    ]);

    const type = ref(null);
    const dataTableGroup = ref("");
    const isLoading = ref(false);
    const birthdayMessage = ref(false);
    const selectedUser = ref(false);

    const changeStatus = (event) => {
      dataTableGroup.value = event;
    };

    const nextMessage = () => {
      $("#selectMessageType").modal("hide");
      $("#message").modal("show");
    };

    const selectMessageToSend = () => {
      $("#selectMessageType").modal("show");
    };

    const selectMessageType = (type) => {
      if (type === "selected_birthday") {
        birthdayMessage.value = true;
        selectedUser.value = false;
      }

      if (type === "selected_user") {
        birthdayMessage.value = false;
        selectedUser.value = true;
      }
    };

    const sendMessage = () => {};

    const isDone = (event) => {
      isLoading.value = event;
    };

    return {
      changeStatus,
      status,
      GroupCSVHeader,
      statics,
      nextMessage,
      sendMessage,
      type,
      dataTableGroup,
      isLoading,
      isDone,
      selectMessageToSend,
      selectMessageType,
      birthdayMessage,
      selectedUser,
      queryType,
      queryFilter,
    };
  },
  created: function () {
    this.moment = moment;
  },
};
</script>

<style scoped>
.downloadAndMessageAlign {
  text-align: center;
  position: inherit;
  margin-top: 15px;
}
.dropdown-menu-lg {
  min-width: auto;
}
.table td,
.table th {
  border-top: 0 !important;
}
.table td {
  vertical-align: middle;
}
.custom-control-label {
  font-weight: 500 !important;
}
.send {
  color: rgb(4, 98, 193);
}
.selected-message-type {
  background-color: #333;
  color: #cacaca !important;
}
</style>
