<template>
  <img
    ref="imgRef"
    :src="localSrc"
    :alt="alt"
    @error="replaceWithPlaceholder()"
    @load="$emit('load')"
  >
</template>

<script>
import { ref, watch } from 'vue'
import { decode } from 'blurhash'

export default {
  props: {
    src: {
      type: String,
      default: ''
    },
    placeholder: {
      type: String,
      default: 'https://perfectfriends.com/images/defaults/folder.png'
    },
    alt: {
      type: String,
      default: ''
    },
    blurHash: {
      type: Object,
      default: undefined
    }
  },
  emits: ['load'],
  setup (props) {
    const imgRef = ref(null)
    const localSrc = ref(props.src)

    const replaceWithPlaceholder = () => {
      if (localSrc.value !== props.placeholder) {
        localSrc.value = props.placeholder
      }
    }

    /* Methods */
    const generateBlurHashSrc = (blurHash) => {
      const { hash, width, height } = blurHash
      const pixels = decode(hash, width, height)
      const canvas = document.createElement('canvas')
      canvas.width = width
      canvas.height = height
      const ctx = canvas.getContext('2d')
      const imageData = ctx.createImageData(width, height)
      imageData.data.set(pixels)
      ctx.putImageData(imageData, 0, 0)
      return ctx.canvas.toDataURL()
    }

    /* Lifecycles */
    if (props.blurHash?.hash) {
      localSrc.value = generateBlurHashSrc(props.blurHash)
    }

    /* Watchers */
    watch(
      () => props.src,
      (newSrc) => {
        if (props.blurHash?.hash) {
          localSrc.value = generateBlurHashSrc(props.blurHash)
          const img = new Image()
          img.onload = () => {
            localSrc.value = newSrc
          }
          img.src = newSrc
        } else {
          localSrc.value = newSrc
        }
      },
      {
        immediate: true
      }
    )

    return {
      replaceWithPlaceholder,
      localSrc,
      imgRef
    }
  }
}
</script>
