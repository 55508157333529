<template>
  <div>
    <ce-button
      type="button"
      class="btn-md btn-gradient-gold"
      @click="sendClick()"
    >
      <slot name="label" />
    </ce-button>

    <teleport to="body">
      <ce-premium-account-alert
        ref="premiumAccountAlert"
        dialog-title="Send Message"
        :redirect-to="redirectUrl"
      />
    </teleport>
  </div>
</template>

<script>
import { computed, onMounted, ref } from 'vue'

import { usePage } from '@inertiajs/inertia-vue3'
import { useStore } from 'vuex'
import CeButton from '@/components/Buttons/CeButton.vue'
import CePremiumAccountAlert from '@/components/Modal/CePremiumAccountAlert.vue'
import ChatTypes from '@/Use/use-chat-types.js'

const useConversation = () => {
  const store = useStore()
  const { CHAT_TYPES } = ChatTypes()
  const createConversation = (id, tableLookup) => {
    // event type
    if (tableLookup === CHAT_TYPES.EVENT) {
      // store.dispatch('listener/listenToNewEvent', id);
      return store.dispatch('messaging/conversation/createEventChat', {
        tableId: id,
        tableLookup
      })
    }

    // event inquiry type
    if (tableLookup === CHAT_TYPES.EVENT_INQUIRY) {
      return store.dispatch('messaging/conversation/createEventInquiryChat', {
        tableId: id,
        tableLookup
      })
    }

    // Personal message for events or groups
    if (
      [
        CHAT_TYPES.PERSONAL_MESSAGE_EVENTS,
        CHAT_TYPES.PERSONAL_MESSAGE_GROUPS
      ].includes(tableLookup)
    ) {
      return store.dispatch('messaging/conversation/create', {
        friendId: 0,
        tableId: id,
        tableLookup
      })
    }

    // group type
    // store.dispatch('listener/listenToNewGroup', id);
    return store.dispatch('messaging/conversation/createGroupChat', {
      tableId: id,
      tableLookup
    })
  }

  const smartAddWidget = (options) =>
    store.commit('messaging/widget/smartAddWidget', options)

  const removeWidget = (id, name) =>
    store.commit('messaging/widget/removeWidgetByTable', {
      tableId: id,
      widgetName: name
    })

  // add new conversation, this will also check if conversation exist so no duplicate widget
  const addConversationIfNotPresent = (conversation) =>
    store.commit(
      'messaging/conversation/addConversationGroupedChatIfNotPresent',
      {
        conversation
      }
    )

  // update conversation if user is blocked update it
  const updateConversation = (conversation) =>
    store.commit('messaging/conversation/updateConversationItem', {
      conversation
    })
  return {
    createConversation,
    smartAddWidget,
    addConversationIfNotPresent,
    updateConversation,
    removeWidget
  }
}

export default {
  name: 'GroupLiveChatButton',
  components: {
    CeButton,
    CePremiumAccountAlert
  },
  props: {
    id: {
      type: Number,
      default: 0
    },
    tableLookup: {
      type: Number,
      default: 0
    }
  },
  setup (props) {
    const { createConversation, smartAddWidget, updateConversation } =
      useConversation()
    const premiumAccountAlert = ref(null)
    const redirectUrl = ref(null)
    const isProcessing = ref(false)
    const currentUser = computed(() => usePage().props.value.auth.data)

    // to re-enable later
    // const hasMessagingAccess = computed(
    //   () => usePage().props.value.messaging.can_access_messaging
    // );

    const hasMessagingAccess = ref(false)

    // Methods
    const sendClick = async () => {
      isProcessing.value = true
      // create conversation and chat widget
      try {
        const conversation = await createConversation(
          props.id,
          Number(props.tableLookup)
        )
        smartAddWidget({
          conversation,
          widgetOpen: true
        })
        updateConversation(conversation)
      } finally {
        isProcessing.value = false
      }
    }
    // Lifecycle Hooks
    onMounted(() => {
      redirectUrl.value = `${window.location.origin}/inbox?send_message_to=${currentUser.value.id}`
    })

    return {
      currentUser,
      hasMessagingAccess,
      premiumAccountAlert,
      sendClick,
      redirectUrl
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~bootstrap/scss/bootstrap-utilities';

/* purgecss start ignore */
:deep(.btn-md) {
  min-width: 100%;
}

/* purgecss end ignore */
@include media-breakpoint-up(md) {
  .group--buttons {
    .btn-md {
      min-width: 152px !important;
    }
  }
}
</style>
