<template>
  <div>
    <template v-if="!hasInvited">
      <div class="card-body">
        <div class="container">
          <h1 class="fs-1 text-center">
            Don't forget to invite the rest of your friends!
          </h1>
        </div>
      </div>
      <template v-if="myFriends?.length > 0 || true">
        <event-invite-friends-form
          class="form-1"
          :event-id="eventForm.id"
          :my-friends="myFriends"
          :my-friends-total-count="myFriends.meta?.length"
          :has-more-friends="myFriends.links?.next"
          @success="hasInvited = true"
        >
          <template #submit="{ submit, isSubmitting }">
            <div class="row">
              <template v-if="!isSubmitting">
                <div class="col-6 col-sm-6 mb-3">
                  <ce-button
                    class="btn-lg btn btn-gray text-white text-bold w-100"
                    @click="skip()"
                  >
                    Skip
                  </ce-button>
                </div>
                <div class="col-6 col-sm-6 mb-3">
                  <ce-button
                    class="btn-lg btn-gradient-gold text-bold w-100"
                    :is-loading="isSubmitting"
                    :disabled="isSubmitting"
                    @click="submit"
                  >
                    Continue
                  </ce-button>
                </div>
              </template>
              <template v-else>
                <div class="col-12 mb-3">
                  <ce-button
                    class="btn-lg btn-gradient-gold text-bold w-100"
                    :is-loading="isSubmitting"
                    :disabled="isSubmitting"
                    @click="submit"
                  >
                    Continue
                  </ce-button>
                </div>
              </template>
            </div>
          </template>
        </event-invite-friends-form>
      </template>
      <template v-else>
        <div class="card-body">
          <div class="container">
            <h1 class="fs-1 ff-merriweather fw-bold mb-3 text-center" />
          </div>
        </div>
        <div class="card-footer text-muted form-footer">
          <div class="container">
            <div class="row">
              <div class="col-12 col-sm-12 mb-3">
                <ce-button
                  class="btn-lg btn-gradient-gold text-bold w-100"
                  @click="viewEvent()"
                >
                  Continue
                </ce-button>
              </div>
            </div>
          </div>
        </div>
      </template>
    </template>
    <template v-else>
      <div class="card-body">
        <div class="container">
          <h1 class="fs-1 text-center">
            Congratulations,<br />your invites have been sent!
          </h1>
          <img src="../../assets/images/invites-sent.svg" class="w-100" />
        </div>
      </div>
      <div class="card-footer text-muted form-footer">
        <div class="container">
          <div class="row">
            <div class="col-6 col-sm-6 mb-3">
              <ce-button
                class="btn-lg btn-gradient-gold text-bold w-100"
                @click="viewEvent()"
              >
                Visit {{ dynamicWordFor("Event") }}
              </ce-button>
            </div>
            <div class="col-6 col-sm-6 mb-3">
              <ce-button
                class="btn-lg btn-gradient-gold text-bold w-100"
                @click="skip()"
              >
                Return to Admin Panel
              </ce-button>
            </div>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import { defineAsyncComponent, computed, ref } from "vue";
import { useStore } from "vuex";
import { useRoute, useRouter } from "vue-router";
import { GATHERING_TYPES, useDynamicWord } from "@/Use/events.js";

export default {
  components: {
    EventInviteFriendsForm: defineAsyncComponent(() =>
      import("@/Modules/Event/EventInviteFriendsForm.vue")
    ),
  },
  props: {
    gatheringType: {
      type: Number,
      default: GATHERING_TYPES.EVENT,
    },
  },
  emits: ["createEvent", "eventIsPublished", "isLoading", "cancelEdit"],
  async setup(props, { emit }) {
    const store = useStore();
    const route = useRoute();
    const router = useRouter();

    const eventId = ref(router.currentRoute._value.params.id);
    const isLoaded = ref(false);

    const hasInvited = ref(false);

    const { dynamicWordFor } = useDynamicWord({
      gatheringType: props.gatheringType,
    });

    emit("isLoading", true);
    const getEventInfoResource = await store.dispatch("eventEdit", {
      id: eventId.value,
      api: "review-and-publish",
    });

    const getInviteFriendsResource = await store.dispatch("inviteFriends", {
      id: eventId.value,
    });
    emit("isLoading", false);

    const myFriends = computed(() => getInviteFriendsResource.data.myFriends);

    const slug = computed(() => getEventInfoResource.data.slug);
    const eventForm = computed(() => getEventInfoResource.data.form);
    const gatheringType = computed(
      () => getEventInfoResource.data.gatheringType
    );
    const isPublished = computed(() => getEventInfoResource.data.isPublished);

    emit("eventIsPublished", isPublished);

    const eventCreate = (response) => {
      emit("createEvent", response, { steps: "invite-friends" });
    };

    const skip = () => {
      emit("cancelEdit");
    };

    const viewEvent = () => {
      router.push({
        name: `Preview${dynamicWordFor("Event")}`,
        params: { slug: slug.value },
      });
    };

    return {
      dynamicWordFor,
      isPublished,
      eventCreate,
      eventForm,
      isLoaded,
      slug,
      hasInvited,
      skip,
      viewEvent,
      myFriends,
      // backUrl,
    };
  },
};
</script>

<style lang="scss" scoped>
@import "../../scss/customizations";

:deep() {
  @import "../../scss/form/form-1";
}

.container {
  max-width: 680px;
}
.form-footer {
  background-color: #3a3b44;
  color: #fff;
  min-height: 83px;
}
.form-footer .ce-buttons-button {
  min-height: 50px;
}
</style>
