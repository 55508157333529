<template>
  <ce-button
    type="button"
    :is-loading="isProcessing"
    :disabled="isProcessing"
    @click="handleClick()"
  >
    <slot :is-published="localIsPublished">{{
      localIsPublished ? 'Unpublish' : 'Publish'
    }}</slot>
  </ce-button>
</template>

<script>
import { inject, ref, watchEffect } from 'vue';
import { UNPUBLISH_STATUSES } from '@/Use/events.js';
import axios from 'axios';

export default {
  props: {
    eventId: {
      type: Number,
      default: null,
      required: true,
    },
    slug: {
      type: String,
      default: '',
      required: true,
    },
    isPublished: {
      type: Boolean,
      default: false,
    },
    askToPost: {
      type: Boolean,
      default: true,
    },
  },
  emits: ['update:isPublished', 'published', 'unpublished', 'fail'],
  setup(props, { emit }) {
    const dialog = inject('dialog');
    const isProcessing = ref(false);
    const localIsPublished = ref(props.isPublished);

    const publish = async ({ postToWall = false } = { postToWall: false }) => {
      if (isProcessing.value) {
        return false;
      }

      try {
        isProcessing.value = true;
        await axios.post(`/api/events/${props.slug}/publish`, {
          postToWall,
        });
        localIsPublished.value = true;
      } catch (exception) {
        emit('fail', exception);
        dialog.show(
          'An unexpected error encountered. Please try again later.',
          {
            title: 'System Error',
          }
        );
      } finally {
        isProcessing.value = false;
      }

      return true;
    };

    const askPublishingConfirmation = () => {
      dialog.show('Are you sure you want to publish this event?', {
        title: 'Publish',
        size: 'lg',
        buttons: [
          {
            label: 'Yes and post it on my wall',
            class: 'btn-gradient-gold',
            handler: async (closeDialog) => {
              publish({ postToWall: true });
              closeDialog();
            },
          },
          {
            label: 'Yes but without posting on my wall',
            class: 'btn-gold',
            handler: async (closeDialog) => {
              publish({ postToWall: false });
              closeDialog();
            },
          },
          {
            label: 'Close',
            class: 'btn-light-gray',
            handler: (closeDialog) => {
              closeDialog();
            },
          },
        ],
      });
    };

    const unpublish = async () => {
      if (isProcessing.value) {
        return false;
      }

      try {
        isProcessing.value = true;
        await axios.post(`/api/events/unpublish/${props.eventId}`, {
          status: UNPUBLISH_STATUSES.POSTPONED,
        });
        localIsPublished.value = false;
        return true;
      } catch (e) {
        dialog.show('An unexpected error encountered.', {
          title: 'System error',
        });
        return false;
      } finally {
        isProcessing.value = false;
      }
    };

    const askUnpublishingConfirmation = () => {
      dialog.show('Are you sure you want to unpublish this event?', {
        title: 'Unpublish',
        buttons: [
          {
            label: 'Yes',
            class: 'btn-gradient-gold',
            handler: async (closeDialog) => {
              unpublish();
              closeDialog();
            },
          },
          {
            label: 'No',
            class: 'btn-light-gray',
            handler: (closeDialog) => {
              closeDialog();
            },
          },
        ],
      });
    };

    const handleClick = () => {
      if (props.isPublished) {
        askUnpublishingConfirmation();
      } else if (props.askToPost) {
        askPublishingConfirmation();
      } else {
        publish();
      }
    };

    /* Watchers */
    watchEffect(() => {
      if (localIsPublished.value) {
        emit('update:isPublished', true);
        emit('published');
      } else {
        emit('update:isPublished', false);
        emit('unpublished');
      }
    });

    return {
      localIsPublished,
      isProcessing,
      handleClick,
    };
  },
};
</script>
