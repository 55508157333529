import { ref, watch, computed } from 'vue'
import cloneDeep from 'lodash/cloneDeep'

export default (props, { emit }) => {
  const localValue = ref([])
  const preferenceValue = ref(null)
  const noPreferenceValue = computed({
    get: () =>
      preferenceValue.value === null
        ? false
        : preferenceValue.value.length === 0,
    set: (newValue) => {
      if (newValue) {
        preferenceValue.value = []
      }
    }
  })

  /* Watchers */

  watch(
    localValue,
    (newLocalValue) => {
      emit('update:modelValue', newLocalValue)
    },
    {
      deep: true
    }
  )

  watch(
    preferenceValue,
    (newPreferenceValue, old) => {
      if (old ? newPreferenceValue.length !== old.length : false) {
        const newLocalValue = cloneDeep(newPreferenceValue)
        if (noPreferenceValue.value) {
          newLocalValue.unshift(0)
        }
        localValue.value = newLocalValue
      }
    },
    {
      deep: true
    }
  )

  watch(
    () => props.modelValue,
    (newModelValue) => {
      localValue.value = newModelValue
      preferenceValue.value = newModelValue.filter((v) => !!Number(v))
    },
    {
      immediate: true
    }
  )

  return {
    localValue,
    preferenceValue,
    noPreferenceValue
  }
}
