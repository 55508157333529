<template>
    <modal-component
        :modal-id="`alert-modal`" 
        :header-style="`bg-linear`"
        :size='`modal-md`'
        :profile="false"
    >
        <template v-slot:modalBody>
            <div class="modal-body">
                <div class="mr-4 ml-4">
                    <h3 class="row text-center"><strong>
                        <p>{{ message }}</p>
                        </strong>
                    </h3>
                </div>
                <center class="mt-3">
                    <button 
                        type="submit"
                        class="btn-gradient-gold mt-2 mb-2 close-gradient-gold-color"
                        @click="closeModal"
                    >
                        Close
                    </button>
                </center>
            </div>
        </template>
    </modal-component>
</template>
<script>
import ModalComponent from './ModalComponent.vue'
import $ from 'jquery'
import { watch } from 'vue'

export default {
    components: { ModalComponent },
    props: {
        message: {
            type: String,
            required: true
        },
        isShow: {
            type: Boolean,
            required: true
        }
    },
    setup(props) {
        const closeModal = () => {
            $("#alert-modal").modal('hide')
        }

        return {
            closeModal
        }
    },
}
</script>