<template>
  <modal-component
    v-if="isAllowedToPost"
    ref="modalRef"
    class="createDiscussion"
    :modal-id="modalId"
    :size="`modal-md`"
    :profile="false"
    @close="closeModal"
  >
    <template #modalBody>
      <div class="modal-body px-4 pt-4">
        <h4 class="fw-bold">
          Start a Discussion
        </h4>
        <div :disabled="loading">
          <div class="form-field col-12">
            <label class="form-label required-input">Discussion Title</label>
            <input
              id="discussion-title"
              v-model="form.title"
              type="text"
              class="form-control"
            >
            <form-text
              name="title"
              :errors="formErrors"
            />
          </div>
          <div class="form-field col-12 mt-2">
            <label class="form-label required-input">Discussion</label>
            <ce-textarea
              id="discussion-text"
              v-model="form.text"
            />
            <form-text
              name="discussion"
              :errors="formErrors"
            />
          </div>
          <div class="d-flex col-12">
            <div class="flex-fill">
              <div
                ref="attachmentsRef"
                class="attachments mx-n1"
              />
              <visual-media-field
                ref="visualMediaFieldRef"
                v-model="attachmentMediaIds"
                v-model:is-uploading="isUploadingAttachments"
                :attachments-container="attachmentsRef"
                additional-description="Maximum 10 photos per post"
                maximum-images="10"
                multiple
                photo
                video
                :disabled="attachmentMediaIds.length === 10"
              />
            </div>
            <div class="align-self-end">
              <ce-checkbox
                v-model="postToWall"
                :value="true"
                is-switch="true"
                style="margin-left: 40px;"
              >
                Share on your wall
              </ce-checkbox>
            </div>
          </div>
          <div class="form-field col-12 my-2" />
        </div>
      </div>

      <div
        class="modal-footer pb-4 d-flex align-items-center justify-content-around border-0"
      >
        <ce-button
          type="button"
          :is-loading="loading"
          :disabled="loading || isUploadingAttachments"
          class="btn-green text-white"
          @click="submit()"
        >
          Post
        </ce-button>
        <ce-button
          type="button"
          class="btn-outline-green"
          @click="closeModal"
        >
          Cancel
        </ce-button>
      </div>
    </template>
  </modal-component>
  <modal-component
    v-else
    ref="modalRef"
    class="createDiscussion"
    :modal-id="modalId"
    :size="`modal-md`"
    :profile="false"
    @close="closeModal"
  >
    <div class="modal-body px-4 pt-4">
      <h4 class="fw-bold">
        You are not allowed to create a discussion yet
      </h4>
      <p>{{ disabledMessage }}</p>
    </div>

    <div
      class="modal-footer pb-4 d-flex align-items-center justify-content-around border-0"
    >
      <ce-button
        type="button"
        class="btn-outline-green"
        @click="closeModal"
      >
        Close
      </ce-button>
    </div>
  </modal-component>
</template>

<script>
import { ref, inject } from 'vue'
// import useModal from '@/Use/use-modal.js';
import axios from 'axios'
import VisualMediaField from '@/components/Inputs/VisualMediaField.vue'
import CeModal from './CeModal.vue'
import CeText from '../Inputs/CeText.vue'
import FormText from '../Forms/FormText.vue'
import CeTextarea from '../Inputs/CeTextarea.vue'
import CeCheckbox from '../Inputs/CeCheckbox.vue'
import CeButton from '../Buttons/CeButton.vue'
import ModalComponent from '../ModalComponent.vue'

export default {
  components: {
    VisualMediaField,
    CeModal,
    CeText,
    CeTextarea,
    CeCheckbox,
    CeButton,
    FormText,
    ModalComponent
  },
  props: {
    entityId: {
      type: Number,
      required: true
    },
    type: {
      type: String,
      required: true
    },
    isAllowedToPost: {
      type: Boolean,
      required: true
    },
    disabledMessage: {
      type: String,
      required: false,
      default: ''
    },
    modalId: {
      type: String,
      required: false,
      default: 'ce-modal'
    }
  },
  emits: ['newDiscussionCreated', 'close'],
  setup (props, context) {
    // const { modalRef, open, close } = useModal();
    const loading = ref(false)
    const showError = ref(false)
    const errorMessage = ref('')
    const form = ref({
      title: null,
      text: null
    })
    const formErrors = ref([])
    const postToWall = ref(true)
    const attachmentMediaIds = ref([])
    const isUploadingAttachments = ref(false)
    const attachmentsRef = ref(null)

    const createdDiscussion = (newItem) => {
      context.emit('newDiscussionCreated', newItem)
    }

    const closeModal = () => {
      form.value.title = null
      form.value.text = null
      showError.value = false
      formErrors.value = []
      attachmentMediaIds.value = []
      context.emit('close', true)
    }
    const dialog = inject('dialog')

    const confirmDuplicateDiscussion = (title, text, attachmentIds) => {
      dialog.show(
        'Discussion topic already exist.  Would you like to proceed with posting a duplicate topic?',
        {
          title: 'Discussion Topic Exist',
          buttons: [
            {
              label: 'No',
              class: 'btn-light-gray',
              handler: async (closeDialog) => {
                form.value.title = null
                form.value.text = null
                showError.value = false
                loading.value = false
                closeDialog()
                open()
              }
            },
            {
              label: 'Yes',
              class: 'btn-red',
              handler: async (closeDialog) => {
                axios
                  .post(`/${props.type}s/${props.entityId}/discussions`, {
                    title,
                    discussion: text,
                    postToWall: postToWall.value,
                    proceedDuplicateTopic: true,
                    attachments: attachmentIds
                  })
                  .then((res) => {
                    if (res.status === 200 && res.data.error !== '') {
                      showError.value = true
                      errorMessage.value = res.data.error
                      loading.value = false
                      return
                    }

                    form.value = {
                      title: null,
                      text: null
                    }
                    createdDiscussion(res.data)
                    loading.value = false
                    closeDialog()
                  })
              }
            }
          ]
        }
      )
    }

    const submit = () => {
      loading.value = true
      showError.value = false

      const attachedIds = attachmentMediaIds.value

      axios
        .post(`/${props.type}s/${props.entityId}/discussions`, {
          title: form.value.title,
          discussion: form.value.text,
          postToWall: postToWall.value,
          attachments: attachedIds
        })
        .then((res) => {
          if (res.status === 200 && res.data.error !== '') {
            showError.value = true
            errorMessage.value = res.data.error
            loading.value = false
            return
          }

          form.value = {
            title: null,
            text: null
          }
          createdDiscussion(res.data)
          loading.value = false
          close()
        })
        .catch((err) => {
          // Check if there's an error on the back-end request
          if (err.response.data?.error) {
            if (typeof err.response.data.error === 'object') {
              formErrors.value = err.response.data.error
              loading.value = false
            } else {
              // show the duplicate message
              const { title, text } = form.value
              close()
              confirmDuplicateDiscussion(title, text, attachedIds)
              formErrors.value = []
            }
          }
        })
    }

    return {
      // modalRef,
      // open,
      // close,
      submit,
      loading,
      form,
      formErrors,
      postToWall,
      showError,
      errorMessage,
      closeModal,
      confirmDuplicateDiscussion,
      attachmentMediaIds,
      isUploadingAttachments,
      attachmentsRef
    }
  }
}
</script>
<style lang="scss" scoped>
.attachments {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;

  > :deep(div) {
    margin: 0.75rem 0;
    width: 50px !important;
    height: 50px !important;
  }

  > :deep(div img) {
    background-color: #E7E7E7;
  }
}
.createDiscussion {
  z-index: 1054; // fix to show the prompt message when upload has error
}

#discussion-title {
  background-color: #E7E7E7;
  box-shadow: none;
  border-color: #E7E7E7;
  z-index: auto;
}
</style>
