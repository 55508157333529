<template>
  <h1 class="m-0">
    Alert
  </h1>
</template>

<script>
export default {
  name: 'App'
}
</script>

<style>
  .color-gray{
    color:#999
  }
</style>
