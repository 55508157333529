<template>
  <ce-accordion-item>
    <template #header>
      <label class="d-block text-start small fw-semibold ls-1">AGE</label>
    </template>
    <div class="pb-3">
      <ce-range
        v-model="localValue"
        :min="minAge"
        :max="maxAge"
      >
        <template #label="{ startValue, endValue }">
          <small>{{ startValue }} - {{ endValue }}</small>
        </template>
      </ce-range>
    </div>
  </ce-accordion-item>
</template>

<script>
import { ref, watch } from 'vue'
import useAge from '@/Use/use-age.js'

export default {
  props: {
    modelValue: {
      type: Array,
      default: null
    }
  },
  emits: ['update:modelValue'],
  setup (props, { emit }) {
    const { min: minAge, max: maxAge } = useAge()
    const localValue = ref([minAge, maxAge])

    /* Watchers */
    watch(
      localValue,
      (newLocalValue) => {
        emit('update:modelValue', newLocalValue)
      },
      {
        deep: true
      }
    )

    // watch(
    //   () => props.modelValue,
    //   (newModelValue) => {
    //     localValue.value = newModelValue;
    //   },
    //   {
    //     immediate: true,
    //   }
    // );

    return {
      minAge,
      maxAge,
      localValue
    }
  }
}
</script>
