<template>
  <group-review-publish-form
    class="form-1"
    :group-id="groupForm.id"
    :slug="slug"
    :data="{
      bannerPhotoSrc: groupForm?.image_modified_url?.modified,
      previewVideoSrc: groupForm?.video_url,
      previewVideoIsTranscoding: groupForm?.video_is_transcoding,
      name: groupForm?.name,
      isChatEnabled: !!groupForm?.is_chat_enabled,
      interests: groupForm?.interests?.map((interest) => interest.interest),
      category: groupForm?.category?.id,
      type: groupForm?.type?.id,
      description: groupForm?.description,
      admins: groupForm.admins.admins.map((admin) => ({
        id: admin.id,
        firstName: admin.first_name,
        lastName: admin.last_name,
        primaryPhotoUrl: admin.primary_photo,
        username: admin.user_name,
        role: admin.role.id,
      })),
      members: groupForm.members.map((member) => ({
        id: member.id,
        firstName: member.first_name,
        lastName: member.last_name,
        primaryPhotoUrl: member.primary_photo,
        username: member.user_name,
      })),
      invites: groupForm.invites.map((invite) => ({
        id: invite.user.id,
        firstName: invite.user.first_name,
        lastName: invite.user.last_name,
        primaryPhotoUrl: invite.user.primary_photo,
        username: invite.user.user_name,
      })),
    }"
    :is-published="isPublished"
    @success="groupReviewAndPublish"
  >
    <template #title>
      <h1 class="fs-1 mb-5">Please review the information you provided</h1>
    </template>
    <template #invite>
      <template v-if="isPublished">
        <hr class="bg-light-gray my-5" />
        <label class="form-label d-block text-start">Invite People</label>
        <div class="row pb-5 mb-5 gy-2">
          <div class="col-md-6">
            <router-link
              class="btn btn-gradient-gold w-100 h-100 d-flex align-items-center justify-content-center"
              :to="{
                name: 'group-edit-invite-past-events',
                params: { slug: slug },
              }"
            >
              Invite from Groups, past events and huddles
            </router-link>
          </div>
          <div class="col-md-6">
            <router-link
              class="btn btn-gradient-gold w-100 h-100 d-flex align-items-center justify-content-center"
              :to="{
                name: 'group-edit-invite-friends',
                params: { slug: slug },
              }"
            >
              Invite your remaining friends
            </router-link>
          </div>
        </div>
      </template>
    </template>
    <template #submit="{ submit, isSubmitting }">
      <div class="row">
        <div class="col-6 col-sm-6 mb-3">
          <ce-button class="btn-lg btn btn-gray text-white text-bold w-100">
            Cancel
          </ce-button>
        </div>
        <div class="col-6 col-sm-6 mb-3">
          <ce-button
            class="btn-lg btn-gradient-gold text-bold w-100"
            :is-loading="isSubmitting"
            :disabled="isSubmitting"
            @click="submit"
          >
            {{ isPublished ? "Continue" : "Publish" }}
          </ce-button>
        </div>
      </div>
    </template>
  </group-review-publish-form>
</template>

<script>
import { defineAsyncComponent, computed, ref } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";

export default {
  components: {
    GroupReviewPublishForm: defineAsyncComponent(() =>
      import("@/Modules/Group/GroupReviewPublishForm.vue")
    ),
  },
  props: {
    backUrl: {
      type: String,
      default: "",
    },
  },
  emits: ["createGroup", "isLoading", "cancelEdit"],
  async setup(props, { emit }) {
    const store = useStore();

    const router = useRouter();

    const slug = ref(router.currentRoute._value.params.slug);

    emit("isLoading", true);
    const getGroupInfoResource = await store.dispatch("group/groupEdit", {
      slug: slug.value,
      api: "review-and-publish",
    });
    emit("isLoading", false);

    store.dispatch("group/setGroupInfo", getGroupInfoResource.data);
    const groupForm = computed(() => getGroupInfoResource.data);

    const groupReviewAndPublish = (response) => {
      emit("createGroup", response.data, { steps: "review-and-publish" });
    };

    const isPublished = computed(() => getGroupInfoResource.data.isPublished);

    return {
      isPublished,
      groupReviewAndPublish,
      slug,
      groupForm,
    };
  },
};
</script>

<style lang="scss" scoped>
:deep() {
  @import "../../scss/form/form-1";
}

.container {
  max-width: 680px;
}
</style>
