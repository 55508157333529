<template>
  <div
    class="ce-avatar avatar text-center"
    :class="{ 'rounded-circle overflow-hidden': !square }"
    role="img"
    :aria-label="alt"
  >
    <slot />
  </div>
</template>

<script>
import { ref } from 'vue'

export default {
  name: 'CeAvatar',
  props: {
    src: {
      type: String,
      required: false,
      default: null
    },
    alt: {
      type: String,
      required: false,
      default: null
    },
    square: {
      type: Boolean,
      required: false,
      default: false
    },
    size: {
      type: [Object, Number],
      required: false,
      default: 55,
      validator: (value) =>
        (typeof value === 'object' && value.width && value.height) ||
        typeof value === 'number'
    }
  },
  setup (props) {
    const avatar = ref(`url(${props.src})`)
    return {
      avatar
    }
  },
  computed: {
    backgroundImage () {
      if (!this.src) {
        return ''
      }

      return `url(${this.src})`
    },
    width () {
      if (typeof this.size === 'object') {
        return `${this.size.width}px`
      }

      return `${this.size}px`
    },
    height () {
      if (typeof this.size === 'object') {
        return `${this.size.height}px`
      }

      return `${this.size}px`
    }
  }
}
</script>

<style lang="scss" scoped>
.avatar {
  &::before {
    content: '';
    display: block;
    position: relative;
    background-image: v-bind(avatar);
    width: v-bind(width);
    height: v-bind(height);
    background-size: cover;
  }
}
</style>
