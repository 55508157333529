<template>
  <modal-component
    v-if="isAllowedToPost"
    ref="modalRef"
    class="createDiscussion"
    :modal-id="modalId"
    :size="`modal-md`"
    :profile="false"
    @close="closeModal"
  >
    <template #modalBody>
      <div class="modal-body px-4 pt-4">
        <h4 class="fw-bold">
          Create a New Album
        </h4>
        <div>
          <form
            :disabled="loading"
            @submit.prevent="submit()"
          >
            <div class="form-field col-12">
              <label class="form-label required-input">Album Name</label>
              <ce-text
                id="discussion-title"
                v-model="form.title"
              />
              <form-text
                name="title"
                :errors="formErrors"
              />
            </div>
          </form>
        </div>
      </div>

      <div
        class="modal-footer pb-4 d-flex align-items-center justify-content-around border-0"
      >
        <ce-button
          type="submit"
          :is-loading="loading"
          :disabled="loading"
          class="btn-green text-white"
          @click="submit()"
        >
          Create
        </ce-button>
        <ce-button
          type="button"
          class="btn-outline-green"
          @click="close()"
        >
          Cancel
        </ce-button>
      </div>
    </template>
  </modal-component>
  <modal-component
    v-else
    ref="modalRef"
    class="createDiscussion"
    :modal-id="modalId"
    :size="`modal-md`"
    :profile="false"
    @close="closeModal"
  >
    <template #modalBody>
      <div class="modal-body px-4 pt-4">
        <h4 class="fw-bold">
          You are not allowed to create an album
        </h4>
        <p>{{ disabledMessage }}</p>
      </div>

      <div
        class="modal-footer pb-4 d-flex align-items-center justify-content-around border-0"
      >
        <ce-button
          type="button"
          class="btn-outline-green"
          @click="close"
        >
          Close
        </ce-button>
      </div>
    </template>
  </modal-component>
</template>

<script>
import { ref } from 'vue'
import axios from 'axios'
import ModalComponent from '../ModalComponent.vue'

export default {
  components: { ModalComponent },
  props: {
    entityId: {
      type: Number,
      required: true
    },
    entity: {
      type: String,
      required: true
    },
    isAllowedToPost: {
      type: Boolean,
      required: true
    },
    disabledMessage: {
      type: String,
      required: false,
      default: ''
    },
    modalId: {
      type: String,
      require: true
    }
  },
  emits: ['newAlbumCreated', 'close'],
  setup (props, context) {
    const loading = ref(false)
    const form = ref({
      title: null
    })
    const formErrors = ref([])
    const postToWall = ref(true)

    const createdAlbum = (newItem) => {
      context.emit('newAlbumCreated', newItem)
    }
    const submit = () => {
      axios
        .post(`api/${props.entity}s/${props.entityId}/albums`, {
          name: form.value.title
        })
        .then((res) => {
          form.value = {
            title: null
          }
          createdAlbum(res.data)
          close()
        })
        .catch((err) => {
          formErrors.value = [err]
        })
    }

    const close = () => {
      context.emit('close', true)
    }

    return {
      submit,
      loading,
      form,
      formErrors,
      postToWall,
      close
    }
  }
}
</script>
