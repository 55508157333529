<template>
  <modal-component
    :modal-id="modalId"
    :title="`${isEdit ? 'Update' : 'Add'} ${planName} Plan Price`"
    :header-style="`bg-linear`"
    :size="`modal-md`"
    :profile="false"
    @closeModal="closeModal"
  >
    <template v-slot:modalBody>
      <div class="modal-body">
        <template v-if="!isEdit">
          <div class="form-group required mb-2">
            <label for="plan-name" class="fs-6 fw-bold mb-1">Plan</label>
            <div class="field-highlight fs-4">
              {{ planName }}
            </div>
          </div>

          <div class="form-group required mb-2">
            <label for="plan-interval" class="fs-6 fw-bold mb-1">Interval</label>
            <ce-select
              id="plan-interval"
              v-model="form.interval"
              class=""
              :options="intervals"
              option-value-key="value"
              option-label-key="label"
            />
          </div>
          <div class="form-group required mb-2">
            <label for="plan-description" class="fs-6 fw-bold mb-1"
              >Description</label
            >
            <ce-textarea
              id="plan-description"
              v-model.trim="form.description"
              auto-height
            />
          </div>
          <div class="form-group required mb-2">
            <label for="plan-price" class="fs-6 fw-bold mb-1">Price</label>
            <div id="plan-price" class="input-group ce-inputs-text">
              <span class="input-group-text fw-bold border-0">$</span>
              <input
                ref="inputRef"
                v-model="form.price"
                type="number"
                min="1"
                step="0.1"
                class="form-control h-100"
              />
            </div>
          </div>
        </template>
        <div class="form-group required mb-2">
          <label for="plan-price" class="fs-6 fw-bold mb-1">Tags</label>
          <tags-input
            element-id="tags"
            v-model="form.tags"
            :value="form.existingTags"
            :text-id="`id`"
            :text-field="`value`"
            :placeholder="`Add a separate tag by hitting enter key`"
            @initialized="onInitialized"
            @tag-added="onDataAdded"
            @tag-removed="onDataRemoved">
          </tags-input>
        </div>
        <div class="form-group required mb-2 row">
          <label for="plan-name" class="col-md-10 fs-6 fw-bold mb-1">Status</label>
          <div class="col-md-2 float-right">
            <ce-checkbox
              v-model="form.status"
              style="margin-left: 40px;"
              is-switch
            >
              <template #prepend="{ value }">
                {{ value ? 'Active' : 'Inactive' }}
              </template>
            </ce-checkbox>
          </div>
        </div>
      </div>
    </template>
    <template v-slot:modalFooter>
      <div class="modal-footer">
        <button
          type="button"
          class="btn btn-link text-decoration-none text-secondary"
          data-dismiss="modal"
          @click="closeModal"
        >
          Cancel
        </button>
        <button
          type="button"
          class="btn btn-link text-decoration-none text-primary"
          @click="nextAction"
          data-dismiss="modal"
        >
          Submit
        </button>
      </div>
    </template>
  </modal-component>
</template>

<script>
import { computed, onMounted, ref } from "vue";
import ModalComponent from "@/components/ModalComponent.vue";
import TagsInput from '@voerro/vue-tagsinput'

const intervals = [
  {
    label: "1 Month",
    value: 1,
  },
  {
    label: "3 Months",
    value: 3,
  },
  {
    label: "6 Months",
    value: 6,
  },
  {
    label: "12 Months",
    value: 12,
  },
];

export default {
  components: {
    ModalComponent,
    TagsInput
  },
  props: {
    modalId: {
      type: String,
      default: "",
    },
    planId: {
      type: String,
      default: "",
    },
    planName: {
      type: String,
      default: "",
    },
    setPrice: {
      type: [Array, Object],
      required: false,
      default: []
    },
    existingTags: {
      type: [Array, Object],
      required: false,
      default: () => []
    },
    isEdit: {
      type: Boolean,
      required: true,
      default: false
    }
  },
  emits: ["actionSubmit", "closeModal"],
  setup(props, { emit }) {
    const form = computed(() => ({
      id: props.setPrice?.id || '',
      plan_id: props.planId,
      interval: props.setPrice?.interval_count || '',
      description: props.setPrice?.description || '',
      price: props.setPrice?.price || '',
      status: (parseInt(props.setPrice.status) === 1 ? true : false) || true,
      tags: [],
      name: props.setPrice?.name || '',
      existingTags: props.existingTags.map((existing) => ({
        value: existing.value
      }))
    }));

    const selectedTags = ref([]);
    
    const nextAction = () => {
      console.log(selectedTags.value);
      form.value.tags = [];
      form.value.tags = selectedTags.value.filter(function(item, pos) {
          return selectedTags.value.indexOf(item) == pos;
      });
      if (props.setPrice.interval_count !== form.value.interval) {
        form.value.name =  intervals.find(data => data.value === form.value.interval).label;
      }
      onInitialized();
      emit("actionSubmit", form);
      form.value = [];
    };

    const onInitialized = () => {
      selectedTags.value = [];
      // form.value = [];
    }

    const onDataAdded = (data) => {
      selectedTags.value.push(data.value)
    }

    const onDataRemoved = (data) => {
      const index = selectedTags.value.indexOf(data.value)
      selectedTags.value.splice(index, 1)
    }

    const closeModal = () => {
      onInitialized();
      emit('closeModal')
    }
    
    return {
      form,
      nextAction,
      intervals,
      selectedTags,
      onDataAdded,
      onDataRemoved,
      closeModal,
      onInitialized
    };
  },
};
</script>
<style lang="scss" scoped>
@import "../../scss/custom";

.field-highlight {
  background: $color-light-gray;
  border-radius: 6px;
  padding: 0.5rem 0.875rem;
}
.input-group-text {
  background-color: #e7e7e7 !important;
}
:deep(.form-text.text-red) {
  font-size: 14px !important;
  font-weight: bold;
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
</style>
