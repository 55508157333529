<template>
  <div class="ce-inputs-textarea">
    <textarea
      ref="textareaRef"
      v-model="localValue"
      :placeholder="placeholder"
      class="form-control"
      :class="inputClass"
      :style="{
        height: height,
      }"
      :maxlength="max"
      :disabled="disabled"
      @blur="$emit('blur')"
      @change="valueChange"
      @keypress="(e) => $emit('keypress', e)"
    />
    <slot name="error">
      <template v-if="max || showMinLabelOnly">
        <div class="character-count d-flex">
          <div
            v-if="min && textCount < min"
            class="flex-fill text-start text-red"
          >
            A minimum of {{ min }} characters is required.
          </div>
          <div
            v-if="max"
            class="flex-fill"
          >
            {{ textCount }} / {{ max }}
          </div>
        </div>
      </template>
    </slot>
  </div>
</template>

<script>
import { watch, ref, computed, onMounted, nextTick } from 'vue'

export default {
  props: {
    modelValue: {
      type: String,
      default: ''
    },
    placeholder: {
      type: String,
      default: ''
    },
    inputClass: {
      type: [String, Array],
      default: ''
    },
    max: {
      type: Number,
      default: null
    },
    min: {
      type: Number,
      default: null
    },
    autoHeight: {
      type: Boolean,
      default: false
    },
    showMinLabelOnly: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  emits: ['update:modelValue', 'blur', 'change', 'keypress'],
  setup (props, { emit }) {
    const localValue = ref(props.modelValue)
    const textCount = computed(
      () => localValue.value.replace(/\s+/g, ' ').length
    )
    const textareaRef = ref(null)
    const height = ref(null)

    /* Methods */
    const imposeMaxCharacters = (texts) => {
      if (!props.max) {
        return texts
      }
      return texts.substr(0, props.max)
    }

    const setCorrectHeight = async () => {
      // Perform only when textarea is already mounted
      if (!textareaRef.value) {
        return false
      }
      height.value = '1px' // Needed to kick-in a proper scrollHeight
      await nextTick() // Wait for the value to apply first
      height.value = `${textareaRef.value.scrollHeight + 2}px` // Added 2px for the border
      return true
    }

    /* Watchers */
    watch(localValue, (newLocalValue) => {
      if (props.autoHeight) {
        setCorrectHeight()
      }
      emit('update:modelValue', imposeMaxCharacters(newLocalValue || ''))
    })

    watch(
      () => props.modelValue,
      (newModelValue) => {
        localValue.value = imposeMaxCharacters(newModelValue || '')
      },
      {
        immediate: true
      }
    )

    const valueChange = () => {
      emit('change', localValue.value)
    }

    /* Lifecycles */
    onMounted(() => {
      if (props.autoHeight) {
        setCorrectHeight()
      }
    })

    return {
      localValue,
      valueChange,
      textCount,
      textareaRef,
      height
    }
  }
}
</script>

<style lang="scss" scoped>
.ce-inputs-textarea {
  textarea {
    resize: none;
  }
}
.form-control, .form-control:focus {
  background-color: #E7E7E7;
  box-shadow: none;
  border-color: #E7E7E7;
  z-index: auto;
  min-height: 70px !important;
}
</style>
