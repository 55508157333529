<template>
  <div
    class="ce-inputs-checkbox form-check"
    :class="{ 'form-switch': isSwitch }"
  >
    <template v-if="$slots.prepend">
      <label class="form-check-label prepend" :for="uniqueId">
        <span><slot name="prepend" :value="localValue" /></span>
      </label>
    </template>
    <input
      :id="uniqueId"
      v-model="localValue"
      :value="value"
      class="form-check-input"
      :type="type"
      :disabled="disabled"
    />

    <template v-if="$slots.append">
      <label class="form-check-label" :for="uniqueId">
        <span><slot name="append" :value="localValue" /></span>
      </label>
    </template>
    <template v-else-if="!$slots.prepend">
      <label class="form-check-label" :for="uniqueId">
        <span><slot :value="localValue" /></span>
      </label>
    </template>
  </div>
</template>

<script>
import { ref, getCurrentInstance, watch } from "vue";

export default {
  props: {
    modelValue: {
      type: [Boolean, Number, String, Array],
      default: false,
    },
    value: {
      type: [Boolean, Number, String, Object],
      default: null,
    },
    type: {
      type: String,
      default: "checkbox",
    },
    isSwitch: {
      type: Boolean,
      default: false,
    },
    isReadOnly: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["update:modelValue"],
  setup(props, { emit }) {
    const localValue = ref(null);
    const uniqueId = `ce-checkbox-${getCurrentInstance().uid}`;

    /* Watchers */
    watch(localValue, (newLocalValue) => {
      emit("update:modelValue", newLocalValue);
    });

    watch(
      () => props.modelValue,
      (newModelValue) => {
        localValue.value = newModelValue;
      },
      {
        immediate: true,
      }
    );

    return {
      uniqueId,
      localValue,
    };
  },
};
</script>
<style scoped>
.form-check-input {
  position: relative;
}
</style>
