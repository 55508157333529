<template>
  <ce-button
    ref="triggerRef"
    type="button"
    :class="value ? classWhenTrue : classWhenFalse"
    :disabled="isBusy"
    :is-loading="isBusy"
    @click="toggleFavorite()"
  >
    <slot :value="value" />
  </ce-button>
</template>

<script>
import { watch, ref } from 'vue'
import useFavorite from '@/Use/use-favorite.js'

export default {
  props: {
    isFavorite: {
      type: Boolean,
      default: false
    },
    userId: {
      type: Number,
      default: 0
    },
    classWhenTrue: {
      type: [String, Array],
      default: '',
      required: false
    },
    classWhenFalse: {
      type: [String, Array],
      default: '',
      required: false
    }
  },
  emits: ['update:isFavorite'],
  setup (props, { emit }) {
    const triggerRef = ref(null)
    const { isFavorite, isBusy, toggleFavorite } = useFavorite(
      props.isFavorite,
      props.userId
    )

    /* Watchers */
    watch(isFavorite, () => {
      emit('update:isFavorite', isFavorite.value)
    })

    return { triggerRef, value: isFavorite, isBusy, toggleFavorite }
  }
}
</script>
