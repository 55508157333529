import { ref, computed, inject, watch, onMounted, onBeforeMount } from 'vue'
import emitter from '@/emitter.js'
import axios from 'axios'

export default (initialState, userId) => {
  const dialog = inject('dialog')
  const isFavorite = ref(initialState)
  const isAddingToFavorite = ref(false)
  const isRemovingFromFavorite = ref(false)
  const isBusy = computed(
    () => isAddingToFavorite.value || isRemovingFromFavorite.value
  )

  /* Methods */
  const addToFavorite = async () => {
    try {
      isAddingToFavorite.value = true
      await axios.post('/api/favorite', {
        to_user_id: userId,
        is_like: 1
      })
      isFavorite.value = true
    } catch (e) {
      dialog?.show(
        e.response?.data?.error ||
          'Sorry we are unable to continue with your request. Please try again.',
        {
          title: 'Favorites'
        }
      )
    } finally {
      isAddingToFavorite.value = false
    }
  }

  const removeFromFavorite = async () => {
    try {
      isRemovingFromFavorite.value = true
      await axios.post('/api/favorite', {
        to_user_id: userId,
        is_like: 0
      })
      isFavorite.value = false
    } catch (e) {
      dialog?.show(
        e.response?.data?.error ||
          'Sorry we are unable to continue with your request. Please try again.',
        {
          title: 'Favorites'
        }
      )
    } finally {
      isRemovingFromFavorite.value = false
    }
  }

  const toggleFavorite = () => {
    if (isFavorite.value) {
      removeFromFavorite()
    } else {
      addToFavorite()
    }
  }

  const getMyFavorites = async (params, page = 1) =>
    axios.get('/api/myfavorites', {
      params: {
        ...params,
        ...{
          page
        }
      }
    })

  const getFavoritedMe = async (params, page = 1) =>
    axios.get('/api/favoritedme', {
      params: {
        ...params,
        ...{
          page
        }
      }
    })

  /* Watchers */
  watch(isFavorite, (newFavoriteStatus) => {
    emitter.emit(`FAVORITE_STATUS_UPDATED.${userId}`, newFavoriteStatus)
  })

  /* Lifecycles */
  onMounted(() => {
    emitter.on(`FAVORITE_STATUS_UPDATED.${userId}`, (newFavoriteStatus) => {
      isFavorite.value = newFavoriteStatus
    })
  })

  onBeforeMount(() => {
    emitter.off(`FAVORITE_STATUS_UPDATED.${userId}`)
  })

  return {
    isFavorite,
    isAddingToFavorite,
    isRemovingFromFavorite,
    isBusy,
    addToFavorite,
    removeFromFavorite,
    toggleFavorite,
    getMyFavorites,
    getFavoritedMe
  }
}
