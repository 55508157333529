<template>
  <Preloader :is-loading="isLoading" />
  <div class="card card-success">
    <div class="card-body">
      <header-stories-component
        :title="`View Group`"
        :search-for="`groups`"
        :show-search="false"
        :show-total="false"
      />
    </div>
  </div>
  <div class="card card-primary profile">
    <div class="card-body">
      <div class="col-xl-12 col-lg-12 col-md-12">
        <div class="row">
          <div class="col-xl-3 col-lg-3 col-md-3 col-sm-12">
            <strong><h6>Find people belong this group</h6></strong>
            <filter-user-component @search="search($event)" />
          </div>
          <div
            v-if="!isLoading && groupResource"
            class="col-xl-9 col-lg-9 col-md-9 col-sm-12"
          >
            <group-preview :group="groupResource" :sub-pages="subPages" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { onMounted, ref } from "vue";
import router from "@/router";
import { useStore } from "vuex";
import HeaderStoriesComponent from "@/components/HeaderStoriesComponent.vue";
import FilterUserComponent from "../../components/FilterUserComponent.vue";
import GroupPreview from "./GroupPreview.vue";
import Preloader from "../../Layouts/Common/Preloader.vue";
export default {
  components: {
    HeaderStoriesComponent,
    FilterUserComponent,
    GroupPreview,
    Preloader,
  },
  setup() {
    const store = useStore();
    const groupResource = ref("");
    const slug = ref(router.currentRoute._value.params.slug);
    const isLoading = ref(false);
    const subPages = ref(null);

    onMounted(async () => {
      isLoading.value = true;
      await store
        .dispatch("group/loadGroupBySlug", { slug: slug.value })
        .then((response) => {
          if (response) {
            groupResource.value = response.data.group;
            localStorage.setItem("preview-group", groupResource.value);
            subPages.value = response.data.subpages;
            isLoading.value = false;
          }
        });
    });
    return {
      groupResource,
      slug,
      isLoading,
      subPages,
    };
  },
};
</script>
<style lang="scss" scoped>
@import "../../scss/index.scss";
</style>
