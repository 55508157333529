<template>
  <group-category-interest-form
    class="form-1"
    :group-id="groupForm.id"
    :data="{
      interests: groupForm?.interests.map((interest) => interest.id),
      category: groupForm?.category_id,
      type: groupForm?.type_id,
    }"
    @success="groupCategoryInterestCreated"
  >
    <template #submit="{ submit, isSubmitting }">
      <div class="row">
        <div class="col-6 col-sm-6 mb-3">
          <ce-button
            class="btn-lg btn btn-gray text-white text-bold w-100"
            @click="$emit('cancelEdit')"
          >
            Cancel
          </ce-button>
        </div>
        <div class="col-6 col-sm-6 mb-3">
          <ce-button
            class="btn-lg btn-gradient-gold text-bold w-100"
            :is-loading="isSubmitting"
            :disabled="isSubmitting"
            @click="submit"
          >
            {{ isPublished ? "Save" : "Continue" }}
          </ce-button>
        </div>
      </div>
    </template>
  </group-category-interest-form>
</template>

<script>
import { defineAsyncComponent, computed, ref } from "vue";
import { useStore } from "vuex";
import { useRoute, useRouter } from "vue-router";

export default {
  components: {
    GroupCategoryInterestForm: defineAsyncComponent(() =>
      import("@/Modules/Group/GroupCategoryInterestForm.vue")
    ),
  },
  props: {
    backUrl: {
      type: String,
      default: "",
    },
  },

  emits: ["createGroup", "groupIsPublished", "isLoading", "cancelEdit"],
  async setup(props, { emit }) {
    const store = useStore();
    const route = useRoute();
    const router = useRouter();

    const slug = ref(router.currentRoute._value.params.slug);

    emit("isLoading", true);
    const getGroupInfoResource = await store.dispatch("group/groupEdit", {
      slug: slug.value,
      api: "category-interest",
    });
    emit("isLoading", false);

    store.dispatch("group/setGroupInfo", getGroupInfoResource.data);
    const groupForm = computed(() => getGroupInfoResource.data.form);

    const groupCategoryInterestCreated = (response) => {
      emit("createGroup", response, { steps: "category_interest" });
    };

    const isPublished = computed(() => getGroupInfoResource.data.isPublished);

    emit("groupIsPublished", isPublished);

    return {
      isPublished,
      groupCategoryInterestCreated,
      slug,
      groupForm,
    };
  },
};
</script>

<style lang="scss" scoped>
:deep() {
  @import "../../scss/form/form-1";
}

.container {
  max-width: 680px;
}
</style>
