<template>
  <div class="card card-success">
    <div class="card-body">
      <span class="fw-bold color-black h1">Subscribers</span>
      <button 
      type="button" 
      class="btn-gradient-gold mt-2 mb-2 close-gradient-gold-color float-right fw-bold" 
      @click="addSubscriptionModal">Add Subscription</button>
    </div>
  </div>
  <subscription-table
    :is-load="isLoad"
  />
  <div id="confirm" />

   <add-subscription-modal
      :modal-id="`add-subscription-modal`"
      @actionSubmit="addSubscriber"
    />
</template>

<script>
import { computed, ref } from "vue";
import { useStore } from "vuex";
import { useRoute } from "vue-router";
import moment from "moment";
import { userEnums } from "@/composable/enums.js";
import SubscriptionTable from "@/components/Tables/SubscriptionTable.vue";
import CsvButtonComponent from "@/components/CsvButtonComponent.vue";
import Messenger from "../Message/Messenger.vue";
import AddSubscriptionModal from '@/components/Modal/AddSubscriberModal.vue';

export default {
  name: "App",
  components: {
    SubscriptionTable,
    CsvButtonComponent,
    Messenger,
    AddSubscriptionModal,
  },
  setup() {

    const status = ref("active");
    const { UserCSVHeader } = userEnums();
    const store = useStore();
    const filterAddress = ref(null);
    const headerFilter = ref(null);

    const route = useRoute();
    const queryType = route.query.type;
    const queryFilter = route.query.filter;

    const userList = computed(() => store.state.user.userList);
    const zodiac = computed(() => store.state.user.enums.zodiacSign);
    const dataTableUser = ref("");
    const isLoading = ref(false);
    const isLoad = ref(false);

    const changeStatus = (event) => {
      dataTableUser.value = event;
    };

    const getZodiac = (zodiacNumber) => {
      const getZodiac = zodiac.value.find(
        (zodiac) => zodiac.id === parseInt(zodiacNumber)
      );
      return getZodiac.value;
    };

    const isDone = (event) => {
      isLoading.value = event;
    };

    const searchHeader = (event) => {
      dataTableUser.value = "headerFilter";
      headerFilter.value = event;
    };

    const filterAdvance = (data) => {
      filterAddress.value = data;
    };

    const reset = () => {
      dataTableUser.value = "all";
      // headerFilter.value = null;
      // filterAddress.value = null;
    };

    const addSubscriptionModal = () => {
      $("#add-subscription-modal").modal("show");
    }

    const addSubscriber = async(data) => {
      isLoad.value = false;
      let form = {
        email: data.email,
        admin: 1,
        id: data.id,
      }
      let response = await store.dispatch("saveSubscriber", form);
      if (response) {
        isLoad.value = true;
      }
    }

    return {
      changeStatus,
      status,
      UserCSVHeader,
      userList,
      getZodiac,
      zodiac,
      dataTableUser,
      isLoading,
      isDone,
      filterAddress,
      filterAdvance,
      searchHeader,
      headerFilter,
      reset,
      queryType,
      queryFilter,
      addSubscriptionModal,
      addSubscriber,
      isLoad
    };
  },
  created: function () {
    this.moment = moment;
  },
};
</script>

<style scoped>
.downloadAndMessageAlign {
  text-align: center;
  position: inherit;
  margin-top: 15px;
}
.dropdown-menu-lg {
  min-width: auto;
}
.table td,
.table th {
  border-top: 0 !important;
}
.table td {
  vertical-align: middle;
}
.custom-control-label {
  font-weight: 500 !important;
}
.send {
  color: rgb(4, 98, 193);
}
.selected-message-type {
  background-color: #333;
  color: #cacaca !important;
}
</style>
