<template>
  <ce-accordion-item>
    <template #header>
      <label class="d-block text-start small fw-semibold ls-1">BIRTHDAY</label>
    </template>
    <div class="row gx-2 pb-3">
      <div class="form-field col-8">
        <ce-select
          v-model="localValue.month"
          placeholder="Month"
          :options="months"
          option-label-key="label"
          option-value-key="value"
        />
      </div>
      <div class="form-field col-4">
        <ce-select
          v-model="localValue.day"
          placeholder="Day"
          :options="Array.from({ length: 31 }, (_, i) => i + 1)"
        />
      </div>
    </div>
  </ce-accordion-item>
</template>

<script>
import { ref, watch } from 'vue'

export default {
  props: {
    modelValue: {
      type: Object,
      default: null
    }
  },
  emits: ['update:modelValue'],
  setup (props, { emit }) {
    const localValue = ref({ month: null, day: null })
    const months = ref([
      { value: 1, label: 'January' },
      { value: 2, label: 'February' },
      { value: 3, label: 'March' },
      { value: 4, label: 'April' },
      { value: 5, label: 'May' },
      { value: 6, label: 'June' },
      { value: 7, label: 'July' },
      { value: 8, label: 'August' },
      { value: 9, label: 'September' },
      { value: 10, label: 'October' },
      { value: 11, label: 'November' },
      { value: 12, label: 'December' }
    ])

    /* Watchers */
    watch(
      localValue,
      (newLocalValue) => {
        emit('update:modelValue', newLocalValue)
      },
      {
        deep: true
      }
    )

    // watch(
    //   () => props.modelValue,
    //   (newModelValue) => {
    //     localValue.value = newModelValue;
    //   },
    //   {
    //     immediate: true,
    //   }
    // );

    return {
      months,
      localValue
    }
  }
}
</script>
