import axios from 'axios'

const setupHeaderConfig = (config) => {
    // -------------------------------------------------------------
    // Note: This will be uncommented once I finish the auth state / store
    // -------------------------------------------------------------
    // const token
    const token = localStorage.getItem('token')
        // !config.headers ? config.headers = {} : null\
    if (config.headers === undefined) {
        config.headers.Authorization = 'Bearer ' + token
    } else {
        config.headers.Authorization = 'Bearer ' + token
    }
    return config
}

export default ({
    state: {
        eventList: [],
        eventStatistics: [{
            icon: 'calendar-days',
            labels: 'Events',
            data: 0,
            link: 'manage-events',
            heading: [],
            menu1: [{
                    label: 'Add an event',
                    link: '/event/add/'
                },
                {
                    label: 'Edit an event',
                    link: '/manage-events?type=edit'
                },
                {
                    label: 'Publish an event',
                    link: '/manage-events?type=publish'
                },
                {
                    label: 'Flag an event',
                    link: '/manage-events?type=flag'
                },
                {
                    label: 'Unsuspend',
                    link: '/manage-events?type=unsuspend'
                }
            ],
            menu2: [{
                    label: 'Suspend an event',
                    link: '/manage-events?type=suspend'
                },
                {
                    label: 'Deactivate an event',
                    link: '/manage-events?type=deactivate'
                },
                {
                    label: 'Reactivate an event',
                    link: '/manage-events?type=reactivate'
                },
                {
                    label: 'Delete an event',
                    link: '/manage-events?type=delete'
                },
                {
                    label: 'Remove Flag',
                    link: '/manage-events?type=remove-flag'
                }
            ]
        }],
        huddleList: [],
        huddleStatistics: [{
            icon: 'people-group',
            labels: 'Huddles',
            data: 0,
            link: 'manage-huddles',
            heading: [],
            menu1: [{
                    label: 'Add a huddle',
                    link: '/huddle/add/'
                },
                {
                    label: 'Edit a huddle',
                    link: '/manage-huddles?type=edit'
                },
                {
                    label: 'Publish a huddle',
                    link: '/manage-huddles?type=publish'
                },
                {
                    label: 'Flag a huddle',
                    link: '/manage-huddles?type=flag'
                },
                {
                    label: 'Unsuspend',
                    link: '/manage-huddles?type=unsuspend'
                }
            ],
            menu2: [{
                    label: 'Suspend a huddle',
                    link: '/manage-huddles?type=suspend'
                },
                {
                    label: 'Deactivate a huddle',
                    link: '/manage-huddles?type=deactivate'
                },
                {
                    label: 'Reactivate a huddle',
                    link: '/manage-huddles?type=reactivate'
                },
                {
                    label: 'Delete a huddle',
                    link: '/manage-huddles?type=delete'
                },
                {
                    label: 'Remove Flag',
                    link: '/manage-huddles?type=remove-flag'
                }
            ]
        }]
    },
    mutations: {
        userStatistics(state) {
            return state.statistics
        },
        LOAD_EVENT_STATISTICS(state, payload) {
            state.eventStatistics[0].heading = payload.statistics
            state.eventStatistics[0].data = payload.all
        },
        LOAD_HUDDLE_STATISTICS(state, payload) {
            state.huddleStatistics[0].heading = payload.statistics
            state.huddleStatistics[0].data = payload.all
        },
        LOAD_EVENT(state, payload) {
            state.eventList = payload
        }
    },
    getters: {},
    actions: {
        async eventEdit({ commit }, dataInfo, config = { headers: {} }) {
            const newConfig = setupHeaderConfig(config);
            return await axios.get(`admin-panel/event/${dataInfo.id}/edit/${dataInfo.api}`, newConfig).then((response) => {
                return response.data;
            })
        },
        async invitePastEvents({ commit }, dataInfo, config = { headers: {} }) {
            const newConfig = setupHeaderConfig(config);
            return await axios.get(`admin-panel/event/${dataInfo.id}/invite-past-events`, newConfig).then((response) => {
                return response.data;
            })
        },
        async inviteFriends({ commit }, dataInfo, config = { headers: {} }) {
            const newConfig = setupHeaderConfig(config);
            return await axios.get(`admin-panel/event/${dataInfo.id}/invite-friends`, newConfig).then((response) => {
                return response.data;
            })
        },

        async loadEventStatistic({ commit }, params, config = { headers: {} }) {
            // eslint-disable-next-line no-console
            /* eslint-disable */
            console.log(commit);
            const newConfig = setupHeaderConfig(config);
            return await axios.get(`admin-panel/event/statistics?gathering_type=${params.gatheringType}`, newConfig).then((response) => {
                if (params.gatheringType === 1) {
                    commit("LOAD_EVENT_STATISTICS", response.data.data);
                }
                if (params.gatheringType === 2) {
                    commit("LOAD_HUDDLE_STATISTICS", response.data.data);
                }

                return response.data.data;
            })
        },
        async loadEventList({ commit }, params, config = { headers: {} }) {
            config.params = params
            const newConfig = setupHeaderConfig(config)
            return await axios.get(params.search ? `admin-panel/event?${params.search}` : `admin-panel/event`, newConfig).then((response) => {
                commit("LOAD_EVENT", response.data.data);
                return response.data;
            })
        },
        async loadEventUserStatistic({ commit }, params, config = { headers: {} }) {
            // eslint-disable-next-line no-console
            /* eslint-disable */
            console.log(commit);
            const newConfig = setupHeaderConfig(config);
            return await axios.get(`admin-panel/event/${params.id}/statistic`, newConfig).then((response) => {
                return response.data.data;
            })
        },
        async loadUserEventList({ commit }, params, config = { headers: {} }) {
            // eslint-disable-next-line no-console
            /* eslint-disable */
            console.log(commit);
            const newConfig = setupHeaderConfig(config);
            return await axios.get(`admin-panel/event/${params.id}?type=${params.type}`, newConfig).then((response) => {
                return response.data;
            })
        },
        async selectEventToMessage({ commit }, params, config = { headers: {} }) {
            // eslint-disable-next-line no-console
            /* eslint-disable */
            console.log(commit);
            const newConfig = setupHeaderConfig(config);
            return await axios.get(`/events?keyword=${params}&perPage=7`, newConfig).then((response) => {
                return response.data.data;
            })
        },
        async loadEventBySlug({ commit }, params, config = { headers: {} }) {
            // eslint-disable-next-line no-console
            /* eslint-disable */
            console.log(commit);
            config.params = params
            const newConfig = setupHeaderConfig(config)
            return await axios.get(`admin-panel/event/slug`, newConfig).then((response) => {
                return response.data;
            })
        },
        async loadSearchEventList({ commit }, params, config = { headers: {} }) {
            console.log(commit);
            const newConfig = setupHeaderConfig(config)
            return await axios.get(`admin-panel/event?page_type=${params.page_type}&page_filter=${params.page_filter}&type=${params.type}&${params.search}`, newConfig).then((response) => {
                commit("LOAD_EVENT", response.data.data);
                return response.data;
            })
        },
        async reactivateEvent({ commit }, params, config = { headers: {} }) {
            console.log(commit);
            const newConfig = setupHeaderConfig(config)
            return await axios.get(`admin-panel/event/reactivate/${params}`, newConfig).then((response) => {
                return response.data;
            })
        },
        async deleteEvent({ commit }, params, config = { headers: {} }) {
            console.log(commit);
            const newConfig = setupHeaderConfig(config)
            return await axios.delete(`admin-panel/event/${params}`, newConfig).then((response) => {
                return response.data;
            })
        },
        async fetchFeedCallback({ commit }, params, config = { headers: {} }) {
            // eslint-disable-next-line no-console
            /* eslint-disable */
            console.log(commit);
            config.params = params
            const newConfig = setupHeaderConfig(config)
            return await axios.get(params.url + '?perPage=' + params.perPage + '&&page=' + params.currentPage, newConfig).then((response) => {
                return response.data;
            })
        },
    },
});
