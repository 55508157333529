<template>
  <div class="info-box">
    <div class="user-block">
      <img
        class="rounded"
        :src="`${src}`"
        alt="User Image"
        :style="!hasType ? 'margin-top: -9px' : ''"
        @error="imageUrlAlt"
      />
      <span class="username text-bold text-primary">
        {{ name }}
      </span>
      <span v-if="hasType" class="description">{{ accountType }}</span>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    src: {
      type: String,
      default: "//via.placeholder.com/150",
    },
    name: {
      type: String,
      default: "",
    },
    accountType: {
      type: String,
      default: "Standard Account",
    },
    hasType: {
      type: Boolean,
      default: true,
    },
  },

  setup() {
    const imageUrlAlt = (event) => {
      event.target.src = "//via.placeholder.com/150";
    };

    return { imageUrlAlt };
  },
};
</script>
