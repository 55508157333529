import axios from 'axios'

const setupHeaderConfig = (config) => {
    // -------------------------------------------------------------
    // Note: This will be uncommented once I finish the auth state / store
    // -------------------------------------------------------------
    // const token = store.getters['auth/accessToken']
    const token = localStorage.getItem('token')
        // !config.headers ? config.headers = {} : null\
    if (config.headers === undefined) {
        config.headers.Authorization = 'Bearer ' + token
    } else {
        config.headers.Authorization = 'Bearer ' + token
    }
    return config
}

export default ({
    state: {

    },
    mutations: {},
    getters: {},
    actions: {
        async multipleMediaUpload({ commit }, params, config = {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        }) {
            // eslint-disable-next-line no-console
            /* eslint-disable */
            console.log(commit);
            const newConfig = setupHeaderConfig(config);
            return await axios.post('/media', params, newConfig).then((response) => {
                return response.data.data;
            })
        },
        async selectUserToMessage({ commit }, params, config = { headers: {} }) {
            // eslint-disable-next-line no-console
            /* eslint-disable */
            console.log(commit);
            const newConfig = setupHeaderConfig(config);
            return await axios.get(`/account/messaging/friends?search=${params}&perPage=7`, newConfig).then((response) => {
                return response.data.data;
            })
        },
        async sendMessage({ commit }, params, config = { headers: {} }) {
            // eslint-disable-next-line no-console
            /* eslint-disable */
            console.log(commit);
            const newConfig = setupHeaderConfig(config);
            return await axios.post('/admin-panel/messaging', params, newConfig).then((response) => {
                return response.data.data;
            })
        },
    },
})