<template>
  <ce-accordion-item>
    <template #header>
      <label
        class="d-block text-start small fw-semibold ls-1"
      >EDUCATION LEVEL</label>
    </template>
    <div class="checkbox-wrapper mb-3">
      <div class="no-preference-checkbox">
        <ce-checkbox
          v-model="noPreferenceValue"
          :value="true"
          type="radio"
        >
          No Preference
        </ce-checkbox>
      </div>
      <ce-checkboxes
        v-model="localValue"
        class="preference-checkboxes"
        :options="
          $store.getters['option/educationLevels'].filter((v) => v.id !== 0)
        "
        :option-value-key="'id'"
        :option-label-key="'value'"
      />
    </div>
  </ce-accordion-item>
</template>

<script>
import { useStore } from 'vuex'
import useBrowsePreferMultipleField from '@/Use/use-browse-prefer-multiple-field.js'

export default {
  props: {
    modelValue: {
      type: Array,
      default: () => []
    }
  },
  emits: ['update:modelValue'],
  setup (props, { emit }) {
    const store = useStore()
    store.dispatch('option/loadEducationLevels')

    return {
      ...useBrowsePreferMultipleField(props, { emit })
    }
  }
}
</script>

<style lang="scss" scoped>
// @import '~@/scss/browse/browse-prefer-multiple-field';
</style>
