import axios from 'axios'

const setupHeaderConfig = (config) => {
    // -------------------------------------------------------------
    // Note: This will be uncommented once I finish the auth state / store
    // -------------------------------------------------------------
    // const token
    const token = localStorage.getItem('token')
        // !config.headers ? config.headers = {} : null\
    if (config.headers === undefined) {
        config.headers.Authorization = 'Bearer ' + token
    } else {
        config.headers.Authorization = 'Bearer ' + token
    }

    return config
}

export default ({
    state: {
        authUser: {}
    },
    mutations: {

    },
    getters: {},
    actions: {

        async loadTiersList({ commit }, params, config = { headers: {} }) {
            config.params = params
            const newConfig = setupHeaderConfig(config)
            return await axios.get('admin-panel/subscriptions/user-tiers?admin', newConfig).then((response) => {
                return response.data
            })
        },

        async loadTiersPricesList({ commit }, params, config = { headers: {} }) {
            config.params = params
            const newConfig = setupHeaderConfig(config)
            return await axios.get('admin-panel/subscriptions/tier-prices', newConfig).then((response) => {
                return response.data
            })
        },

        async loadAbilitiesList({ commit }, params, config = { headers: {} }) {
            config.params = params
            const newConfig = setupHeaderConfig(config)
            return await axios.get('admin-panel/subscriptions/abilities', newConfig).then((response) => {
                return response.data
            })
        },

        async loadFeatureBlockersList({ commit }, params, config = { headers: {} }) {
            config.params = params
            const newConfig = setupHeaderConfig(config)
            return await axios.get('admin-panel/subscriptions/feature-blocker', newConfig).then((response) => {
                return response.data
            })
        },

        async updateFeatureBlockersList({ commit }, params, config = { headers: {} }) {
            config.params = params
            console.log(params)
            const newConfig = setupHeaderConfig(config)
            return await axios.patch(`featureblocker/${params.http_code}`, params, newConfig).then((response) => {
                return response.data
            })
        },

        async loadPriceList({ commit }, params, config = { headers: {} }) {
            config.params = params
            const newConfig = setupHeaderConfig(config)
            return await axios.get('/prices').then((response) => {
                return response.data.data
            })
        },

        async searchSubscriber({ commit }, params, config = { headers: {} }) {
            const newConfig = setupHeaderConfig(config);
            return await axios.get(`admin-panel/users/search?${params}`, newConfig).then((response) => {
                return response.data.data;
            });
        },

        async saveSubscriber({ commit }, params, config = { headers: {} }) {
            const newConfig = setupHeaderConfig(config);
            return await axios.post('subscriptions', params, newConfig).then((response) => {
                return response.data.data;
            })
        },

        async saveAbilities({ commit }, params, config = { headers: {} }) {
            const newConfig = setupHeaderConfig(config);
            return await axios.post('abilities', params, newConfig).then((response) => {
                return response.data.data;
            })
        },

        async updateAbilities({ commit }, params, config = { headers: {} }) {
            const newConfig = setupHeaderConfig(config);
            return await axios.put(`abilities/${params.id}`, params.form, newConfig).then((response) => {
                return response.data.data;
            })
        },

    },
});
