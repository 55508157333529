<template>
  <div>
    <discussion-item
      v-for="item in discussions"
      :key="item.id"
      :item="item"
      :type="type"
      :entity-id="entityId"
      :is-allowed-to-post="isAllowedToPost"
      :show-tool-bar="showToolBar"
      :show-share="showShare"
      :can-view-comment="canViewComment"
      :can-reply="canReply"
      @delete-topic="onDeleteTopic($event)"
    />
    <div class="attendee-pagination">
      <ce-pagination
        v-model:current-page="currentPage"
        :per-page="meta.per_page"
        :total="meta.total"
      />
    </div>
  </div>
</template>

<script>
import { ref, watch } from 'vue'
import DiscussionItem from '@/components/Discussion/DiscussionItem.vue'
import CePagination from '@/components/Paginations/CePagination.vue'
import axios from 'axios'
import { useStore } from 'vuex'

export default {
  components: {
    DiscussionItem,
    CePagination
  },
  props: {
    entityId: {
      type: Number,
      required: true
    },
    type: {
      type: String,
      required: true
    },
    isAllowedToPost: {
      type: Boolean,
      required: false,
      default: false
    },
    // Will be use to get specific discussion to show
    discussionId: {
      type: Number,
      default: 0
    },
    showToolBar: {
      type: Boolean,
      default: true
    },
    showShare: {
      type: Boolean,
      default: true
    },
    canViewComment: {
      type: Boolean,
      default: true
    },
    canReply: {
      type: Boolean,
      default: true
    }
  },
  setup (props) {
    const store = useStore()
    const discussions = ref([])
    const currentPage = ref(1)
    const meta = ref({ current_page: currentPage, total: 0, per_page: 15 })
    const sortBy = ref('new-discussions')

    const fetchDiscussions = async (sort = 'new-discussions') => {
      let page = currentPage.value
      if (sortBy.value !== sort) {
        // reset currentPage
        page = 1
      }
      sortBy.value = sort

      // console.log('fetchDiscussions', sortBy.value, page);
      let getUrl = `${props.type}s/${props.entityId}/discussions`

      // Get specific discussion id
      if (props.discussionId) {
        getUrl += `/${props.discussionId}`
      }
      const params = {
        url: getUrl,
        sort: sort,
        page: page
      }

      await store.dispatch('loadDiscussion', params).then((res) => {
        meta.value = res.data.meta
        discussions.value = res.data.data
        currentPage.value = meta.value.current_page
      })
    }

    fetchDiscussions()

    watch(currentPage, () => {
      fetchDiscussions(sortBy.value)
    })

    const onDeleteTopic = (id) => {
      axios
        .delete(`api/${props.type}s/${props.entityId}/discussions/${id}`)
        .then(() => {
          fetchDiscussions()
        })
        .catch(() => {})
    }

    return {
      discussions,
      meta,
      currentPage,
      fetchDiscussions,
      onDeleteTopic
    }
  }
}
</script>
