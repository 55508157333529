<template>
  <Preloader :is-loading="isLoading" />
  <div class="card card-success">
    <div class="card-body">
      <header-stories-component
        :module-name="dynamicWordFor('event')"
        :title="`View ${dynamicWordFor('Event')}`"
        :show-search="false"
        :show-total="false"
      />
    </div>
  </div>
  <div class="card card-primary profile">
    <div class="card-body">
      <div class="col-xl-12 col-lg-12 col-md-12">
        <div class="row">
          <div class="col-xl-3 col-lg-3 col-md-3 col-sm-12">
            <strong
              ><h6>
                Find people belong this {{ dynamicWordFor("event") }}
              </h6></strong
            >
            <filter-user-component @search="search($event)" />
          </div>
          <div
            v-if="!isLoading && eventResource"
            class="col-xl-9 col-lg-9 col-md-9 col-sm-12"
          >
            <event-preview :event="eventResource" :sub-pages="subPages" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { onMounted, ref } from "vue";
import router from "@/router";
import { useStore } from "vuex";
import HeaderStoriesComponent from "@/components/HeaderStoriesComponent.vue";
import FilterUserComponent from "../../components/FilterUserComponent.vue";
import Preloader from "../../Layouts/Common/Preloader.vue";
import EventPreview from "./EventPreview.vue";

import { GATHERING_TYPES, useDynamicWord } from "@/Use/events.js";

export default {
  components: {
    HeaderStoriesComponent,
    FilterUserComponent,
    Preloader,
    EventPreview,
  },
  props: {
    gatheringType: {
      type: Number,
      default: GATHERING_TYPES.EVENT,
    },
  },
  setup(props) {
    const store = useStore();
    const eventResource = ref("");
    const slug = ref(router.currentRoute._value.params.slug);
    const isLoading = ref(false);
    const subPages = ref(null);

    const { dynamicWordFor } = useDynamicWord({
      gatheringType: props.gatheringType,
    });

    onMounted(async () => {
      isLoading.value = true;
      await store
        .dispatch("loadEventBySlug", { slug: slug.value })
        .then((response) => {
          if (response) {
            eventResource.value = response.data.event;
            localStorage.setItem("preview-event", eventResource.value);
            subPages.value = response.data.subpages;
            isLoading.value = false;
          }
        });
    });
    return {
      eventResource,
      slug,
      isLoading,
      subPages,
      dynamicWordFor,
    };
  },
};
</script>
<style lang="scss" scoped>
@import "../../scss/index.scss";
</style>
