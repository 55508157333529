<template>
  <form>
    <!-- retain for later use, might need for putting captions -->
    <ce-textarea
      v-if="allowCaptions"
      v-model.trim="postBody"
      placeholder="What are you interested in today?"
    />
    <span class="text-red">{{ errorMessage }}</span>
    <div
      v-if="attachments.length > 0"
      class="attachments mx-n1"
    >
      <div v-if="!sendingDisabled()">
        <ce-image
          class="h-100 w-100"
          style="
            object-fit: none;
            background-color: #e7e7e7;
            border-radius: 5px;
            margin-left: 0.25rem;
          "
          src="https://perfectfriends.com/images/btn-add.png"
          @click="addMoreItemsClicked()"
        />
      </div>
      <div style="width: 0.5rem !important" />
      <template
        v-for="(attachment, index) in attachments"
        :key="attachment.id"
      >
        <template v-if="attachment.type === 'video'">
          <video-field
            v-model="attachment.mediaId"
            v-model:is-uploading="attachment.isUploading"
            class="mx-1"
            :upload="attachment.file"
            :badge-number="index + 1"
            @removed="attachments.splice(index, 1)"
          />
        </template>
        <template v-else-if="attachment.type === 'photo'">
          <photo-field
            v-model="attachment.mediaId"
            v-model:is-uploading="attachment.isUploading"
            placeholder="/images/defaults/photo.png"
            class="mx-1"
            :upload="attachment.file"
            :badge-number="index + 1"
            @removed="attachments.splice(index, 1)"
          />
        </template>
      </template>
    </div>
    <div class="d-flex justify-content-around py-2">
      <div class="toolbar">
        <ce-visual-media-picker
          ref="mediaPickerRef"
          :file-types="[...PHOTO_FILE_TYPES, ...VIDEO_FILE_TYPES]"
          :modal-container="baseLayoutRef"
          multiple
          @invalid-file-type="showInvalidFileTypePrompt()"
          @picked="
            addAttachments($event);
            disablePosting = false;
          "
        >
          <template #default="{ browseMedia }">
            <ce-button
              icon
              title="Attach Photo or Video"
              @click="browseMedia"
            >
              <span class="icon-picture" />
            </ce-button>
          </template>
          <template #browse-options="{ chooseFromFile, takeFromCamera }">
            <div class="row p-3">
              <p class="text-muted text-center font-monospace mb-3">
                Upload your Perfect Friends videos or photos.
              </p>
              <p class="text-muted text-center font-monospace mb-3">
                Maximum {{ maximumImages }} photos per post.
              </p>
              <div
                class="mb-3 mb-md-0"
                :class="[isMobileDevice ? 'col-md-6' : 'col-md-12']"
              >
                <ce-button
                  type="button"
                  class="btn-nero w-100"
                  @click="chooseFromFile()"
                >
                  Add Photo or Video
                </ce-button>
              </div>
              <div
                v-if="isMobileDevice"
                class="col-md-6"
              >
                <ce-button
                  type="button"
                  class="btn-nero w-100"
                  @click="takeFromCamera()"
                >
                  Take From Device
                </ce-button>
              </div>
            </div>
          </template>
        </ce-visual-media-picker>
      </div>
      <div class="d-grid gap-2 d-md-flex justify-content-md-end">
        <ce-button
          v-if="attachments.length == 0"
          type="submit"
          class="btn-nero me-md-2"
          :is-loading="isBusy"
          @click.prevent="mediaPickerRef.browseMedia()"
        >
          Add Photo or Video
        </ce-button>
        <ce-button
          v-if="attachments.length > 0"
          type="submit"
          class="btn-nero"
          :is-loading="isBusy"
          :disabled="sendingDisabled()"
          @click.prevent="sendPost()"
        >
          Upload
        </ce-button>
      </div>
    </div>
  </form>
</template>

<script>
import { usePage } from '@inertiajs/inertia-vue3'
import uniqueId from 'lodash/uniqueId'
import {
  defineAsyncComponent,
  ref,
  computed,
  inject,
  // watch,
  nextTick,
  provide
} from 'vue'
import axios from 'axios'
import { FILE_TYPES as VIDEO_FILE_TYPES, isAVideo } from '@/Use/use-video.js'
import { FILE_TYPES as PHOTO_FILE_TYPES, isAPhoto } from '@/Use/use-photo.js'
import CeTextarea from '../Inputs/CeTextarea.vue'
import CeImage from '../Images/CeImage.vue'
import VideoField from '../Inputs/VideoField.vue'
import PhotoField from '../Inputs/PhotoField.vue'
import CeVisualMediaPicker from '../Utilities/CeVisualMediaPicker.vue'
import CeButton from '../Buttons/CeButton.vue'

export default {
  components: {
    CeTextarea,
    CeImage,
    CeVisualMediaPicker,
    CeButton,
    PhotoField,
    VideoField
  },
  props: {
    submitUrl: {
      type: String,
      default: null
    },
    maximumImages: {
      type: Number,
      default: 10
    }
  },
  emits: ['new-item-posted'],
  setup (props, context) {
    const auth = computed(() => usePage().props.value.auth)
    const userId = auth.value?.data?.id || -1
    const dialog = inject('dialog')
    const isBusy = ref(false)
    const croppedPhotoSrc = ref('')
    const isUploadingImage = ref(false)
    const cropperOptions = {
      viewMode: 3,
      aspectRatio: 2.581,
      dragMode: 'move'
    }
    const image = ref(null)
    const errorMessage = ref('')

    const images = ref([])

    const baseLayoutRef = inject('baseLayoutRef')
    const createWallPostModalRef = ref(null)
    const disablePosting = ref(true)
    const photoUploaderRef = ref(null)
    const showPicker = ref(true)
    const videoUploaderRef = ref(null)
    const selectedFileType = ref('')
    const attachments = ref([])
    const isUploadingAttachments = computed(() =>
      attachments.value.some((attachment) => attachment.isUploading)
    )
    let hasReachedMaxNumberOfVideo = false
    let attemptedToUploadMultipleVideos = false

    const maxNumberOfImage = ref(props.maximumImages)
    const hasReachedMaxNumberOfImage = computed(
      () =>
        attachments.value.reduce(
          (acc, curr) => (curr.type === 'photo' ? acc + 1 : acc),
          0
        ) >= maxNumberOfImage.value
    )

    let attemptedToUploadMultipleImages = false
    const isMobileDevice = /Mobile/i.test(window.navigator.userAgent)

    /* Methods */
    const showInvalidFileTypePrompt = () => {
      dialog.show(
        'Please make sure to select only valid photo and video file types.',
        {
          title: 'Invalid file type detected'
        }
      )
    }

    const allowCaptions = ref(false)

    const addAttachments = (files) => {
      hasReachedMaxNumberOfVideo = false
      attemptedToUploadMultipleVideos = false
      attemptedToUploadMultipleImages = false
      const attachmentsTemporary = []

      files
        .slice(0, props.maximumImages - attachments.value.length)
        .forEach((file) => {
          if (isAVideo(file)) {
            if (!hasReachedMaxNumberOfVideo) {
              attachmentsTemporary.push({
                id: uniqueId('attachments'),
                type: 'video',
                isUploading: null,
                mediaId: null,
                file
              })
              hasReachedMaxNumberOfVideo = true
            } else {
              attemptedToUploadMultipleVideos = true
            }
          } else if (isAPhoto(file)) {
            if (!hasReachedMaxNumberOfImage.value) {
              attachmentsTemporary.push({
                id: uniqueId('attachments-'),
                type: 'photo',
                isUploading: null,
                mediaId: null,
                file
              })
            } else {
              attemptedToUploadMultipleImages = true
            }
          }
        })

      attachments.value = [...attachments.value, ...attachmentsTemporary]

      if (attemptedToUploadMultipleVideos) {
        dialog.show(
          'Only 1 video upload is allowed at this moment and the first video from your selection is the one we accepted.',
          {
            title: 'Attachment Notice',
            buttons: [
              {
                label: 'Close',
                class: 'btn-light-gray',
                handler: (closeModal) => {
                  closeModal()
                }
              }
            ]
          }
        )
      }

      if (attemptedToUploadMultipleImages) {
        dialog.show(
          `Only ${props.maximumImages} images can be uploaded at this time. The first ${props.maximumImages} images selected will be used for this post.`,
          {
            title: 'Attachment Notice',
            buttons: [
              {
                label: 'Close',
                class: 'btn-light-gray',
                handler: (closeModal) => {
                  closeModal()
                }
              }
            ]
          }
        )
      }
    }

    const hasEmail = (input) => {
      const re =
        /(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))/;// eslint-disable-line
      return re.test(input)
    }

    const hasPhone = (input) => {
      const re = /\+?[0-9][0-9()\-\s+]{4,20}[0-9]/;// eslint-disable-line
      return re.test(input)
    }

    const postBody = ref('')

    const sendPost = () => {
      if (postBody.value.length === 0 && attachments.value.length === 0) return

      // check if it has an email
      if (hasEmail(postBody.value)) {
        errorMessage.value = 'You are not allowed to post an email address'
        return
      }

      // check if it has a phone number
      if (hasPhone(postBody.value)) {
        errorMessage.value = 'You are not allowed to post a phone number'
        return
      }

      isBusy.value = true

      const data = {
        body: postBody.value,
        media_id: attachments.value.map((attachment) => attachment.mediaId)
      }

      axios
        .post(props.submitUrl, data)
        .then((res) => {
          postBody.value = ''
          croppedPhotoSrc.value = ''
          image.value = 0
          context.emit('new-item-posted', res.data.data)
          errorMessage.value = ''
          images.value = []
          attachments.value = []

          if (res.data.data.is_transcoding) {
            dialog.show(
              'Please wait for your post until we finish processing your video.',
              {
                title: 'Processing your video',
                buttons: [
                  {
                    label: 'Close',
                    class: 'btn-light-gray',
                    handler: (closeModal) => {
                      closeModal()
                    }
                  }
                ]
              }
            )
          }
        })
        .catch((err) => {
          /* eslint-disable no-console */
          console.error(err)
          /* eslint-enable no-console */
        })
        .finally(() => {
          isBusy.value = false
          showPicker.value = false
        })
    }

    const uploadedImage = (img) => {
      disablePosting.value = false
      images.value.push(img)
      image.value = img.id
      showPicker.value = false
      nextTick(() => {
        showPicker.value = true
      })
    }

    const removedImage = () => {
      try {
        if (!postBody.value) {
          disablePosting.value = true
        }
        images.value = null
        image.value = 0
        croppedPhotoSrc.value = ''
        isUploadingImage.value = false
      } catch (e) {
        /* eslint-disable no-console */
        console.error(e)
        /* eslint-enable no-console */
      }
    }

    const newItemPosted = (item) => {
      // console.log('WallPostForm newPostCreated', item);
      context.emit('new-item-posted', item)
    }

    const launchFilePicker = (type) => {
      nextTick().then(() => {
        selectedFileType.value = type
        if (type === 'image') photoUploaderRef.value.browsePhoto()
        else if (type === 'video') videoUploaderRef.value.browsePhoto()
      })
    }

    provide('hideTutorial', true) // remove the tutorial for cropping

    const sendingDisabled = () => {
      return (
        isBusy.value ||
        attachments.value.length === 0 ||
        isUploadingAttachments.value
      )
    }

    const mediaPickerRef = ref(null)

    const addMoreItemsClicked = () => {
      if (attachments.value.length >= props.maximumImages) {
        dialog.show(
          `Only ${props.maximumImages} images can be uploaded at this time. The first 10 images selected will be used for this post.`,
          {
            title: 'Attachment Notice',
            buttons: [
              {
                label: 'Close',
                class: 'btn-light-gray',
                handler: (closeModal) => {
                  closeModal()
                }
              }
            ]
          }
        )
      } else {
        mediaPickerRef.value.browseMedia()
      }
    }

    return {
      postBody,
      sendPost,
      isBusy,
      croppedPhotoSrc,
      isUploadingImage,
      cropperOptions,
      image,
      errorMessage,
      uploadedImage,
      images,
      createWallPostModalRef,
      userId,
      baseLayoutRef,
      newItemPosted,
      disablePosting,
      photoUploaderRef,
      launchFilePicker,
      showPicker,
      videoUploaderRef,
      selectedFileType,
      removedImage,
      attachments,
      addAttachments,
      isUploadingAttachments,
      VIDEO_FILE_TYPES,
      PHOTO_FILE_TYPES,
      showInvalidFileTypePrompt,
      allowCaptions,
      sendingDisabled,
      mediaPickerRef,
      isMobileDevice,
      addMoreItemsClicked
    }
  }
}
</script>

<style lang="scss" scoped>

.toolbar {
  display: none;
}

.toolbar > button > span {
  &,
  [class^='icon-'],
  [class*=' icon-'] {
    display: flex;
    font-size: 1.25rem;
  }
}

.add-image {
  width: 100px;
  height: 100px;
}

.photo-field {
  :deep(.cropper-wrap) {
    max-width: 500px !important;
  }

  :deep(.modal-dialog) {
    max-width: calc(550px + 3rem);
  }

  :deep(.cropping-image-container) {
    padding-bottom: 38.81%;
  }

  :deep(.img-upload-btn) {
    // height: 240px !important;
    background-color: #E7E7E7;
    transition: background-color 0.25s ease;

    &::before {
      background-color: #AAA;
    }

    .icon-outline-plus {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translateX(-50%) translateY(-50%);
      display: block;
      color: #fff;
      font-size: 2rem;
      z-index: 1;
    }

    &:active,
    &:focus,
    &:hover {
      background-color: #AAA;
    }
  }

  :deep(.img-upload-btn) > img {
    width: 100% !important;
    height: 100% !important;
  }

  &:not(.has-image) {
    :deep(.img-upload-btn) > img {
      display: none;
    }
  }
}

.attachments {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;

  > div {
    margin: 0.75rem 0;
    width: 100px !important;
    height: 100px !important;
  }

  > div :deep(img) {
    background-color: #E7E7E7;
  }
}

// :deep(.photo-field) {
//   width: 100px !important;
//   height: 100px !important;
// }

// :deep(.photo-field.hide-picker) {
//   .photo-picker {
//     width: 0 !important;
//     height: 0 !important;
//   }

//   .photo-picker .img-upload-btn {
//     display: none;
//   }
// }
</style>
