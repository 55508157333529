<template>
  <event-category-interest-form
    class="form-1"
    :type="gatheringType"
    :event-id="eventForm.id"
    :data="{
      interests: eventForm.interests.map((interest) => interest.id),
      category: eventForm.category,
      type: eventForm.type,
    }"
    @success="eventCreate"
  >
    <template #title>
      <h1 class="fs-1 ff-merriweather fw-bold mb-5">
        Let’s get your {{ dynamicWordFor("event") }} created!
      </h1>
    </template>
    <template #submit="{ submit, isSubmitting }">
      <div class="row">
        <div class="col-6 col-sm-6 mb-3">
          <ce-button
            class="btn-lg btn btn-gray text-white text-bold w-100"
            @click="$emit('cancelEdit')"
          >
            Cancel
          </ce-button>
        </div>
        <div class="col-6 col-sm-6 mb-3">
          <ce-button
            class="btn-lg btn-gradient-gold text-bold w-100"
            :is-loading="isSubmitting"
            :disabled="isSubmitting"
            @click="submit"
          >
            {{ isPublished ? "Save" : "Continue" }}
          </ce-button>
        </div>
      </div>
    </template>
  </event-category-interest-form>
</template>

<script>
import { defineAsyncComponent, computed, ref } from "vue";
import { useStore } from "vuex";
import { useRoute, useRouter } from "vue-router";
import { GATHERING_TYPES, useDynamicWord } from "@/Use/events.js";

export default {
  components: {
    EventCategoryInterestForm: defineAsyncComponent(() =>
      import("@/Modules/Event/EventCategoryInterestForm.vue")
    ),
  },
  props: {
    gatheringType: {
      type: Number,
      default: GATHERING_TYPES.EVENT,
    },
  },
  emits: ["createEvent", "eventIsPublished", "isLoading", "cancelEdit"],
  async setup(props, { emit }) {
    const store = useStore();
    const route = useRoute();
    const router = useRouter();

    const eventId = ref(router.currentRoute._value.params.id);
    const isLoaded = ref(false);

    const { dynamicWordFor } = useDynamicWord({
      gatheringType: props.gatheringType,
    });

    emit("isLoading", true);
    const getEventInfoResource = await store.dispatch("eventEdit", {
      id: eventId.value,
      api: "category-interest",
    });
    emit("isLoading", false);

    const eventCreate = (response) => {
      emit("createEvent", response, {
        steps: "category-interest",
        isPublished: getEventInfoResource.data.isPublished,
      });
    };

    const eventForm = computed(() => getEventInfoResource.data.form);

    const isPublished = computed(() => getEventInfoResource.data.isPublished);

    emit("eventIsPublished", isPublished);

    return {
      isPublished,
      eventCreate,
      eventForm,
      isLoaded,
      dynamicWordFor,
      // backUrl,
    };
  },
};
</script>

<style lang="scss" scoped>
@import "../../scss/custom";

:deep() {
  @import "../../scss/form/form-1";
}

.container {
  max-width: 680px;
}
</style>
