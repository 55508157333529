<template>
  <div>
    <template v-if="isLoading">
      <div class="text-center py-5">
        <span class="animation-loading-spinner text-gray" />
      </div>
    </template>
    <template v-else>
      <template v-if="posts.length > 0">
        <ce-infinite-result
          v-model:items="posts"
          :has-more-pages="hasMorePages"
          :api-callback="apiCallback"
          item-grid-class=""
        >
          <template #default="{ item: post }">
            {{ entity }}
            <wall-post-card
              class="mb-5"
              :post="post"
              :can-comment="canComment"
              :can-view-comment="canViewComment"
              :entity="entity"
            />
          </template>
        </ce-infinite-result>
      </template>
      <template v-else>
        <div
          class="alert alert-yellow text-center"
          role="alert"
        >
          Nothing has been posted yet.
        </div>
      </template>
    </template>
  </div>
</template>

<script>
import { defineAsyncComponent, ref, provide, computed } from 'vue'
import CeInfiniteResult from '@/components/Utilities/CeInfiniteResult.vue'

export default {
  components: {
    CeInfiniteResult,
    WallPostCard: defineAsyncComponent(() =>
      import('@/Modules/Wall/WallPostCard.vue')
    )
  },
  props: {
    fetchFeedCallback: {
      type: Function,
      required: true
    },
    websocketConfig: {
      type: Object,
      default: null,
      required: false
    },
    entity: {
      type: Object,
      required: true
    },
    canReact: {
      type: Boolean,
      default: false
    },
    type: {
      type: String,
      default: 'wall'
    },
    canComment: {
      type: Boolean,
      default: false
    },
    canViewComment: {
      type: Boolean,
      default: true
    }
  },
  setup (props) {
    const posts = ref([])
    const canReact = computed(() => props.canReact)
    const isLoading = ref(false)
    const currentPage = ref(1)
    const hasMorePages = ref(false)

    const fetchFeed = async () => {
      try {
        isLoading.value = true
        const { data, links } = await props.fetchFeedCallback(
          currentPage.value
        )

        hasMorePages.value = links.next !== null
        posts.value = posts.value.concat(Object.values(data))
      } catch (err) {
        /* eslint-disable no-console */
        console.error(err)
        /* eslint-enable no-console */
      } finally {
        isLoading.value = false
      }
    }

    const apiCallback = async (page) => {
      return {
        data: await props.fetchFeedCallback(page)
      }
    }

    /* Lifecycles */
    provide('entity', props.entity)
    provide('canReact', canReact)
    provide('wallType', props.type)
    if (props.websocketConfig) {
      window.Echo.private(props.websocketConfig.channel).listen(
        props.websocketConfig.event,
        ({ discussion }) => {
          posts.value.unshift(discussion)
        }
      )
    }
    fetchFeed()

    return {
      posts,
      fetchFeed,
      isLoading,
      currentPage,
      hasMorePages,
      apiCallback
    }
  }
}
</script>
