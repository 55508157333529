export default () => {
  const min = 55 // inches
  const max = 89 // inches

  // value in inches
  const toString = (value) => {
    const convertedToFeet = value / 12

    const feet = Math.trunc(convertedToFeet)
    const inches = Math.round((convertedToFeet % 1) * 12)

    return `${feet}' ${inches}"`
  }

  return {
    min,
    max,
    toString
  }
}
