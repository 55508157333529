<template>
  <div class="d-flex">
    <div
      v-if="items.length > 0"
      class="attachments-grid h-100 w-100 pb-3 px-3"
    >
      <template
        v-for="(item, i) in displayItems"
        :key="i"
      >
        <div
          class="bg-light-gray placeholder-glow position-relative"
          :class="{
            'w-100 h-100': item.media?.media_type_id === 0,
            'attached-video': item.media?.media_type_id === 1,
            'vertical-span': i === 0 && items.length >= 3,
          }"
          @click="contentIsReady ? openAttachment(i) : null"
        >
          <template v-if="item.media?.media_type_id === 0">
            <ce-image
              :src="item.media.sizes?.md"
              :blur-hash="item.media.blurhash"
              class="attached-photo"
              :class="{ placeholder: !contentIsReady }"
              @load="contentIsReady = true"
            />
          </template>
          <template v-else-if="item.media?.media_type_id === 1">
            <ce-video-player
              :src="item.media.original"
              class="w-100 h-100"
              :class="{ placeholder: !contentIsReady }"
              :autoplay="autoplay"
              readonly
              @ready="contentIsReady = true"
            />
            <template v-if="contentIsReady">
              <span
                class="icon-play fs-1 text-white position-absolute top-50 start-50 translate-middle"
              />
            </template>
          </template>
          <template v-if="i === 2 && items.length > 3">
            <span
              class="background-shaded fs-1 text-white position-absolute top-50 start-50 translate-middle d-flex align-items-center pe-none"
            >
              <span class="icon-add me-2" /> {{ items.length - 3 }}
            </span>
          </template>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import { ref, computed } from 'vue'
import { Fancybox } from '@fancyapps/ui'
// import { Inertia } from '@inertiajs/inertia';

export default {
  name: 'WallPostAttachments',
  props: {
    items: {
      type: Array,
      default: () => {
        return []
      }
    },
    css: {
      type: String,
      default: () => 'lb-h-250 lb-h-md-400 lb-h-lg-600'
    },
    cells: {
      type: Number,
      default: () => 5
    }
  },

  setup (props) {
    const currentMediaSource = ref('')
    const currentMediaIndex = ref(-1)
    const loading = ref(false)
    const contentIsReady = ref(false)
    const displayItems = computed(() => props.items.slice(0, 3))

    /**
     * Returns the item's url depending on if it has a redirectUrl or not
     * @param {number} index
     * @returns {string}
     */
    function itemUrl (index) {
      return props.items[index].redirectUrl
        ? props.items[index].redirectUrl
        : props.items[index].src
    }

    /**
     * Opens the fancy box to display media like images or videos
     * @param {number} index - Index of the attachment being opened
     */
    function openFancyBox (index) {
      Fancybox.show(
        props.items.map((item) => ({
          src:
            item.media.media_type_id === 1
              ? item.media.original
              : item.media.sizes?.lg
        })),
        {
          startIndex: index,
          hideScrollbar: false
        }
      )
    }

    /**
     * Opens the attachment using multiple ways based on the post type
     * @param {number} index - Index of the attachment being opened
     */
    async function openAttachment (index) {
      // const attachment = props.items[index];

      // if (['wall', 'event_wall'].includes(attachment.postType)) {
      //   openFancyBox(index);
      // } else {
      //   await Inertia.visit(attachment.redirectUrl);
      // }
      openFancyBox(index)
    }

    function bg (i) {
      return i && i.length > 0 ? `background-image: url('${i}')` : ''
    }

    const autoplay = /iPad|iPhone|iPod/.test(navigator.userAgent)

    return {
      bg,
      currentMediaSource,
      currentMediaIndex,
      loading,
      itemUrl,
      openFancyBox,
      openAttachment,
      contentIsReady,
      autoplay,
      displayItems
    }
  }
}
</script>

<style lang="scss" scoped>

/* purgecss start ignore */
@import '@fancyapps/ui/dist/fancybox.css';

/* todo: convert this into SCSS */

.attachments-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 4px;

  > div {
    max-height: 300px;
  }

  > div.vertical-span {
    grid-row: span 2;
    max-height: 600px;
  }
}

.attached-photo,
.attached-video {
  cursor: pointer;
}

.attached-photo {
  width: 100%;
  height: 100%;
  object-fit: contain;

  &.placeholder {
    height: 300px;
  }
}

.attached-video {
  width: 100%;
  height: 400px;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius:5px;
  overflow: hidden;

  :deep(.video-js) {
    width: 100%;
    height: 100%;
  }
}

.background-shaded {
  background: rgba(#000, 0.5);
  padding: 0 5px;
  height: 45px;
  border-radius: 5px;
}

/* purgecss end ignore */
</style>
