export const useTitle = (module) => {
  const words = {
    user: {
        'update-profile': 'Edit User Info',
        message: 'Message a User',
        flag: 'Flag a User',
        suspend: 'Suspend a User',
        unsuspend: 'Unsuspend a User',
        deactivate: 'Deactivate a User',
        reactivate: 'Reactivate a User',
        delete: 'Delete a User',
    },
    group: {
        edit: 'Edit a Group',
        published: 'Publish a Group',
        unpublished: 'Publish a Group',
        flagged: 'Flag a Group',
        unsuspend: 'Unsuspend a Group',
        suspend: 'Suspend a Group',
        deactivated: 'Deactivate a Group',
        reactivated: 'Reactivate a Group',
        deleted: 'Delete an Group',
        'remove-flag': 'Remove Flag Group',
    },
    event: {
        edit: 'Edit an Event',
        publish: 'Publish an Event',
        unpublish: 'Publish an Event',
        flag: 'Flag an Event',
        unsuspend: 'Unsuspend an Event',
        suspend: 'Suspend an Event',
        deactivate: 'Deactivate an Event',
        reactivate: 'Reactivate an Event',
        delete: 'Delete an Event',
        'remove-flag': 'Remove Flag Event',
    },
    huddle: {
        edit: 'Edit a Huddle',
        publish: 'Publish a Huddle',
        unpublish: 'Publish a Huddle',
        flag: 'Flag a Huddle',
        unsuspend: 'Unsuspend a Huddle',
        suspend: 'Suspend a Huddle',
        deactivate: 'Deactivate a Huddle',
        reactivate: 'Reactivate a Huddle',
        delete: 'Delete a Huddle',
        'remove-flag': 'Remove Flag Huddle',
    },
  }

  const dynamicTitle = (title) => {
    const capitalized = []
    module.module?.split(' ').forEach(word => {
    capitalized.push(
            word.charAt(0).toUpperCase() +
            word.slice(1).toLowerCase()
        )
    })

    const capTitle = capitalized.join(' ')

    return words[module.module]?.[title] ?? `${capTitle}s`
  }

  return {
    dynamicTitle,
  }
}

export const useTextModification = () => {
    const capitalize = (text) => {
        const capitalized = []
        text?.split(' ').forEach(word => {
            capitalized.push(
                word.charAt(0).toUpperCase() +
                word.slice(1).toLowerCase()
            )
        });
        return capitalized.join(' ')
    }

    return { capitalize }
}
