<template>
  <div
    class="input-group ce-inputs-text"
    :class="[sizeClass]"
  >
    <template v-if="$slots.prepend">
      <slot name="prepend" />
    </template>
    <template v-else-if="prepend">
      <span class="input-group-text">{{ prepend }}</span>
    </template>
    <input
      ref="inputRef"
      v-mask="computedMask"
      :value="localValue"
      :type="computedType"
      :max="max"
      :min="min"
      :placeholder="placeholder"
      class="form-control"
      :class="inputClass"
      :disabled="disabled"
      :maxlength="maxlength"
      @input="updateLocalValue($event)"
      @blur="handleBlur()"
    >
    <template v-if="localIsLoading !== null">
      <span
        class="input-group-text loading-indicator"
        :class="{ 'is-loading': localIsLoading }"
      />
    </template>
    <template v-if="$slots.append">
      <slot name="append" />
    </template>
    <template v-else-if="append">
      <span class="input-group-text">{{ append }}</span>
    </template>
  </div>
</template>
<script>
/* global google */
import { computed, watch, ref, nextTick, inject, onMounted } from 'vue'
import autocomplete from 'autocompleter'
import flatpickr from 'flatpickr'

const locationObjectToString = (locationObject) => {
  return `${[
    locationObject.venue || '',
    locationObject.city || '',
    locationObject.state || ''
  ]
    .filter(Boolean)
    .join(', ')} ${locationObject.zipCode || ''}`.trim()
}

export default {
  props: {
    modelValue: {
      type: [String, Object],
      default: ''
    },
    type: {
      type: String,
      default: 'text'
    },
    placeholder: {
      type: String,
      default: ''
    },
    disabled: {
      type: Boolean,
      default: false
    },
    inputClass: {
      type: [String, Array],
      default: ''
    },
    suggestions: {
      type: Array,
      default: () => []
    },
    suggestionValue: {
      type: String,
      default: null
    },
    suggestionLabel: {
      type: String,
      default: null
    },
    prepend: {
      type: String,
      default: null
    },
    append: {
      type: String,
      default: null
    },
    size: {
      type: String,
      default: null
    },
    isLoading: {
      type: Boolean,
      default: (props) => (props.type === 'location' ? false : null)
    },
    locationType: {
      type: String,
      default: 'city' // city | venue
    },
    autocompleteClassName: {
      type: String,
      required: false,
      default: ''
    },
    maxDate: {
      type: Date,
      default: null
    },
    minDate: {
      type: Date,
      default: null
    },
    mask: {
      type: [String, Object],
      default: null,
      required: false
    },
    maxlength: {
      type: Number,
      default: null
    }
  },
  emits: ['update:modelValue', 'update:isLoading', 'selected', 'blur'],
  setup (props, { emit }) {
    const moment = inject('moment')
    const localValue = ref(null)
    const inputRef = ref(null)
    const inputPluginInstance = ref(null)
    const isGoogleMapLoaded = ref(null)
    const localIsLoading = ref(props.isLoading)
    const hasModifiedLocation = ref(false)

    const computedType = computed(() => {
      switch (props.type) {
        case 'numeric':
          return 'tel'

        case 'birthdate':
          return 'date'

        case 'calendar':
          return 'date'

        default:
          return props.type
      }
    })

    const sizeClass = computed(() => {
      switch (props.size) {
        case 'sm':
          return 'input-group-sm'

        case 'lg':
          return 'input-group-lg'

        default:
          return ''
      }
    })

    // maximum date
    const max = computed(() => {
      switch (props.type) {
        case 'birthdate':
          return inject('moment')().subtract(18, 'years').format('YYYY-MM-DD')

        default:
          return props.maxDate
            ? inject('moment')(props.maxDate).format('YYYY-MM-DD')
            : null
      }
    })

    // minimum date
    const min = computed(() => {
      switch (props.type) {
        case 'birthdate':
          return inject('moment')().subtract(115, 'years').format('YYYY-MM-DD')

        default:
          return props.minDate
            ? inject('moment')(props.minDate).format('YYYY-MM-DD')
            : null
      }
    })

    const computedMask = computed(() => {
      switch (props.type) {
        case 'numeric':
          return '0000000'

        case 'telephone':
          return '000-000-0000'

        default:
          return props.mask || null
      }
    })

    /* Methods */
    const updateLocalValue = async (e) => {
      await nextTick()
      localValue.value = e.target.value
      if (props.type === 'location') {
        hasModifiedLocation.value = true
      }
      return true
    }

    const loadGoogleMapsApi = async () => {
      isGoogleMapLoaded.value = false
      const scriptId = 'google-maps-api'
      if (document.querySelector(`#${scriptId}`)) {
        return true
      }
      const script = document.createElement('script')
      script.id = scriptId
      script.src = `https://maps.googleapis.com/maps/api/js?key=${process.env.VUE_APP_GOOGLE_API_KEY}&libraries=places`
      document.head.appendChild(script)

      await new Promise((resolve) => {
        script.onload = () => {
          resolve()
        }
      })
      isGoogleMapLoaded.value = true
      return true
    }

    const handleBlur = () => {
      if (hasModifiedLocation.value === true) {
        localValue.value = locationObjectToString(props.modelValue)
      }

      if (props.type === 'birthdate') {
        emit('blur', max, min)
      } else {
        emit('blur')
      }
    }

    /* Watchers */
    watch(localValue, (newLocalValue) => {
      if (props.type === 'location' && newLocalValue !== '') {
        return false
      }
      emit('update:modelValue', newLocalValue)
      return true
    })

    watch(
      () => props.modelValue,
      (newModelValue) => {
        localValue.value =
          props.type === 'location'
            ? locationObjectToString(newModelValue)
            : newModelValue

        if (props.type === 'location') {
          localValue.value = locationObjectToString(newModelValue)
        } else if (props.suggestions.length > 0) {
          const selectedSuggestion = props.suggestions.find((suggestion) => {
            if (props.suggestionValue) {
              return suggestion[props.suggestionValue] === newModelValue
            }
            return suggestion === newModelValue
          })
          localValue.value =
            selectedSuggestion?.[props.suggestionLabel] || newModelValue
        } else {
          localValue.value = newModelValue
        }
      },
      {
        immediate: true
      }
    )

    watch(localIsLoading, (newLocalIsLoading) => {
      emit('update:isLoading', newLocalIsLoading)
    })

    watch(
      () => props.isLoading,
      (newIsLoading) => {
        localIsLoading.value = newIsLoading
      },
      {
        immediate: true
      }
    )

    watch(
      () => props.minDate,
      (newMinDate) => {
        if (
          !props.disabled &&
          (props.type === 'date' ||
            props.type === 'calendar' ||
            props.type === 'time')
        ) {
          inputPluginInstance.value.set('minDate', newMinDate || undefined)
        }
      }
    )

    watch(
      () => props.maxDate,
      (newMaxDate) => {
        if (
          !props.disabled &&
          (props.type === 'date' ||
            props.type === 'calendar' ||
            props.type === 'time')
        ) {
          inputPluginInstance.value.set('maxDate', newMaxDate || undefined)
        }
      }
    )

    /* Lifecycles */
    onMounted(() => {
      if (props.type === 'location') {
        loadGoogleMapsApi()
      }

      if (props.type === 'location' || props.suggestions.length > 0) {
        autocomplete({
          input: inputRef.value,
          className: `ce-inputs-text-autocomplete ${props.autocompleteClassName}`,
          preventSubmit: props.type === 'location',
          debounceWaitMs: props.type === 'location' ? 250 : 0,
          async fetch (text, update) {
            const lowerText = text.toLowerCase()
            let suggestions = []

            if (props.type === 'location') {
              const service = new google.maps.places.AutocompleteService()
              localIsLoading.value = true
              const googleMapSuggestions = await new Promise((resolve) => {
                const types = []
                if (props.locationType === 'city') {
                  types.push('(cities)')
                } else if (props.locationType === 'venue') {
                  types.push('geocode')
                  types.push('establishment')
                }

                service.getPlacePredictions(
                  {
                    input: lowerText,
                    types,
                    componentRestrictions: { country: 'us' }
                  },
                  (predictions) => {
                    resolve(predictions)
                  }
                )
              })
              localIsLoading.value = false
              suggestions = googleMapSuggestions || []
            } else {
              suggestions = props.suggestions.filter((suggestion) => {
                let suggestionToFilter = suggestion
                if (props.suggestionValue) {
                  suggestionToFilter = suggestion[props.suggestionLabel]
                }
                return suggestionToFilter.toLowerCase().startsWith(lowerText)
              })
            }

            update(suggestions)
          },
          render (suggestion) {
            const div = document.createElement('div')
            if (props.type === 'location') {
              div.innerHTML = suggestion.description
            } else {
              div.innerHTML = props.suggestionLabel
                ? `${suggestion[props.suggestionLabel]}`
                : suggestion[props.suggestionValue] || suggestion
            }

            return div
          },
          onSelect (suggestion) {
            if (props.type === 'location') {
              const geocoder = new google.maps.Geocoder()
              geocoder.geocode(
                {
                  placeId: suggestion.place_id
                },
                (results, status) => {
                  if (status === 'OK') {
                    if (results[0]) {
                      const venue =
                        props.locationType === 'venue'
                          ? suggestion.structured_formatting.main_text
                          : null
                      let city = ''
                      let state = ''
                      let zipCode = ''
                      const geometryLocation = results[0].geometry.location
                      const lat = geometryLocation.lat()
                      const lng = geometryLocation.lng()

                      results[0].address_components.forEach(
                        (addressComponent) => {
                          if (addressComponent.types.includes('locality')) {
                            city = addressComponent.short_name
                          }
                          if (
                            addressComponent.types.includes(
                              'administrative_area_level_1'
                            )
                          ) {
                            state = addressComponent.short_name
                          }
                          if (addressComponent.types.includes('postal_code')) {
                            zipCode = addressComponent.short_name
                          }
                        }
                      )

                      const locationObject = {
                        venue,
                        city,
                        state,
                        zipCode,
                        lat,
                        lng
                      }

                      emit('update:modelValue', locationObject)
                      localValue.value = locationObjectToString(locationObject)
                      hasModifiedLocation.value = false
                      inputRef.value.blur()
                    }
                  }
                }
              )
            } else {
              emit(
                'update:modelValue',
                suggestion[props.suggestionValue] || suggestion
              )
              localValue.value =
                suggestion[props.suggestionLabel] ||
                suggestion[props.suggestionValue] ||
                suggestion
            }

            emit('selected')
          }
        })
      }

      if (props.type === 'date' || props.type === 'calendar') {
        inputPluginInstance.value = flatpickr(inputRef.value, {
          enableTime: false,
          altInput: true,
          altFormat: 'F d, Y',
          dateFormat: 'Y-m-d',
          minDate:
            props.disabled || !min.value
              ? undefined
              : moment(min.value, 'YYYY-MM-DD').toDate(),
          maxDate:
            props.disabled || !max.value
              ? undefined
              : moment(max.value, 'YYYY-MM-DD').toDate(),
          inline: props.type === 'calendar'
        })
      }

      if (props.type === 'time') {
        inputPluginInstance.value = flatpickr(inputRef.value, {
          enableTime: true,
          noCalendar: true,
          altInput: true,
          altFormat: 'G:i K',
          dateFormat: 'H:i:S'
        })
      }
    })

    return {
      moment,
      localValue,
      inputRef,
      computedType,
      sizeClass,
      computedMask,
      max,
      min,
      updateLocalValue,
      loadGoogleMapsApi,
      isGoogleMapLoaded,
      handleBlur,
      localIsLoading
    }
  }
}
</script>

<style lang="scss">
/* purgecss start ignore */
.flatpickr-calendar.inline {
  &,
  .flatpickr-rContainer,
  .flatpickr-days,
  .dayContainer {
    width: 100%;
  }

  .dayContainer {
    max-width: 100%;
  }

  .dayContainer .flatpickr-day {
    max-width: unset;
  }
}

/* purgecss end ignore */
</style>

<style lang="scss" scoped>
/* purgecss start ignore */
// @import '@flatpickr/dist/flatpickr.min.css';

/* purgecss end ignore */
</style>
