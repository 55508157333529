<template>
  <div class="ce-utilities-infinite-result">
    <slot
      name="wrapper"
      :local-items="localItems"
    >
      <div :class="itemGridClass">
        <template
          v-for="(item, index) in localItems"
          :key="item.id"
        >
          <slot
            :item="item"
            :index="index"
          />
        </template>
      </div>
    </slot>

    <template v-if="nextPage && !hideLoadMore">
      <div>
        <slot
          name="loader"
          :load-more="loadMore"
          :is-loading="isLoading"
        >
          <div class="text-center mt-5">
            <ce-button
              ref="loadMoreButtonRef"
              type="button"
              class="btn-sm btn-transparent text-gray"
              :disabled="isLoading"
              :is-loading="isLoading"
              @click="loadMore()"
            >
              Load More
            </ce-button>
          </div>
        </slot>
      </div>
    </template>
  </div>
</template>

<script>
import { ref, onMounted, onBeforeUnmount, watch } from 'vue'
import debounce from 'lodash/debounce'

export default {
  props: {
    items: {
      type: Array,
      default: () => []
    },
    hasMorePages: {
      type: Boolean,
      default: false
    },
    hideLoadMore: {
      type: Boolean,
      default: false
    },
    /*
     * Provides the next page <integer> as first argument
     * Return value must be in Laravel pagination response format
     */
    apiCallback: {
      type: Function,
      default: () => {}
    },
    itemGridClass: {
      type: [String, Array],
      default: 'items-grid'
    },
    scrollContainer: {
      type: [HTMLElement, Window],
      default: window
    }
  },
  emits: ['update:items'],
  setup (props, { emit }) {
    const nextPage = ref(null)
    const localItems = ref(null)
    const isLoading = ref(false)
    const loadMoreButtonRef = ref(null)

    /* Methods */
    const init = () => {
      if (nextPage.value === null) {
        nextPage.value = props.hasMorePages ? 2 : false
      }
      localItems.value = props.items
    }

    const loadMore = async () => {
      if (nextPage.value === false || isLoading.value === true) {
        return false
      }

      try {
        isLoading.value = true
        const {
          data: { data: items, links }
        } = await props.apiCallback(nextPage.value)

        if (links.next) {
          nextPage.value += 1
        } else {
          nextPage.value = false
        }

        localItems.value = [...localItems.value, ...items]
      } finally {
        isLoading.value = false
      }

      return true
    }

    const scrollHandler = debounce(() => {
      if (!loadMoreButtonRef.value) {
        return false
      }

      const observer = new window.IntersectionObserver(
        ([entry]) => {
          if (entry.isIntersecting) {
            loadMore()
          }
        },
        {
          root: null,
          threshold: 0.1
        }
      )

      observer.observe(loadMoreButtonRef.value.$el)

      return true
    }, 250)

    const resetPagination = () => {
      nextPage.value = null
    }

    /* Lifecycles */
    init()

    onMounted(() => {
      props.scrollContainer?.addEventListener('scroll', scrollHandler)
    })

    onBeforeUnmount(() => {
      props.scrollContainer?.removeEventListener('scroll', scrollHandler)
    })

    /* Watchers */
    watch(
      () => props.items,
      () => {
        init()
      }
    )

    watch(
      localItems,
      (newItems) => {
        emit('update:items', newItems)
      },
      {
        deep: true
      }
    )

    return {
      localItems,
      loadMore,
      nextPage,
      isLoading,
      loadMoreButtonRef,
      resetPagination
    }
  }
}
</script>
