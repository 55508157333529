<template>
  <div class="container">
    <div
      class="d-flex align-items-center"
      :class="{ 'opacity-50': hasSentInvite }"
    >
      <div>
        <ce-avatar
          :src="
            friend.primary_photo_modified ||
              friend.primary_photo?.sizes?.thumbnail
          "
          :alt="''"
          class="bg-light-gray overflow-hidden rounded-1"
          :square="true"
        />
      </div>
      <div class="flex-grow-1 text-start ms-md-3 ms-2">
        {{ friend.first_name }} {{ friend.last_name }}
      </div>
      <div class="text-end">
        <ce-button
          class="btn-sm btn-transparent p-0 text-gold"
          :class="{ disabled: hasSentInvite }"
          @click="sendInvite"
        >
          Send Invite
        </ce-button>
      </div>
    </div>
  </div>
</template>
<script>
import { inject, ref } from 'vue'
import CeAvatar from '@/components/UI/CeAvatar.vue'
import CeButton from '@/components/Buttons/CeButton.vue'
// import { useFriendInvites } from '@/Use/groups.js';

export default {
  components: {
    CeButton,
    CeAvatar
  },
  props: {
    groupId: {
      type: Number,
      default: 0
    },
    friend: {
      type: Object,
      required: true
    },
    queryParams: {
      type: Object,
      default: () => {}
    }
  },
  setup (props) {
    // const { sendFriendInvite, fetchFriendLists } = useFriendInvites();
    const hasSentInvite = ref(false)
    const dialog = inject('dialog')

    const sendInvite = async () => {
      try {
        // await sendFriendInvite(props.groupId, [props.friend.id]);
        // fetchFriendLists(props.groupId, props.queryParams);
        hasSentInvite.value = true
      } catch (e) {
        dialog.show('We are unable to send the invite. Please try again.', {
          title: 'Invite Friend.',
          buttons: [
            {
              label: 'Close',
              class: 'btn-nero'
            }
          ]
        })
        hasSentInvite.value = false
      }
    }
    return {
      sendInvite,
      hasSentInvite
    }
  }
}
</script>
