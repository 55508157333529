import axios from 'axios'

const setupHeaderConfig = (config) => {
    // -------------------------------------------------------------
    // Note: This will be uncommented once I finish the auth state / store
    // -------------------------------------------------------------
    // const token
    const token = localStorage.getItem('token')
        // !config.headers ? config.headers = {} : null\
    if (config.headers === undefined) {
        config.headers.Authorization = 'Bearer ' + token
    } else {
        config.headers.Authorization = 'Bearer ' + token
    }
    return config
}


export default ({
    namespaced: true,
    state: () => ({
        newGroupInfo: null,
        groupList: [],
        groupStatistics: [{
            icon: 'users',
            labels: 'Groups',
            data: 0,
            link: 'manage-groups',
            heading: [],
            menu1: [{
                    label: 'Add a group',
                    link: '/group/add/'
                },
                {
                    label: 'Edit a group',
                    link: '/manage-groups?type=edit'
                },
                {
                    label: 'Publish a group',
                    link: '/manage-groups?type=publish'
                },
                {
                    label: 'Flag a group',
                    link: '/manage-groups?type=flag'
                },
                {
                    label: 'Unsuspend',
                    link: '/manage-groups?type=unsuspend'
                }
            ],
            menu2: [{
                    label: 'Suspend a group',
                    link: '/manage-groups?type=suspend'
                },
                {
                    label: 'Deactivate a group',
                    link: '/manage-groups?type=deactivate'
                },
                {
                    label: 'Reactivate a group',
                    link: '/manage-groups?type=reactivate'
                },
                {
                    label: 'Delete a group',
                    link: '/manage-groups?type=delete'
                },
                {
                    label: 'Remove Flag',
                    link: '/manage-groups?type=remove-flag'
                }
            ]
        }]
    }),
    mutations: {
        userStatistics(state) {
            return state.statistics
        },
        LOAD_GROUP_STATISTICS(state, payload) {
            state.groupStatistics[0].heading = payload.statistics
            state.groupStatistics[0].data = payload.all
        },
        LOAD_GROUP(state, payload) {
            state.groupList = payload
        },
        setNewGroupInfo: (state, newGroupInfo) => (state.newGroupInfo = newGroupInfo)
    },
    getters: {
        newGroupInfo: state => state.newGroupInfo
    },
    actions: {
        setGroupInfo({ commit }, newGroupInfo) {
            console.log('newGroupInfo', newGroupInfo)
            commit('setNewGroupInfo', newGroupInfo)
        },
        async fetchFeedCallback({ commit }, params, config = { headers: {} }) {
            // eslint-disable-next-line no-console
            /* eslint-disable */
            console.log(commit);
            config.params = params
            const newConfig = setupHeaderConfig(config)
            return await axios.get(params.url + '?perPage=' + params.perPage + '&&page=' + params.currentPage, newConfig).then((response) => {
                return response.data;
            })
        },
        async loadGroupMembers({ commit }, params, config = { headers: {} }) {
            // eslint-disable-next-line no-console
            /* eslint-disable */
            console.log(commit);
            config.params = params
            const newConfig = setupHeaderConfig(config)
            return await axios.get(`/groups/${params.groupId}/members`, newConfig).then((response) => {
                return response.data;
            })
        },
        clearNewGroupInfo({ commit }) {
            commit('setNewGroupInfo');
        },
        async groupEdit({ commit }, slugInfo, config = { headers: {} }) {
            // eslint-disable-next-line no-console
            /* eslint-disable */
            console.log(commit);
            const newConfig = setupHeaderConfig(config);
            return await axios.get(`admin-panel/group/${slugInfo.slug}/edit/${slugInfo.api}`, newConfig).then((response) => {
                console.log("groupEdit", response);
                return response.data;
            })
        },
        async invitePastEvents({ commit }, slugInfo, config = { headers: {} }) {
            // eslint-disable-next-line no-console
            /* eslint-disable */
            console.log(commit);
            const newConfig = setupHeaderConfig(config);
            return await axios.get(`admin-panel/group/${slugInfo.slug}/invite-past-events`, newConfig).then((response) => {
                console.log("invite-past-events", response);
                return response.data;
            })
        },
        async inviteFriends({ commit }, slugInfo, config = { headers: {} }) {
            // eslint-disable-next-line no-console
            /* eslint-disable */
            console.log(commit);
            const newConfig = setupHeaderConfig(config);
            return await axios.get(`admin-panel/group/${slugInfo.slug}/invite-friends`, newConfig).then((response) => {
                console.log("invite-past-events", response);
                return response.data;
            })
        },
        async loadGroupStatistic({ commit }, params, config = { headers: {} }) {
            // eslint-disable-next-line no-console
            /* eslint-disable */
            console.log(commit);
            const newConfig = setupHeaderConfig(config);
            return await axios.get(`admin-panel/group/statistics`, newConfig).then((response) => {
                commit("LOAD_GROUP_STATISTICS", response.data.data);
            })
        },
        async loadGroupList({ commit }, params, config = { headers: {} }) {
            config.params = params
            const newConfig = setupHeaderConfig(config)
            return await axios.get(`admin-panel/group`, newConfig).then((response) => {
                commit("LOAD_GROUP", response.data.data);
                return response.data;
            })
        },
        async loadGroupUserStatistic({ commit }, params, config = { headers: {} }) {
            // eslint-disable-next-line no-console
            /* eslint-disable */
            console.log(commit);
            const newConfig = setupHeaderConfig(config);
            return await axios.get(`admin-panel/group/${params.id}/statistic`, newConfig).then((response) => {
                return response.data.data;
            })
        },
        async loadUserGroupList({ commit }, params, config = { headers: {} }) {
            // eslint-disable-next-line no-console
            /* eslint-disable */
            console.log(commit);
            const newConfig = setupHeaderConfig(config);
            return await axios.get(`admin-panel/group/${params.id}?type=${params.type}`, newConfig).then((response) => {
                return response.data;
            })
        },
        async selectGroupToMessage({ commit }, params, config = { headers: {} }) {
            // eslint-disable-next-line no-console
            /* eslint-disable */
            console.log(commit);
            const newConfig = setupHeaderConfig(config);
            return await axios.get(`/groups?keyword=${params}&perPage=7`, newConfig).then((response) => {
                return response.data.data;
            })
        },
        async loadGroupBySlug({ commit }, params, config = { headers: {} }) {
            // eslint-disable-next-line no-console
            /* eslint-disable */
            console.log(commit);
            config.params = params
            const newConfig = setupHeaderConfig(config)
            return await axios.get('admin-panel/group/slug', newConfig).then((response) => {
                return response.data;
            })
        },
        async loadSearchGroupList({ commit }, params, config = { headers: {} }) {
            console.log(commit);
            const newConfig = setupHeaderConfig(config)
            return await axios.get(`admin-panel/group?page_type=${params.page_type}&page_filter=${params.page_filter}&type=${params.type}&${params.search}`, newConfig).then((response) => {
                commit("LOAD_GROUP", response.data.data);
                return response.data;
            })
        },
        async reactivateGroup({ commit }, params, config = { headers: {} }) {
            console.log(commit);
            const newConfig = setupHeaderConfig(config)
            return await axios.get(`admin-panel/group/reactivate/${params}`, newConfig).then((response) => {
                return response.data;
            })
        },
        async deleteGroup({ commit }, params, config = { headers: {} }) {
            console.log(commit);
            const newConfig = setupHeaderConfig(config)
            return await axios.delete(`admin-panel/group/${params}`, newConfig).then((response) => {
                return response.data;
            })
        },
        async fetchFeedCallback({ commit }, params, config = { headers: {} }) {
            // eslint-disable-next-line no-console
            /* eslint-disable */
            console.log(commit);
            config.params = params
            const newConfig = setupHeaderConfig(config)
            return await axios.get(params.url + '?perPage=' + params.perPage + '&&page=' + params.currentPage, newConfig).then((response) => {
                return response.data;
            })
        },
        async publishedGroup({ commit }, params, config = { headers: {} }) {
            console.log(commit);
            const newConfig = setupHeaderConfig(config)
            return await axios.patch(`admin-panel/group/${params}`, newConfig).then((response) => {
                return response.data;
            })
        },
    }
});