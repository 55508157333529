<template>
  <div
    class="ce-utilities-aspect-ratio"
    :style="{
      paddingBottom:
        aspectRatio !== null ? `calc(100% / ${aspectRatio})` : null,
    }"
  >
    <div
      class="ce-utilities-aspect-ratio-wrapper"
      :class="{
        'restricted-aspect-ratio': aspectRatio !== null,
      }"
    >
      <div>
        <slot />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    aspectRatio: {
      type: Number,
      default: null
    }
  }
}
</script>

<style lang="scss" scoped>
.ce-utilities-aspect-ratio {
  position: relative;
  width: 100%;

  > .ce-utilities-aspect-ratio-wrapper.restricted-aspect-ratio {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  > .ce-utilities-aspect-ratio-wrapper.restricted-aspect-ratio > div {
    width: 100%;
    height: 100%;
  }
}
</style>
