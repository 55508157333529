<template>
  <template v-if="type === 'group_wall'">
    <wall-post-form class="mb-4" :submit-url="postUrl" />
  </template>
  <wall-post-feed
    ref="feedContainerRef"
    :group-id="group?.id"
    :fetch-feed-callback="fetchFeedCallback"
    :websocket-config="websocketConfig"
    :entity="group"
    :can-react="true"
    :can-comment="true"
    :type="type"
    :can-view-comment="canViewComment"
  />
</template>
<script>
import { computed, ref } from 'vue';
import { useStore } from "vuex";
import WallPostForm from '../Wall/WallPostForm.vue';
import WallPostFeed from '../Wall/WallPostFeed.vue';
export default {
  name: 'GroupWall',
  components: {
    WallPostForm,
    WallPostFeed
  },
  props: {
    group: {
      type: Object,
      required: true,
    },
    type: {
      type: String,
      default: 'group_wall',
    },
    // Get the specific wall discussion
    dicussionId: {
      type: Number,
      default: 0,
    },
    canViewComment: {
      type: Boolean,
      default: true,
    },
  },
  setup(props) {
    const store = useStore();
    const feedContainerRef = ref(false);
    const group = computed(() => props.groupResource);
    // todo: add websocket
    const websocketConfig = ref(null);
    // Listen only on group_wall
    // When vewing from user wall, don't call the websocket if there's an group wall post shared
    if (props.type === 'group_wall') {
      console.log(group.value);
      // websocketConfig.value = {
      //   channel: `group-wall.${props.group.id}`,
      //   event: '.group-wallpost-created',
      // };
    }
    const fetchFeedCallback = async (currentPage) => {
      let url = `/group_wall/${props.group.id}/discussions`;
      // Get specific event discussion wall post
      if (props.type === 'group_wall_shared') {
        url += `/${props.dicussionId}`;
      }
      return await store.dispatch("group/fetchFeedCallback", {url: url, perPage: 10, page: currentPage,}).then((response) => {
        if (response) {
            return response;
        }
      });
    };
    const postUrl = ref(`api/group_wall/${props.group.id}/discussions`);
    return {
      feedContainerRef,
      postUrl,
      fetchFeedCallback,
      websocketConfig,
      group
    };
  },
};
</script>
