<template>
  <div>
    <div class="card card-success mb-4">
      <div class="card-body">
        <h1 class="m-0 ff-merriweather fs-1 text-nero fw-bold">
          Add a {{ dynamicWordFor("Events") }}
        </h1>
      </div>
    </div>
    <div class="card border-bottom-1">
      <event-name-location-form
        class="form-1"
        @success="eventNameLocationCreated"
        :type="gatheringType"
      >
        <template #title>
          <h1 class="fs-1 ff-merriweather fw-bold mb-5">
            Let’s get your {{ dynamicWordFor("event") }} created!
          </h1>
        </template>
        <template #submit="{ submit, isSubmitting }">
          <div class="row">
            <div class="col-6 col-sm-6 mb-3">
              <ce-button
                class="btn-lg btn btn-gray text-white text-bold w-100"
                @click="cancelCreate()"
              >
                Cancel
              </ce-button>
            </div>
            <div class="col-6 col-sm-6 mb-3">
              <ce-button
                class="btn-lg btn-gradient-gold text-bold w-100"
                :is-loading="isSubmitting"
                :disabled="isSubmitting"
                @click="submit"
              >
                {{ isPublished ? "Save" : "Continue" }}
              </ce-button>
            </div>
          </div>
        </template>
      </event-name-location-form>
    </div>
  </div>
</template>

<script>
import { defineAsyncComponent, computed, ref, onMounted, watch } from "vue";
import { useStore } from "vuex";
import { useRoute, useRouter } from "vue-router";
import Preloader from "../../Layouts/Common/Preloader.vue";

import {
  GATHERING_TYPES,
  liveChatEnabledTypes,
  useDynamicWord,
} from "@/Use/events.js";

export default {
  components: {
    EventForm: defineAsyncComponent(() =>
      import("@/components/Events/EventForm.vue")
    ),
    EventNameLocationForm: defineAsyncComponent(() =>
      import("@/Modules/Event/EventNameLocationForm.vue")
    ),
    Preloader,
  },
  props: {
    eventInfo: {
      type: Object,
      default: () => {},
    },
    gatheringType: {
      type: Number,
      default: GATHERING_TYPES.EVENT,
    },
  },
  emits: ["isLoading", "onGoHome"],
  setup(props, { emit }) {
    const isPublished = ref(false);

    const store = useStore();

    const route = useRoute();
    const router = useRouter();

    const { dynamicWordFor } = useDynamicWord({
      gatheringType: props.gatheringType,
    });

    const eventNameLocationCreated = async (response, page) => {
      router.replace({
        name: `${dynamicWordFor("event")}-edit-category-interest`,
        params: { id: response.data.id },
      });
    };

    const cancelCreate = () => {
      emit("onGoHome", true);
    };

    const liveChatEnabledOptions = [
      {
        label: "No",
        value: liveChatEnabledTypes.NO,
      },
      {
        label: "Yes",
        value: liveChatEnabledTypes.YES,
      },
    ];

    return {
      cancelCreate,
      eventNameLocationCreated,
      liveChatEnabledOptions,
      isPublished,
      dynamicWordFor,
      GATHERING_TYPES,
      // slug
    };
  },
};
</script>
<style lang="scss" scoped>
@import "../../scss/customizations";

:deep() {
  @import "../../scss/form/form-1";
}

.container {
  max-width: 680px;
}
.card-footer {
  background-color: #3a3b44;
  color: #fff;
  min-height: 83px;
}
.back-button {
  color: $color-nero;
  text-decoration: none;
  line-height: 1.2;
}

.form-column-wrap {
  max-width: 680px;
}
</style>
