<template>
  <div>
    <div class="card card-success">
      <div class="card-body">
        <header-stories-component
          :module-name="dynamicWordFor('event')"
          :search-for="dynamicWordFor('events')"
          :query-type="queryParams.queryType"
          :query-filter="queryParams.queryFilter"
          :gathering-type="gatheringType"
          :total="statsTotal"
          @done="filterAdvance($event)"
          @search-filter="searchFilter($event, 'header')"
          @search-filter-advance="searchFilter($event, 'advance')"
          @reset="reset"
        />
      </div>
    </div>
    <div class="card card-success">
      <div class="card-body color-gray">
        <div class="row">
          <div class="col-md-12 col-lg-8 col-xl-9">
            <div class="row">
              <statistics-card-component
                :card="statics"
                :module-name="dynamicWordFor('event')"
                :gathering-type="gatheringType"
                :type="`${dynamicWordFor('Event')}`"
                :query-type="queryParams.queryType"
                :query-filter="queryParams.queryFilter"
                @change-status="onFilterChange"
              />
            </div>
          </div>
          <div class="col-md-12 col-lg-4 col-xl-3 border-start">
            <div class="row downloadAndMessageAlign">
              <div class="col-sm-6">
                <csv-button-component
                  :csv-header="EventCSVHeader"
                  :type="`${dynamicWordFor('Event')}`"
                />
              </div>
              <div class="col-sm-6">
                <messenger
                  :message-to="`${dynamicWordFor('Event')}`"
                  :select-message-title="`Message ${dynamicWordFor('Event')}`"
                  :message-title="`Message ${dynamicWordFor('Event')}`"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <event-table
      :user-type="dataTableType"
      @done="isDone($event)"
      :gathering-type="gatheringType"
      :query-type="queryParams.queryType"
      :query-filter="queryParams.queryFilter"
      :search-type="queryParams.searchFilterType"
      :search-filter="queryParams.searchFilterData"
    />
    <div id="confirm" />
  </div>
</template>

<script>
import { onMounted, ref, reactive } from "vue";
import $ from "jquery";
import { userEnums } from "@/composable/enums.js";
import EventTable from "@/components/Tables/EventTable.vue";
import CsvButtonComponent from "@/components/CsvButtonComponent.vue";
import StatisticsCardComponent from "@/components/StatisticsCardComponent.vue";
import HeaderStoriesComponent from "@/components/HeaderStoriesComponent.vue";
import { useStore } from "vuex";
import moment from "moment";
import Preloader from "../../Layouts/Common/Preloader.vue";
import Messenger from "../Message/Messenger.vue";
import { useRouter, useRoute } from "vue-router";
import { GATHERING_TYPES, useDynamicWord } from "@/Use/events.js";

export default {
  name: "App",
  components: {
    EventTable,
    CsvButtonComponent,
    StatisticsCardComponent,
    HeaderStoriesComponent,
    Preloader,
    Messenger,
  },
  props: {
    gatheringType: {
      type: Number,
      default: GATHERING_TYPES.EVENT,
    },
  },
  emits: ["isLoading"],
  setup(props, { emit }) {
    const router = useRouter();
    const status = ref("active");
    const { EventCSVHeader } = userEnums();
    const store = useStore();
    const headerFilter = ref("");
    const filterAddress = ref("");
    const dataTableType = ref("");
    const statsTotal = ref(0);
    const statics = ref([]);

    const { dynamicWordFor } = useDynamicWord({
      gatheringType: props.gatheringType,
    });

    onMounted(() => {
      getStats();
    });

    const getStats = async () => {
      const responseStats = await store.dispatch("loadEventStatistic", {
        gatheringType: props.gatheringType,
      });
      statsTotal.value = responseStats["all"];
      const data = JSON.parse(JSON.stringify(responseStats["statistics"]));
      statics.value = [
        { label: "Active", value: data.active },
        { label: "Deactivated", value: data.deactivated },
        { label: "Unpublished", value: data.unpublished },
        { label: "Flagged", value: data.flagged },
        { label: "Suspended", value: data.suspended },
        { label: "Deleted", value: data.deleted },
      ];
    };

    const route = useRoute();
    const queryType = route.query.type;
    const queryFilter = route.query.filter;

    const queryParams = reactive({
      queryType: route.query.type,
      queryFilter: route.query.filter,
      searchFilterType: null,
      searchFilterData: null,
    });

    const nextMessage = () => {
      $("#selectMessageType").modal("hide");
      $("#message").modal("show");
    };

    const sendMessage = () => {};

    const isDone = (event) => {
      emit("isLoading", event);
    };

    const searchFilter = (event, searchType) => {
      queryParams.searchFilterType = searchType;
      queryParams.searchFilterData = event;
    };

    const filterAdvance = (data) => {
      filterAddress.value = data;
    };
    const reset = () => {
      headerFilter.value = "";
      queryParams.searchFilterData = null;
    };

    const onFilterChange = (filter) => {
      queryParams.queryFilter = filter;
      queryParams.searchFilterData = null;
    };

    return {
      status,
      EventCSVHeader,
      statics,
      nextMessage,
      sendMessage,
      dataTableType,
      isDone,
      searchFilter,
      filterAdvance,
      reset,
      headerFilter,
      filterAddress,
      dynamicWordFor,
      GATHERING_TYPES,
      queryType,
      queryFilter,
      onFilterChange,
      queryParams,
      statsTotal,
    };
  },
  created: function () {
    this.moment = moment;
  },
};
</script>

<style scoped>
.downloadAndMessageAlign {
  text-align: center;
  position: inherit;
  margin-top: 15px;
}
.dropdown-menu-lg {
  min-width: auto;
}
.table td,
.table th {
  border-top: 0 !important;
}
.table td {
  vertical-align: middle;
}
.custom-control-label {
  font-weight: 500 !important;
}
.send {
  color: rgb(4, 98, 193);
}
.selected-message-type {
  background-color: #333;
  color: #cacaca !important;
}
</style>
