<template>
  <form>
    <ce-textarea
      v-model.trim="postBody"
      placeholder="What are you interested in today?"
    />
    <form-text
      name="body"
      :errors="formErrors"
    />
    <span class="text-red">{{ errorMessage }}</span>
    <div
      ref="attachmentsRef"
      class="attachments mx-n1"
    />
    <div class="d-flex align-items-center justify-content-between py-2">
      <div class="toolbar">
        <visual-media-field
          ref="visualMediaFieldRef"
          v-model="attachmentMediaIds"
          v-model:is-uploading="isUploadingAttachments"
          :attachments-container="attachmentsRef"
          additional-description="Maximum 10 photos per post"
          maximum-images="10"
          multiple
          photo
          video
          :disabled="attachmentMediaIds.length === 10"
        />
      </div>
      <div class="flex flex-column align-self-start">
        <ce-button
          type="submit"
          class="btn btn-nero"
          :is-loading="isBusy"
          :disabled="isBusy || disablePosting || isUploadingAttachments"
          @click.prevent="sendPost()"
        >
          Post
        </ce-button>
      </div>
    </div>
    <teleport :to="baseLayoutRef">
      <create-wall-post-modal
        ref="createWallPostModalRef"
        :entity-id="userId"
        @newPostCreated="newPostCreated"
      />
    </teleport>
  </form>
</template>

<script>
import {
  ref,
  inject,
  watch,
  provide
} from 'vue'
import axios from 'axios'
import FormText from '@/components/Forms/FormText.vue'
import CeTextarea from '@/components/Inputs/CeTextarea.vue'
import VisualMediaField from '../../components/Inputs/VisualMediaField.vue'

export default {
  components: {
    // CreateWallPostModal: defineAsyncComponent(() =>
    //   import('@/components/Modal/CreateWallPostModal.vue')
    // ),
    CeTextarea,
    FormText,
    VisualMediaField
  },
  props: {
    submitUrl: {
      type: String,
      default: null
    }
  },
  emits: ['newPostCreated'],
  setup (props, context) {
    const userId = 1
    const dialog = inject('dialog')
    const isBusy = ref(false)
    const errorMessage = ref('')
    const visualMediaFieldRef = ref(null)

    const formErrors = ref([])
    const baseLayoutRef = inject('baseLayoutRef')
    const createWallPostModalRef = ref(null)
    const disablePosting = ref(true)
    const attachmentsRef = ref(null)
    const attachmentMediaIds = ref([])
    const isUploadingAttachments = ref(false)

    /* Methods */
    const hasEmail = (input) => {
      const re =
        /(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))/;// eslint-disable-line
      return re.test(input)
    }

    const hasPhone = (input) => {
      const re = /\+?[0-9][0-9()\-\s+]{4,20}[0-9]/;// eslint-disable-line
      return re.test(input)
    }

    const postBody = ref('')
    watch(postBody, (newPostBody) => {
      errorMessage.value = ''
      disablePosting.value =
        newPostBody.length === 0 && attachmentMediaIds.value.length === 0

      // check if it has an email
      if (hasEmail(postBody.value)) {
        errorMessage.value = 'You are not allowed to post an email address'
        disablePosting.value = true
        return
      }

      // check if it has a phone number
      if (hasPhone(postBody.value)) {
        errorMessage.value = 'You are not allowed to post a phone number'
        disablePosting.value = true
      }
    })

    const sendPost = () => {
      formErrors.value = []
      if (postBody.value.length === 0 && attachmentMediaIds.value.length === 0) { return }

      // check if it has an email
      if (hasEmail(postBody.value)) {
        errorMessage.value = 'You are not allowed to post an email address'
        return
      }

      // check if it has a phone number
      if (hasPhone(postBody.value)) {
        errorMessage.value = 'You are not allowed to post a phone number'
        return
      }

      isBusy.value = true
      const url = props.submitUrl
        ? props.submitUrl
        : `api/wall/${userId}/discussions`

      const data = {
        body: postBody.value,
        attachments: attachmentMediaIds.value
      }

      axios
        .post(url, data)
        .then((res) => {
          postBody.value = ''
          context.emit('newPostCreated', res.data.data)
          errorMessage.value = ''
          visualMediaFieldRef.value.removeAttachments()

          if (res.data.data.is_transcoding) {
            dialog.show(
              'Please wait for your post until we finish processing your video.',
              {
                title: 'Processing your video',
                buttons: [
                  {
                    label: 'Close',
                    class: 'btn-light-gray',
                    handler: (closeModal) => {
                      closeModal()
                    }
                  }
                ]
              }
            )
          }
        })
        .catch((error) => {
          formErrors.value = error.response.data.error
        })
        .finally(() => {
          isBusy.value = false
        })
    }

    const newPostCreated = (item) => {
      // console.log('WallPostForm newPostCreated', item);
      context.emit('newPostCreated', item)
    }

    provide('hideTutorial', true) // remove the tutorial for cropping

    watch(attachmentMediaIds, (newAttachmentMediaIds) => {
      if (newAttachmentMediaIds?.length > 0) {
        disablePosting.value = false
      }
    })

    return {
      postBody,
      sendPost,
      isBusy,
      errorMessage,
      createWallPostModalRef,
      userId,
      baseLayoutRef,
      newPostCreated,
      disablePosting,
      attachmentsRef,
      attachmentMediaIds,
      isUploadingAttachments,
      visualMediaFieldRef,
      formErrors
    }
  }
}
</script>

<style lang="scss" scoped>
.toolbar > button > span {
  &,
  [class^='icon-'],
  [class*=' icon-'] {
    display: flex;
    font-size: 1.25rem;
  }
}

.add-image {
  width: 100px;
  height: 100px;
}

.photo-field {
  :deep(.cropper-wrap) {
    max-width: 500px !important;
  }

  :deep(.modal-dialog) {
    max-width: calc(550px + 3rem);
  }

  :deep(.cropping-image-container) {
    padding-bottom: 38.81%;
  }

  :deep(.img-upload-btn) {
    // height: 240px !important;
    background-color: #E7E7E7;
    transition: background-color 0.25s ease;

    &::before {
      background-color: #AAA;
    }

    .icon-outline-plus {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translateX(-50%) translateY(-50%);
      display: block;
      color: #fff;
      font-size: 2rem;
      z-index: 1;
    }

    &:active,
    &:focus,
    &:hover {
      background-color: #AAA;
    }
  }

  :deep(.img-upload-btn) > img {
    width: 100% !important;
    height: 100% !important;
  }

  &:not(.has-image) {
    :deep(.img-upload-btn) > img {
      display: none;
    }
  }
}

.attachments {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;

  > :deep(div) {
    margin: 0.75rem 0;
    width: 100px !important;
    height: 100px !important;
  }

  > :deep(div img) {
    background-color: #E7E7E7;
  }
}

// :deep(.photo-field) {
//   width: 100px !important;
//   height: 100px !important;
// }

// :deep(.photo-field.hide-picker) {
//   .photo-picker {
//     width: 0 !important;
//     height: 0 !important;
//   }

//   .photo-picker .img-upload-btn {
//     display: none;
//   }
// }
</style>
