<template>
  <Preloader :is-loading="isLoading"></Preloader>
  <div class="card p-0 mt-5">
    <div class="table-responsive">
      <table class="table table-items">
        <thead>
          <tr>
            <th scope="col" class="col-md-2">
              <h1 class="fs-14 fw-bold color-gray">NAME</h1>
            </th>
            <th scope="col" class="col-md-4">
              <h1 class="fs-14 fw-bold color-gray">LABEL</h1>
            </th>
            <th scope="col" class="col-md-1">
              <h1 class="fs-14 fw-bold color-gray">GROUP</h1>
            </th>
            <th scope="col" class="col-md-2">
              <h1 class="fs-14 fw-bold color-gray">INFO</h1>
            </th>
            <th scope="col" class="col-md-2">
              <h1 class="fs-14 fw-bold color-gray">IS FEATURED</h1>
            </th>
            <th scope="col" class="col-md-1">
              <h1 class="fs-14 fw-bold color-gray text-center">SORT #</h1>
            </th>
            <th scope="col" class="col-md-1">
              <h1 class="fs-14 fw-bold color-gray text-center">STATUS</h1>
            </th>
            <th scope="col" class="col-md-1">
              <h1 class="fs-14 fw-bold color-gray text-center">ACTION</h1>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="row in abilitiesList" :key="row.in" class="fs-14">
            <td class="col-md-2">{{ row.name }}</td>
            <td class="col-md-4">{{ row.label }}</td>
            <td class="col-md-1">{{ row.group }}</td>
            <td class="col-md-2">{{ row.hint }}</td>
            <td class="col-md-2">{{ parseInt(row.is_important) === 1 ? 'YES' : 'NO' }}</td>
            <td class="dropdown text-center col-md-1">{{ row.sort_number }}</td>
            <td class="dropdown text-center col-md-1">{{ parseInt(row.status) === 1 ? 'ACTIVE' : 'INACTIVE' }}</td>
            <td class="dropdown text-center col-md-1">
              <ce-button type="button" class="btn btn-green btn-sm fw-normal" @click="changeAbilities(row)">Update</ce-button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>

</template>
<script>
import { onMounted, ref, watch } from "vue";
import { useStore } from "vuex";
import moment from "moment";
import Preloader from "../../Layouts/Common/Preloader.vue";
export default {
  name: "App",
  components: {
    Preloader,
  },
  props: {
    isLoad: {
      type: Boolean,
      required: true,
      default: false
    }
  },
  emits: ["done", "updateAbilities"],
  setup(props, { emit }) {
    const abilitiesList = ref([]);
    const store = useStore();
    const isLoading = ref(false);
    const currentPage = ref(1);
    const totalPage = ref(null);

    const onPageChange = (page) => {
      currentPage.value = page;
    };

    const loadAbilities = async () => {
      isLoading.value = true;
      let response = await store.dispatch("loadAbilitiesList", {
        type: event,
        perPage: 25,
        page: currentPage.value,
      });
      if (response) {
        abilitiesList.value = response.data;
        isLoading.value = false;
      }
    }
    
    const changeAbilities = (row) => {
      emit('updateAbilities', row);
    }
    onMounted( async () => {
      loadAbilities();
    })

    watch(
      () => currentPage.value,
      () => loadAbilities()
    );

    watch(
      () => props.isLoad,
      () => loadAbilities()
    );

    
    return {
      abilitiesList,
      isLoading,
      currentPage,
      onPageChange,
      totalPage,
      changeAbilities
    };
  },
  created: function () {
    this.moment = moment;
  },
};
</script>
<style scoped>
.color-gray {
  color: #727e8b;
}
.form-control:focus {
  box-shadow: none;
}
.btn:focus {
  box-shadow: none;
}
.color-gold {
  color: #b4914d;
  text-decoration: none;
  font-weight: 600;
}
.top-70 {
  top: 0% !important;
}
.start-58 {
  left: 58% !important;
}
.card {
  border: 0;
}
.widget-user-header {
  padding: 0;
  height: auto !important;
}
.widget-user .widget-user-header {
  padding: 0px;
}
.downloadAndMessageAlign {
  text-align: center;
  position: inherit;
  margin-top: 15px;
}
.dropdown-menu-lg {
  min-width: auto;
}
.link-name {
  color: rgb(85, 85, 194);
}
.link-name:hover {
  cursor: pointer;
}
.fa {
  color: black;
}
.fas:hover {
  cursor: pointer;
}
.fs-14 {
  font-size: 14px !important;
}

.table-responsive {
  margin-top: -35px;
}
.tbl-ll-date {
  color: #6c757d;
  position: relative;
  left: 25px;
  float: left;
  margin-top: -5px;
}
.tbl-img {
  min-width: 30px;
  max-width: 30px;
  min-height: 30px;
  max-height: 30px;
}
.profile-img {
  object-fit: contain;
  border-radius: 0.25em 0.25em 0em 0px;
  width: 100%;
}
.table-items th {
  padding-bottom: 2px;
  border-top: 2px solid transparent;
  border-bottom: 2px solid transparent;
}
.table-items tbody,
tr,
td {
  white-space: break-all !important;
}
.background-gold {
  background: gold;
}
.txt-color-gray {
  color: #6c757d;
}
.fa-circle-plus,
.fa-pen-to-square,
.fa-magnifying-glass-plus:hover {
  cursor: pointer;
}
</style>
