import OptionRepository from '@/Repositories/option.js'

export default {
  namespaced: true,
  state: () => ({
    zodiacSigns: null,
    incomeLevels: null,
    ethnicities: null,
    genders: null,
    smokingFrequencies: null,
    drinkingFrequencies: null,
    childrenStatuses: null,
    educationLevels: null,
    relationshipStatuses: null,
    bodyTypes: null,
    interests: null,
    eventRoles: null,
    timezones: null,
    categories: null,
    types: null,
    chatTypes: null
  }),
  mutations: {
    setZodiacSigns (state, zodiacSigns) {
      state.zodiacSigns = zodiacSigns
    },

    setIncomeLevels (state, incomeLevels) {
      state.incomeLevels = incomeLevels
    },

    setEthnicities (state, ethnicities) {
      state.ethnicities = ethnicities
    },

    setGenders (state, genders) {
      state.genders = genders
    },

    setSmokingFrequencies (state, smokingFrequencies) {
      state.smokingFrequencies = smokingFrequencies
    },

    setDrinkingFrequencies (state, drinkingFrequencies) {
      state.drinkingFrequencies = drinkingFrequencies
    },

    setChildrenStatuses (state, childrenStatuses) {
      state.childrenStatuses = childrenStatuses
    },

    setEducationLevels (state, educationLevels) {
      state.educationLevels = educationLevels
    },

    setRelationshipStatuses (state, relationshipStatuses) {
      state.relationshipStatuses = relationshipStatuses
    },

    setBodyTypes (state, bodyTypes) {
      state.bodyTypes = bodyTypes
    },

    setInterests (state, interests) {
      state.interests = interests
    },

    setEventRoles (state, eventRoles) {
      state.eventRoles = eventRoles
    },

    setTimezones (state, timezones) {
      state.timezones = timezones
    },

    setCategories (state, categories) {
      state.categories = categories
    },

    setTypes (state, types) {
      state.types = types
    },

    setChatTypes (state, chatTypes) {
      state.chatTypes = chatTypes
    }
  },
  getters: {
    /* Zodiac Sign */
    zodiacSigns (state) {
      return state.zodiacSigns || []
    },

    zodiacSignTranslation: (state, getters) => (value) => {
      return (
        getters.zodiacSigns.find((zodiacSign) => zodiacSign.id === value)
          ?.value || ''
      )
    },

    hasBeenLoadedZodiacSigns (state) {
      return state.zodiacSigns !== null
    },

    /* Income Level */
    incomeLevels (state) {
      return state.incomeLevels || []
    },

    incomeLevelTranslation: (state, getters) => (value) => {
      return (
        getters.incomeLevels.find((incomeLevel) => incomeLevel.id === value)
          ?.value || ''
      )
    },

    hasBeenLoadedIncomeLevels (state) {
      return state.incomeLevels !== null
    },

    /* Ethnicity */
    ethnicities (state) {
      return state.ethnicities || []
    },

    ethnicityTranslation: (state, getters) => (value) => {
      return (
        getters.ethnicities.find((ethnicity) => ethnicity.id === value)
          ?.value || ''
      )
    },

    hasBeenLoadedEthnicities (state) {
      return state.ethnicities !== null
    },

    /* Gender */
    genders (state) {
      return state.genders || []
    },

    genderTranslation: (state, getters) => (value) => {
      return getters.genders.find((gender) => gender.id === value)?.value || ''
    },

    hasBeenLoadedGenders (state) {
      return state.genders !== null
    },

    /* Smoking Frequency */
    smokingFrequencies (state) {
      return state.smokingFrequencies || []
    },

    smokingFrequencyTranslation: (state, getters) => (value) => {
      return (
        getters.smokingFrequencies.find(
          (smokingFrequency) => smokingFrequency.id === value
        )?.value || ''
      )
    },

    hasBeenLoadedSmokingFrequencies (state) {
      return state.smokingFrequencies !== null
    },

    /* Drinking Frequency */
    drinkingFrequencies (state) {
      return state.drinkingFrequencies || []
    },

    drinkingFrequencyTranslation: (state, getters) => (value) => {
      return (
        getters.drinkingFrequencies.find(
          (drinkingFrequency) => drinkingFrequency.id === value
        )?.value || ''
      )
    },

    hasBeenLoadedDrinkingFrequencies (state) {
      return state.drinkingFrequencies !== null
    },

    /* Children Status */
    childrenStatuses (state) {
      return state.childrenStatuses || []
    },

    childrenStatusTranslation: (state, getters) => (value) => {
      return (
        getters.childrenStatuses.find(
          (childrenStatus) => childrenStatus.id === value
        )?.value || ''
      )
    },

    hasBeenLoadedChildrenStatuses (state) {
      return state.childrenStatuses !== null
    },

    /* Education Level */
    educationLevels (state) {
      return state.educationLevels || []
    },

    educationLevelTranslation: (state, getters) => (value) => {
      return (
        getters.educationLevels.find(
          (educationLevel) => educationLevel.id === value
        )?.value || ''
      )
    },

    hasBeenLoadedEducationLevels (state) {
      return state.educationLevels !== null
    },

    /* Relationship Status */
    relationshipStatuses (state) {
      return state.relationshipStatuses || []
    },

    relationshipStatusTranslation: (state, getters) => (value) => {
      return (
        getters.relationshipStatuses.find(
          (relationshipStatus) => relationshipStatus.id === value
        )?.value || ''
      )
    },

    hasBeenLoadedRelationshipStatuses (state) {
      return state.relationshipStatuses !== null
    },

    /* Body Type */
    bodyTypes (state) {
      return state.bodyTypes || []
    },

    bodyTypeTranslation: (state, getters) => (value) => {
      return (
        getters.bodyTypes.find((bodyType) => bodyType.id === value)?.value || ''
      )
    },

    hasBeenLoadedBodyTypes (state) {
      return state.bodyTypes !== null
    },

    /* Interest */
    interests (state) {
      return state.interests || []
    },

    interestTranslation: (state, getters) => (value) => {
      return (
        getters.interests.find((interest) => interest.id === value)?.value || ''
      )
    },

    hasBeenLoadedInterests (state) {
      return state.interests !== null
    },

    /* Event Role */
    eventRoles (state) {
      return state.eventRoles || []
    },

    eventRoleTranslation: (state, getters) => (value) => {
      return (
        getters.eventRoles.find((eventRole) => eventRole.id === value)?.value ||
        ''
      )
    },

    hasBeenLoadedEventRoles (state) {
      return state.eventRoles !== null
    },

    /* Timezones */
    timezones (state) {
      return state.timezones || []
    },

    timezoneTranslation: (state, getters) => (value) => {
      return (
        getters.timezones.find((timezone) => timezone.id === value)?.value || ''
      )
    },

    hasBeenLoadedTimezones (state) {
      return state.timezones !== null
    },

    /* Categories */
    categories (state) {
      return state.categories || []
    },

    categoryTranslation: (state, getters) => (value) => {
      return (
        getters.categories.find((category) => category.id === value)?.name || ''
      )
    },

    hasBeenLoadedCategories (state) {
      return state.categories !== null
    },

    /* Types */
    types (state) {
      return state.types || []
    },

    typeTranslation: (state, getters) => (value) => {
      return getters.types.find((type) => type.id === value)?.name || ''
    },

    hasBeenLoadedTypes (state) {
      return state.types !== null
    },

    /* Chat Types */
    chatTypes (state) {
      return state.chatTypes || []
    },

    chatTypeTranslation: (state, getters) => (value) => {
      return (
        getters.chatTypes.find((chatType) => chatType.id === value)?.value || ''
      )
    },

    hasBeenLoadedChatTypes (state) {
      return state.chatTypes !== null
    }
  },
  actions: {
    async loadZodiacSigns ({ commit, getters }) {
      if (getters.hasBeenLoadedZodiacSigns) {
        return false
      }

      const {
        data: {
          data: { zodiac_sign: zodiacSignOptions }
        }
      } = await OptionRepository.getZodiacSigns()

      commit('setZodiacSigns', zodiacSignOptions)

      return true
    },

    async loadIncomeLevels ({ commit, getters }) {
      if (getters.hasBeenLoadedIncomeLevels) {
        return false
      }

      const {
        data: {
          data: { income_level: incomeLevelOptions }
        }
      } = await OptionRepository.getIncomeLevels()

      commit('setIncomeLevels', incomeLevelOptions)

      return true
    },

    async loadEthnicities ({ commit, getters }) {
      if (getters.hasBeenLoadedEthnicities) {
        return false
      }

      const {
        data: {
          data: { ethnicity: ethnicityOptions }
        }
      } = await OptionRepository.getEthnicities()

      commit('setEthnicities', ethnicityOptions)

      return true
    },

    async loadGenders ({ commit, getters }) {
      if (getters.hasBeenLoadedGenders) {
        return false
      }

      const {
        data: {
          data: { gender: genderOptions }
        }
      } = await OptionRepository.getGenders()

      commit('setGenders', genderOptions)

      return true
    },

    async loadSmokingFrequencies ({ commit, getters }) {
      if (getters.hasBeenLoadedSmokingFrequencies) {
        return false
      }

      const {
        data: {
          data: { smoking: smokingFrequencyOptions }
        }
      } = await OptionRepository.getSmokingFrequencies()

      commit('setSmokingFrequencies', smokingFrequencyOptions)

      return true
    },

    async loadDrinkingFrequencies ({ commit, getters }) {
      if (getters.hasBeenLoadedDrinkingFrequencies) {
        return false
      }

      const {
        data: {
          data: { drinking: drinkingFrequencyOptions }
        }
      } = await OptionRepository.getDrinkingFrequencies()

      commit('setDrinkingFrequencies', drinkingFrequencyOptions)

      return true
    },

    async loadChildrenStatuses ({ commit, getters }) {
      if (getters.hasBeenLoadedChildrenStatuses) {
        return false
      }

      const {
        data: {
          data: { any_children: childrenOptions }
        }
      } = await OptionRepository.getChildrenOptions()

      commit('setChildrenStatuses', childrenOptions)

      return true
    },

    async loadEducationLevels ({ commit, getters }) {
      if (getters.hasBeenLoadedEducationLevels) {
        return false
      }

      const {
        data: {
          data: { educational_level: educationLevelOptions }
        }
      } = await OptionRepository.getEducationLevels()

      commit('setEducationLevels', educationLevelOptions)

      return true
    },

    async loadRelationshipStatuses ({ commit, getters }) {
      if (getters.hasBeenLoadedRelationshipStatuses) {
        return false
      }

      const {
        data: {
          data: { relationship_status: relationshipStatusOptions }
        }
      } = await OptionRepository.getRelationshipStatuses()

      commit('setRelationshipStatuses', relationshipStatusOptions)

      return true
    },

    async loadBodyTypes ({ commit, getters }) {
      if (getters.hasBeenLoadedBodyTypes) {
        return false
      }

      const {
        data: {
          data: { body_type: bodyTypeOptions }
        }
      } = await OptionRepository.getBodyTypes()

      commit('setBodyTypes', bodyTypeOptions)

      return true
    },

    async loadInterests ({ commit }) {
      // if (getters.hasBeenLoadedInterests) {
      //   return false;
      // }

      const {
        data: {
          data: { interest: interestOptions }
        }
      } = await OptionRepository.getInterests()

      commit('setInterests', interestOptions)

      return true
    },

    async loadEventRoles ({ commit, getters }) {
      if (getters.hasBeenLoadedEventRoles) {
        return false
      }
      const {
        data: { data: eventRoleOptions }
      } = await OptionRepository.getEventRoles()

      commit('setEventRoles', eventRoleOptions)

      return true
    },

    async loadTimezones ({ commit, getters }) {
      if (getters.hasBeenLoadedTimezones) {
        return false
      }
      const {
        data: { data: timezoneOptions }
      } = await OptionRepository.getTimezones()

      commit('setTimezones', timezoneOptions)

      return true
    },

    async loadCategories ({ commit, getters }) {
      if (getters.hasBeenLoadedCategories) {
        return false
      }
      const {
        data: { data: categoryOptions }
      } = await OptionRepository.getCategories()

      commit('setCategories', categoryOptions)

      return true
    },

    async loadTypes ({ commit, getters }) {
      if (getters.hasBeenLoadedTypes) {
        return false
      }
      const {
        data: { data: typeOptions }
      } = await OptionRepository.getTypes()

      commit('setTypes', typeOptions)

      return true
    },

    async loadChatTypes ({ commit, getters }) {
      // if (getters.hasBeenLoadedChatTypes) {
      //   return false;
      // }

      const {
        data: { data: chatTypeOptions }
      } = await OptionRepository.getChatTypes()

      commit('setChatTypes', chatTypeOptions)

      return true
    }
  }
}
