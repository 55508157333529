<template>
  <ce-modal
    ref="modal"
    class="premium-account-alert"
    @close="$emit('cancel')"
  >
    <div class="modal-header bg-yellow text-white">
      <h5 class="modal-title">
        {{
          enablePayment
            ? dialogTitle
            : 'Whoa! I’m happy you’re excited to start communicating!'
        }}
      </h5>
      <button
        type="button"
        class="btn-close"
        data-bs-dismiss="modal"
        aria-label="Close"
      />
    </div>
    <div class="modal-body">
      <div
        class="alert d-block d-sm-flex align-items-center"
        role="alert"
      >
        <ce-icon
          icon="info-circle"
          class="me-2"
        />
        <slot name="message">
          {{
            enablePayment
              ? 'Please upgrade to premium account to access this feature.'
              : 'I’m sorry but we are still in beta testing haven’t launched this feature yet. The ability to send members messages will be provided once we officially launch, which is NLT December 18th, 2021 - Kenyatta Craten'
          }}
        </slot>
      </div>
    </div>
    <div
      v-if="enablePayment"
      class="modal-footer"
    >
      <inertia-link
        href="/premium-upgrade"
        :data="{ redirect: redirectTo }"
        class="btn btn-nero"
        @click.prevent="$emit('upgrade')"
      >
        Upgrade to Premium Account
      </inertia-link>
    </div>
  </ce-modal>
</template>

<script>
import { ref } from 'vue'
import CeModal from '@/components/Modal/CeModal.vue'
import CeIcon from '@/components/UI/CeIcon.vue'

export default {
  name: 'CePremiumAccountAlert',
  components: { CeIcon, CeModal },
  props: {
    redirectTo: {
      type: String,
      required: false,
      default: undefined
    },
    dialogTitle: {
      type: String,
      required: false,
      default: 'Upgrade to Premium Account'
    },
    enablePayment: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  emits: ['upgrade', 'cancel'],
  setup () {
    const modal = ref(null)

    const open = () => {
      modal.value.open()
    }

    return { modal, open }
  }
}
</script>

<style scoped>
.ce-icon {
  font-size: 2rem;
  color: #ccc;
}
</style>
