<template>
  <nav
    v-show="totalPages > 1"
    class="ce-paginations-pagination navigation style-1"
  >
    <ul class="pagination">
      <li class="page-item prev">
        <slot
          name="prev"
          :has-prev-page="hasPrevPage"
          :prev-page="localValue - 1"
        >
          <button
            :disabled="!hasPrevPage"
            aria-label="Previous"
            class="page-link"
            @click.prevent="prev()"
          >
            <span aria-hidden="true"> Prev </span>
          </button>
        </slot>
      </li>
      <li
        v-for="page in pageBullets"
        :key="page"
        :class="{ selected: localValue === page }"
        class="page-item"
      >
        <slot
          name="pageNum"
          :page="page"
        >
          <a
            class="page-link"
            href=""
            @click.prevent="goTo(page)"
          >
            {{ page }}
          </a>
        </slot>
      </li>
      <li class="page-item next">
        <slot
          name="next"
          :has-next-page="hasNextPage"
          :next-page="localValue + 1"
        >
          <button
            :disabled="!hasNextPage"
            aria-label="Next"
            class="page-link"
            @click.prevent="next()"
          >
            <span aria-hidden="true"> Next </span>
          </button>
        </slot>
      </li>
    </ul>
  </nav>
</template>

<script>
import { onMounted, ref, watch } from 'vue'

export default {
  name: 'CePagination',
  props: {
    currentPage: {
      type: Number,
      required: false,
      default: 0
    },
    total: {
      type: Number,
      required: false,
      default: 0
    },
    perPage: {
      type: Number,
      required: false,
      default: 15
    },
    maxBulletPages: {
      type: Number,
      default: 5
    }
  },
  emits: ['update:currentPage'],
  setup (props, { emit }) {
    const localValue = ref(1)

    onMounted(() => {
      localValue.value = props.currentPage
    })

    watch(localValue, (value) => {
      emit('update:currentPage', value)
    })

    return {
      localValue
    }
  },
  computed: {
    totalPages () {
      return Math.ceil(this.total / this.perPage)
    },
    hasPrevPage () {
      return this.localValue > 1
    },
    hasNextPage () {
      return this.localValue < this.totalPages
    },
    pageBullets () {
      const fromPage =
        this.localValue > Math.floor(this.maxBulletPages / 2)
          ? this.localValue - Math.floor(this.maxBulletPages / 2)
          : 1
      const toPage = Math.min(
        this.totalPages + 1,
        fromPage + this.maxBulletPages
      )

      const pages = []
      for (let ctr = fromPage; ctr < toPage; ctr += 1) {
        pages.push(ctr)
      }

      return pages
    }
  },
  methods: {
    prev () {
      if (!this.hasPrevPage) return
      this.localValue -= 1
    },
    next () {
      if (!this.hasNextPage) return
      this.localValue += 1
    },
    goTo (page) {
      this.localValue = page
    }
  }
}
</script>
