<template>
  <ce-button
    type="button"
    :is-loading="isProcessing"
    :disabled="isProcessing"
    @click="handleClick()"
  >
    <slot>Delete</slot>
  </ce-button>
</template>

<script>
import { inject, ref } from 'vue';
import axios from 'axios';
import { GATHERING_TYPES, useDynamicWord } from '@/Use/events.js';
import CeButton from '../Buttons/CeButton.vue';

export default {
    components: { CeButton },
  props: {
    eventId: {
      type: Number,
      default: null,
      required: true,
    },
    slug: {
      type: String,
      default: '',
      required: true,
    },
    type: {
      type: String,
      default: GATHERING_TYPES.EVENT,
    },
  },
  emits: ['deleted', 'failed'],
  setup(props, { emit }) {
    const dialog = inject('dialog');
    const { dynamicWordFor } = useDynamicWord({
      gatheringType: props.type,
    });
    const isProcessing = ref(false);

    const deleteEvent = async () => {
      if (isProcessing.value) {
        return false;
      }

      try {
        isProcessing.value = true;
        await axios.delete(`/api/events/${props.eventId}`);
        emit('deleted');
      } catch (exception) {
        emit('failed', exception);
        if (exception?.response?.status === 403) {
          dialog.show('You are not allowed to do this action.', {
            title: 'Unauthorized',
          });
        } else {
          dialog.show(
            'An unexpected error encountered. Please try again later.',
            {
              title: 'System Error',
            }
          );
        }
      } finally {
        isProcessing.value = false;
      }

      return true;
    };

    const askDeleteConfirmation = () => {
      dialog.show(
        `Are you sure you want to delete this ${dynamicWordFor('event')}?`,
        {
          title: `Delete ${dynamicWordFor('Event')}`,
          buttons: [
            {
              label: 'Yes',
              class: 'btn-gradient-gold',
              handler: (closeDialog) => {
                deleteEvent();
                closeDialog();
              },
            },
            {
              label: 'Cancel',
              class: 'btn-light-gray',
              handler: (closeDialog) => {
                closeDialog();
              },
            },
          ],
        }
      );
    };

    const handleClick = () => {
      askDeleteConfirmation();
    };

    return {
      isProcessing,
      handleClick,
    };
  },
};
</script>
