<template>
  <span
    class="fa"
    :class="iconClasses"
  />
</template>

<script>
import { computed } from 'vue'

export default {
  name: 'CeIcon',
  props: {
    icon: {
      type: String,
      required: true,
      default: ''
    },
    type: {
      type: String,
      required: false,
      default: 'fa'
    }
  },
  setup (props) {
    const iconClass = computed(() => {
      if (props.type === 'icomoon') {
        return `fa-${props.icon}`
      }
      return ''
    })

    const iconClasses = computed(() => {
      const classes = [iconClass.value]
      if (props.type === 'icomoon') {
        classes.push('icon')
      }

      return classes
    })

    return { iconClasses, iconClass }
  }
}
</script>
