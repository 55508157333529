<template>
  <div class="w-100 h-100" :class="{ 'has-image': !!imgSrc }">
    <ce-visual-media-picker
      ref="photoPickerRef"
      class="w-100 h-100 position-relative photo-picker"
      :modal-container="baseLayoutRef"
      :file-types="FILE_TYPES"
      @picked="uploadPhoto($event)"
      @closed="pickerDismissed()"
      @invalid-file-type="promptInvalidFileType()"
    >
      <template #default="{ browseMedia: pickerBrowsePhoto, dropzoneRef }">
        <button
          :ref="dropzoneRef"
          type="button"
          class="btn btn-transparent border-0 p-0 img-upload-btn position-relative w-100 h-100"
          :disabled="isUploadingPhoto"
          @click="pickerBrowsePhoto()"
        >
          <ce-aspect-ratio :aspect-ratio="aspectRatio">
            <ce-image
              :src="imgSrc || placeholder"
              :blur-hash="blurHash"
              class="mw-100 h-100 rounded-2"
              style="object-fit: contain"
              :alt="alt"
              :placeholder="placeholder"
            />
          </ce-aspect-ratio>
          <template v-if="isUploadingPhoto">
            <span class="loading-indicator" />
          </template>
          <template v-else>
            <span class="icon-outline-plus" />
          </template>
        </button>
        <template v-if="!isUploadingPhoto && !nonRemoveable">
          <button
            type="button"
            class="btn p-0 remove-btn"
            @click="removePhoto()"
          >
            <span class="icon-trash" />
          </button>
        </template>
        <span v-if="badgeNumber > 0" class="badge-number p-0">
          {{ badgeNumber }}
        </span>
      </template>
      <template #browse-options="{ chooseFromFile, takeFromCamera }">
        <div class="row p-3">
          <p class="text-muted text-center font-monospace mb-3">
            <slot name="instruction">
              All profile images must, at a minimum, be in good taste, clearly
              display your face, and be an accurate representation of yourself.
              Profiles that do not meet this strict guideline will be subject to
              suspension, deactivation and/or removal.
            </slot>
          </p>
          <div
            class="mb-3 mb-md-0"
            :class="[isMobileDevice ? 'col-md-6' : 'col-md-12']"
          >
            <ce-button
              type="button"
              class="btn-nero w-100"
              @click="chooseFromFile()"
            >
              Add Photo
            </ce-button>
          </div>
          <div v-if="isMobileDevice" class="col-md-6">
            <ce-button
              type="button"
              class="btn-nero w-100"
              @click="takeFromCamera()"
            >
              Take From Device
            </ce-button>
          </div>
        </div>
      </template>
    </ce-visual-media-picker>
    <teleport :to="baseLayoutRef">
      <template v-if="croppable">
        <photo-editor-modal
          ref="photoEditorModalRef"
          :aspect-ratio="aspectRatio"
          :view-mode="cropperViewMode"
          :cropper-options="cropperOptions"
          @finished="displayEditedPhoto($event)"
        >
          <template #afterCropper>
            <slot name="afterCropper" />
          </template>
        </photo-editor-modal>
      </template>
    </teleport>
  </div>
</template>

<script>
import { defineAsyncComponent, inject, ref, watch } from "vue";
import MediaRepository from "@/Repositories/media.js";
import { FILE_TYPES } from "@/Use/use-photo.js";
import { isMobileDevice } from "@/Use/use-user-agent.js";

export default {
  components: {
    PhotoEditorModal: defineAsyncComponent(() =>
      import("@/components/Modal/PhotoEditorModal.vue")
    ),
  },
  props: {
    modelValue: {
      type: Number,
      default: null,
    },
    src: {
      type: String,
      default: null,
    },
    placeholder: {
      type: String,
      default:
        process.env.VUE_APP_API_BASE + "/images/defaults/cover-photo.png",
    },
    editable: {
      type: Boolean,
      default: true,
    },
    isUploading: {
      type: Boolean,
      default: false,
    },
    croppable: {
      type: Boolean,
      default: false,
    },
    nonRemoveable: {
      type: Boolean,
      default: false,
    },
    aspectRatio: {
      type: Number,
      required: false,
      default: 1,
    },
    cropperOptions: {
      type: Object,
      required: false,
      default: () => ({}),
    },
    cropperViewMode: {
      type: Number,
      required: false,
      default: 3,
    },
    alt: {
      type: String,
      default: null,
    },
    upload: {
      type: File,
      default: undefined,
    },
    blurHash: {
      type: Object,
      default: undefined,
    },
    thumbnailSize: {
      type: String,
      default: "",
    },
    badgeNumber: {
      type: Number,
      default: 0,
    },
  },
  emits: [
    "update:modelValue",
    "update:isUploading",
    "update:src",
    "finished",
    "removed",
  ],
  setup(props, { emit }) {
    const baseLayoutRef = inject("baseLayoutRef");
    const dialog = inject("dialog");
    const localValue = ref(null);
    const imgSrc = ref(null);
    const uploadedPhotoId = ref(null);
    const isUploadingPhoto = ref(false);
    const photoEditorModalRef = ref(null);
    const photoPickerRef = ref(null);

    /* Methods */
    const removePhoto = () => {
      localValue.value = null;
      imgSrc.value = null;
      uploadedPhotoId.value = null;
      emit("removed");
    };

    const loadImgSrc = async () => {
      if (localValue.value === null) {
        return false;
      }
      const {
        data: { data: media },
      } = await MediaRepository.get(localValue.value);

      if (media) {
        imgSrc.value =
          media.sizes?.[props.thumbnailSize] ||
          media.modified ||
          media.original;
      }

      imgSrc.value = imgSrc.value;

      return true;
    };

    const browsePhoto = () => {
      photoPickerRef.value.browseMedia();
    };

    const displayEditedPhoto = (photoSrc) => {
      localValue.value = uploadedPhotoId.value;
      imgSrc.value = photoSrc;
      photoPickerRef.value.browseMode = null;
      // photoPickerRef.value.browsePhoto();

      emit("finished", { id: localValue.value, src: imgSrc.value });
    };

    const uploadPhoto = async (photo) => {
      try {
        isUploadingPhoto.value = true;
        const {
          data: { data: media },
        } = await MediaRepository.upload(photo);
        isUploadingPhoto.value = false;
        uploadedPhotoId.value = media.id;
        if (props.croppable) {
          photoEditorModalRef.value.open({
            id: media.id,
            src: media.original,
          });
        } else {
          displayEditedPhoto(media.original);
        }
      } catch (e) {
        dialog.show(
          e.response?.data.message || "Sorry, problem with the upload",
          {
            title: "Uploads",
          }
        );
        isUploadingPhoto.value = false;
      }
    };

    const pickerDismissed = () => {
      // console.log('pickerDismissed');
    };

    const promptInvalidFileType = () => {
      dialog.show("Please select a valid image file type.", {
        title: "Invalid File Type",
      });
    };

    // const displayEditedPhoto = ({ id, src }) => {
    //   localValue.value = id;
    //   imgSrc.value = src;
    // };

    /* Watchers */
    watch(localValue, (newLocalValue) => {
      emit("update:modelValue", newLocalValue);
    });

    watch(
      () => props.modelValue,
      (newModelValue) => {
        localValue.value = newModelValue;
        loadImgSrc();
      },
      {
        immediate: true,
      }
    );

    watch(imgSrc, (newImgSrc) => {
      emit("update:src", newImgSrc);
    });

    watch(
      () => props.src,
      (newSrc) => {
        imgSrc.value = newSrc;
      },
      {
        immediate: true,
      }
    );

    watch(isUploadingPhoto, (newIsUploadingPhoto) => {
      emit("update:isUploading", newIsUploadingPhoto);
    });

    watch(
      () => props.isUploading,
      (newIsUploading) => {
        isUploadingPhoto.value = newIsUploading;
      },
      {
        immediate: true,
      }
    );

    /* Lifecycles */
    if (props.upload) {
      uploadPhoto(props.upload);
    }

    return {
      localValue,
      loadImgSrc,
      imgSrc,
      removePhoto,
      browsePhoto,
      uploadPhoto,
      isUploadingPhoto,
      photoEditorModalRef,
      displayEditedPhoto,
      photoPickerRef,
      isMobileDevice,
      pickerDismissed,
      baseLayoutRef,
      promptInvalidFileType,
      FILE_TYPES,
    };
  },
};
</script>

<style lang="scss" scoped>
@import "../../scss/customizations";

.remove-btn {
  display: none;
}

.photo-picker {
  &:hover .remove-btn {
    background: #e7e7e7;
    border-radius: 50%;
    box-shadow: 0 4px 10px rgba(#212121, 0.25);
    color: #212121;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 32px;
    height: 32px;
    position: absolute;
    top: 0;
    right: 0;
    font-size: 1rem;
    z-index: 2;
  }
}

.img-upload-btn {
  position: relative;

  > .icon-outline-plus {
    display: none;
  }

  &:hover {
    &::before {
      content: " ";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: #000;
      opacity: 0.5;
      z-index: 1;
    }

    > .icon-outline-plus {
      @include absolute-center;

      display: block;
      color: #fff;
      font-size: 2rem;
      z-index: 1;
    }
  }
  .loading-indicator {
    @include loading-spinner;
    @include absolute-center;
  }
}

.badge-number {
  position: absolute;
  right: 0;
  background-color: rgb(180, 145, 77);
  height: 30px;
  width: 30px;
  border-radius: 15px;
  margin: 2px;
  color: white;
  font-size: 1em;
  line-height: 1.8em;
  text-align: center;
}
</style>
