<template>
  <div
    v-if="!isDeleted"
    :class="levelClass"
  >
    <inertia-link :href="`/${authorUsername}`">
      <ce-avatar
        :src="avatar"
        :alt="name"
        :square="true"
        :size="size"
        class="my-1"
      />
    </inertia-link>
    <div class="d-flex flex-column flex-grow-1">
      <template v-if="mode === 'reply'">
        <div>
          <ce-textarea
            v-model="comment"
            class="ms-2"
          />
          <form-text
            name="comment"
            :errors="formErrors"
          />
        </div>
        <div class="d-flex">
          <div class="flex-fill">
            <div
              ref="attachmentsRef"
              class="attachments mx-n1"
            />
            <visual-media-field
              ref="visualMediaFieldRef"
              v-model="attachmentMediaIds"
              v-model:is-uploading="isUploadingAttachments"
              :attachments-container="attachmentsRef"
              additional-description="Maximum 10 photos per post"
              maximum-images="10"
              multiple
              photo
              video
              :disabled="attachmentMediaIds.length === 10"
            />
          </div>
          <div class="text-end">
            <ce-button
              class="btn-sm btn-green text-white ms-auto mt-1"
              :disabled="isUploadingAttachments"
              @click="sendReply()"
            >
              Send
            </ce-button>
          </div>
        </div>
      </template>
      <template v-else>
        <div class="border p-2 mx-2 my-1 rounded bg-white-smoke">
          <inertia-link
            class="btn btn-transparent p-0 font-weight-bold"
            :href="`/${authorUsername}`"
          >
            {{ name }}
          </inertia-link>
          <!--          <span class="title font-weight-bold">{{ name }}</span>-->
          <template v-if="!isEditing">
            <p>
              {{ comment }}
              <span class="text-muted">{{ isEdited ? '(Edited)' : '' }}</span>
            </p>
          </template>
          <template v-else>
            <ce-textarea
              v-model="comment"
              class="ms-2"
            />
            <form-text
              name="comment"
              :errors="formErrors"
            />
          </template>
        </div>
        <!-- show attachments -->
        <wall-post-attachments
          v-if="attachments && attachments.length > 0"
          :items="attachments"
          :cells="3"
        />
      </template>

      <div class="comment-footer d-flex flex-grow-1 mx-2">
        <span>{{ timestampDisplay }}</span>
        <a
          v-if="!isEditing && isAllowedToPost && allowReply && mode !== 'reply'"
          class="unlink mx-2"
          href="#"
          @click.prevent="toggleReply()"
        >{{ isReplying ? 'Cancel' : 'Reply' }}</a>
        <a
          v-if="
            isAllowedToPost && isAllowedToEdit && canEdit && mode !== 'reply'
          "
          class="unlink mx-2"
          href="#"
          @click.prevent="toggleEdit()"
        >{{ isEditing ? 'Cancel' : 'Edit' }}</a>
        <div class="ms-auto">
          <ce-button
            v-if="isEditing"
            class="btn-sm btn-green text-white ms-auto mt-1"
            @click="sendUpdate()"
          >
            Update
          </ce-button>
          <ce-button
            v-if="isEditing"
            class="btn-sm btn-red text-white ms-2 mt-1"
            @click="deleteComment()"
          >
            Delete
          </ce-button>
        </div>
        <span
          v-if="!isAllowedToPost && mode !== 'reply' && canReply"
          class="mx-2"
        >
          You don't have permissions to comment on this post.
        </span>
        <div v-if="level === 1 && isAllowedToPost && showShare">
          <ce-button
            class="btn-sm btn-gold text-white ms-auto my-2"
            @click="share()"
          >
            <span class="fas fa-share-nodes me-2 text-white" />Share
          </ce-button>
        </div>
      </div>
      <comment
        v-if="isReplying"
        mode="reply"
        :replying="isReplying"
        class="mx-2"
        :type="type"
        :entity-id="entityId"
        :discussion-id="discussionId"
        :parent-id="commentId"
        :super-parent-id="superParentId"
        :avatar="$store.getters['user/primaryPhotoSrc']"
        :author-id="authorId"
        :show-share="showShare"
        @new-comment-created="newCommentCreated"
      />

      <comment
        v-for="reply in replies"
        :key="reply.id"
        :comment-id="reply.id"
        :level="2"
        :name="reply.posted_by.first_name + ' ' + reply.posted_by.last_name"
        :message="reply.comment"
        :avatar="reply.posted_by.primary_photo.modified"
        :type="type"
        :parent-id="commentId"
        :super-parent-id="superParentId"
        :discussion-id="discussionId"
        :entity-id="entityId"
        :is-allowed-to-post="isAllowedToPost"
        :timestamp="reply.updated_at"
        :author-id="reply.posted_by.id"
        :created-at="reply.posted_by.created_at"
        :attachments="reply.attachments"
      />
    </div>
  </div>
</template>

<script>
import { ref, computed, inject } from 'vue'
import { useStore } from 'vuex'
import CeAvatar from '@/components/UI/CeAvatar.vue'
import axios from 'axios'
import filters from '@/Filters/index.js'
import CeTextarea from '../Inputs/CeTextarea.vue'
import FormText from '@/components/Forms/FormText.vue'
import VisualMediaField from '@/components/Inputs/VisualMediaField.vue'
import WallPostAttachments from '@/Modules/Wall/WallPostAttachments.vue'

export default {
  components: {
    CeAvatar,
    CeTextarea,
    FormText,
    VisualMediaField,
    WallPostAttachments
  },
  props: {
    avatar: {
      type: String,
      required: false,
      default:
        'axioss://ypfs3.s3.us-west-2.amazonaws.com/photos/NDzakueyXTeKG9crkuoCuv2gqUK4TOp6FSuymo7a.jpg'
    },
    name: {
      type: String,
      required: false,
      default: 'Sender'
    },
    authorId: {
      type: Number,
      required: true
    },
    authorUsername: {
      type: String,
      required: true
    },
    message: {
      type: String,
      required: false,
      default: ''
    },
    type: {
      type: String,
      required: true
    },
    commentId: {
      type: Number,
      required: false,
      default: 0
    },
    superParentId: {
      type: Number,
      required: false,
      default: null
    },
    parentId: {
      type: Number,
      required: false,
      default: null
    },
    entityId: {
      type: Number,
      required: true
    },
    discussionId: {
      type: Number,
      required: true
    },
    level: {
      type: Number,
      required: false,
      default: 1
    },
    replying: {
      type: Boolean,
      required: false,
      default: false
    },
    mode: {
      type: String,
      required: false,
      default: 'display'
    },
    timestamp: {
      type: String,
      required: false,
      default: ''
    },
    isAllowedToPost: {
      type: Boolean,
      required: false,
      default: false
    },
    isAllowedToEdit: {
      type: Boolean,
      required: false,
      default: true
    },
    createdAt: {
      type: String,
      required: false,
      default: ''
    },
    showShare: {
      type: Boolean,
      default: true
    },
    canReply: {
      type: Boolean,
      default: true
    },
    attachments: {
      type: Array,
      default: () => [],
      required: false
    }
  },
  emits: ['newCommentCreated'],
  setup (props, context) {
    const store = useStore()
    const { relativeTime } = filters
    const formErrors = ref([])
    const size =
      props.level === 1 || props.mode === 'reply'
        ? {
            height: 55,
            width: 55
          }
        : {
            height: 30,
            width: 30
          }

    const levelClass =
      props.level === 1 ? 'd-flex mb-3 pb-1' : 'd-flex mb-3 pb-1 level-2'

    const isReplying = ref(false)

    const isEditing = ref(false)
    const isEdited = ref(
      props.createdAt !== '' && props.timestamp !== props.createdAt
    )

    const originalComment = ref(props.message)
    const comment = ref(props.message)
    const dialog = inject('dialog')
    const attachmentMediaIds = ref([])
    const isUploadingAttachments = ref(false)
    const attachmentsRef = ref(null)

    const toggleReply = () => {
      isReplying.value = !isReplying.value
    }

    const toggleEdit = () => {
      if (isEditing.value) {
        comment.value = originalComment.value
      }
      isEditing.value = !isEditing.value
      // comment.value = isEditing.value ? comment.value : '';
    }

    const sharePost = async () => {
      await axios.post(
        `api/${props.type}s/${props.entityId}/discussions/share`,
        {
          entityId: props.entityId,
          discussionId: props.discussionId
        }
      )
    }

    const share = () => {
      dialog.show('Are you sure?', {
        title: 'You want to share this on your wall',
        buttons: [
          {
            label: 'Yes',
            class: 'btn-gradient-gold',
            handler: (closeDialog) => {
              sharePost()
              closeDialog()
            }
          },
          {
            label: 'Cancel',
            class: 'btn-outline-gold'
          }
        ]
      })
    }

    const replies = ref([])

    const auth = computed(() => store.state.user.authUser)
    const userId = auth.value.id

    const canEdit = props.authorId === userId

    const timestampDisplay = ref(relativeTime(props.timestamp))

    const isDeleted = ref(false)

    // this allows the user to click on the reply button
    const allowReply = ref(
      !(
        props.superParentId &&
        props.parentId &&
        props.commentId &&
        props.superParentId !== props.parentId &&
        props.commentId !== props.parentId
      )
    )

    const sendReply = () => {
      // if (comment.value.length === 0) return;
      // WIP
      const url = !props.parentId
        ? `api/${props.type}s/${props.entityId}/discussions/${props.discussionId}/comments`
        : `api/${props.type}s/${props.entityId}/discussions/${props.discussionId}/comments?parent_id=${props.parentId}`

      formErrors.value = []
      axios
        .post(url, {
          comment: comment.value,
          attachments: attachmentMediaIds.value
        })
        .then((res) => {
          comment.value = ''
          context.emit('newCommentCreated', res.data.data)
        })
        .catch((err) => {
          if (err.response?.data?.error) {
            formErrors.value = err.response.data.error
          }
          /* eslint-disable no-console */
          /* eslint-enable no-console */
        })
    }

    const sendUpdate = () => {
      if (comment.value.length === 0) return

      const url = `api/${props.type}s/${props.entityId}/discussions/${props.discussionId}/comments/${props.commentId}`

      formErrors.value = []
      axios
        .post(url, {
          comment: comment.value,
          _method: 'PATCH'
        })
        .then((res) => {
          originalComment.value = comment.value
          isEditing.value = false
          isEdited.value = true
          timestampDisplay.value = relativeTime(res.data.data.updated_at)
        })
        .catch((err) => {
          if (err.response.data?.error) {
            formErrors.value = err.response.data.error
          }
          /* eslint-disable no-console */
          console.error(err)
          /* eslint-enable no-console */
        })
    }

    const deleteComment = () => {
      const url = `api/${props.type}s/${props.entityId}/discussions/${props.discussionId}/comments/${props.commentId}`

      axios
        .delete(url)
        .then(() => {
          isEditing.value = false
          isEdited.value = true
          isDeleted.value = true
        })
        .catch((err) => {
          /* eslint-disable no-console */
          console.error(err)
          /* eslint-enable no-console */
        })
    }

    const newCommentCreated = (data) => {
      if (!data) return
      isReplying.value = false
      // console.log('newCommentCreated', data);
      if (!data.parentId) {
        // append to the 1st level comments
        context.emit('newCommentCreated', data)
      } else {
        // append to the child comment
        replies.value.unshift(data)
      }
    }

    const fetchReplies = () => {
      if (!props.commentId) return

      const url = `${props.type}s/${props.entityId}/discussions/${props.discussionId}/comments?parent_id=${props.commentId}`
      axios
        .get(url)
        .then((res) => {
          replies.value = res.data.data
        })
        .catch((err) => {
          /* eslint-disable no-console */
          console.error(err)
          /* eslint-enable no-console */
        })
    }

    fetchReplies()

    return {
      size,
      levelClass,
      isReplying,
      isEditing,
      toggleReply,
      toggleEdit,
      sendReply,
      sendUpdate,
      deleteComment,
      comment,
      replies,
      newCommentCreated,
      fetchReplies,
      allowReply,
      relativeTime,
      userId,
      canEdit,
      timestampDisplay,
      isEdited,
      isDeleted,
      formErrors,
      share,
      attachmentMediaIds,
      isUploadingAttachments,
      attachmentsRef
    }
  }
}
</script>

<style lang="scss" scoped>

.title {
  font-weight: 700;
}
.unlink {
  text-decoration: none;
}
.level-2 {
  margin-left: 62px !important;
}
@media only screen and (max-width: 600px) {
  .level-2 {
    margin-left: 10px !important;
  }
}
.comment-footer {
  font-size: 13px;
}

.attachments {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;

  > :deep(div) {
    margin: 0.75rem 0;
    width: 100px !important;
    height: 100px !important;
  }

  > :deep(div img) {
    background-color: #FEFEFE;
  }
}

:deep(.attachments-grid) {
  display: grid;
  grid-template-columns: auto auto;
  gap: 4px;
}
</style>
