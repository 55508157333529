import moment from 'moment'

const relativeTime = (value, options) => {
  const { empty } = options || {}

  const dateTime = moment(value)
  if (!value || !dateTime.isValid()) {
    return empty
  }

  const date = dateTime.clone().startOf('day')

  if (date.isSameOrAfter(moment().startOf('day'))) {
    // same as current day
    return dateTime.format('h:mm a')
  }
  if (date.isSameOrAfter(moment().subtract(1, 'week'))) {
    // current week
    return dateTime.format('ddd h:mm a')
  }
  if (date.isSameOrAfter(moment().subtract(1, 'year'))) {
    // Same year
    return dateTime.format('MMM DD')
  }
  return dateTime.format('MMM YYYY')
}

const calendarTime = (value) => {
  const dateTime = moment(value)

  if (!value || !dateTime.isValid()) {
    return ''
  }

  const date = dateTime.clone().startOf('day')

  if (date.isSameOrAfter(moment().startOf('day'))) {
    // same as current day
    return dateTime.format('h:mm a')
  }

  return dateTime.format('MMM DD h:mm a')
}

export default {
  relativeTime,
  calendarTime
}
